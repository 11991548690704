import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { API_URL } from '../../global/constants';

const DeleteJobDialog = ({
	open,
	setOpen,
	jobId,
	setJobId,
	jobRole,
	setJobRole,
	setJobPostings,
}) => {
	const [loading, setLoading] = useState(false);
	const handleClose = () => {
		setOpen(false);
		setJobId('');
		setJobRole('');
	};
	const deleteJob = async () => {
		setLoading(true);
		const { data } = await axios.post(
			`${API_URL}/labs/delete-job-posting`,
			{
				job_id: jobId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			}
		);
		setLoading(false);
		if (data.success) {
			toast.success(data.message);
			setJobPostings((jobPostings) =>
				jobPostings.filter((jobPosting) => jobPosting.id !== jobId)
			);
			handleClose();
		} else {
			toast.error(data.message);
		}
	};
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle id="alert-dialog-title">
				{`Are you sure you want to delete ${jobRole} job?`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					This will delete the job and all the interviews related to it.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} disabled={loading}>
					Cancel
				</Button>
				<Button onClick={deleteJob} disabled={loading || !jobId}>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteJobDialog;
