import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import exitinterview from '../images/exitinterview.png';
import './style.css';
import CallIcon from '@mui/icons-material/Call';
import one from '../images/one.png';
import two from '../images/two.png';
import three from '../images/three.png';
import four from '../images/four.png';

const ExitInterviews = () => {
	return (
		<Stack display="flex" justifyContent={'center'} alignItems={'center'} pt={3} pb={3}>
			<Stack textAlign={'center'} alignItems={'center'}>
				<Typography
					fontFamily={'Poppins'}
					fontWeight={'700'}
					fontSize={{ sm: '3rem', xs: '2rem' }}
					color={'#224C84'}
					textAlign={'center'}
					p={1}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					Unbiased and actionable
					<br /> exit interviews
				</Typography>
				<Typography
					color={'#224C84'}
					textAlign={'center'}
					fontSize={{ sm: '1.3rem', xs: '1.2rem' }}
					data-aos="fade-up"
					data-aos-duration="1200"
					data-aos-delay="300"
				>
					Gain insights from departing crew member{' '}
				</Typography>
			</Stack>
			<Stack direction={{ sm: 'row', xs: 'column-reverse' }}>
				<Stack justifyContent={'center'} alignItems={'center'} spacing={2} p={2}>
					<Stack
						direction={{ sm: 'row', xs: 'column' }}
						justifyContent={'space-between'}
						spacing={2}
					>
						<Stack
							direction={'row'}
							width={'300px'}
							spacing={1}
							data-aos="zoom-in"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<img alt="one" src={one} width={'30px'} height={'40px'} />
							<Typography
								fontFamily={'Poppins'}
								fontSize={'1.2rem'}
								color={'#224C84'}
							>
								Define custom interview structure
							</Typography>
						</Stack>
						<Stack
							direction={'row'}
							width={'300px'}
							spacing={1}
							data-aos="zoom-in"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<img alt="two" src={two} width={'30px'} height={'40px'} />
							<Typography
								fontFamily={'Poppins'}
								fontSize={'1.2rem'}
								color={'#224C84'}
							>
								Translates subjective feedback into objective data points
							</Typography>
						</Stack>
					</Stack>
					<Stack
						direction={{ sm: 'row', xs: 'column' }}
						justifyContent={'space-between'}
						spacing={2}
					>
						<Stack
							direction={'row'}
							width={'300px'}
							spacing={1}
							data-aos="zoom-in"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<img alt="three" src={three} width={'30px'} height={'40px'} />
							<Typography
								fontFamily={'Poppins'}
								fontSize={'1.2rem'}
								color={'#224C84'}
							>
								Instant sentiment analysis report
							</Typography>
						</Stack>
						<Stack
							direction={'row'}
							width={'300px'}
							spacing={1}
							data-aos="zoom-in"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<img alt="four" src={four} width={'30px'} height={'40px'} />
							<Typography
								fontFamily={'Poppins'}
								fontSize={'1.2rem'}
								color={'#224C84'}
							>
								Combined insights from all exit interviews
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				<Stack
					alignItems={{ xs: 'center', sm: 'start' }}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					<img
						src={exitinterview}
						alt="exit-interview"
						className="exit-interview"
						width={'300px'}
					/>
				</Stack>
			</Stack>
			<Button
				variant="contained"
				size="large"
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
				onClick={() => (window.location.href = 'https://meetings.hubspot.com/nrohatgi')}
				startIcon={<CallIcon fontSize="large" />}
				style={{
					backgroundColor: '#224C84',
					fontFamily: 'Poppins',
					fontSize: '1.2rem',
					textTransform: 'none',
					borderRadius: '15px',
				}}
			>
				Book a Demo
			</Button>
		</Stack>
	);
};

export default ExitInterviews;
