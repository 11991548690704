import CameraIcon from '@mui/icons-material/Camera';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MicIcon from '@mui/icons-material/Mic';
import MicNoneIcon from '@mui/icons-material/MicNone';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import axios from 'axios';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Modal,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	Tooltip,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useNavigate, useLocation } from 'react-router';
import '../../../node_modules/react-internet-meter/dist/index.css';
import { Header } from '../shared/Header';
import CameraCheck from './CameraCheck';
import InternetSpeed from './InternetSpeed';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { isTouchDevice } from '../../global/Utils';
import { API_URL, TEST_AUDIO_URL } from '../../global/constants';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectInterviewData, setInterviewDetails } from '../../features/interview/interview';
import { PROCTORING_TOOL } from '../../global/constants';
export const REACT_APP_ENV = process.env.REACT_APP_ENV;

const gdmOptions = {
	video: {
		displaySurface: 'monitor',
	},
	audio: {
		echoCancellation: true,
		noiseSuppression: true,
		sampleRate: 44100,
		suppressLocalAudioPlayback: false,
	},
	surfaceSwitching: 'exclude',
	selfBrowserSurface: 'exclude',
	systemAudio: 'exclude',
	monitorTypeSurfaces: 'include',
};

// import test_speaker from "";

const EnvironmentCheck = () => {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const interview_key = params.interview_key;
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [cameraPermission, setCameraPermission] = useState(false);
	const [micPermission, setMicPermission] = useState(false);
	const [micWorking, setMicWorking] = useState(false);
	const [isAudioRecording, setIsAudioRecording] = useState(false);
	const [micTryAgain, setMicTryAgain] = useState(false);
	const [micTestLoading, setMicTestLoading] = useState(false);
	const [Whisperneeded, setWhisperneeded] = useState(false);
	const [micTested, setMicTested] = useState(false);
	const [selfAudioPlaying, setSelfAudioPlaying] = useState(false);
	const [selfAudio, setSelfAudio] = useState(null);
	const [speakerWorking, setSpeakerWorking] = useState(false);
	const [speakerTested, setSpeakerTested] = useState(false);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [internetSpeedIsGood, setInternetSpeedIsGood] = useState(false);
	const [startSpeedTest, setStartSpeedTest] = useState(false);
	const [cameraConditionsOk, setCameraConditionsOk] = useState(false);
	const [open, setOpen] = useState(false);
	const [tryAgain, setTryAgain] = useState(false);

	const { finalTranscript, transcript, browserSupportsSpeechRecognition, isMicrophoneAvailable } =
		useSpeechRecognition();
	const interview_data = useSelector(selectInterviewData);

	const { startRecording, stopRecording, recordingBlob } = useAudioRecorder();

	const transcribe = async (recordingBlob) => {
		console.log(recordingBlob);
		setMicTestLoading(true);
		const key = `audio-test.mp3`;
		var file = new File([recordingBlob], key, { type: 'audio/mpeg' });
		const formData = new FormData();
		formData.append('file', file);
		console.log(file);
		let response = await axios.post(`${API_URL}/labs/check/audiototext`, formData);
		const transcript = response?.data?.text;
		let words = transcript.split(' ');
		if (words.length > 3) {
			setTryAgain(false);
			setMicWorking(true);
		} else {
			setMicTryAgain(true);
		}
		setMicTestLoading(false);
		console.log('transcript', transcript);
	};

	useEffect(() => {
		setWhisperneeded(isTouchDevice());
		if (!browserSupportsSpeechRecognition) {
			setWhisperneeded(true);
		}
		if (!isMicrophoneAvailable) {
			setWhisperneeded(true);
		}
	}, [browserSupportsSpeechRecognition, isMicrophoneAvailable]);

	useEffect(() => {
		if (!recordingBlob) return;
		transcribe(recordingBlob);
	}, [recordingBlob]);

	const generateCandidateDetails = async (candidateCode) => {
		setIsLoading(true);
		try {
			let result = '';
			if (sessionStorage.getItem('mock-interview')) {
				result = await axios.get(`${API_URL}/labs/mock-interview/${candidateCode}`);
			} else {
				result = await axios.get(`${API_URL}/labs/interview/${candidateCode}`);
			}

			if (result?.data?.error) {
				setErrorMessage(result?.data?.message);
				setIsLoading(false);
				return null;
			}
			const { candidate_interview, fullName } = result.data;
			const interview_data = { candidate_interview, fullName };
			console.log('generateCandidateDetails--', interview_data);
			if (candidate_interview.proctoring_tool === PROCTORING_TOOL) {
				const jwtpayload = {
					// Your payload here
				};
				const response = await axios.post(`${API_URL}/proctoring/token`, jwtpayload);
				interview_data.proctoring_token = response.data.token;
			}

			dispatch(setInterviewDetails(interview_data));
			return interview_data;
		} catch (error) {
			console.error('Error generating candidate details:', error);
			setErrorMessage('Failed to load interview data');
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	const generateCandidateDetailsCall = useCallback(async () => {
		let interviewData;
		if (state.interviewKey) {
			interviewData = await generateCandidateDetails(state.interviewKey);
		} else {
			interviewData = await generateCandidateDetails(interview_key);
		}
		if (!interviewData) return;
		dispatch(setInterviewDetails(interviewData));
	}, [dispatch, interview_key]);

	useEffect(() => {
		if (state && state.interviewKey) {
			generateCandidateDetailsCall();
		}
	}, [generateCandidateDetailsCall]);

	useEffect(() => {
		if (!interview_data) return;

		if (!interview_data.isDemo) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(() => setCameraPermission(true))
				.catch(() => setCameraPermission(false));
		}

		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(() => setMicPermission(true))
			.catch(() => setMicPermission(false));
	}, [interview_data]);

	useEffect(() => {
		let words = transcript.split(' ');
		console.log('transcript :', transcript);
		if (words.length > 3) {
			setIsAudioRecording(false);
			SpeechRecognition.stopListening();
			setTryAgain(false);
			setMicWorking(true);
		}
		setMicTestLoading(false);
	}, [transcript]);

	const handleClose = () => setOpen(false);
	const handleOpen = () => setOpen(true);

	const startMobileAudioRecording = () => {
		startRecording();
		setIsAudioRecording(true);
	};

	const stopMobileAudioRecording = () => {
		setIsAudioRecording(false);
		stopRecording();
	};

	const startAudioRecording = () => {
		setIsAudioRecording(true);
		SpeechRecognition.startListening();
	};

	const stopAudioRecording = () => {
		setIsAudioRecording(false);
		SpeechRecognition.stopListening();
		isvoicedetected(finalTranscript);
	};

	const grantCameraPermission = () => {
		navigator.mediaDevices
			.getUserMedia({ video: true })
			.then((stream) => {
				console.log(stream);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const grantMicPermission = () => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => {
				console.log(stream);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const isvoicedetected = (transcript) => {
		console.log(`called isvoicedetected with transcript :${transcript}`);
		setMicTestLoading(true);
		let words = transcript.split(' ');
		if (words.length > 3) {
			setTryAgain(false);
			setMicWorking(true);
			setMicTryAgain(false);
		} else {
			setMicTryAgain(true);
		}
		setMicTestLoading(false);
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		borderRadius: '10px',
		boxShadow: 24,
		p: 2,
		justifyContent: 'center',
		alignItems: 'center',
	};

	const testAudio = new Audio(TEST_AUDIO_URL);

	if (!browserSupportsSpeechRecognition || !isMicrophoneAvailable) {
		return <span>Browser doesn't support speech recognition.</span>;
	}

	if (!interview_data || Object.keys(interview_data).length === 0) {
		return <div>No interview data available.</div>;
	}

	const { fullName, isDemo, isStudentInterview, candidate_interview } = interview_data;
	const { _id: interviewKey, is_coding_test_included } = candidate_interview;

	const startInterview = () => {
		const state = { interviewKey, fullName, isStudentInterview, isDemo };

		if (is_coding_test_included) {
			navigate(`/coding-test/${fullName}/${interviewKey}`, { state });
		} else if (isStudentInterview) {
			navigate(`/interview`, { state: { ...state, isStudentInterview: true } });
		} else if (isDemo) {
			navigate(`/demo/${fullName}/${interviewKey}`, { state });
		} else {
			navigate(`/interview`);
		}
	};

	const shouldStartInterview = isDemo
		? micPermission && speakerWorking && micWorking && internetSpeedIsGood
		: cameraPermission &&
			micPermission &&
			speakerWorking &&
			micWorking &&
			internetSpeedIsGood &&
			cameraConditionsOk;
	return (
		<Stack bg={'#ECF7FA'} justifyContent={'center'} alignItems={'center'}>
			<Header fullName={fullName} />
			<Stack mt={'2vh'} p={3} bgcolor={'#ECF7FA'} width={'fit-content'}>
				<Typography fontWeight={'bold'} fontSize={'1.5rem'}>
					How to succeed in your AI-HYR Interview?
				</Typography>
				<ul>
					<li>Speak clearly into the microphone.</li>
					<li>Sit in a quiet and well-lit room.</li>
					<li>
						In case of any technical issues, please contact us at{' '}
						<a href="mailto:interview@aihyr.com">interview@aihyr.com</a>
					</li>
					<li>Make sure you have consistent high-speed Internet connectivity.</li>
				</ul>
				<Typography fontWeight={'bold'} fontSize={'1.5rem'}>
					Environment Check
				</Typography>
				<Stack spacing={2} mt={2}>
					{!isDemo && (
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							spacing={{ sm: 3, xs: 1 }}
							alignItems={{ sm: 'center', xs: 'start' }}
						>
							<Typography
								fontSize="1.2rem"
								fontWeight={'bold'}
								display={'flex'}
								alignItems={'center'}
							>
								{cameraConditionsOk ? (
									<CheckCircleOutlineIcon
										color="success"
										style={{ margin: '5px' }}
									/>
								) : (
									<CancelIcon color="error" style={{ margin: '5px' }} />
								)}
								Camera
							</Typography>
							{!cameraPermission && !isDemo && (
								<Button
									variant="outlined"
									startIcon={<CameraIcon />}
									size="small"
									style={{ height: 'fit-content' }}
									onClick={grantCameraPermission}
								>
									Grant Permission
								</Button>
							)}
							<Modal open={open} onClose={handleClose}>
								<Box sx={style}>
									<Stack spacing={2}>
										{/* <Webcam
                                        height={"500px"}
                                        mirrored={true}
                                        style={{ alignSelf: "center" }}
                                    /> */}
										<CameraCheck
											setCameraConditionsOk={setCameraConditionsOk}
										/>
										<Button variant="outlined" onClick={handleClose}>
											Close Camera
										</Button>
									</Stack>
								</Box>
							</Modal>
							{cameraPermission && (
								<Button
									variant="outlined"
									startIcon={<CameraAltIcon />}
									size="small"
									style={{ height: 'fit-content' }}
									onClick={handleOpen}
									disabled={cameraConditionsOk}
								>
									Check Conditions
								</Button>
							)}
						</Stack>
					)}
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ sm: 3, xs: 1 }}
						alignItems={{ sm: 'center', xs: 'start' }}
					>
						<Typography
							fontSize="1.2rem"
							fontWeight={'bold'}
							display={'flex'}
							alignItems={'center'}
						>
							{speakerWorking ? (
								<CheckCircleOutlineIcon color="success" style={{ margin: '5px' }} />
							) : (
								<CancelIcon color="error" style={{ margin: '5px' }} />
							)}
							Speaker
						</Typography>

						<Button
							size="small"
							disabled={audioPlaying || speakerWorking}
							startIcon={<VolumeUpIcon />}
							variant="outlined"
							style={{ height: 'fit-content' }}
							onClick={() => {
								testAudio.play();
								setAudioPlaying(true);
								testAudio.addEventListener('ended', () => {
									setSpeakerTested(true);
									setAudioPlaying(false);
								});
								// if (testAudio.played) {
								//     setSpeakerTested(true);
								// }
							}}
						>
							{audioPlaying
								? 'Playing...'
								: tryAgain
									? 'Try Again'
									: speakerWorking
										? 'Speaker Working'
										: 'Test Speaker'}
						</Button>
						{speakerTested && !audioPlaying && (
							<>
								{/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={speakerWorking}
                                            onChange={() =>
                                                setSpeakerWorking(true)
                                            }
                                        />
                                    }
                                    label="Did you hear the audio?"
                                /> */}
								<Typography>Did you hear the audio?</Typography>
								<RadioGroup row>
									<FormControlLabel
										disabled={speakerWorking}
										onClick={() => {
											setSpeakerWorking(true);
											setTryAgain(false);
										}}
										value={'yes'}
										control={<Radio />}
										label="Yes"
									/>
									<FormControlLabel
										disabled={speakerWorking}
										onClick={() => {
											!speakerWorking && setTryAgain(true);
										}}
										value={'no'}
										control={<Radio />}
										label="No"
									/>
								</RadioGroup>
								{/* {tryAgain && <Typography>Try Again</Typography>} */}
							</>
						)}
					</Stack>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ sm: 3, xs: 1 }}
						alignItems={{ sm: 'center', xs: 'start' }}
					>
						<Typography
							fontSize="1.2rem"
							fontWeight={'bold'}
							display={'flex'}
							alignItems={'center'}
						>
							{micWorking ? (
								<CheckCircleOutlineIcon color="success" style={{ margin: '5px' }} />
							) : (
								<CancelIcon color="error" style={{ margin: '5px' }} />
							)}
							Microphone
						</Typography>
						{!micPermission && (
							<Button
								variant="outlined"
								startIcon={<MicIcon />}
								size="small"
								style={{ height: 'fit-content' }}
								onClick={grantMicPermission}
							>
								Grant Permission
							</Button>
						)}
						{micPermission && (
							<Button
								onClick={() => {
									if (Whisperneeded) {
										startMobileAudioRecording();
									} else {
										startAudioRecording();
									}
								}}
								startIcon={<MicNoneIcon />}
								disabled={isAudioRecording || micWorking || micTestLoading}
								size="small"
								variant="outlined"
								style={{ height: 'fit-content' }}
							>
								{isAudioRecording
									? 'Listening...'
									: micTestLoading
										? 'Testing...'
										: micTryAgain
											? 'Try Again'
											: micWorking
												? 'Microphone Working'
												: 'Test Microphone'}
							</Button>
						)}
						{isAudioRecording && (
							<Typography>
								Please speak this sentence in your microphone -{' '}
								<p style={{ fontSize: '700', color: 'green' }}>
									"I am excited for the interview"
								</p>
							</Typography>
						)}
						{micPermission && isAudioRecording && (
							<Button
								onClick={() => {
									if (Whisperneeded) {
										stopMobileAudioRecording();
									} else {
										stopAudioRecording();
									}
								}}
								disabled={micWorking}
								startIcon={<MicNoneIcon />}
								size="small"
								variant="outlined"
								style={{ height: 'fit-content' }}
							>
								Stop Recording
							</Button>
						)}
						{selfAudio && (
							<Button
								size="small"
								startIcon={<VolumeUpIcon />}
								disabled={selfAudioPlaying || micWorking}
								variant="outlined"
								style={{ height: 'fit-content' }}
								onClick={() => {
									selfAudio.play();
									setSelfAudioPlaying(true);
									selfAudio.addEventListener('ended', () => {
										setMicTested(true);
										setSelfAudioPlaying(false);
									});
								}}
							>
								{selfAudioPlaying ? 'Listening...' : 'Listen Recording'}
							</Button>
						)}
						{micPermission && selfAudio && micTested && (
							<FormControlLabel
								control={
									<Checkbox
										checked={micWorking}
										onChange={() => setMicWorking(true)}
									/>
								}
								label="Did you hear your recording?"
							/>
						)}
					</Stack>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ sm: 3, xs: 1 }}
						alignItems={{ sm: 'center', xs: 'start' }}
					>
						<Typography
							fontSize="1.2rem"
							fontWeight={'bold'}
							display={'flex'}
							alignItems={'center'}
						>
							{internetSpeedIsGood ? (
								<CheckCircleOutlineIcon color="success" style={{ margin: '5px' }} />
							) : (
								<CancelIcon color="error" style={{ margin: '5px' }} />
							)}
							Internet Speed
						</Typography>

						{/* {startSpeedTest && !internetSpeedIsGood && (
                            
                        )} */}

						<InternetSpeed
							startSpeedTest={startSpeedTest}
							setStartSpeedTest={setStartSpeedTest}
							setInternetSpeedIsGood={setInternetSpeedIsGood}
							internetSpeedIsGood={internetSpeedIsGood}
						/>
					</Stack>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ sm: 3, xs: 1 }}
						alignItems={{ sm: 'center', xs: 'start' }}
					></Stack>
				</Stack>
			</Stack>
			<Stack
				justifyContent={'center'}
				alignItems={'center'}
				p={2}
				spacing={2}
				bgcolor={'#ECF7FA'}
			>
				{shouldStartInterview && (
					<>
						<Typography fontStyle={'italic'} textAlign={'center'}>
							Everything looks good, You are ready to start the interview🥳🎉
						</Typography>
						<Typography fontStyle={'italic'} textAlign={'center'}>
							👇🏻Click on the button below to start the interview👇🏻
						</Typography>
					</>
				)}

				<Button
					disabled={!shouldStartInterview}
					alignSelf="center"
					variant="contained"
					style={{ width: 'fit-content' }}
					onClick={startInterview}
				>
					Start Interview
				</Button>

				{(REACT_APP_ENV === 'stagingenv' || !REACT_APP_ENV) && (
					<Tooltip title="This is the QA environment" arrow>
						<span>
							<Button
								disabled={shouldStartInterview}
								alignSelf="center"
								variant="contained"
								style={{ width: 'fit-content' }}
								onClick={startInterview}
							>
								Start Interview
							</Button>
						</span>
					</Tooltip>
				)}
			</Stack>
			{/* {isScreenSharingEnabled && <Screenshare interviewKey={interviewKey} isStudentInterview={isStudentInterview} />} */}
		</Stack>
	);
};

export default EnvironmentCheck;
