import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const ReminderEmailDialog = ({
	reminderMailDialogOpen,
	handleReminderMailDialogClose,
	sendReminderMail,
	sendReminderMailLoading,
	jobId,
	templateId,
	setTemplateId,
}) => {
	return (
		<Dialog open={reminderMailDialogOpen}>
			<DialogTitle>Send Reminder Mail</DialogTitle>
			<DialogContent dividers>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Enter Template ID"
					type="text"
					fullWidth
					onChange={(event) => setTemplateId(event.target.value)}
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleReminderMailDialogClose} disabled={sendReminderMailLoading}>
					Close
				</Button>
				<Button
					onClick={sendReminderMail}
					disabled={sendReminderMailLoading || !templateId || !jobId}
				>
					Send Mail
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReminderEmailDialog;
