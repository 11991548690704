import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SubmitCodeDialog = ({ open, setOpen, handleSubmitCode }) => {
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{'Are you sure you want to submit the code?'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					This will submit the code for this question. After this, you will not be able to
					edit the code for this question.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					onClick={() => {
						handleSubmitCode();
						handleClose();
					}}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SubmitCodeDialog;
