import React from 'react';
import { Button, Stack } from '@mui/material';
import telephone from '../images/telephone.png';
import footer from '../images/footer.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from 'react-router';

const Footer = () => {
	const navigate = useNavigate();

	return (
		<div>
			<Stack
				height={'30vh'}
				direction={{ sm: 'row', xs: 'column-reverse' }}
				alignItems={{ sm: 'end', xs: 'start' }}
				justifyContent={'end'}
				style={{
					backgroundImage: `url(${footer})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
			>
				<Stack
					width={{ sm: '50%', xs: '100%' }}
					direction={'row'}
					pl={{ sm: 2 }}
					justifyContent={{ xs: 'center', sm: 'start' }}
				>
					<Button
						// variant="contained"
						onClick={() => navigate('/terms-of-use')}
						style={{
							// backgroundColor: "#224C84",
							color: '#fff',
							fontFamily: 'Poppins',
							fontSize: '1.2rem',
							textTransform: 'none',
							// borderRadius: "15px",
						}}
					>
						Terms of use
					</Button>
					<Button
						onClick={() => navigate('/privacy-policy')}
						style={{
							// backgroundColor: "#224C84",
							color: '#fff',
							fontFamily: 'Poppins',
							fontSize: '1.2rem',
							textTransform: 'none',
							// borderRadius: "15px",
						}}
					>
						Privacy Policy
					</Button>
				</Stack>
				<Stack
					direction={'row'}
					width={{ sm: '50%', xs: '100%' }}
					justifyContent={{ xs: 'center', sm: 'start', lg: 'end' }}
					mr={{ lg: 10 }}
				>
					<Button
						// variant="contained"
						size="large"
						onClick={() =>
							window.open('https://twitter.com/aihyr_official', '_blank', 'noopener')
						}
						style={{
							// backgroundColor: "#D2ECF6",
							color: '#ffff',
							textTransform: 'none',
							fontWeight: '600',
						}}
					>
						<TwitterIcon
							color={'#fff'}
							style={{
								fontSize: '3rem',
								padding: 0,
							}}
						/>
					</Button>
					<Button
						// variant="contained"
						size="large"
						onClick={() =>
							window.open(
								'https://www.linkedin.com/company/aihyr/',
								'_blank',
								'noopener'
							)
						}
						style={{
							// backgroundColor: "#D2ECF6",
							color: '#ffff',
							textTransform: 'none',
							fontWeight: '600',
						}}
					>
						<LinkedInIcon
							color={'#fff'}
							style={{
								fontSize: '3rem',
								padding: 0,
							}}
						/>
					</Button>
				</Stack>
			</Stack>
		</div>
	);
};

export default Footer;
