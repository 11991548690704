import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WifiIcon from '@mui/icons-material/Wifi';

const InternetSpeed = ({
	startSpeedTest,
	setStartSpeedTest,
	setInternetSpeedIsGood,
	internetSpeedIsGood,
}) => {
	const [thresholdUnit, setThresholdUnit] = useState('megabyte');
	const imageUrl = 'https://aihyr.com/static/media/blue-logo.a20a294b38051fc9dd55.png';
	const downloadSize = '10452'; //bytes
	const [threshold] = useState(3);
	const [pingInterval] = useState(1000);
	const [isNetworkDown, setIsNetworkDown] = useState(false);
	// const [startSpeedTest, setStartSpeedTest] = useState(false);
	const [speed, setSpeed] = useState(0);
	const callbackFunctionOnNetworkDown = (speed) => {
		setSpeed(speed * 8);
		console.log('Network Down', speed * 8 + ' MBPS');
	};
	const callbackFunctionOnNetworkTest = (speed) => {
		setSpeed(speed * 8);
		console.log('Network Test', speed * 8 + ' MBPS');
	};
	const callbackFunctionOnError = (err) => {
		console.log('Error', err);
	};

	window.addEventListener('offline', () => setIsNetworkDown(true));
	window.addEventListener('online', () => setIsNetworkDown(false));

	const MeasureConnectionSpeed = () => {
		var startTime, endTime;
		var download = new Image();
		startTime = new Date().getTime();

		var cacheBuster = '?nnn=' + startTime;
		download.src = imageUrl + cacheBuster;

		download.onload = function (d) {
			endTime = new Date().getTime();
			showResults(startTime, endTime);
		};

		download.onerror = function (err, msg) {
			console.log('err', err);
			callbackFunctionOnError(err);
		};
		setStartSpeedTest(true);
	};

	const showResults = (startTime, endTime) => {
		const duration = (endTime - startTime) / 1000;

		const bitsLoaded = downloadSize * 8;
		const speedBps = (bitsLoaded / duration).toFixed(2);
		const speedKbps = (speedBps / 1024).toFixed(2);
		const speedMbps = (speedKbps / 1024).toFixed(2);

		setNetworStatus(speedBps, speedKbps, speedMbps);
	};

	const setNetworStatus = (speedBps, speedKbps, speedMbps) => {
		if (thresholdUnit === 'byte') {
			if (speedBps < threshold) {
				setIsNetworkDown(true);
				callbackFunctionOnNetworkDown(speedBps);
			} else {
				setIsNetworkDown(false);
			}
			callbackFunctionOnNetworkTest(speedBps);
		} else if (thresholdUnit === 'kilobyte') {
			if (speedKbps < threshold) {
				setIsNetworkDown(true);
				callbackFunctionOnNetworkDown(speedKbps);
			} else {
				setIsNetworkDown(false);
			}
			callbackFunctionOnNetworkTest(speedKbps);
		} else if (thresholdUnit === 'megabyte') {
			if (speedMbps < threshold) {
				setIsNetworkDown(true);
				callbackFunctionOnNetworkDown(speedMbps);
			} else {
				setIsNetworkDown(false);
			}
			callbackFunctionOnNetworkTest(speedMbps);
		} else {
			console.log('Invalid thresholdUnit');
		}
	};

	useEffect(() => {
		if (startSpeedTest) {
			const interval = setInterval(() => {
				MeasureConnectionSpeed();
			}, pingInterval);
			if (speed > threshold) {
				clearInterval(interval);
				setInternetSpeedIsGood(true);
				// setStartSpeedTest(false);
			}
			return () => clearInterval(interval);
		}
	});

	return (
		<>
			<Button
				startIcon={<WifiIcon />}
				disabled={startSpeedTest || internetSpeedIsGood}
				onClick={() => {
					setInternetSpeedIsGood(false);
					setStartSpeedTest(true);
				}}
				size="small"
				variant="outlined"
				style={{ height: 'fit-content' }}
			>
				{!startSpeedTest
					? 'Test Speed'
					: internetSpeedIsGood
						? 'Speed is Good'
						: 'Testing Speed'}
			</Button>
			{!internetSpeedIsGood && <Typography>{speed} Mbps</Typography>}
		</>
	);
};

export default InternetSpeed;
