import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Button } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getCompletiontime } from '../../helpers/utils';
import { useNavigate } from 'react-router';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#004792',
		color: 'white',
		fontSize: '14px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	'& > td': {
		borderBottom: 'none',
	},
	'& > th': {
		borderBottom: 'none',
	},
}));

function createData(attempt_no, score, time, interview_id) {
	return { attempt_no, score, time, interview_id };
}

// const rows = [
// 	createData(1, 15, 12.45, 24),
// 	createData(2, 23, 7.89, 37),
// 	createData(3, 26, 7.45, 24),
// ];

export default function StudentTable({ all_interviews }) {
	const [rows, setrows] = React.useState([]);

	React.useEffect(() => {
		const newrows = all_interviews.map((interview) =>
			createData(
				interview['attemptno'],
				interview['overall_score'],
				getCompletiontime(
					interview['time_spent'],
					interview['createdAt'],
					interview['updatedAt']
				),
				interview['_id']
			)
		);

		setrows(newrows);
	}, [all_interviews]);

	const navigate = useNavigate();
	return (
		<TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
			<Table sx={{ minWidth: 650 }} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell>
							<Typography sx={{ margin: 'auto' }}>Attempt No</Typography>
						</StyledTableCell>
						<StyledTableCell>Score</StyledTableCell>
						<StyledTableCell>
							<Typography sx={{}} align="center" alignItems={'center'}>
								Completion time <br /> (MM:HH)
							</Typography>
						</StyledTableCell>
						<StyledTableCell sx={{ margin: 'auto' }}>
							<Typography sx={{ margin: 'auto' }}>Report </Typography>
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row['interview_id']}>
							<StyledTableCell component="th" scope="row">
								<Typography sx={{ margin: 'auto' }}>{row['attempt_no']}</Typography>
							</StyledTableCell>
							<StyledTableCell>
								<div
									style={{
										width: '50px',
										height: '50px',
									}}
								>
									<CircularProgressbar
										strokeWidth={12}
										value={
											row['score'] && row['score'] !== 'NaN'
												? parseInt(row['score'])
												: 0
										}
										text={`${row['score'] ? parseInt(row['score']) : 0}%`}
										styles={buildStyles({
											textSize: '20px',
											textColor: '#004aad',
											pathColor: '#004aad',
										})}
									/>
								</div>
							</StyledTableCell>
							<StyledTableCell>
								<Typography sx={{ margin: 'auto' }}>{row['time']}</Typography>
							</StyledTableCell>
							<StyledTableCell align="right">
								<Button
									variant="outlined"
									size="small"
									style={{
										color: '#224C84',
										textTransform: 'none',
										width: '130px',
									}}
									startIcon={<VisibilityIcon />}
									onClick={() => {
										navigate(`/collegestudentReport/${row['interview_id']}`);
									}}
								>
									View Report
									{/* </a> */}
								</Button>
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
