import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { Header } from '../shared/Header';
import CsvDownloadButton from 'react-json-to-csv';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import { useParams } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function IncompleteInterviews() {
	const columns = [
		{ id: 'interview_key', label: 'Interview Key', minWidth: 170 },
		{ id: 'name', label: 'Name', minWidth: 170 },
		{ id: 'email', label: 'Email', minWidth: 170 },
		{ id: 'contact', label: 'Contact', minWidth: 170 },
		{
			id: 'completed_questions',
			label: 'Completed Questions',
			minWidth: 170,
		},
	];
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [loading, setLoading] = React.useState(false);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const [data, setData] = React.useState([]);
	const { job_id, type } = useParams();
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [filteredData, setFilteredData] = React.useState([]);

	const [filter, setFilter] = React.useState('name');
	const [searchBarName, setSearchBarName] = React.useState('Name');
	const handleChange = (event) => {
		setFilter(event.target.value);
	};
	const filters = [
		// { id: 'interview_key', label: 'Interview Key',  },
		{ id: 'name', label: 'Name' },
		{ id: 'email', label: 'Email' },
		{ id: 'contact', label: 'Contact' },
	];
	const requestSearch = (searchedVal) => {
		const filteredRows = data.filter((row) => {
			return row[filter].toLowerCase().includes(searchedVal.toLowerCase());
		});
		setFilteredData(filteredRows);
	};
	React.useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const { data } = await axios.get(
				`${API_URL}/labs/job/interviews/incomplete/${job_id}/${type}`,
				{
					headers: {
						authorization: `Authorization ${
							JSON.parse(sessionStorage.getItem('admin')).token
						}`,
					},
				}
			);
			if (data.success) {
				toast.success(data.message);
				setData(data.data.incompleted_interviews);
				console.log(data.data.incompleted_interviews);
				setFilteredData(data.data.incompleted_interviews);
			} else {
				setData([]);
				toast.error(data.message);
			}
			setLoading(false);
		};
		getData();
	}, []);
	return (
		<>
			<Toaster />
			{!loading && <Header />}
			{loading && <CircularStatic />}
			{!loading && (
				<Stack p={2}>
					<Stack
						direction="row"
						display="flex"
						justifyContent="space-between"
						// pl={2}
					>
						<Typography variant="h5" fontWeight="bold">
							All Incompleted Interviews
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						width={'100%'}
						// spacing={2}
						mb={2}
						mt={2}
					>
						<TextField
							size="small"
							fullWidth
							variant="outlined"
							onChange={(e) => requestSearch(e.target.value)}
							//   placeholder={`Search using ${searchBarName.toLowerCase()}...`}
							placeholder={`Search...`}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="start">
										<Typography
											borderRadius={'3px'}
											textAlign={'center'}
											// width={'8rem'}
											fontWeight={'bold'}
											color={'blue'}
											sx={{
												background: 'rgb(0, 0, 255, 0.1)',
											}}
											pl={1}
											fontSize={'12px'}
											mr={'-18.5px'}
										>
											{searchBarName}
											<Tooltip title="Choose Filter">
												<IconButton
													p={0}
													size="small"
													onClick={handleClick}
													aria-controls={
														open ? 'account-menu' : undefined
													}
													aria-haspopup="true"
													aria-expanded={open ? 'true' : undefined}
												>
													<FilterListIcon
														fontSize="small"
														sx={{
															color: 'blue',
														}}
													/>
												</IconButton>
											</Tooltip>
										</Typography>
									</InputAdornment>
								),
							}}
						/>

						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							{filters.map((col, i) => {
								return (
									<MenuItem value={col.id} onClick={() => setFilter(col.id)}>
										{col.label}
									</MenuItem>
								);
							})}
						</Menu>
						<Tooltip title="Download Candidate Data">
							<CsvDownloadButton
								className="csv-download-button"
								data={data}
								delimiter=","
								filename={`incomplete-interviews-${job_id}.csv`}
							>
								<DownloadIcon />
							</CsvDownloadButton>
						</Tooltip>
					</Stack>
					<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={'start'}
												style={{
													minWidth: column.minWidth,
													fontWeight: 'bold',
													fontSize: '1.1rem',
												}}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredData
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={row.code}
												>
													{columns.map((column) => {
														const value = row[column.id];
														return (
															<TableCell
																key={column.id}
																align={column.align}
															>
																{column.format &&
																typeof value === 'number'
																	? column.format(value)
																	: value}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={filteredData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</Stack>
			)}
		</>
	);
}
