import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { Stack, Typography } from '@mui/material';
import VideosList from '../CandidateReport/videosList';
import { Header } from '../shared/Header';
import { FaCircleArrowLeft, FaCircleArrowRight } from 'react-icons/fa6';

const theme = createTheme({
	palette: {
		neutral: {
			main: '#cccccc',
		},
		excellent: {
			main: '#66bb6a',
		},
		good: {
			main: '#c0eb34',
		},
		belowavg: {
			main: '#ffd500',
		},
		poor: {
			main: '#eb3434',
		},
	},
});
const AdminMockInterviewReport = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [reportData, setReportData] = useState();
	const [overallScorePercentage, setOverallScorePercentage] = useState();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [role, setRole] = useState('');
	const params = useParams();
	const location = useLocation();
	const filteredData = location.state?.filteredData;
	console.log(filteredData);
	const navigate = useNavigate();
	const [questionVideos, setQuestionVideos] = useState([]);

	const [weightage, setWeightage] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [skills, setSkills] = useState([]);
	const [candidateInterviewVideo, setCandidateInterviewVideo] = useState('');
	const { key } = params;

	const [authorized, setAuthorized] = useState(true);
	// const { admin, setAdmin } = useAdminContext();

	const currentIndex = filteredData?.findIndex((item) => item._id === key);
	// const previousIndex = currentIndex > 0 ? currentIndex - 1 : null;
	let previousIndex = null;
	for (let i = currentIndex - 1; i >= 0; i--) {
		if (filteredData[i].has_interview_ended) {
			previousIndex = i;
			break;
		}
	}
	// const nextIndex = currentIndex < filteredData?.length - 1 ? currentIndex + 1 : null;
	let nextIndex = null;
	for (let i = currentIndex + 1; i < filteredData?.length; i++) {
		if (filteredData[i].has_interview_ended) {
			nextIndex = i;
			break;
		}
	}

	const previousInterviewKey = previousIndex !== null ? filteredData[previousIndex]._id : null;
	const nextInterviewKey = nextIndex !== null ? filteredData[nextIndex]._id : null;

	const handlePreviousClick = () => {
		if (previousInterviewKey) {
			navigate(`/mock-interview/report/${previousInterviewKey}`, { state: { filteredData } });
		}
	};

	const handleNextClick = () => {
		if (nextInterviewKey) {
			navigate(`/mock-interview/report/${nextInterviewKey}`, { state: { filteredData } });
		}
	};

	useEffect(() => {
		const getReportData = async () => {
			setIsLoading(true);
			const response = await axios.get(`${API_URL}/labs/admin/mock-interview/report/${key}`);
			console.log(response.data.data);
			if (response.data.status === 401) {
				console.log('Unauthorized');
				setAuthorized(false);
				setIsLoading(false);
				return;
			}
			console.log(response.data);
			setFirstName(response.data.data.first_name);
			setLastName(response.data.data.last_name);
			setEmail(response.data.data.contact);
			setContact(response.data.data.phone_number);
			setRole(response.data.data.role);
			setQuestions(response.data.data.questions);
			setOverallScorePercentage(parseInt(response.data.data.overall_score));
			setCandidateInterviewVideo(response.data.data?.candidate_interview_video);
			setReportData(response.data.data.interview_details.slice(0, -1));
			setQuestionVideos(response.data.data.interview.question_videos);
			setIsLoading(false);
		};

		if (key) {
			getReportData();
		}
	}, [key]);
	const getPathColor = (value) => {
		if (value > 75) {
			return '#66bb6a';
		} else if (value >= 50 && value <= 75) {
			console.log('returnring #ceoffefefe');
			return '#c0eb34';
		} else if (value >= 25 && value < 50) {
			return '#ffd500';
		} else {
			return '#eb3434';
		}
	};

	const getLinearProgressColor = (value) => {
		return value >= 75 ? 'success' : value >= 50 && value < 75 ? 'warning' : 'error';
	};
	const getRatingTag = (rating) => {
		if (rating === 1) {
			return (
				<Typography
					width={'200px'}
					fontWeight={'bold'}
					color={'#ffffff'}
					p={1}
					bgcolor={'#eb3434'}
					borderRadius={'15px'}
					textAlign={'center'}
				>
					Needs Improvement
				</Typography>
			);
		}
		if (rating === 2) {
			return (
				<Typography
					width={'200px'}
					fontWeight={'bold'}
					color={'#ffffff'}
					p={1}
					bgcolor={'#ffd500'}
					borderRadius={'15px'}
					textAlign={'center'}
				>
					Fair
				</Typography>
			);
		}
		if (rating === 3) {
			return (
				<Typography
					width={'200px'}
					fontWeight={'bold'}
					p={1}
					color={'#ffffff'}
					bgcolor={'#c0eb34'}
					borderRadius={'15px'}
					textAlign={'center'}
				>
					Good
				</Typography>
			);
		}
		if (rating === 4) {
			return (
				<Typography
					width={'200px'}
					fontWeight={'bold'}
					p={1}
					bgcolor={'#66bb6a'}
					borderRadius={'15px'}
					textAlign={'center'}
				>
					Excellent
				</Typography>
			);
		}
	};

	return (
		<Stack
			// display={"flex"}
			justifyContent={'center'}
			alignItems={'center'}
			bgcolor={'#ECF7FA'}
			// height={"auto"}
		>
			<Header role={role} />
			{isLoading && <CircularStatic />}
			{!authorized && !isLoading && (
				<Stack
					maxWidth={'800px'}
					// bgcolor={"#fff"}
					height={'auto'}
					p={3}
				>
					<Typography fontSize={'1.2rem'} color={'#000'} fontWeight={'bold'}>
						You Don't have access to view this report.
					</Typography>
				</Stack>
			)}
			{!isLoading && (
				<Stack
					maxWidth={'1300px'}
					// bgcolor={'#fff'}
					height={'auto'}
					direction="row"
					justifyContent="space-around"
					alignItems="top"
					p={0.4}
				>
					<Typography
						variant="h4"
						fontWeight="bold"
						style={{
							cursor: previousInterviewKey ? 'pointer' : 'default',
							color: previousInterviewKey ? '#004aad' : 'gray',
							margin: '5px',
						}}
						onClick={handlePreviousClick}
						disabled={previousInterviewKey === null}
					>
						<FaCircleArrowLeft />
					</Typography>
					<Stack maxWidth={'800px'} bgcolor={'#fff'} height={'auto'}>
						{/* Header */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography
								fontSize={'1.2rem'}
								color={'#fff'}
								fontWeight={'bold'}
							>{`Round 1: Interview report for ${
								firstName + ' ' + lastName
							}`}</Typography>
							<Typography
								fontSize={'1.2rem'}
								color={'#fff'}
								fontWeight={'bold'}
							>{`Role : ${role}`}</Typography>
						</Stack>
						{/* Candidate Information */}
						<Stack
							direction={'row'}
							display={'flex'}
							justifyContent={{ sm: 'center' }}
							alignItems={{ sm: 'center' }}
							p={3}
						>
							<Stack
								alignItems={{ sm: 'start', xs: 'center' }}
								width={{ sm: '50%', xs: 'fit-content' }}
								pl={{ sm: 5, xs: 0 }}
							>
								<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
									Name:{' '}
								</Typography>
								<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
									Email:{' '}
								</Typography>
								<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
									Phone:{' '}
								</Typography>
							</Stack>
							<Stack width={'50%'}>
								<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
									{firstName + ' ' + lastName}
								</Typography>
								<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
									{email}
								</Typography>
								<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
									{contact}
								</Typography>
							</Stack>
						</Stack>
						{/* Recommendation */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
								Performance Summary
							</Typography>
						</Stack>
						<Stack
							direction={{ sm: 'row', xs: 'column' }}
							s={{ width: '100%' }}
							spacing={2}
							justifyContent="center"
							alignItems="center"
							p={2}
						>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={overallScorePercentage < 25 ? 'poor' : 'neutral'}
										sx={{
											width: '180px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Needs Improvement
								</Typography>
							</Stack>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={
											overallScorePercentage >= 25 &&
											overallScorePercentage < 50
												? 'belowavg'
												: 'neutral'
										}
										sx={{
											width: '180px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									fair
								</Typography>
							</Stack>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={
											overallScorePercentage >= 50 &&
											overallScorePercentage < 75
												? 'good'
												: 'neutral'
										}
										sx={{
											width: '180px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Good
								</Typography>
							</Stack>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={
											overallScorePercentage >= 75 ? 'excellent' : 'neutral'
										}
										sx={{
											width: '180px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Excellent
								</Typography>
							</Stack>
						</Stack>
						{/* Performance Summary */}
						{/* <Stack bgcolor={"#004aad"} p={1} spacing={1}>
                        <Typography
                            fontSize={"1.2rem"}
                            color={"#fff"}
                            fontWeight={"bold"}
                        >
                            Performance Summary
                        </Typography>
                    </Stack> */}
						<Stack
							direction={{ sm: 'row', xs: 'column' }}
							sx={{ width: '100%' }}
							spacing={3}
							p={2}
							justifyContent="center"
							alignItems="center"
						>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Overall Score
								</Typography>
								<div
									style={{ width: '150px', height: '150px' }}
									justifyContent="center"
									alignItems="center"
								>
									<CircularProgressbar
										strokeWidth={12}
										value={overallScorePercentage}
										text={`${
											overallScorePercentage
												? `${overallScorePercentage} %`
												: 'NA'
										}`}
										styles={buildStyles({
											textSize: '16px',
											textColor: getPathColor(overallScorePercentage),
											pathColor: getPathColor(overallScorePercentage),
										})}
									/>
								</div>
							</Stack>

							<div className="performance-legend">
								<div className="legendItem">
									<div className="success" />
									<Typography fontWeight={'bold'} color={'#004aad'}>
										Excellent
									</Typography>
								</div>
								<div className="legendItem">
									<div className="warning" />
									<Typography pt={1} pb={1} fontWeight={'bold'} color={'#004aad'}>
										Good
									</Typography>
								</div>
								<div className="legendItem">
									<div className="fair" />
									<Typography pt={1} pb={1} fontWeight={'bold'} color={'#004aad'}>
										Fair
									</Typography>
								</div>
								<div className="legendItem">
									<div className="error" />
									<Typography fontWeight={'bold'} color={'#004aad'}>
										Needs Improvement
									</Typography>
								</div>
							</div>
						</Stack>
						{skills.length !== 0 &&
							skills.map((skill) => {
								return (
									<>
										<Stack bgcolor={'#004aad'} p={1} spacing={1}>
											<Typography
												fontSize={'1.2rem'}
												color={'#fff'}
												fontWeight={'bold'}
											>
												{skill.skill}
											</Typography>
										</Stack>
										{skill.sub_skills.map((sub_skill) => {
											const candidate_total = sub_skill.candidate_total;
											const total = sub_skill.total;
											const scaledScore = (candidate_total / total) * 100;
											const rating = (candidate_total / total) * 5;
											const pathColor = getLinearProgressColor(scaledScore);
											return (
												<Stack
													direction={{
														sm: 'row',
														xs: 'column',
													}}
													justifyContent="center"
													alignItems="center"
													spacing={2}
													p={2}
												>
													<Stack
														sx={{ width: '300px' }}
														justifyContent={{
															sm: 'start',
															xs: 'center',
														}}
														alignItems={{
															sm: 'start',
															xs: 'center',
														}}
													>
														<Typography
															fontWeight={'bold'}
															color={'#004aad'}
															textAlign={{
																sm: 'start',
																xs: 'center',
															}}
														>
															{sub_skill.sub_skill}
														</Typography>
													</Stack>
													<ThemeProvider theme={theme}>
														<LinearProgress
															variant="determinate"
															color={pathColor}
															value={scaledScore}
															sx={{
																width: '200px',
																height: '20px',
																borderRadius: '28px',
																backgroundColor: '#cccccc',
															}}
														/>
													</ThemeProvider>
													<Rating
														name="half-rating-read"
														defaultValue={parseInt(rating)}
														max={5}
														precision={0.1}
														readOnly
													/>
												</Stack>
											);
										})}
									</>
								);
							})}

						{/* Weightage */}

						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
								Below are the rating definitions on all the answer evaluations.
							</Typography>
						</Stack>
						<Stack p={1} spacing={1}>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								1 - Needs Improvement
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								2 - Fair
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								3 - Good
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								4 - Excellent
							</Typography>
							{/* <Typography color={"#004AAD"} fontWeight={"bold"}>
                            5 - Significantly strong answer
                        </Typography> */}
						</Stack>
						{/* Candidate's Answer and Detailed Evaluation */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
								Candidate's Answers & Detailed Evaluation{' '}
							</Typography>
						</Stack>
						<Stack>
							{/* For Other Questions */}

							{reportData &&
								reportData.length > 0 &&
								reportData.map((question) => {
									const questionText = questions.find(
										(q) => q.question_id == question.question_id
									);
									return (
										!question.is_last_question && (
											<Stack m={2}>
												<Typography
													// m={2}
													// mb={0}
													fontWeight={900}
													fontSize={'1.3rem'}
													color={'#004AAD'}
												>
													<u>{question.skill}</u>
												</Typography>
												<Typography
													// m={2}
													// mb={0}
													// fontWeight={900}
													fontSize={'1rem'}
													color={'#A9A9A9'}
												>
													{question.sub_skill?.join()}
												</Typography>
												<Stack spacing={1}>
													<Typography
														color="#005aad"
														fontSize={'1.1rem'}
														fontWeight={'bold'}
													>
														Question : {+question.question_id + 1}
													</Typography>
													<Typography>
														{questionText.question_text}
													</Typography>
													<Stack
														direction={{
															sm: 'row',
															xs: 'column',
														}}
														justifyContent={'space-between'}
														alignItems={{
															sm: 'center',
															xs: 'start',
														}}
													>
														<Typography
															color="#005aad"
															fontSize={'1.1rem'}
															fontWeight={'bold'}
														>
															Candidate Response
														</Typography>
														<Typography>
															{getRatingTag(question.rating)}
														</Typography>
													</Stack>
													<>
														<audio controls>
															<source
																src={question.answer_audio_link}
																type="audio/mpeg"
															/>
															Your browser does not support the audio
															tag.
														</audio>
													</>
													<Typography>{question.answer}</Typography>
													{question.translatedAnswer && (
														<Typography>
															{question.translatedAnswer}
														</Typography>
													)}
													{question.skippableQuestionRes && (
														<Typography
															color="#005aad"
															fontSize={'1.1rem'}
															fontWeight={'bold'}
														>
															Answer Summary
														</Typography>
													)}
													{question.skippableQuestionRes && (
														<Typography>
															{question.skippableQuestionRes}
														</Typography>
													)}
													{question.strong_points &&
														question.strong_points?.length > 0 && (
															<Typography
																color="#005aad"
																fontSize={'1.1rem'}
																fontWeight={'bold'}
															>
																Strong Points
															</Typography>
														)}
													<ul>
														{question.strong_points &&
															question.strong_points?.length > 0 &&
															question.strong_points?.map((sp) => (
																<li>{sp}</li>
															))}
													</ul>

													{question.weak_points &&
														question.weak_points?.length > 0 && (
															<Typography
																color="#005aad"
																fontSize={'1.1rem'}
																fontWeight={'bold'}
															>
																Weak Points
															</Typography>
														)}
													<ul>
														{question.weak_points &&
															question.weak_points.length > 0 &&
															question.weak_points.map((wp) => (
																<li>{wp}</li>
															))}
													</ul>
													{question.recommended_answer && (
														<Typography
															color="#005aad"
															fontSize={'1.1rem'}
															fontWeight={'bold'}
														>
															Recommended Answer
														</Typography>
													)}
													{question.recommended_answer && (
														<Typography>
															{question.recommended_answer}
														</Typography>
													)}
													<hr />
												</Stack>
											</Stack>
										)
									);
								})}
						</Stack>
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
								Candidate's Interview Videos
							</Typography>
						</Stack>
						<br></br>
						<br></br>
						<VideosList questionVideos={questionVideos} questions={questions} />
						<Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
							<Typography color={'gray'} w={'full'} textAlign={'center'} p={2}>
								Report Generated by{' '}
								<a href="https://aihyr.com" target="_blank" rel="noreferrer">
									AI.HYR
								</a>
							</Typography>
						</Stack>
					</Stack>
					<Typography
						variant="h4"
						fontWeight="bold"
						style={{
							cursor: nextInterviewKey ? 'pointer' : 'default',
							color: nextInterviewKey ? '#004aad' : 'gray',
							margin: '5px',
						}}
						onClick={handleNextClick}
						disabled={nextInterviewKey === null}
					>
						<FaCircleArrowRight />
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};

export default AdminMockInterviewReport;
