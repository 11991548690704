import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Button, IconButton, Paper, Tooltip, Typography, styled } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { Header } from '../shared/Header';
import DeleteJobDialog from './DeleteJobDialog';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .MuiDataGrid-columnHeaders': {
		background: '#224C84',
	},
	'& .MuiDataGrid-menuIcon button': {
		color: '#fff',
	},
	'& .MuiDataGrid-iconButtonContainer button': {
		color: '#fff',
	},
	'&  .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
		color: '#fff',
		fontWeight: 'bold',
	},
}));

const NewTable = ({ jobPostings, setJobPostings }) => {
	const navigate = useNavigate();
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [jobId, setJobId] = useState('');
	const [jobRole, setJobRole] = useState('');

	const columns = [
		{
			field: 'jobRole',
			headerName: 'Job Role',
			width: 320,
			renderCell: (params) => (
				<Button
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						//   width: "150px",
						textAlign: 'start',
						color: '#224C84',
						whiteSpace: 'wrap',
					}}
					onClick={() => {
						console.log(params.row, '...row is clicked');
						navigate(`${params.row.id}`, {
							state: {
								jobRole: params.row.jobRole,
								completedInterviews: true,
								//find student details based on college-interview id

								collegeinterviewid: params.row.id,
							},
						});
					}}
				>
					{params.row.jobRole}
				</Button>
			),
		},
		{
			field: 'totalApplicants',
			headerName: 'Total Attempts',
			align: 'center',
			headerAlign: 'center',
			width: 220,
			renderCell: (params) => (
				<Button
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						color: '#224C84',
					}}
					onClick={() => {
						console.log(params.row, '...row is clicked');
						navigate(`/collegeinterview/total-applicants/${params.row.id}`, {
							state: {
								jobRole: params.row.jobRole,
								completedInterviews: true,
								//find student details based on college-interview id

								collegeinterviewid: params.row.id,
							},
						});
					}}
				>
					{params.row.totalApplicants}
				</Button>
			),
		},
		{
			field: 'allCompletedInterviews',
			headerName: 'Total Completed Interviews',
			align: 'center',
			headerAlign: 'center',
			width: 240,
			renderCell: (params) => (
				<Typography
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						//   width: "150px",
						// color: '#224C84',
					}}
					// onClick={() => {
					//   console.log(params.row, "...row is clicked");
					//   navigate(`${params.row.id}`, {
					//     state: {
					//       jobRole: params.row.jobRole,
					//       completedInterviews: true,
					//       //find student details based on college-interview id

					//       collegeinterviewid: params.row.id,
					//     },
					//   });
					// }}
				>
					{params.row.allCompletedInterviews}
				</Typography>
			),
		},
		{
			field: 'allIncompletedInterviews',
			headerName: 'Total Incomplete Interviews',
			align: 'center',
			headerAlign: 'center',
			width: 240,
			renderCell: (params) => (
				<Button
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						//   width: "150px",
						color: '#224C84',
					}}
					onClick={() => {
						navigate(`/job/incomplete-interviews/${params.row.id}/college`);
					}}
					// onClick={() => {
					//   console.log(params.row, "...row is clicked");
					//   navigate(`${params.row.id}`, {
					//     state: {
					//       jobRole: params.row.jobRole,
					//       completedInterviews: false,
					//       //find student details based on college-interview id
					//       collegeinterviewid: params.row.id,
					//     },
					//   });
					// }}
				>
					{params.row.allIncompletedInterviews}
				</Button>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 250,
			filterable: false,
			sortable: false,
			renderCell: (params) => (
				<>
					<Tooltip title="Add Applicant">
						<IconButton
							onClick={() => {
								console.log(params, 'click add applicant');
								navigate(
									`/add-candidate/college/${params.row.companyId}/${params.row.id}`
								);
							}}
							sx={{
								color: '#224C84',
							}}
						>
							<PersonAddIcon />
						</IconButton>
					</Tooltip>

					{/* <Tooltip title="View resume evaluation">
						<IconButton
						// onClick={() => {
						//   navigate(
						//     `/job/view-resume-evaluation/${params.row.companyId}/${params.row.id}`,
						//     {
						//       state: {
						//         jobRole: params.row.jobRole,
						//         jobId: params.row.id,
						//         companyId: params.row.companyId,
						//       },
						//     }
						//   );
						// }}
						// sx={{
						//   color: "#224C84",
						// }}
						>
							<WysiwygIcon />
						</IconButton>
					</Tooltip>
					{isSuperAdmin && (
						<>
							<Tooltip title="Edit Job Posting">
								<IconButton
									onClick={() => {
										navigate(`/edit-job/${params.row.id}`);
									}}
									sx={{
										color: '#224C84',
									}}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Delete Job Posting">
								<IconButton
									// onClick={() => {
									//   setDeleteDialogOpen(true);
									//   setJobId(params.row.id);
									//   setJobRole(params.row.jobRole);
									// }}
									sx={{
										color: 'red',
									}}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</>
					)} */}
				</>
			),
		},
	];

	useEffect(() => {
		const roles = JSON.parse(sessionStorage.getItem('admin')).roles;
		console.log(roles, 'roles');
		if (roles.includes('aihyr-admin')) {
			setIsSuperAdmin(true);
		}
	}, []);

	return (
		<Box
			sx={{
				backgroundColor: '#ECF7FA',
				padding: '10px',
				height: '100vh',
				width: '100%',
				display: 'flex',
				margin: '20px',
				flexDirection: 'column',
			}}
		>
			<DeleteJobDialog
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				jobId={jobId}
				setJobId={setJobId}
				jobRole={jobRole}
				setJobRole={setJobRole}
				setJobPostings={setJobPostings}
			/>
			<Header />
			<Toaster />
			<Typography
				variant="h6"
				fontWeight={'bold'}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: '30px',
					marginBottom: '30px',
				}}
			>
				All Job Postings
			</Typography>

			<Box sx={{ width: '100%', margin: '20px', marginTop: '20px' }}>
				<Paper sx={{ width: '90%', mb: 2, p: 2 }}>
					<StyledDataGrid
						columns={columns}
						rows={jobPostings}
						slots={{ toolbar: GridToolbar }}
						disableRowSelectionOnClick
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 10,
								},
							},
						}}
						sx={{
							height: '80vh',
						}}
						pageSizeOptions={[5, 10, 15, 20]}
					/>
				</Paper>
			</Box>
		</Box>
	);
};

export default NewTable;
