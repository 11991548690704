import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton, Input, Stack, Typography } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { useParams } from 'react-router';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

const UserTables = (props) => {
	const columns = [
		{ id: 'sno', label: 'S.No', minWidth: '50px', maxWidth: '50px' },
		{ id: 'first_name', label: 'First Name', maxWidth: '130px' },
		{ id: 'last_name', label: 'Last Name', maxWidth: '130px' },
		{ id: 'email', label: 'Email', minWidth: '200px' },
		{ id: 'contact', label: 'Contact', maxWidth: '130px' },
		// { id: "add_candidate", label: "Add Applicant" },
		{ id: 'remove_candidate', label: 'Remove' },
	];
	const { role } = useParams();
	const { users, setUsers, authToken } = props;
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [addApplicantsLoading, setAddApplicantsLoading] = React.useState(false);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const params = useParams();
	const addApplicant = async (first_name, last_name, email, contact, i) => {
		const config = {
			headers: {
				Authorization: `Bearer ${authToken}`,
				'content-type': 'multipart/form-data',
			},
		};
		const formData = new FormData();
		formData.append('first_name', first_name);
		formData.append('last_name', last_name);
		formData.append('contact', contact);
		formData.append('email', email);
		formData.append('role', role);
		await axios.post(
			`${API_URL}/labs/applicant/new/${params.company_id}/${params.job_id}`,
			formData,
			config
		);
		setUsers((prev) => {
			const newUsers = [...prev];
			newUsers.splice(i, 1);
			return newUsers;
		});
	};
	const addApplicants = async () => {
		setAddApplicantsLoading(true);
		for (let i = 0; i < users.length; i++) {
			if (!users[i].first_name || users.first_name === '') {
				toast.error(`First name is required for S.No. ${i + 1}`);
				setAddApplicantsLoading(false);
				return;
			}
			if (!users[i].last_name || users.last_name === '') {
				toast.error(`Last name is required for S.No. ${i + 1}`);
				setAddApplicantsLoading(false);
				return;
			}
			if (!users[i].email || users.email === '') {
				toast.error(`Email is required for S.No. ${i + 1}`);
				setAddApplicantsLoading(false);
				return;
			}
			if (!users[i].contact || users.contact === '') {
				toast.error(`Contact is required for S.No. ${i + 1}`);
				setAddApplicantsLoading(false);
				return;
			}
		}
		const config = {
			headers: {
				Authorization: `Bearer ${authToken}`,
				'content-type': 'application/json',
			},
		};

		const { data } = await axios.post(
			`${API_URL}/labs/applicants/new/${params.company_id}/${params.job_id}`,
			{
				candidates: users,
				role: role,
			},
			config
		);
		if (data.success) {
			toast.success('Applicants added successfully');
			setUsers([]);
		} else {
			toast.error('Applicants could not be added');
		}
		setAddApplicantsLoading(false);
	};
	return (
		<>
			{users.length > 0 && (
				<>
					<Paper sx={{ width: '100%', mt: 2, mb: 2 }}>
						<TableContainer>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ fontWeight: 'bold' }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{users
										// .slice(
										//     page * rowsPerPage,
										//     page * rowsPerPage + rowsPerPage
										// )
										.map((row, i) => {
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={row.code}
												>
													{columns.map((column) => {
														const value = row[column.id];
														return (
															<TableCell
																key={column.id}
																align={'start'}
																style={{
																	minWidth: column?.minWidth,
																	maxWidth: column?.maxWidth,
																}}
															>
																{column.id === 'add_candidate' ? (
																	<Button
																		variant="outlined"
																		style={{
																			textTransform: 'none',
																			color: '#224C84',
																			borderColor: '#224C84',
																		}}
																		startIcon={
																			<PersonAddAltIcon />
																		}
																		onClick={() =>
																			addApplicant(
																				row['first_name'],
																				row['last_name'],
																				row['email'],
																				row['contact'],
																				i
																			)
																		}
																	>
																		Add Applicant
																	</Button>
																) : column.id ===
																  'remove_candidate' ? (
																	<IconButton
																		key={i}
																		variant="contained"
																		style={{
																			textTransform: 'none',
																			backgroundColor: 'red',
																			color: '#fff',
																			borderColor: 'red',
																		}}
																		onClick={() => {
																			setUsers((prev) => {
																				const newUsers = [
																					...prev,
																				];
																				newUsers.splice(
																					i,
																					1
																				);
																				return newUsers;
																			});
																		}}
																	>
																		<ClearIcon />
																	</IconButton>
																) : column.id === 'sno' ? (
																	<Typography>{i + 1}</Typography>
																) : (
																	<Input
																		type="text"
																		fullWidth
																		onChange={(e) => {
																			setUsers((prev) => {
																				const newUsers = [
																					...prev,
																				];
																				newUsers[i][
																					column.id
																				] = e.target.value;
																				return newUsers;
																			});
																		}}
																		// disableUnderline
																		variant="outlined"
																		value={value}
																	/>
																)}
																{/* {value} */}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>

						{/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
					</Paper>
					<Stack direction={'row'} spacing={2}>
						<Button
							m={2}
							disabled={addApplicantsLoading}
							variant={'outlined'}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								borderColor: '#224C84',
							}}
							onClick={addApplicants}
							startIcon={<PersonAddAltIcon />}
						>
							Add All Applicants
						</Button>
						<Button
							m={2}
							variant={'outlined'}
							disabled={addApplicantsLoading}
							onClick={() => {
								setUsers([
									...users,
									{
										first_name: '',
										last_name: '',
										email: '',
										contact: '',
										resume: '',
									},
								]);
							}}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								borderColor: '#224C84',
							}}
							startIcon={<AddIcon />}
						>
							Add Row
						</Button>
						<Button
							disabled={addApplicantsLoading}
							m={2}
							variant={'outlined'}
							onClick={() => {
								setUsers([]);
							}}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								borderColor: '#224C84',
							}}
							startIcon={<ClearIcon />}
						>
							Clear All
						</Button>
					</Stack>
				</>
			)}
		</>
	);
};

UserTables.propTypes = {
	users: PropTypes.arrayOf(
		PropTypes.shape({
			first_name: PropTypes.string,
			last_name: PropTypes.string,
			email: PropTypes.string,
			contact: PropTypes.string,
			address: PropTypes.string,
			resume: PropTypes.string,
			source: PropTypes.string,
		})
	),
};

export default UserTables;
