import { createContext, useState, useEffect, useContext } from 'react';

export const AdminContext = createContext();

const initialState = {
	loading: true,
	exists: false,
};

export const AdminContextProvider = ({ children }) => {
	const [admin, setAdmin] = useState(initialState);

	const logout = () => {
		sessionStorage.removeItem('admin');
		setAdmin(initialState);
	};

	useEffect(() => {
		const adminData = JSON.parse(sessionStorage.getItem('admin'));

		if (adminData) {
			setAdmin({
				...adminData,
				loading: false,
				exists: true,
			});
		} else {
			setAdmin({
				loading: false,
				exists: false,
			});
		}
	}, []);

	const value = { admin, setAdmin, logout };
	return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};

export const useAdminContext = () => {
	return useContext(AdminContext);
};
