import { Alert, Button } from '@mui/material';
import React, { useEffect, useRef } from 'react';

// Import the WebSocket address where your server is running

// const WEBSOCKET_SERVER_URL = "ws://localhost:8000/upload/";
const WEBSOCKET_SERVER_URL = 'wss://aihyrproctoringwebapp.azurewebsites.net/check/';

const CameraCheck = (props) => {
	const videoRef = useRef(null);
	const wsRef = useRef(null);
	const [isConnected, setIsConnected] = React.useState(true);
	const [isEnvOk, setIsEnvOk] = React.useState(false);
	const [isTesting, setIsTesting] = React.useState(true);
	const [countdown, setCountdown] = React.useState(10);
	useEffect(() => {
		if (countdown > 0) {
			const timer = setInterval(() => {
				setCountdown(countdown - 1);
			}, 1000);

			return () => {
				clearInterval(timer);
			};
		}
	}, [countdown]);
	useEffect(() => {
		// Create a new WebSocket connection
		if (!isEnvOk) {
			wsRef.current = new WebSocket(WEBSOCKET_SERVER_URL);

			// Handle WebSocket events
			wsRef.current.onopen = () => {
				console.log('WebSocket connected');
				setIsTesting(true);
				// setIsConnected(true);
			};

			wsRef.current.onclose = () => {
				console.log('WebSocket disconnected');
				setIsConnected(false);
				setIsTesting(false);
			};

			wsRef.current.onerror = (error) => {
				console.error('WebSocket error: ', error);
				setIsTesting(false);
			};

			wsRef.current.onmessage = (event) => {
				const data = JSON.parse(event.data);
				if (data.Brightness && data.Face_detection) {
					setIsEnvOk(true);
					props.setCameraConditionsOk(true);
					// toast.success("Your environment is good to go!");
				}
				console.log('Received data:', JSON.parse(event.data));
				setIsTesting(false);
			};
		}

		return () => {
			// Close the WebSocket connection when the component unmounts
			wsRef.current.close();
		};
	}, [isConnected]);

	useEffect(() => {
		const setupWebcam = async () => {
			const constraints = { video: true };
			const stream = await navigator.mediaDevices.getUserMedia(constraints);
			videoRef.current.srcObject = stream;
			videoRef.current.addEventListener('loadeddata', () => {
				const frameRate = 10; // Adjust the frame rate as needed

				function sendFrame() {
					if (
						videoRef.current &&
						// !props.hasInterviewEnded &&
						!videoRef.current.paused &&
						!videoRef.current.ended
					) {
						const canvas = document.createElement('canvas');
						const context = canvas.getContext('2d');
						canvas.width = videoRef.current.videoWidth;
						canvas.height = videoRef.current.videoHeight;
						context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
						// wsRef.current.send("hello there");
						const imageData = canvas.toDataURL('image/jpeg');
						// Assuming imageData contains the data URL
						const dataUrlParts = imageData.split(',');

						if (dataUrlParts.length === 2) {
							const base64Data = dataUrlParts[1];
							if (wsRef.current.readyState === 1) {
								// Now, base64Data contains the actual Base64-encoded image data
								// You can send this to the server
								// console.log("sending data...");
								const data = {
									image: base64Data,
									interview_key: '1234',
								};
								const jsonData = JSON.stringify(data);
								// Now, base64Data contains the actual Base64-encoded image data
								// You can send this to the server
								// console.log("sending data...");
								wsRef.current.send(jsonData);

								// wsRef.current.send(base64Data);
								// wsRef.current.send({ image: base64Data, interview_key:"6539605f1204e3261cb47ae3" })
							}
						}

						// console.log(imageData)
					}
					// console.log("yes")
					setTimeout(sendFrame, 1000 / frameRate);
				}

				sendFrame();
			});
		};

		setupWebcam();
	}, []);

	return (
		<>
			{/* <Toaster /> */}
			{isTesting && (
				<Alert severity="info">Testing your environment. Wait for {countdown} sec.</Alert>
			)}
			{isEnvOk && <Alert severity="success">Your environment is good!</Alert>}
			{!isEnvOk && !isTesting && (
				<Alert severity="error">Please check your lighting and face position.</Alert>
			)}
			<video
				ref={videoRef}
				autoPlay
				playsInline
				muted
				style={{
					height: '300px',
				}}
			/>
			{!isEnvOk && !isTesting && (
				<Button
					variant="contained"
					onClick={() => {
						setIsConnected(true);
						setCountdown(10);
					}}
				>
					Test Again
				</Button>
			)}
		</>
	);
};

export default CameraCheck;
