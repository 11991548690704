import { useNavigate } from 'react-router-dom';
import { Header } from '../shared/Header';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PopupWidget } from 'react-calendly';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export const LaunchScreen = () => {
	const navigate = useNavigate();

	const goToLoginPage = (value) => {
		navigate(`/login/${value}`, {
			state: { type: value },
		});
	};

	return (
		<>
			<Header />
			<Stack direction={{ xs: 'column', md: 'row' }} bgcolor="#ECF7FA">
				<Stack direction="column" width={{ xs: '100%', md: '60%' }}>
					<Typography fontSize="2rem" fontWeight="500" m={2} mb={0}>
						Transform your recruiting with unbiased,
						<br /> data-driven insights
					</Typography>
					<Typography fontSize="1.5rem" m={2} variant="body2" mb={0}>
						Our automated interviews assess candidates' soft skills and match them with
						your job requirements, providing data-driven insights to make unbiased
						hiring decisions. Say goodbye to manual and time-consuming interviews - let
						our AI find your perfect candidate.
					</Typography>
					<Stack spacing={2} direction={{ xs: 'column', md: 'row' }} m={2}>
						<Button
							variant="contained"
							sx={{ textTransform: 'none' }}
							endIcon={<ArrowForwardIcon />}
							onClick={() => goToLoginPage('candidate')}
						>
							For Candidates
						</Button>
						<Button
							variant="outlined"
							sx={{ textTransform: 'none' }}
							endIcon={<ArrowForwardIcon />}
							onClick={() => goToLoginPage('company')}
						>
							For Companies
						</Button>
					</Stack>
				</Stack>
				<Box width={{ md: '40%' }} m={2} bgcolor="#ECF7FA">
					<iframe
						src="https://share.synthesia.io/embeds/videos/d46d1097-ec7b-4a7c-b27b-d857fa218404"
						loading="lazy"
						title="Providentia Interview Master"
						allow="encrypted-media; fullscreen;"
						scrolling="no"
						height="100vh"
						width="100%"
						style={{
							width: '100%',
							height: '100%',
							top: 0,
							left: 0,
							border: 'none',
							padding: 0,
							margin: 0,
							overflow: 'hidden',
						}}
					></iframe>
				</Box>
			</Stack>
			<Stack
				direction="column"
				display="flex"
				justifyContent="center"
				alignItems="center"
				pt="40px"
				pb="50px"
				bgcolor="#ECF7FA"
			>
				<Typography textAlign="center" fontSize="2rem" fontWeight="medium">
					Why Use Providentia Interview Master?
				</Typography>
				<Stack direction={{ xs: 'column', md: 'row' }} spacing={2} m={2}>
					<Card sx={{ padding: '20px' }}>
						<CardContent>
							<Typography
								variant="h5"
								component="div"
								gutterBottom
								style={{ color: '#1976d2' }}
							>
								Objectively Personalized
							</Typography>
							<Typography variant="body2" style={{ lineHeight: '25px' }}>
								Evaluate all candidates for mandatory job skills with personalized
								questions based on their profile and experience
							</Typography>
						</CardContent>
					</Card>
					<Card sx={{ padding: '20px' }}>
						<CardContent>
							<Typography
								variant="h5"
								component="div"
								gutterBottom
								style={{ color: '#1976d2' }}
							>
								Unbiased Assessment
							</Typography>
							<Typography variant="body2" style={{ lineHeight: '25px' }}>
								Eliminate personal biases and stereotypes from the process. Select
								the best candidates.
							</Typography>
						</CardContent>
					</Card>
					<Card sx={{ padding: '20px' }}>
						<CardContent>
							<Typography
								variant="h5"
								component="div"
								gutterBottom
								style={{ color: '#1976d2' }}
							>
								Cost-effective
							</Typography>
							<Typography variant="body2" style={{ lineHeight: '25px' }}>
								Save 100% time on scheduling and first-round screening. Let us do
								the heavy lifting for you.
							</Typography>
						</CardContent>
					</Card>
					<Card sx={{ padding: '20px' }}>
						<CardContent>
							<Typography
								variant="h5"
								component="div"
								gutterBottom
								style={{ color: '#1976d2' }}
							>
								Transparency
							</Typography>
							<Typography variant="body2" style={{ lineHeight: '25px' }}>
								Get an objective report with interview transcripts and a detailed
								answer evaluation explanation.
							</Typography>
						</CardContent>
					</Card>
				</Stack>
			</Stack>
			<PopupWidget
				url="https://calendly.com/khyatithakur"
				rootElement={document.getElementById('root')}
				text="Click Here To Book A Demo!"
				textColor="#000000"
				color="#ffc107"
			/>
		</>
	);
};
