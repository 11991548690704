import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const TestCompleteDialog = ({ open, setOpen, handleGoToNextSection, isInterviewRequired }) => {
	return (
		<Dialog
			open={open}
			// onClose={handleClose}
		>
			<DialogTitle id="alert-dialog-title">{'Test Completed'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{isInterviewRequired
						? 'Coding test is completed please click on below button to give the  interview'
						: 'To Save the Answers please click on below button'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{/* <Button onClick={handleClose}>Cancel</Button> */}
				<Button onClick={handleGoToNextSection}>
					{isInterviewRequired ? 'Go To Next Section' : 'Save answers'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TestCompleteDialog;
