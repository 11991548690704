import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import examplebots from '../images/example-bots.gif';
import './style.css';
import { useNavigate } from 'react-router';
const ExampleBots = () => {
	const navigate = useNavigate();
	return (
		<Stack
			direction={{ sm: 'row', xs: 'column-reverse' }}
			justifyContent={{ sm: 'start', xs: 'center' }}
			alignItems={'center'}
			p={2}
			// width={"90vw"}
		>
			<Stack
				direction={'column'}
				justifyContent={'center'}
				// alignItems={"start"}
				alignItems={{ xs: 'center', sm: 'start' }}
				ml={{ sm: 15, xs: 0 }}
			>
				<Typography
					fontFamily={'Poppins'}
					fontWeight={'700'}
					fontSize={{ sm: '3rem', xs: '2rem' }}
					color={'#224C84'}
					alignSelf={'flex-start'}
					textAlign={{ xs: 'center', sm: 'start' }}
					p={0}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					On-demand
					<br /> interviews, anytime,
					<br /> anywhere
				</Typography>
				<Button
					variant="contained"
					size="large"
					onClick={() => navigate('/login/candidate')}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
					style={{
						backgroundColor: '#D2ECF6',
						color: '#224C84',
						textTransform: 'none',
						fontWeight: '600',
						width: '200px',
					}}
				>
					For Candidates
				</Button>
				<Typography
					fontFamily={'Poppins'}
					fontWeight={'700'}
					fontSize={{ sm: '3rem', xs: '2rem' }}
					color={'#224C84'}
					textAlign={{ xs: 'center', sm: 'start' }}
					p={1}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					Hire unbiased, fast,
					<br /> and the best
				</Typography>
				<Button
					variant="contained"
					size="large"
					onClick={() => navigate('/login/company')}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
					style={{
						backgroundColor: '#D2ECF6',
						color: '#224C84',
						textTransform: 'none',
						fontWeight: '600',
						width: '200px',
					}}
				>
					For Companies
				</Button>
			</Stack>
			<Stack
				alignItems={'center'}
				ml={{ xs: 0, sm: 10 }}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
			>
				<img
					src={examplebots}
					className="example-bots"
					alt="example-bots"
					width={'600px'}
				/>
			</Stack>
		</Stack>
	);
};

export default ExampleBots;
