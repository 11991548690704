import React from 'react';
import laptopImage from '../images/laptop-image.png';
import ProgressStats from '../images/progress-stats.jpeg';
import { useMediaQuery } from '@mui/material';

const TrackProgress = () => {
	const isMobile = useMediaQuery('(max-width:600px)');

	return (
		<div
			style={{
				// background: 'rgba(211, 211, 211, 0.2)',
				background: 'white',
				color: '#224C84',
				minHeight: isMobile ? '100%' : '100vh',
				maxWidth: '100%',
				display: 'flex',
				flexDirection: isMobile ? 'column' : 'row',
				textAlign: isMobile ? 'center' : 'start',
				gap: '60px',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'hidden',
				position: 'relative',
				margin: '0 5vw',
				borderRadius: '15px',
			}}
		>
			<div
				style={{
					padding: '4rem',
					display: 'flex',
					background: 'rgb(236, 247, 250, 1)',
					boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
					flexDirection: isMobile ? 'column' : 'row',
					textAlign: isMobile ? 'center' : 'start',
					alignItems: 'center',
					maxWidth: isMobile ? '90vw' : '100vw',
					gap: isMobile ? '20px' : '40px',
				}}
			>
				<div
					style={{
						maxWidth: isMobile ? '100vw' : '450px',
						position: 'relative',
						top: '-2rem',
					}}
				>
					<h1
						style={{
							fontSize: isMobile ? '2.3rem' : '2.5rem',
							fontWeight: 'bold',
							marginBottom: 0,
						}}
					>
						Track your progress
					</h1>
					<p
						style={{
							fontSize: '1.0rem',
							color: '#224C84',
							width: '100%',
						}}
					>
						Track your progress and see how you improve over time. Our AI-driven
						insights will help you identify areas of improvement and help you prepare
						better for your next interview.
					</p>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						maxWidth: isMobile ? '200px' : '800px',
					}}
				>
					<img
						style={{
							width: isMobile ? '80vw' : '90%',
							boxShadow: '0 0 15px 5px rgba(0, 0, 0, 0.2)',
							borderRadius: '15px',
						}}
						src={ProgressStats}
						alt="ProgressStats Image"
					/>
				</div>
				{/* <div id="video-laptop" style={{
                    position: 'relative',
                    width: '560px',
                    height: '390px',
                    background: `url(${laptopImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    overflow: 'hidden', // Hide overflow initially
                }}>
                    <div style={{
                        position: 'absolute',
                        top: '13%', // Adjust this value to fit the laptop screen
                        left: '12%', // Adjust this value to fit the laptop screen
                        width: '76%', // Fit the width of the laptop screen
                        height: '277px', // Set a fixed height for the container
                        overflow: 'auto', // Allow overflow to scroll
                    }}>
                        <img
                            src={ProgressStats}
                            alt="Progress Stats"
                            style={{
                                width: '100%', // Fit the width of the container
                                height: '500px', // Set a height greater than the container to enable scrolling
                            }}
                        />
                    </div>
                </div> */}
			</div>
		</div>
	);
};

export default TrackProgress;
