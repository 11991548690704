import { Box, Button, Stack, Typography } from '@mui/material';
import styles from './endInterview.module.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../global/constants';

export const EndInterview = (props) => {
	const [selectedRating, setSelectedRating] = useState('');
	const [feedback, setFeedback] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

	const navigate = useNavigate();

	const submitFeedback = async () => {
		if (selectedRating === '') {
			return alert('Please select a rating');
		}
		setIsLoading(true);
		const data = {
			rating: selectedRating,
			feedback: feedback,
		};
		const response = await axios.post(`${API_URL}/labs/feedback/${props?.interviewKey}`, data);
		setIsLoading(false);
		if (response.data.success) {
			setIsFeedbackSubmitted(true);
		}
	};

	const handleBackBtn = () => {
		navigate('/login/candidate', {
			state: {
				type: 'candidate',
			},
		});
	};

	return (
		<Box
			// m={0}
			// p={3}
			width="100vw"
			height="auto"
			display="flex"
			// justifyContent="center"
			alignItems="center"
			flexDirection="column"
			// bgcolor="#232333"
			textAlign="center"
		>
			{props?.isPracticeInterview && (
				<Typography variant="h4" color={'#ffffff'} mt={3} className={styles.feedbackHeader}>
					The Practice Interview has ended.
				</Typography>
			)}
			{/* {!props?.isPracticeInterview && (
                <>
                    <Typography
                        variant="h5"
                        color="#ffffff"
                        mt={3}
                        ml={2}
                        mr={2}
                        className={styles.feedbackSecondaryHeader}
                    >
                        Our team will be in touch with you for the next steps.
                    </Typography>
                    <Typography
                        variant="h5"
                        color="#ffffff"
                        mt={3}
                        ml={2}
                        mr={2}
                        className={styles.feedbackSecondaryHeader}
                    >
                        You can close this window.
                    </Typography>
                </>
            )} */}
			{props?.isPracticeInterview && (
				<Button
					variant="contained"
					onClick={handleBackBtn}
					sx={{ textTransform: 'none', marginTop: 3 }}
				>
					Go Back
				</Button>
			)}
			{!props?.isPracticeInterview && !isFeedbackSubmitted && (
				<Stack
					bgcolor="#ffffff"
					p={3}
					mt={3}
					mb={3}
					borderRadius={4}
					className={styles.feedbackContainer}
				>
					<Typography
						variant="h3"
						textAlign="left"
						fontSize="22px"
						fontWeight="bold"
						mb={2}
					>
						Please rate your interview experience
					</Typography>
					<Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
						<div
							className={styles.ratingOption}
							onClick={() => setSelectedRating('terrible')}
							style={{
								background: selectedRating === 'terrible' ? '#def3fc' : '',
							}}
							onKeyDown={() => setSelectedRating('terrible')}
						>
							Terrible!
						</div>
						<div
							className={styles.ratingOption}
							onClick={() => setSelectedRating('poor')}
							style={{
								background: selectedRating === 'poor' ? '#def3fc' : '',
							}}
							onKeyDown={() => setSelectedRating('poor')}
						>
							Poor
						</div>
						<div
							className={styles.ratingOption}
							onClick={() => setSelectedRating('ok')}
							style={{
								background: selectedRating === 'ok' ? '#def3fc' : '',
							}}
							onKeyDown={() => setSelectedRating('ok')}
						>
							OK
						</div>
						<div
							className={styles.ratingOption}
							onClick={() => setSelectedRating('good')}
							style={{
								background: selectedRating === 'good' ? '#def3fc' : '',
							}}
							onKeyDown={() => setSelectedRating('good')}
						>
							Good
						</div>
						<div
							className={styles.ratingOption}
							onClick={() => setSelectedRating('great')}
							style={{
								background: selectedRating === 'great' ? '#def3fc' : '',
							}}
							onKeyDown={() => setSelectedRating('great')}
						>
							Great!
						</div>
					</Stack>
					<Typography
						variant="h3"
						textAlign="left"
						fontSize="22px"
						fontWeight="bold"
						mb={1}
						mt={2}
					>
						How can we improve?
					</Typography>
					<textarea
						value={feedback}
						onChange={(e) => setFeedback(e.target.value)}
						className={styles.feedbackArea}
					></textarea>
					<Button
						variant="contained"
						onClick={() => submitFeedback()}
						sx={{ textTransform: 'none' }}
					>
						Submit Feedback
					</Button>
				</Stack>
			)}
			{!props?.isPracticeInterview && isFeedbackSubmitted && (
				<p style={{ color: '#000000' }}>Thank you for your valuable feedback!</p>
			)}
		</Box>
	);
};
