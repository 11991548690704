// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

// Import required modules
import { Scrollbar } from 'swiper/modules';
import {
	fetchQuestions,
	selectAllQuestions,
	getQuestionsError,
	getQuestionsStatus,
} from '../../features/feedback/questions';
// Import MUI components
import Button from '@mui/material/Button';
import Npsrating from './Rating';

export default function SwiperContainer() {
	const [currentIndex, setCurrentIndex] = useState(0);

	const dispatch = useDispatch();
	const questions_details = useSelector(selectAllQuestions);
	const questionStatus = useSelector(getQuestionsStatus);
	console.log(questionStatus, 'question status');
	const error = useSelector(getQuestionsError);
	useEffect(() => {
		if (questionStatus === 'idle') {
			dispatch(fetchQuestions());
		}
	}, [questionStatus, dispatch]);
	console.log(questions_details, 'questions');

	const handleSlideChange = (swiper) => {
		setCurrentIndex(swiper.activeIndex);
	};

	return (
		<>
			<Swiper
				onSlideChange={handleSlideChange}
				effect="fade"
				// scrollbar={{
				// 	hide: true,
				// }}
				// modules={[Scrollbar]}
				allowTouchMove={false}
				className="mySwiper"
				style={{ position: 'relative' }}
			>
				{questions_details.map((question_detail) => {
					return (
						<SwiperSlide key={question_detail.id}>
							<Box sx={{ p: 4, backgroundColor: 'white' }}>
								<Npsrating
									question={question_detail.question}
									followupquestion={question_detail.followupquestion}
									question_id={question_detail._id}
								/>
							</Box>
						</SwiperSlide>
					);
				})}
				<SwiperSlide>
					<Box sx={{ p: 4, textAlign: 'center' }}>
						<Typography variant="h4" component="div">
							Thank you for your feedback!
						</Typography>
					</Box>
				</SwiperSlide>
			</Swiper>
		</>
	);
}
