import React, { useState } from 'react';
import { Header } from '../shared/Header';
import { Stack, Typography, Button, TextField, InputAdornment, Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import MenuItem from '@mui/material/MenuItem';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';

import CsvDownloadButton from 'react-json-to-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
// import { DataGrid } from "@mui/x-data-grid";
import toast from 'react-hot-toast';
import './all-reports.css';

import Cell from './Tablecell';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	height: '80vh',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	borderRadius: '20px',
	boxShadow: 24,
	overflow: 'auto',
	p: 4,
};
const AllReports = () => {
	const params = useParams();
	// const navigate = useNavigate();
	const downloadRef = useRef(null);
	const { job_id } = params;
	const [completedInterviews, setCompletedInterviews] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [jobTitle, setJobTitle] = useState('');
	const [jobDescription, setJobDescription] = useState('');
	const navigate = useNavigate();
	const [filter, setFilter] = useState('name');
	const [searchBarName, setSearchBarName] = useState('Name');
	const handleChange = (event) => {
		setFilter(event.target.value);
	};
	const [modalOpen, setModalOpen] = useState(false);
	const [roles, setRoles] = useState([]);
	const [resume, setResume] = useState(null);
	const [columns, setColumns] = useState([
		// { id: 'interview_key', label: 'Interview Key',  },
		{
			id: 'name',
			label: 'Name',
			// minWidth: 200,
			// align: "center",
			// textAlign: "center",
		},
		// { id: "email", label: "Email" },
		// { id: "contact", label: "Contact" },
		{
			id: 'interview_date_time',
			label: 'Interview Date & Time',
			// align: "center",
			// textAlign: "center",
		},
		{
			id: 'completion_time',
			label: `Completion Time${'\n'} (in minutes)`,
			// align: "center",
			// textAlign: "center",
		},
		{
			id: 'overall_score',
			label: 'Overall Score',
			// align: "center",
			// textAlign: "center",
		},
		{
			id: 'recommendation',
			label: 'Recommendation',
			// align: "center",
			// textAlign: "center",
		},
		{
			id: 'interview_key',
			label: 'Report',
			// align: "center",
			// textAlign: "center"
		},
	]);
	const handleModalOpen = () => setModalOpen(true);
	const handleModalClose = () => setModalOpen(false);

	const getData = async () => {
		setLoading(true);
		const { data } = await axios.get(`${API_URL}/labs/job/details/${job_id}`, {
			headers: {
				authorization: `Authorization ${JSON.parse(sessionStorage.getItem('admin')).token}`,
			},
		});
		setJobTitle(data.data.job_role);
		setJobDescription(data.data.job_description);

		const roles = JSON.parse(sessionStorage.getItem('admin')).roles;
		console.log(roles);
		setRoles(roles);
		let completed_interviews = [];
		let highly_recommended = [];
		let moderate_recommended = [];
		let not_recommended = [];
		let violated = [];
		let others = [];
		console.log(data.data.completed_interviews, 'completed interviews');
		for (let interview of data.data.completed_interviews) {
			if (interview.recommendation === '1') {
				highly_recommended.push(interview);
			} else if (interview.recommendation === '0') {
				moderate_recommended.push(interview);
			} else if (interview.recommendation === '-1') {
				not_recommended.push(interview);
			} else if (interview.interview_end_reason === 'violation') {
				violated.push(interview);
			} else {
				others.push(interview);
			}
		}
		highly_recommended.sort((a, b) => {
			return b.overall_score - a.overall_score;
		});
		moderate_recommended.sort((a, b) => {
			return b.overall_score - a.overall_score;
		});
		not_recommended.sort((a, b) => {
			return b.overall_score - a.overall_score;
		});
		completed_interviews.push(
			...highly_recommended,
			...moderate_recommended,
			...not_recommended,
			...violated,
			...others
		);
		if (!roles.includes('super-admin')) {
			completed_interviews = completed_interviews.filter(
				(interview) => interview.is_confirmed_by_admin
			);
		}
		console.log(completed_interviews, 'completed interviews after');
		setCompletedInterviews(completed_interviews);
		setFilteredData(completed_interviews);
		console.log(completed_interviews);
		setLoading(false);
	};
	useEffect(() => {
		getData();
	}, []);
	useEffect(() => {
		if (roles.includes('super-admin')) {
			const newColumns = [
				{ id: 'status', label: 'Status' },
				{ id: 'candidate_resume', label: 'Resume' },
				{ id: 'is_confirmed_by_admin', label: 'Confirmed' },
			];

			const updatedColumns = [...columns];

			newColumns.forEach((newColumn) => {
				if (!columns.some((column) => column.id === newColumn.id)) {
					updatedColumns.push(newColumn);
				}
			});

			setColumns(updatedColumns);
		}
	}, [roles]);

	useEffect(() => {
		for (let col of filters) {
			if (col.id === filter) {
				setSearchBarName(col.label);
			}
		}
	}, [filter]);

	const filters = [
		// { id: 'interview_key', label: 'Interview Key',  },
		{ id: 'name', label: 'Name' },
		{ id: 'email', label: 'Email' },
		{ id: 'contact', label: 'Contact' },
		{ id: 'interview_key', label: 'Interview Key' },
	];
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		// Save the current page to local storage or any other storage
		sessionStorage.setItem('allReportsCurrentPage', newPage);
	};

	// Restore page number from sessionStorage if available
	useEffect(() => {
		const savedPage = sessionStorage.getItem('allReportsCurrentPage');
		if (savedPage) {
			setPage(Number(savedPage));
		}
	}, []);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const requestSearch = (searchedVal) => {
		const filteredRows = completedInterviews.filter((row) => {
			return row[filter].toLowerCase().includes(searchedVal.toLowerCase());
		});
		console.log(filteredRows, 'filteredRows');
		setFilteredData(filteredRows);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	console.log(filteredData, 'filteredData');
	return (
		<>
			<Header />
			<div style={{ backgroundColor: '#ECF7FA' }}>
				<Stack direction="column" sx={{ width: '100%' }} p={2}>
					<Typography variant="h5" fontWeight="bold">
						Job Title
					</Typography>
					<Tooltip title="View Job Description" placement="right" arrow>
						<Button
							mt={1}
							onClick={handleModalOpen}
							style={{
								padding: 0,
								fontSize: '1.2rem',
								textTransform: 'none',
								width: 'fit-content',
								'&.hover': {
									cursor: 'pointer',
								},
								color: '#224C84',
							}}
						>
							{jobTitle}
						</Button>
					</Tooltip>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '16px',
						}}
					>
						<Button
							variant="outlined"
							color="primary"
							style={{
								minWidth: 'auto',
								marginLeft: 'auto',
							}}
							onClick={async () => {
								const { data } = await axios.post(
									`${API_URL}/labs/mail/bulk/rejection/${job_id}`
								);
								if (data.success) {
									toast.success(data.message, {
										duration: 3000,
										position: 'top-center',
									});
									return true;
								} else {
									toast.error(data.message, {
										duration: 3000,
										position: 'top-center',
									});
									return false;
								}
							}}
						>
							Send Rejection Emails
						</Button>
					</div>

					<Modal
						open={modalOpen}
						onClose={handleModalClose}
						aria-labelledby="parent-modal-title"
						aria-describedby="parent-modal-description"
					>
						<Box sx={style}>
							<Typography variant="h5" fontWeight="bold" mt={2}>
								Job Description
							</Typography>
							<Typography
								variant="body1"
								mt={1}
								style={{
									textAlign: 'justify',
									fontSize: '1.2rem',
								}}
							>
								{jobDescription}
							</Typography>
						</Box>
					</Modal>
				</Stack>
				<Stack direction="row" display="flex" justifyContent="space-between" pl={2}>
					<Typography variant="h5" fontWeight="bold">
						All Completed Interviews
					</Typography>
				</Stack>
				{loading && <CircularStatic />}
				{!loading && (
					<Stack direction="column" m={0} p={2}>
						{/* <Grid
              container
              direction="row"
              // spacing={3}
              // ml={2}
              w="fit-content"
              className="reports-container"
              height="max-content"
            > */}
						<Stack
							direction={'row'}
							width={'100%'}
							// spacing={2}
							mb={2}
						>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								onChange={(e) => requestSearch(e.target.value)}
								//   placeholder={`Search using ${searchBarName.toLowerCase()}...`}
								placeholder={`Search...`}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position="start">
											<Typography
												borderRadius={'3px'}
												textAlign={'center'}
												// width={'8rem'}
												fontWeight={'bold'}
												color={'blue'}
												sx={{
													background: 'rgb(0, 0, 255, 0.1)',
												}}
												pl={1}
												fontSize={'12px'}
												mr={'-18.5px'}
											>
												{searchBarName}
												<Tooltip title="Choose Filter">
													<IconButton
														p={0}
														size="small"
														onClick={handleClick}
														aria-controls={
															open ? 'account-menu' : undefined
														}
														aria-haspopup="true"
														aria-expanded={open ? 'true' : undefined}
													>
														<FilterListIcon
															fontSize="small"
															sx={{
																color: 'blue',
															}}
														/>
													</IconButton>
												</Tooltip>
											</Typography>
										</InputAdornment>
									),
								}}
							/>

							<Menu
								anchorEl={anchorEl}
								id="account-menu"
								open={open}
								onClose={handleClose}
								onClick={handleClose}
								transformOrigin={{
									horizontal: 'right',
									vertical: 'top',
								}}
								anchorOrigin={{
									horizontal: 'right',
									vertical: 'bottom',
								}}
							>
								{filters.map((col, i) => {
									return (
										<MenuItem value={col.id} onClick={() => setFilter(col.id)}>
											{col.label}
										</MenuItem>
									);
								})}
							</Menu>
							<Tooltip title="Download Candidate Data">
								<CsvDownloadButton
									className="csv-download-button"
									data={completedInterviews}
									delimiter=","
									filename="data.csv"
								>
									<DownloadIcon />
								</CsvDownloadButton>
							</Tooltip>
						</Stack>

						<Paper sx={{ width: '100%' }}>
							<TableContainer>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow
											style={{
												alignItems: 'center',
												textAlign: 'center',
												justifyContent: 'center',
											}}
										>
											{columns.map((column) => (
												<TableCell
													key={column.id}
													// align={"center"}
													// align="center"
													// style={{
													//     alignSelf: "center",
													// }}
													// style={{
													//     minWidth:
													//         column.minWidth,
													// }}
												>
													{column.id === 'completion_time' ? (
														<Typography
															fontWeight={'bold'}
															// textAlign={"center"}
															// justifyContent={
															//     "center"
															// }
															// alignItems={
															//     "center"
															// }
														>
															Completion Time
															<Typography
																fontSize={'0.8rem'}
																textAlign={'center'}
															>
																(in minutes)
															</Typography>
														</Typography>
													) : (
														<Typography
															fontWeight={'bold'}
															// textAlign={"center"}
														>
															{column.label}
														</Typography>
													)}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{filteredData
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((row, i) => {
												return (
													<TableRow
														hover
														role="checkbox"
														tabIndex={-1}
														key={row.code}
													>
														<Cell
															completedInterviews={
																completedInterviews
															}
															rowsPerPage={rowsPerPage}
															row={row}
															columns={columns}
															setCompletedInterviews={
																setCompletedInterviews
															}
															page={page}
															job_id={job_id}
															i={i}
															filteredData={filteredData}
															setFilteredData={setFilteredData}
														/>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={completedInterviews.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
						{/* {!loading && completedInterviews.length !== 0 && (
                            <CsvDownloadButton
                                ref={downloadRef}
                                data={completedInterviews}
                                delimiter=","
                                style={{
                                    backgroundColor: "#1976D2",
                                    fontSize: "1rem",
                                    color: "#fff",
                                    border: "0",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                }}
                            >
                                Download Candidate Data
                            </CsvDownloadButton>
                        )} */}
						{/* </Grid> */}
					</Stack>
				)}
			</div>
		</>
	);
};

export default AllReports;
