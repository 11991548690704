import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../shared/Header';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import TabelcellComopnent from './Tablecell';
import SearchIcon from '@mui/icons-material/Search';
import { Stack, Typography, TextField, InputAdornment, Skeleton } from '@mui/material';

import { useNavigate } from 'react-router';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import CsvDownloadButton from 'react-json-to-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useParams } from 'react-router-dom';
import { useAdminContext } from '../../global/AdminContext';
import './styles.css';

const StudentDashboard = () => {
	const [email, setEmail] = useState('');
	const { state } = useLocation();
	const { id: collegeinterviewid } = useParams();

	const { jobRole } = state;

	const { admin } = useAdminContext();

	const [loading, setLoading] = useState(false);
	const [interviews, setInterviews] = useState([]);
	const [Filteredinterviews, setFilteredinterviews] = useState([]);
	const [filter, setFilter] = useState('name');
	const [searchBarName, setSearchBarName] = useState('Name');
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const canBeOpen = open && Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const filters = [
		// { id: 'interview_key', label: 'Interview Key',  },
		{ id: 'interview name', label: 'Student Name' },
	];

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangeRowsPerPage = (event) => {
		let rowsperpage = Math.max(+event.target.value, 5);
		setRowsPerPage(rowsperpage);
		const filterdinterviews = interviews.slice(0, rowsperpage);
		if (filterdinterviews) {
			setFilteredinterviews(filterdinterviews);
		}
		setPage(0);
	};
	const [columns, setColumns] = useState([
		{
			id: 'name',
			label: 'Student Name',
			columnrowmarginleft: '10px',
			columnheadermarginleft: '10px',
		},
		{
			id: 'attempts',
			label: 'Total Attempts',
			columnrowmarginleft: '20px',
			columnheadermarginleft: '0px',
		},

		{
			id: 'max_score',
			label: 'Highest Score',
			columnrowmarginleft: '0px',
			columnheadermarginleft: '0px',
		},
		{
			id: 'min_score',
			label: 'Lowest Score',
			columnrowmarginleft: '20px',
			columnheadermarginleft: '0px',
		},
		{
			id: 'student_id',
			label: 'Progress',
			columnrowmarginleft: '0px',
			columnheadermarginleft: '30px',
		},

		{ id: 'more', label: '' },
	]);

	const getAllInterviews = async () => {
		try {
			// console.log(collegeinterviewid, 'collegeid--');
			const { data } = await axios.get(
				`${API_URL}/labs/getallstudentinterviews/${collegeinterviewid}`,
				{
					headers: {
						authorization: `Bearer ${admin.token}`,
					},
				}
			);

			if (data.success) {
				const interviews = data.data;
				// console.log(data.data, 'data');
				setInterviews(data.data);
				setFilteredinterviews(interviews.slice(0, 10));

				// console.log(data.data);
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	useEffect(() => {
		console.log(sessionStorage);
		if (!sessionStorage.getItem('admin')) {
			navigate('/student/login');
		}
		if (admin.loading) {
			return;
		} else {
			getAllInterviews();
		}
		// getDetails();
		getAllInterviews();
	}, [admin]);
	useEffect(() => {
		const currentpage = page;

		const rowstobedisplayed = interviews.slice(
			currentpage * rowsPerPage,
			(currentpage + 1) * rowsPerPage
		);
		// console.log('page changed', rowstobedisplayed, currentpage);
		setFilteredinterviews(rowstobedisplayed);
	}, [page]);
	useEffect(() => {
		for (let col of filters) {
			if (col.id === filter) {
				setSearchBarName(col.label);
			}
		}
	}, [filter]);
	const requestSearch = (searchedVal) => {
		const filteredRows = interviews.filter((row) => {
			if (searchedVal === '') {
				return true;
			}
			// print(row['first_name']);
			// console.log(row['first_name'], 'x');
			return row['first_name']?.toLowerCase().includes(searchedVal.toLowerCase());
		});
		setFilteredinterviews(filteredRows);
	};
	const handleChangePage = (event, newPage) => {
		// console.log('page changed', newPage);
		setPage(newPage);
	};

	console.log(Filteredinterviews, 'filterd');
	return (
		<div>
			<Toaster />
			<Header />

			<div style={{ paddingLeft: '10%', paddingRight: '10%', backgroundColor: '#ECF7FA' }}>
				<Stack
					mt={{ sm: 1, xs: 1 }}
					py={{ sm: 5, xs: 4 }}
					spacing={{ sm: 2, xs: 5 }}
					bgcolor={'#ECF7FA'}
					direction={{ sm: 'row', xs: 'column-reverse' }}
					justifyContent={{ sm: 'space-evenly', xs: 'center' }}
					alignItems="center"
				>
					<Stack
						direction={{ sm: 'row', xs: 'column-reverse' }}
						spacing={2}
						alignItems={'center'}
						justifyContent={{ sm: 'space-evenly', xs: 'center' }}
					>
						{loading && (
							<Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '240px' }} />
						)}

						{loading && (
							<Skeleton
								variant="button"
								sx={{ fontSize: '1.3rem', width: '240px' }}
							/>
						)}
					</Stack>
					<Typography
						style={{
							fontSize: '1.2rem',
							color: '#224c84',
							width: 'auto',
							textWrap: 'nowrap',
						}}
					>
						{jobRole}
					</Typography>
					<Stack
						direction={'row'}
						width={'100%'}
						// spacing={2}
						mb={2}
					>
						<TextField
							size="small"
							fullWidth
							variant="outlined"
							onChange={(e) => requestSearch(e.target.value)}
							//   placeholder={`Search using ${searchBarName.toLowerCase()}...`}
							placeholder={`Search...`}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="start">
										<Typography
											borderRadius={'3px'}
											textAlign={'center'}
											// width={'8rem'}
											fontWeight={'bold'}
											color={'#224C84'}
											sx={{
												background: 'rgb(0, 0, 255, 0.1)',
											}}
											pl={1}
											fontSize={'12px'}
											mr={'-18.5px'}
										>
											{searchBarName}
											<Tooltip title="Choose Filter">
												<IconButton
													p={0}
													size="small"
													onClick={handleClick}
													aria-controls={
														open ? 'account-menu' : undefined
													}
													aria-haspopup="true"
													aria-expanded={open ? 'true' : undefined}
												>
													<FilterListIcon
														fontSize="small"
														sx={{
															color: 'blue',
														}}
													/>
												</IconButton>
											</Tooltip>
										</Typography>
									</InputAdornment>
								),
							}}
						/>
						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							{filters.map((col, i) => {
								return (
									<MenuItem value={i} onClick={() => setFilter(col.id)}>
										{col.label}
									</MenuItem>
								);
							})}
						</Menu>
						<Tooltip title="Download Candidate Data">
							<CsvDownloadButton
								className="csv-download-button"
								data={interviews}
								delimiter=","
								filename="data.csv"
							>
								<DownloadIcon />
							</CsvDownloadButton>
						</Tooltip>
					</Stack>
				</Stack>
				{/* closed text field */}
				<Stack direction="column" m={0} p={0}>
					<Paper
						sx={{
							width: '100%',
							margin: 'auto',
							borderRadius: '20px',
							overflow: 'hidden',
							boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
						}}
					>
						<TableContainer>
							<Table aria-label="sticky table" sx={{ zIndex: '10' }}>
								<TableHead sx={{ backgroundColor: '#224c84', paddingY: '4px' }}>
									<TableRow>
										{columns.map((column, i) => (
											<TableCell
												key={i}
												// align={"center"}
												//align="center"
												// style={{
												//   alignSelf: "center",
												// }}
											>
												<Typography
													fontWeight={'bold'}
													// textAlign={"center"}
													style={{
														marginLeft: column.columnheadermarginleft,
														color: 'white',
													}}
												>
													{column.label}
													{column.id === 'time' && (
														<>
															<br />
															<Typography
																sx={{
																	fontSize: '0.9rem',
																	textAlign: 'center',
																	marginX: 'auto',
																}}
															>
																(in minutes)
															</Typography>
														</>
													)}
												</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{Filteredinterviews &&
										Filteredinterviews.map((row, i) => (
											<TableRow hover role="checkbox" tabIndex={-1} key={i}>
												<TabelcellComopnent columns={columns} row={row} />
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={interviews.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</Stack>
			</div>
		</div>
	);
};

export default StudentDashboard;
