import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import hiringinterviewimg from '../images/hiring-interviews.png';
import next from '../images/next.png';
import './style.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from 'react-router';

const HiringInterview = () => {
	const navigate = useNavigate();
	return (
		<Stack display="flex" justifyContent={'center'} alignItems={'center'} pt={3} pb={3}>
			<Stack textAlign={'center'} alignItems={'center'}>
				<Typography
					fontFamily={'Poppins'}
					fontWeight={'700'}
					fontSize={{ sm: '3rem', xs: '2rem' }}
					color={'#224C84'}
					textAlign={'center'}
					p={1}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					Hiring Interviews
				</Typography>
				<Typography
					color={'#224C84'}
					textAlign={'center'}
					fontSize={{ sm: '1.3rem', xs: '1.2rem' }}
					data-aos="fade-up"
					data-aos-duration="1200"
					data-aos-delay="300"
				>
					Find talent in THREE simple steps
				</Typography>
			</Stack>
			<Stack direction={{ sm: 'row', xs: 'column' }} width={{ sm: '90%' }} p={3}>
				<Stack
					width={{ sm: '50%' }}
					alignItems={{ sm: 'end', xs: 'center' }}
					mr={{ sm: 5, xs: 0 }}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					<img
						className="hiring-interview"
						src={hiringinterviewimg}
						alt="hiring-interviews"
						width={'300px'}
					/>
				</Stack>
				<Stack width={{ sm: '50%' }} spacing={3} justifyContent={'center'}>
					<Stack
						direction={'row'}
						spacing={2}
						alignItems={'center'}
						mt={2}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<img src={next} alt="next" height={'40px'} />
						<Typography
							fontFamily={'Poppins'}
							fontSize={'1.2rem'}
							p={1}
							color={'#224C84'}
							style={{
								backgroundColor: 'rgb(222, 229, 240, 0.8)',
								borderRadius: '10px',
							}}
						>
							Define job description and job role.
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						spacing={2}
						alignItems={'center'}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<img src={next} alt="next" height={'40px'} />
						<Typography
							fontFamily={'Poppins'}
							fontSize={'1.2rem'}
							p={1}
							color={'#224C84'}
							style={{
								backgroundColor: 'rgb(255, 222, 89, 0.8)',
								borderRadius: '10px',
							}}
						>
							Approve or curate AI suggested skills & questions.{' '}
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						spacing={2}
						alignItems={'center'}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<img src={next} alt="next" height={'40px'} />
						<Typography
							fontFamily={'Poppins'}
							fontSize={'1.2rem'}
							p={1}
							color={'#224C84'}
							style={{
								backgroundColor: 'rgb(193, 255, 114, 0.8)',
								borderRadius: '10px',
							}}
						>
							Send thousand's of interviews instantly.{' '}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Button
				variant="contained"
				size="large"
				data-aos="zoom-in"
				data-aos-duration="1200"
				data-aos-delay="300"
				onClick={() => navigate('/interview/practice')}
				endIcon={<PlayCircleOutlineIcon fontSize="large" />}
				style={{
					backgroundColor: '#224C84',
					fontFamily: 'Poppins',
					fontSize: '1.2rem',
					textTransform: 'none',
					borderRadius: '15px',
				}}
			>
				Try Now
			</Button>
		</Stack>
	);
};

export default HiringInterview;
