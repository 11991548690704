import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { AudioRecorder } from '../AudioRecorder';
import { useLocation } from 'react-router';
import { Header } from '../shared/Header';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import { QuestionPopper } from '../QuestionPopper';
import { CountdownTimer } from '../CountdownTimer';
import { BrowserView, MobileView } from 'react-device-detect';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CircularProgress, Stack } from '@mui/material';
import { ReplayButton } from '../ReplayButton';
import { useNavigate } from 'react-router-dom';

// Dialog to prevent user from leaving the page
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export const DemoInterview = () => {
	const navigate = useNavigate();
	const reactPlayerRef = useRef(null);
	const introVideoRef = useRef(null);
	const [error, setError] = useState(false);
	const [videoUrl, setVideoUrl] = useState('');
	const [mobileVideoUrl, setMobileVideoUrl] = useState('');
	const [mobileIntroVideo, setMobileIntroVideo] = useState('');
	const [desktopIntroVideo, setDesktopIntroVideo] = useState('');
	const [hasIntroVideoEnded, setHasIntroVideoEnded] = useState(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isListening, setIsListening] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const { state } = useLocation();
	const { fullName, interviewKey, isDemo } = state;
	const [questionTimestamps, setQuestionTimestamps] = useState([]);
	const [loading, setLoading] = useState(true);
	const [hasInterviewEnded, setHasInterviewEnded] = useState(false);
	const [listeningTimestamp, setListeningTimestamp] = useState({
		start_time: 0,
		end_time: 0,
	});
	const [start, setStart] = useState(false);
	const [questionsArray, setQuestionsArray] = useState([]);

	const [open, setOpen] = useState(true);
	const [stopInterview, setStopInterview] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [updateEndedToDatabase, setUpdateEndedToDatabase] = useState(false);

	const [playing, setPlaying] = useState(false);
	const [playsinline, setPlaysinline] = useState(false);
	const [isIphone, setIsIphone] = useState(false);
	const [showReplayButton, setShowReplayButton] = useState(false);

	const [answeringTime, setAnsweringTime] = useState('');
	const [showCountdownTimer, setShowCountdownTimer] = useState(false);
	const [desktopPlaying, setDesktopPlaying] = useState(true);
	const [desktopPlaysinline, setDesktopPlaysinline] = useState(true);
	const [uploadVideo, setUploadVideo] = useState(false);

	const [showEndScreen, setShowEndScreen] = useState(false);

	// For preventing user from leaving the page
	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	const [videoUploaded, setVideoUploaded] = useState(false);
	// const [tabSwitches, setTabSwitches] = useState(0);
	let tabSwitches = 0;
	const [calculatingScoreLoading, setCalculatingScoreLoading] = useState(false);

	const endUserInterview = async () => {
		// await axios.post(`${API_URL}/labs/interview/end`, {
		//     interview_key: interviewKey,
		// });
		setPlaying(false);
		setPlaysinline(false);
		setDesktopPlaying(false);
		setDesktopPlaysinline(false);
		setHasInterviewEnded(true);
		setUploadVideo(true);
	};

	// const exitRefresh = useCallback((e) => {
	//     e.preventDefault();
	//     e.returnValue = "";
	// });
	// const checkTabSwitches = useCallback(() => {
	//     if (!hasInterviewEnded) {
	//         setAlertDialogOpen(true);
	//         setPlaying(false);
	//         setPlaysinline(false);
	//         setDesktopPlaying(false);
	//         setDesktopPlaysinline(false);
	//         tabSwitches++;
	//         if (tabSwitches > 3) {
	//             // setAlertDialogOpen(false);
	//             // console.log("You have switched tabs more than 3 times.");
	//             alert(
	//                 "You violated the terms of the interview. The interview has been ended."
	//             );
	//             endUserInterview();
	//             setAlertDialogOpen(false);
	//         }
	//     }
	// });
	// useEffect(() => {
	//     if (!videoUploaded) {
	//         window.addEventListener("beforeunload", exitRefresh);
	//     } else {
	//         window.removeEventListener("beforeunload", exitRefresh);
	//     }
	//     if (!hasInterviewEnded) {
	//         window.addEventListener("blur", checkTabSwitches);
	//     } else {
	//         window.removeEventListener("blur", checkTabSwitches);
	//     }
	//     return () => {
	//         window.removeEventListener("beforeunload", exitRefresh);
	//         window.removeEventListener("blur", checkTabSwitches);
	//     };
	// }, [videoUploaded, hasInterviewEnded]);

	useEffect(() => {
		if (
			navigator.platform === 'iPad' ||
			navigator.platform === 'iPhone' ||
			navigator.platform === 'iPod'
		) {
			console.log('This is an iOS device.');
			setIsIphone(true);
		} else {
			console.log('This is not an iOS device!');
			setIsIphone(false);
		}
	}, []);

	const restartPlaying = () => {
		setPlaying(true);
		setPlaysinline(true);
		setDesktopPlaying(true);
		setDesktopPlaysinline(true);
		setAlertDialogOpen(false);
	};
	useEffect(() => {
		if (mobileIntroVideo && desktopIntroVideo && introVideoRef && introVideoRef.current) {
			introVideoRef.current.playing = true;
			introVideoRef.current.playsinline = true;
			introVideoRef.current.pip = false;
		}
	}, [mobileIntroVideo, desktopIntroVideo]);

	useEffect(() => {
		fetchQuestions(interviewKey);
	}, []);

	useEffect(() => {
		if (reactPlayerRef && reactPlayerRef.current) {
			reactPlayerRef.current.seekTo(
				questionTimestamps[currentQuestionIndex].startTime,
				'seconds'
			);
			setIsRecording(false);
			reactPlayerRef.current.playing = true;
			reactPlayerRef.current.playsinline = true;
			reactPlayerRef.current.pip = false;
		}
		if (currentQuestionIndex === questionTimestamps.length - 1) {
			updateInterviewEnded();
		}
	}, [currentQuestionIndex]);

	const fetchQuestions = async (key) => {
		setLoading(true);
		const res = await axios.get(`${API_URL}/labs/questions?interview_key=${key}`);
		setQuestionTimestamps(res?.data?.data?.timestamps);
		setVideoUrl(res.data.data.video_link);
		setMobileVideoUrl(res.data.data.mobile_video_link);
		setListeningTimestamp(res.data.data.listening_timestamp);
		setMobileIntroVideo(res?.data?.data?.mobile_intro_video);
		setDesktopIntroVideo(res?.data?.data?.desktop_intro_video);
		setQuestionsArray(res?.data?.data?.questions);
		// const questions = res.data.data.
		console.log(res.data.data);
		if (
			!res?.data?.data?.mobile_intro_video ||
			!res?.data?.data?.desktop_intro_video ||
			res?.data?.data?.mobile_intro_video === '' ||
			res?.data?.data?.desktop_intro_video === ''
		) {
			setHasIntroVideoEnded(true);
			setShowCountdownTimer(true);
		}
		// if (res.data.data.start_with !== 0) {
		//     setHasIntroVideoEnded(true);
		//     setShowCountdownTimer(true);
		// }
		setLoading(false);
		setCurrentQuestionIndex(0);
		reactPlayerRef.current.seekTo(questionTimestamps[0].startTime);
	};

	const handlePostQuestionAction = () => {
		if (!isListening && currentQuestionIndex < questionTimestamps.length - 1) {
			setIsListening(true);
			handleListeningLoop();
		}
	};

	const handleProgress = ({ playedSeconds }) => {
		// if (currentQuestionIndex === 0 && !start) {
		//     reactPlayerRef.current.seekTo(questionTimestamps[0].startTime);
		//     setStart(true);
		// }
		if (!start) {
			reactPlayerRef.current.seekTo(questionTimestamps[currentQuestionIndex].startTime);
			setStart(true);
		}
		if (
			!isListening &&
			currentQuestionIndex !== questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].endTime
		) {
			setIsRecording(true);
			setShowReplayButton(true);
			handlePostQuestionAction();
		}

		if (
			currentQuestionIndex >= questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].startTime &&
			!updateAnswerToDatabase
		) {
			setIsListening(false);
			setIsRecording(false);
			setShowReplayButton(false);
			setUpdateEndedToDatabase(true);
		}
		if (
			currentQuestionIndex === questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].endTime
		) {
			setHasInterviewEnded(true);
			// setUploadVideo(true);
			setIsListening(false);
			setIsRecording(false);
			setUpdateEndedToDatabase(true);
			setCalculatingScoreLoading(true);
		}

		if (isListening && playedSeconds >= listeningTimestamp.end_time) {
			reactPlayerRef.current.seekTo(listeningTimestamp.start_time, 'seconds');
		}
	};

	const handleEnded = () => {
		if (currentQuestionIndex < questionTimestamps.length - 1) {
			handlePostQuestionAction();
		} else {
			setHasInterviewEnded(true);
			// setUploadVideo(true);
		}
	};

	const handleListeningLoop = () => {
		reactPlayerRef.current.seekTo(listeningTimestamp.start_time, 'seconds');
	};

	const goToNextQuestion = () => {
		if (currentQuestionIndex >= questionTimestamps.length - 1) {
			setHasInterviewEnded(true);
			// setUploadVideo(true);
			updateInterviewEnded();
			setIsListening(false);
			setIsRecording(false);
		} else {
			setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
		}
	};

	const updateIsListening = (value) => {
		setShowReplayButton(false);
		setIsListening(value);
		if (value) {
			handleListeningLoop();
		} else {
			goToNextQuestion();
		}
	};
	const updateInterviewEnded = async () => {
		// await axios.post(`${API_URL}/labs/interview/end`, {
		//     interview_key: interviewKey,
		// });
		console.log('Interview Ended');
	};

	const updateAnswerToDatabase = async (s3FileName, file) => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('audioLinkKey', s3FileName);
			formData.append('interviewKey', interviewKey);
			formData.append('questionId', currentQuestionIndex);
			formData.append('answeringTime', answeringTime);
			const response = await axios.post(`${API_URL}/labs/saveCandidateAnswer`, formData);
			if (currentQuestionIndex === questionTimestamps.length - 2) {
				setCalculatingScoreLoading(true);
				const response = await axios.get(`${API_URL}/labs/scores/evaluate/${interviewKey}`);
				console.log(response.data);
				if (response) {
					navigate(`/demo/report/${interviewKey}`);
				}
			}
		} catch (error) {
			setError(true);
		}
	};

	const startInterview = () => {
		handleClose();
		setPlaying(true);
		setPlaysinline(true);
	};

	const replayQuestion = () => {
		reactPlayerRef.current.seekTo(questionTimestamps[currentQuestionIndex].startTime);
		setPlaying(true);
		setIsListening(false);
		setIsRecording(false);
		setShowReplayButton(false);
	};

	const handleStopInterview = () => {
		setStopInterview(true);
	};

	const handleRestartInterview = () => {
		navigate('/login/candidate');
	};

	const handleIntroVideoEnded = () => {
		setHasIntroVideoEnded(true);
		setShowCountdownTimer(true);
	};

	const removeCountdownTimer = () => {
		setShowCountdownTimer(false);
	};

	return (
		<>
			<Dialog open={calculatingScoreLoading}>
				<DialogContent>
					<Stack justifyContent={'center'} alignItems={'center'}>
						<CircularProgress />
						<DialogContentText>
							<b>Please Wait</b>
						</DialogContentText>
						<DialogContentText>While we calculate your score...</DialogContentText>
					</Stack>
				</DialogContent>
			</Dialog>
			<Dialog open={alertDialogOpen}>
				<DialogTitle display="flex" alignItems={'center'}>
					<WarningAmberIcon color="red" />
					<b>Warning</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						We noticed that you moved out of the interview.{' '}
						<b>The hiring team is tracking</b> this activity. We recommend staying on
						this page until you complete the interview.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						style={{
							textTransform: 'none',
							color: '#fff',
							backgroundColor: 'green',
						}}
						onClick={restartPlaying}
					>
						Agree & Continue
					</Button>
				</DialogActions>
			</Dialog>
			{isIphone ? (
				<>
					<Header />
					<Box
						p={3}
						display="flex"
						style={{ height: '85vh' }}
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="body2" fontWeight="bold" textAlign="center">
							The interview experience is not available on iPhones. Please use chrome
							or Firefox on your desktop to complete the interview.
						</Typography>
					</Box>
				</>
			) : (
				<div>
					{stopInterview ? (
						<div>
							<p>
								In order to give this interview, you will have to share your Entire
								Screen.
							</p>
							<p>Please press Back Button and restart the interview.</p>
							<Button
								variant="contained"
								onClick={handleRestartInterview}
								sx={{ textTransform: 'none', marginTop: 3 }}
							>
								Restart Interview
							</Button>
						</div>
					) : (
						<>
							<MobileView>
								<div>
									<Modal
										open={open}
										// onClose={handleClose}
										aria-labelledby="modal-modal-title"
										aria-describedby="modal-modal-description"
									>
										<Stack
											justifyContent="center"
											alignItems="center"
											sx={{
												position: 'absolute',
												top: '50%',
												left: '50%',
												transform: 'translate(-50%, -50%)',
												width: '400px',
												p: 4,
											}}
										>
											<Button
												variant="contained"
												onClick={startInterview}
												p={0}
												sx={{ width: '175px' }}
											>
												Start Interview
											</Button>
										</Stack>
									</Modal>
									{!hasInterviewEnded && <Header />}
									{loading && !hasInterviewEnded && <CircularStatic />}
									{!loading && (
										<>
											{!hasInterviewEnded &&
												hasIntroVideoEnded &&
												!showCountdownTimer && (
													<ReactPlayer
														ref={reactPlayerRef}
														url={mobileVideoUrl}
														// controls
														playing={playing}
														playsinline={playsinline}
														width="100%"
														height="90vh"
														progressInterval={1000}
														onProgress={handleProgress}
														onEnded={handleEnded}
														pip={false}
													/>
												)}
										</>
									)}
									{!loading && !!mobileIntroVideo && !hasIntroVideoEnded && (
										<ReactPlayer
											ref={introVideoRef}
											playing
											playsinline
											onEnded={handleIntroVideoEnded}
											width="100%"
											height="90vh"
											url={mobileIntroVideo}
											onContextMenu={(e) => e.preventDefault()}
										/>
									)}
									{showCountdownTimer && (
										<CountdownTimer
											removeCountdownTimer={removeCountdownTimer}
											timerText={'Interview starts in ...'}
										/>
									)}
									{questionsArray &&
										questionsArray.length > 0 &&
										currentQuestionIndex !== questionsArray.length - 1 &&
										!loading &&
										!hasInterviewEnded &&
										hasIntroVideoEnded &&
										!showCountdownTimer && (
											<Stack
												direction="row"
												width={'90vw'}
												display={'flex'}
												justifyContent={'center'}
												alignItems={'center'}
												position={'absolute'}
												top={'90px'}
												right={'20px'}
												left={'20px'}
											>
												<QuestionPopper
													questionTitle={`Question ${
														currentQuestionIndex + 1
													} of ${questionsArray.length - 1}`}
													questionText={
														questionsArray[currentQuestionIndex]
													}
												/>
											</Stack>
										)}
									<Stack
										direction="row"
										width={'100vw'}
										display={'flex'}
										justifyContent={'center'}
										alignItems={'center'}
										position={'absolute'}
										bottom={0}
										gap={'20px'}
									>
										{isRecording && !hasInterviewEnded && (
											<AudioRecorder
												interviewKey={interviewKey}
												handleAnswer={updateAnswerToDatabase}
												updateIsListening={updateIsListening}
												questionId={currentQuestionIndex}
												candidateName={fullName}
												isPractice={false}
												enabledRecording={isRecording}
												setAnsweringTime={setAnsweringTime}
											/>
										)}
										{showReplayButton && (
											<ReplayButton replayQuestion={replayQuestion} />
										)}
									</Stack>
								</div>
							</MobileView>
							<BrowserView>
								{!hasInterviewEnded && <Header />}
								{loading && !hasInterviewEnded && <CircularStatic />}
								{!loading && (
									<>
										{!hasInterviewEnded &&
											hasIntroVideoEnded &&
											!showCountdownTimer && (
												<ReactPlayer
													ref={reactPlayerRef}
													url={videoUrl}
													// controls
													playing={desktopPlaying}
													playsinline={desktopPlaysinline}
													width="100%"
													height="90vh"
													progressInterval={1000}
													onProgress={handleProgress}
													onEnded={handleEnded}
													pip={false}
													onContextMenu={(e) => e.preventDefault()}
												/>
											)}
									</>
								)}
								{!loading && !!desktopIntroVideo && !hasIntroVideoEnded && (
									<ReactPlayer
										ref={introVideoRef}
										playing
										playsinline
										onEnded={handleIntroVideoEnded}
										width="100%"
										height="90vh"
										url={desktopIntroVideo}
										pip={false}
									/>
								)}
								{showCountdownTimer && (
									<CountdownTimer
										removeCountdownTimer={removeCountdownTimer}
										timerText={'Interview starts in ...'}
									/>
								)}
								{questionsArray &&
									questionsArray.length > 0 &&
									currentQuestionIndex !== questionsArray.length - 1 &&
									!loading &&
									!hasInterviewEnded &&
									hasIntroVideoEnded &&
									!showCountdownTimer && (
										<Stack
											direction="row"
											width={'100vw'}
											display={'flex'}
											justifyContent={'left'}
											alignItems={'left'}
											position={'absolute'}
											left={'20px'}
											top={'80px'}
										>
											<QuestionPopper
												questionTitle={`Question ${
													currentQuestionIndex + 1
												} of ${questionsArray.length - 1}`}
												questionText={questionsArray[currentQuestionIndex]}
											/>
										</Stack>
									)}
								<Stack
									direction="row"
									width={'100vw'}
									display={'flex'}
									justifyContent={'center'}
									alignItems={'center'}
									position={'absolute'}
									bottom={'80px'}
									gap={'20px'}
								>
									{isRecording && !hasInterviewEnded && (
										<AudioRecorder
											interviewKey={interviewKey}
											handleAnswer={updateAnswerToDatabase}
											updateIsListening={updateIsListening}
											questionId={currentQuestionIndex}
											candidateName={fullName}
											isPractice={false}
											enabledRecording={isRecording}
											setAnsweringTime={setAnsweringTime}
										/>
									)}
									{showReplayButton && (
										<ReplayButton replayQuestion={replayQuestion} />
									)}
								</Stack>
							</BrowserView>
							{/* <EndScreen interviewKey={interviewKey} fullName={fullName} hasVideoUploaded=} /> */}
						</>
					)}
				</div>
			)}
		</>
	);
};
