import React from 'react';
import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MuiChipsInput } from 'mui-chips-input';

const Questions = (props) => {
	const { details, setDetails } = props;
	const isMobile = window.innerWidth <= 600;
	return (
		<Stack spacing={2}>
			{details.questions.map((question, index) => {
				return (
					<Stack spacing={2}>
						<Stack
							spacing={2}
							justifyContent={'space-between'}
							direction={'row'}
							alignItems={'center'}
						>
							<Typography fontWeight={'bold'}>Question {index + 1}</Typography>
							{index !== 0 && (
								<Button
									style={{
										width: 'fit-content',
										color: '#224C84',
										textTransform: 'none',
										textDecoration: 'underline',
									}}
									onClick={() => {
										setDetails((prev) => {
											const newQuestions = [...prev.questions];
											newQuestions.splice(index, 1);
											return {
												...prev,
												questions: newQuestions,
											};
										});
										// change question_id
										setDetails((prev) => {
											const newQuestions = [...prev.questions];
											newQuestions.forEach((question, index) => {
												question.question_id = index;
											});
											return {
												...prev,
												questions: newQuestions,
											};
										});
									}}
								>
									Remove Question
								</Button>
							)}
						</Stack>
						<TextField
							label={'Question Text'}
							value={question.question_text}
							onChange={(e) => {
								setDetails((prev) => {
									const newQuestions = [...prev.questions];
									newQuestions[index].question_text = e.target.value;
									return {
										...prev,
										questions: newQuestions,
									};
								});
							}}
							fullWidth
						/>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<FormControl
								style={{
									width: '300px',
								}}
								disabled={question.is_last_question || question.skip_evaluation}
							>
								<InputLabel>Skill Type</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={question.skill_type}
									label="Age"
									onChange={(e) => {
										setDetails((prev) => {
											const newQuestions = [...prev.questions];
											newQuestions[index].skill_type = e.target.value;
											return {
												...prev,
												questions: newQuestions,
											};
										});
									}}
								>
									<MenuItem value={'tech'}>Tech</MenuItem>
									<MenuItem value={'soft_skill'}>Soft Skill</MenuItem>
								</Select>
							</FormControl>
							<TextField
								label={'Question Skill'}
								value={question.skill}
								onChange={(e) => {
									setDetails((prev) => {
										const newQuestions = [...prev.questions];
										newQuestions[index].skill = e.target.value;
										return {
											...prev,
											questions: newQuestions,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								disabled={question.is_last_question || question.skip_evaluation}
							/>
						</Stack>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<FormControlLabel
								disabled={question.skip_evaluation}
								control={
									<Checkbox
										checked={question.is_last_question}
										onChange={(e) => {
											setDetails((prev) => {
												const newQuestions = [...prev.questions];
												newQuestions[index].is_last_question =
													e.target.checked;
												if (e.target.checked) {
													newQuestions[index].skill = 'NA';
													newQuestions[index].sub_skill = ['NA'];
													newQuestions[index].definition = 'NA';
													newQuestions[index].skill_type = '';
												} else {
													newQuestions[index].skill = '';
													newQuestions[index].sub_skill = [];
													newQuestions[index].definition = '';
												}
												return {
													...prev,
													questions: newQuestions,
												};
											});
										}}
									/>
								}
								label="Is Last Question?"
							/>
							<FormControlLabel
								disabled={question.is_last_question}
								control={
									<Checkbox
										checked={question.skip_evaluation}
										onChange={(e) => {
											setDetails((prev) => {
												const newQuestions = [...prev.questions];
												newQuestions[index].skip_evaluation =
													e.target.checked;
												if (e.target.checked) {
													newQuestions[index].skill = 'NA';
													newQuestions[index].sub_skill = ['NA'];
													newQuestions[index].skill_type = '';
												} else {
													newQuestions[index].skill = '';
													newQuestions[index].sub_skill = [];
												}
												return {
													...prev,
													questions: newQuestions,
												};
											});
										}}
									/>
								}
								label="Is Skippable?"
							/>
						</Stack>
						<MuiChipsInput
							style={{
								maxWidth: isMobile ? '300px' : '610px',
							}}
							label="Sub Skills"
							value={question.sub_skill}
							helperText={
								question.sub_skill.length > 0 && 'Double click to edit sub skill'
							}
							onChange={(newChips) => {
								setDetails((prev) => {
									const newQuestions = [...prev.questions];
									newQuestions[index].sub_skill = newChips;
									return {
										...prev,
										questions: newQuestions,
									};
								});
							}}
							disabled={question.is_last_question || question.skip_evaluation}
						/>

						<TextField
							label={'Definition'}
							value={question.definition}
							onChange={(e) => {
								setDetails((prev) => {
									const newQuestions = [...prev.questions];
									newQuestions[index].definition = e.target.value;
									return {
										...prev,
										questions: newQuestions,
									};
								});
							}}
							fullWidth
							multiline
							rows={4}
							disabled={question.is_last_question}
						/>
					</Stack>
				);
			})}
			<Button
				style={{
					width: 'fit-content',
					color: '#224C84',
					textTransform: 'none',
					textDecoration: 'underline',
				}}
				onClick={() => {
					setDetails((prev) => ({
						...prev,
						questions: [
							...prev.questions,
							{
								question_text: '',
								is_last_question: false,
								question_id: details.questions.length,
								start_time: null,
								end_time: null,
								skill_type: '',
								skip_evaluation: false,
								skill: '',
								sub_skill: [],
								definition: '',
							},
						],
					}));
				}}
			>
				Add More Questions
			</Button>
		</Stack>
	);
};

export default Questions;
