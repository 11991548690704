import React from 'react';

const BulletPoints = ({ reason }) => {
	// Split the reason into an array of sentences
	let reasonArray;
	if (typeof reason === 'string') {
		reasonArray = reason.split('.').filter((sentence) => sentence.trim() !== '');
	} else {
		reasonArray = reason;
	}

	return (
		<ul>
			{reasonArray.map((sentence, index) => (
				<li key={index}>{sentence.trim()}</li>
			))}
		</ul>
	);
};

export default BulletPoints;
