export const REACT_APP_ENV = process.env.REACT_APP_ENV;
let API_URL = '';
switch (REACT_APP_ENV) {
	case 'stagingenv':
		API_URL = 'https://api-qa-service.aihyr.com';
		break;
	case 'production':
		API_URL = 'https://api-service.aihyr.com';
		break;
	default:
		API_URL = 'http://localhost:3001';
		break;
}
export const PROCTORING_TOOL = 'merittrac';
export const TEST_AUDIO_URL = 'https://aihyrprodstorage.blob.core.windows.net/audio/testaudio.mp3';
export { API_URL };
