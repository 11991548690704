import React from 'react';
import { Stack, TextField } from '@mui/material';

const Videos = (props) => {
	const { details, setDetails } = props;
	const onChange = (e) => {
		const { name, value } = e.target;
		setDetails({
			...details,
			[name]: value,
		});
	};
	return (
		<Stack spacing={2}>
			<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
				<TextField
					label={'Desktop Full Video Link'}
					name="question_video_link"
					value={details.question_video_link}
					onChange={onChange}
					style={{
						width: '300px',
					}}
				/>
				<TextField
					label={'Mobile Full Video Link'}
					name="mobile_question_video_link"
					value={details.mobile_question_video_link}
					onChange={onChange}
					style={{
						width: '300px',
					}}
				/>
			</Stack>
			<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
				<TextField
					label={'Desktop Intro Video Link'}
					name="desktop_intro_video"
					value={details.desktop_intro_video}
					onChange={onChange}
					style={{
						width: '300px',
					}}
				/>
				<TextField
					label={'Mobile Intro Video Link'}
					value={details.mobile_intro_video}
					name="mobile_intro_video"
					onChange={onChange}
					style={{
						width: '300px',
					}}
				/>
			</Stack>
		</Stack>
	);
};

export default Videos;
