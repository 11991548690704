import { useEffect, useState } from 'react';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import axios from 'axios';
import { useAdminContext } from '../../global/AdminContext';
import { useNavigate } from 'react-router';
import NewTable from './newTable';

export default function AdminDashboard() {
	const { admin } = useAdminContext();

	const navigate = useNavigate();
	const [jobPostings, setJobPostings] = useState([]);
	const [loading, setLoading] = useState(true);
	console.log(admin, 'admin details');

	useEffect(() => {
		if (admin.loading) return;
		// if (!admin.exists) navigate("/");
		setLoading(false);
	}, [admin, navigate]);

	useEffect(() => {
		// make api call

		async function getallcollegeinterviews() {
			try {
				if (admin.loading) return;
				if (!admin.exists) {
					setLoading(false);
					console.log('admin data not found. please login and try again');
					return;
				}
				console.log(admin.token, 'token sending');
				const response = await axios.get(`${API_URL}/labs/getallcollegeInterviews`, {
					headers: {
						Authorization: `Bearer ${admin.token}`,
					},
				});
				const { data: interviews } = response;
				console.log(interviews.data, '------interviews------');
				const result = interviews?.data?.map((interview) => {
					return {
						id: interview._id,
						jobRole: interview.role,
						allCompletedInterviews: interview.completed_count,
						allIncompletedInterviews: interview.incompleted_count,
						totalApplicants: interview.total,
						companyId: interview.company_id,
					};
				});
				console.log(result, '-------interview------');
				setJobPostings(result);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		}
		getallcollegeinterviews();

		// fetchAllJobPostings();
	}, [admin]);

	return (
		<>
			{loading ? (
				<CircularStatic />
			) : (
				<NewTable jobPostings={jobPostings} setJobPostings={setJobPostings} />
			)}
		</>
	);
}
