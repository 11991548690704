import { useState, useEffect } from 'react';
import { Stack, Typography, Box, Container, Button } from '@mui/material';
import { EndInterview } from '../EndInterview';
import { Header } from '../shared/Header';
import { InterviewTrivia } from '../InterviewTrivia';
import { Uploader } from '../../helpers/Uploader';
import { useNavigate, useParams } from 'react-router';
import SwiperRatingContainer from './swipercontainer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const EndScreen = () => {
	const params = useParams();
	const navigate = useNavigate();

	const fullName = params?.fullName;
	const [countdown, setCountdown] = useState(10); // Timer value in seconds
	const [hasReloaded, setHasReloaded] = useState(false);

	// Initial page reload useEffect
	useEffect(() => {
		// Check if the page has been reloaded by checking a sessionStorage flag
		if (!sessionStorage.getItem('pageReloaded')) {
			sessionStorage.setItem('pageReloaded', 'true');
			window.location.reload();
		} else {
			setHasReloaded(true); // Set flag to start countdown after reload
		}
	}, []);

	// Reset the session storage flag when the component unmounts
	useEffect(() => {
		return () => {
			// Remove the pageReloaded flag on component unmount
			sessionStorage.removeItem('pageReloaded');
		};
	}, []);

	// Countdown timer useEffect
	useEffect(() => {
		if (hasReloaded && sessionStorage.getItem('careerfairinterview')) {
			// Only start the timer if the countdown is greater than 0
			if (countdown > 0) {
				const timerId = setTimeout(() => {
					setCountdown((prev) => prev - 1);
				}, 1000);
				return () => clearTimeout(timerId); // Clear the timeout to avoid memory leaks
			} else {
				// When countdown reaches 0, navigate to the /career-fair page
				navigate('/career-fair');
			}
		}
	}, [countdown, hasReloaded, navigate]);

	return (
		<>
			<Header fullName={fullName} />
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					mt: '60px',
				}}
				maxWidth="md"
			>
				<Box>
					<Typography
						variant="h3"
						gutterBottom
						sx={{
							color: '#002244',
							fontWeight: 'bold',
						}}
					>
						Thank you for completing the interview!
					</Typography>
					{sessionStorage.getItem('mock-interview') && (
						<Button
							variant="outlined"
							sx={{ marginTop: 1, marginBottom: 1, padding: 1 }}
							onClick={() => navigate('/student/mock-interview/dashboard')}
						>
							Go to Dashboard
							<ArrowForwardIcon />
						</Button>
					)}
					<Typography variant="body1" gutterBottom sx={{ color: '#002244' }}>
						Your responses have been recorded. Our team will review your answers and get
						back to you soon.
					</Typography>
				</Box>
				<Box
					sx={{
						width: '80%',
						height: '225px',
						marginTop: 3,
					}}
				>
					<SwiperRatingContainer />
				</Box>
			</Container>
			{sessionStorage.getItem('careerfairinterview') && (
				<Box
					sx={{
						position: 'fixed',
						bottom: 20,
						left: 0,
						right: 0,
						textAlign: 'center',
					}}
				>
					<Typography variant="h6" sx={{ color: '#002244' }}>
						Redirecting in {countdown} seconds...
					</Typography>
				</Box>
			)}
		</>
	);
};
