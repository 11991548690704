import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import image1 from '../images/page3-1.png';
import CallIcon from '@mui/icons-material/Call';
import './style.css';
const About2 = () => {
	return (
		<Stack
			// bgcolor={"white"}
			// height={{ sm: "100vh", xs: "max-content" }}
			display="flex"
			justifyContent={{ sm: 'center', xs: 'start' }}
			// alignItems={"center"}
			// spacing={5}
			p={2}
		>
			<Stack direction={'row'} width={'90vw'}>
				<Stack alignItems={{ xs: 'center', sm: 'start' }} ml={{ sm: 15, xs: 0 }}>
					<img
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="300"
						className="img3"
						src={image1}
						style={{
							transform: 'rotate(-35deg)',
							marginTop: '20px',
							marginBottom: '5px',
						}}
						alt="img3"
					/>
					<Stack direction={{ sm: 'row', xs: 'column' }}>
						<Stack
							// width={{ sm: "50%", xs: "100%" }}
							display="flex"
							alignItems={{ xs: 'center', sm: 'start' }}
							data-aos="fade-up"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<Typography
								fontFamily={'Poppins'}
								fontWeight={'700'}
								fontSize={{ sm: '3.2rem', xs: '2.2rem' }}
								color={'#224C84'}
								mr={{ sm: 30, xs: 0 }}
								textAlign={{ xs: 'center', sm: 'start' }}
								pb={{ sm: 2, xs: 0 }}
							>
								Are you busy
								<br /> building a rocket
								<br /> ship?
							</Typography>
						</Stack>
						<Stack data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
							<Stack direction={'row'} display="flex" alignItems={'center'}>
								<Typography fontSize={{ sm: '4rem', xs: '2.8rem' }}>😡</Typography>
								<Typography
									color={'#224C84'}
									fontFamily={'Poppins'}
									fontSize={{ sm: '1.2rem', xs: '1rem' }}
								>
									Struggling to find the right crew?
								</Typography>
							</Stack>
							<Stack direction={'row'} display="flex" alignItems={'center'}>
								<Typography fontSize={{ sm: '4rem', xs: '2.8rem' }}>😰</Typography>
								<Typography
									color={'#224C84'}
									fontFamily={'Poppins'}
									fontSize={{ sm: '1.2rem', xs: '1rem' }}
								>
									Overwhelmed by number of applicants?
								</Typography>
							</Stack>
							<Stack direction={'row'} display="flex" alignItems={'center'}>
								<Typography fontSize={{ sm: '4rem', xs: '2.8rem' }}>😳</Typography>
								<Typography
									color={'#224C84'}
									fontFamily={'Poppins'}
									fontSize={{ sm: '1.2rem', xs: '1rem' }}
								>
									Trying to find the needle in the haystack?
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Button
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
				variant="contained"
				size="large"
				onClick={() => (window.location.href = 'https://meetings.hubspot.com/nrohatgi')}
				startIcon={<CallIcon fontSize="large" />}
				style={{
					backgroundColor: '#224C84',
					fontFamily: 'Poppins',
					fontSize: '1.2rem',
					textTransform: 'none',
					borderRadius: '15px',
					width: 'fit-content',
					alignSelf: 'center',
					marginTop: '20px',
				}}
			>
				Talk to us
			</Button>
		</Stack>
	);
};

export default About2;
