import React from 'react';

const QuestionGenerationPopup = ({ jobRole, onClose }) => {
	return (
		<div style={popupStyle}>
			<div style={popupContentStyle}>
				<h3>{jobRole}, Interview questions are being generated. Please wait...</h3>
				<button onClick={onClose} style={buttonStyle}>
					OK
				</button>
			</div>
		</div>
	);
};

const popupStyle = {
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0,0,0,0.5)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 1000,
};

const popupContentStyle = {
	backgroundColor: 'white',
	padding: '20px',
	borderRadius: '8px',
	boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
	textAlign: 'center',
};

const buttonStyle = {
	marginTop: '20px',
	padding: '10px 20px',
	backgroundColor: '#007BFF',
	color: 'white',
	border: 'none',
	borderRadius: '5px',
	cursor: 'pointer',
};

export default QuestionGenerationPopup;
