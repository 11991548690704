import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { Box, IconButton, Paper, Stack, Tooltip, styled } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import LoadingDialog from '../CodeEditor/LoadingDialog';
import DeleteInterviewDialog from './DeleteInterviewDialog';

const MyTable = ({ data }) => {
	console.log(data, 'insid table');
	const [jobsData, setJobsData] = useState([]);
	const navigate = useNavigate();
	const { type } = useParams();
	const labeltype = type === 'jobs-colleges' ? 'College' : 'Company';
	const [jobId, setJobId] = useState('');
	const [pilotId, setPilotId] = useState('');
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [jobRole, setJobRole] = useState('');
	const [loading, setLoading] = useState(false);

	const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
		'& .MuiDataGrid-columnHeaders': {
			background: '#224C84',
		},
		'& .MuiDataGrid-menuIcon button': {
			color: '#fff',
		},
		'& .MuiDataGrid-iconButtonContainer button': {
			color: '#fff',
		},
		'&  .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
			color: '#fff',
			fontWeight: 'bold',
		},
	}));
	const columns = [
		{
			field: 'companyName',
			headerName: `${labeltype} Name`,
			width: 200,
		},
		{
			field: 'companyEmail',
			headerName: `${labeltype} Email`,
			width: 200,
		},
		{
			field: 'jobRole',
			headerName: 'Job Role',
			width: 230,
		},
		{
			field: 'jobId',
			headerName: 'Job ID',
			width: 240,
		},
		{
			field: 'action',
			headerName: 'Interview Actions',
			width: 200,
			renderCell: (params) => (
				<>
					<Tooltip
						title={
							params.row.is_interview_created
								? 'Interview Already Created'
								: 'Create Interview'
						}
					>
						{params.row.is_interview_created ? (
							<div>
								<IconButton
									disabled={params.row.is_interview_created}
									sx={{
										color: '#224C84',
									}}
								>
									<VideoCallIcon />
								</IconButton>
							</div>
						) : (
							<IconButton
								disabled={params.row.is_interview_created}
								onClick={() =>
									navigate(`/create-interview-questions/${params.row.jobId}`)
								}
								sx={{
									color: '#224C84',
								}}
							>
								<VideoCallIcon />
							</IconButton>
						)}
					</Tooltip>

					<Tooltip
						title={
							!params.row.is_interview_created
								? 'Interview Not Created'
								: 'Edit Interview'
						}
					>
						{!params.row.is_interview_created ? (
							<div>
								<IconButton
									disabled={!params.row.is_interview_created}
									sx={{
										color: '#224C84',
									}}
								>
									<EditIcon />
								</IconButton>
							</div>
						) : (
							<IconButton
								disabled={!params.row.is_interview_created}
								onClick={() =>
									navigate(
										`/edit-interview-questions/${params.row.jobId}/${params.row.pilot_id}`
									)
								}
								sx={{
									color: '#224C84',
								}}
							>
								<EditIcon />
							</IconButton>
						)}
					</Tooltip>
					<Tooltip
						title={
							!params.row.is_interview_created
								? 'Interview Not Created'
								: 'Delete Interview'
						}
					>
						{!params.row.is_interview_created ? (
							<div>
								<IconButton
									onClick={() => {
										setJobId(params.row.jobId);
										setPilotId(params.row.pilot_id);
										setJobRole(params.row.jobRole);
										setDeleteDialogOpen(true);
									}}
									sx={{
										color: 'red',
									}}
									disabled={!params.row.is_interview_created}
								>
									<DeleteIcon />
								</IconButton>
							</div>
						) : (
							<IconButton
								onClick={() => {
									setJobId(params.row.jobId);
									setPilotId(params.row.pilot_id);
									setJobRole(params.row.jobRole);
									setDeleteDialogOpen(true);
								}}
								sx={{
									color: 'red',
								}}
								disabled={!params.row.is_interview_created}
							>
								<DeleteIcon />
							</IconButton>
						)}
					</Tooltip>
				</>
			),
		},
	];
	console.log(columns, labeltype, 'columns');

	const deleteJob = async () => {
		setLoading(true);
		const { data } = await axios.put(
			`${API_URL}/labs/delete-pilot`,
			{
				job_id: jobId,
				pilot_id: pilotId,
			},
			{
				headers: {
					Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('admin')).token}`,
				},
			}
		);
		if (data.success) {
			// set is_interview_created to fasle
			const newJobsData = jobsData.map((job) => {
				if (job.jobId === jobId) {
					return {
						...job,
						is_interview_created: false,
					};
				}
				return job;
			});
			setJobsData(newJobsData);
			setJobId('');
			setPilotId('');
			toast.success(data.message);
			setDeleteDialogOpen(false);
		} else {
			toast.error(data.message);
		}
		setLoading(false);
	};
	useEffect(() => {
		setJobsData([]);
	}, [type]);
	useEffect(() => {
		console.log('setting', data);
		if (jobsData?.length > 0 && data.length > 0) {
			return;
		}
		data.forEach((companyData) => {
			companyData.jobs.forEach((job, i) => {
				setJobsData((prev) => [
					...prev,
					{
						id: job.job_id,
						companyName: companyData.company.name,
						companyEmail: companyData.company.email,
						jobRole: job.job_role,
						jobId: job.job_id,
						is_interview_created: job.is_interview_created,
						pilot_id: job.pilot_id,
					},
				]);
			});
		});
	}, [data]);
	console.log(jobsData, 'jobs Data');
	return (
		<Stack justifyContent={'center'} alignItems={'center'}>
			<LoadingDialog open={loading} />
			<DeleteInterviewDialog
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				loading={false}
				onClick={deleteJob}
				setJobId={setJobId}
				setPilotId={setPilotId}
				setJobRole={setJobRole}
				jobId={jobId}
				pilotId={pilotId}
				jobRole={jobRole}
			/>
			<Box sx={{ width: '80%', margin: 'auto', marginTop: '20px' }} bgcolor={'#ECF7FA'}>
				<Paper sx={{ width: '100%', mb: 2, p: 2 }} bgcolor={'#ECF7FA'}>
					<StyledDataGrid
						columns={columns}
						rows={jobsData}
						disableRowSelectionOnClick
						slots={{ toolbar: GridToolbar }}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 10,
								},
							},
						}}
						sx={{
							height: '65vh',
						}}
						pageSizeOptions={[5, 10, 15, 20]}
					/>
				</Paper>
			</Box>
		</Stack>
	);
};

export default MyTable;
