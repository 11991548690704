import { Typography } from '@mui/material';

export const InterviewInstructions = () => {
	return (
		<div style={{ height: '100%', overflowY: 'scroll' }}>
			<Typography variant="h5" component="h5">
				Welcome to AI.HYR!
			</Typography>
			<br />
			<Typography variant="body1">
				Before you begin, please take a moment to familiarize yourself with the instructions
				and guidelines for using our AI interview platform effectively.
			</Typography>
			<br />
			<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
				Preparation:
			</Typography>
			<ul>
				<li>Find a quiet and well-lit room for your video interview practice.</li>
				<li>Ensure that you have a stable internet connection and a working webcam.</li>
				<li>Test your microphone and speakers to ensure clear audio communication.</li>
			</ul>
			<br />
			<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
				Practice Interview Setup:
			</Typography>
			<ul>
				<li>
					Click on the "Start Practice Interview" button to initiate the practice session.
				</li>
				<li>You will be presented with a video of the interviewer.</li>
				<li>
					Listen to each question carefully and take a moment to gather your thoughts
					before responding.
				</li>
			</ul>
			<br />
			<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
				Recording your Responses:
			</Typography>
			<ul>
				<li>
					Once you are ready to begin answering the question, click on the "Record"
					button.
				</li>
				<li>When you are ready to stop recording, click on the “Record” button again.</li>
				<li>
					Each response will be automatically saved when you stop recording your response.
				</li>
			</ul>
			<br />
			<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
				AI Evaluation:
			</Typography>
			<ul>
				<li>
					Our advanced AI algorithms will analyze your recorded responses based on various
					criteria, including relevance of content, english proficiency and communication
					skills.
				</li>
				<li>
					A copy of your report will be sent to the Admin of AI.HYR and they will reach
					out to you with next steps.
				</li>
			</ul>
			<br />
			<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
				Privacy and Data Security:
			</Typography>
			<ul>
				<li>
					Your recorded responses will be securely stored and will not be shared with any
					third parties.
				</li>
			</ul>
			<br />
			Now that you're familiar with the instructions, let's get started! If you haven’t taken
			the Practice Interview yet, we highly recommend you Click{' '}
			<a href="/interview/practice/" style={{ color: 'blue', textDecoration: 'underline' }}>
				here
			</a>{' '}
			to begin your video interview practice. Good luck!
		</div>
	);
};
