import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { isValidEmail } from '../../helpers/utils';

const Admin = (props) => {
	const { details, setDetails } = props;
	return (
		<Stack spacing={2}>
			{details.admins.map((admin, index) => {
				return (
					<Stack spacing={2}>
						<Stack
							spacing={2}
							justifyContent={'space-between'}
							direction={'row'}
							alignItems={'center'}
						>
							<Typography fontWeight={'bold'}>Admin {index + 1}</Typography>
							{index !== 0 && (
								<Button
									style={{
										width: 'fit-content',
										color: '#224C84',
										textTransform: 'none',
										textDecoration: 'underline',
									}}
									onClick={() => {
										setDetails((prev) => {
											const newAdmins = [...prev.admins];
											newAdmins.splice(index, 1);
											return {
												...prev,
												admins: newAdmins,
											};
										});
									}}
								>
									Remove Admin
								</Button>
							)}
						</Stack>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<TextField
								label={'First Name'}
								value={admin.first_name}
								error={!admin.first_name}
								helperText={!admin.first_name && 'First Name is required'}
								onChange={(e) => {
									setDetails((prev) => {
										const newAdmins = [...prev.admins];
										newAdmins[index].first_name = e.target.value;
										return {
											...prev,
											admins: newAdmins,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
							<TextField
								label={'Last Name'}
								value={admin.last_name}
								error={!admin.last_name}
								helperText={!admin.last_name && 'Last Name is required'}
								onChange={(e) => {
									setDetails((prev) => {
										const newAdmins = [...prev.admins];
										newAdmins[index].last_name = e.target.value;
										return {
											...prev,
											admins: newAdmins,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
						</Stack>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<TextField
								label={'Email'}
								value={admin.email}
								error={!admin.email || !isValidEmail(admin.email)}
								helperText={
									(!admin.email && 'Email is required') ||
									(!isValidEmail(admin.email) && 'Please enter a valid email')
								}
								onChange={(e) => {
									setDetails((prev) => {
										const newAdmins = [...prev.admins];
										newAdmins[index].email = e.target.value;
										return {
											...prev,
											admins: newAdmins,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
							<TextField
								label={'Password'}
								value={admin.password}
								error={!admin.password}
								helperText={!admin.password && 'Password is required'}
								onChange={(e) => {
									setDetails((prev) => {
										const newAdmins = [...prev.admins];
										newAdmins[index].password = e.target.value;
										return {
											...prev,
											admins: newAdmins,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
						</Stack>
						<FormControlLabel
							control={
								<Checkbox
									checked={admin.roles.includes('super-admin')}
									onChange={(e) => {
										setDetails((prev) => {
											const newAdmins = [...prev.admins];
											if (e.target.checked) {
												newAdmins[index].roles.push('super-admin');
											} else {
												newAdmins[index].roles = newAdmins[
													index
												].roles.filter((role) => role !== 'super-admin');
											}
											return {
												...prev,
												admins: newAdmins,
											};
										});
									}}
								/>
							}
							label="Is Super Admin?"
						/>
					</Stack>
				);
			})}
			<Button
				style={{
					width: 'fit-content',
					color: '#224C84',
					textTransform: 'none',
					textDecoration: 'underline',
				}}
				onClick={() => {
					setDetails((prev) => ({
						...prev,
						admins: [
							...prev.admins,
							{
								first_name: '',
								last_name: '',
								email: '',
								password: '',
								roles: ['company-admin'],
							},
						],
					}));
				}}
			>
				Add More Admin
			</Button>
		</Stack>
	);
};

export default Admin;
