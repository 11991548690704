import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Header } from './shared/Header';

const PrivacyPolicy = () => {
	return (
		<>
			<Header />
			<Stack p={2} spacing={1} bgcolor={'#fff'}>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Privacy Policy
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'1.8rem'}>
					Providentia Labs Pvt. Ltd. (“AI.HYR”) is committed to protecting the integrity
					and privacy of the personal information that our users share with us.
				</Typography>
				<Typography fontSize={'0.8rem'}>Last Updated on: Aug 29, 2023</Typography>
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					This Privacy Policy describes.
				</Typography>
				{/* <Typography> */}
				<ul>
					<li>Your data protection rights</li>
					<li>What personal information we collect</li>
					<li>How we use the personal information</li>
					<li>How we share the personal information</li>
					<li>How we protect the personal information</li>
					<li>How the amendments to the policy are managed</li>
					<li>How you can contact us</li>
				</ul>
				{/* </Typography> */}
				<Typography>
					This policy applies to <b>aihyr.com</b> and its sub-domains
					<b>("Website")</b> and our Software and related services
					<b>("Services")</b>.
				</Typography>
				<Typography>
					Please read this Privacy Policy carefully and completely, in order to ensure
					that you are fully informed. If you have any questions or concerns about our use
					of your personal information, you can contact us using one of the mechanisms
					provided at the end of this document.
				</Typography>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					What AI.HYR does
				</Typography>
				<Typography>
					AI.HYR helps organizations in the areas of talent management through a variety
					of Services. Our customers/partners/users use our software and related services
					for one or more of the following:
				</Typography>
				<ul>
					<li>Manage the campus recruitment process</li>
					<li>Manage the lateral recruitment process</li>
					<li>Assess skills and personality of candidates/employees/students</li>
					<li>Interview candidates/employees/students</li>
				</ul>
				<Typography>
					AI.HYR is headquartered in India, but works with partners, services providers,
					and customers all around the globe. For more information, please visit our
					website{' '}
					<a href="https://aihyr.com/" target="_blank" rel="noreferrer">
						https://aihyr.com/
					</a>
				</Typography>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					Your data protection rights{' '}
				</Typography>
				<Typography>
					You have the following rights with respect to your personal information
					collected via our Website or Services:
				</Typography>
				<ul>
					<li>
						You have the right to access, correct, update, request portability, or
						request deletion of your personal information. If you wish to do so, you can
						contact us anytime.
					</li>
					<li>
						You have the right to object to processing of your personal information or
						restrict processing of your personal information. If you wish to do so, you
						can contact us anytime.
					</li>
					<li>
						You have the right to withdraw your previously provided consent at any time.
						If you wish to do so, you can contact us anytime.
					</li>
					<li>
						You have the right to file a complaint with your local data protection
						authority about our collection and processing of your personal information.
					</li>
					<li>Please note:</li>
					<ol>
						<li>
							If we have collected your personal information on behalf of our
							customer/partner, then you should contact the customer/partner directly.
						</li>
						<li>
							To protect your privacy, we reserve the right to verify your identity
							before complying with your request.
						</li>
						<li>
							Restricting the processing of your personal information, or deletion of
							your personal information may lead to you not being able to use some or
							all of our Websites and Services.
						</li>
						<li>
							Deletion of your personal information will be done immediately, but some
							residual data may be saved in certain logs which will get purged within
							a year after deleting your data.
						</li>
					</ol>
					<li>
						We do not knowingly collect, maintain, or use Personal Information from
						children under 18 years of age, and no part of our website is directed to
						children under the age of 18. We will take steps to delete it if we learn we
						have inadvertently collected it. If you learn that your child has provided
						us with Personal Information without your consent, you may alert us at
						nishtha@aihyr.com. If we learn that we have collected any Personal
						Information from children under 18, we will promptly take steps to delete
						such information.
					</li>
				</ul>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					What personal information is collected by AI.HYR{' '}
				</Typography>
				<Typography>
					The personal information we collect depends on whether you are using our{' '}
					<b>Website</b> or our <b>Services</b>.
				</Typography>
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					If you are using our Services{' '}
				</Typography>
				<Typography>
					If you are using our Services, then one of our customers/partners has asked you
					to use our systems.
				</Typography>
				<ul>
					<li>
						In such cases, we are acting as a data processor, and we are collecting and
						processing your personal information on their behalf and in accordance with
						their instructions.
					</li>
					<li>
						In such cases, our customers/partners are responsible for ensuring that your
						personal information is being collected and processed in accordance with
						data protection laws.
					</li>
					<li>
						In such cases, if you have questions or concerns about the use of your
						personal data, you should contact the relevant customer/partner.
					</li>
				</ul>
				<Typography>
					If you are using our Services, then we may be asked by our customers/partners to
					collect the following types of personal information:
				</Typography>
				<Typography fontWeight={'bold'}>Information provided by you voluntarily</Typography>
				<ul>
					<li>Email ID</li>
					<li>Login ID</li>
					<li>Name</li>
					<li>Phone Number</li>
					<li>Date of Birth</li>
					<li>Gender</li>
					<li>Profile/Display Picture</li>
					<li>Copy of Government-issued ID</li>
					<li>Country, state, city, postal code, full postal address</li>
					<li>Educational Details: University/college, grades, degree, branch, dates</li>
					<li>Professional Details: Previous/current employers, dates, salary</li>
					<li>Resume/CV</li>
					<li>Social profiles like LinkedIn, Github, etc.</li>
				</ul>
				<Typography fontWeight={'bold'}>
					Information collected by AI.HYR automatically{' '}
				</Typography>
				<ul>
					<li>Your recruitment status - as per the customer/partner process</li>
					<li>Your assessment performance - submitted answers, test scores, rank</li>
					<li>
						Your video, audio recording and captured live images through the proctored
						assessments and interviews
					</li>
					<li>Your IP address, and its derived geographic location</li>
					<li>Your device info - type, device identification numbers, browser used</li>
					<li>Your Service usage - Pages accessed, links clicked</li>
					<li>Other technical information</li>
					<li>
						To collect some of this information, we may use cookies and similar tracking
						mechanisms
					</li>
				</ul>
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					If you are using our Website{' '}
				</Typography>
				<Typography>
					If you are using our Website, then you have directly engaged with AI.HYR.
				</Typography>
				<ul>
					<li>
						In such cases, we are acting as a data controller, and we as AI.HYR are
						collecting and processing your personal information.
					</li>
					<li>
						In such cases, we are responsible for ensuring that your personal
						information is being collected and processed in accordance with data
						protection laws.
					</li>
					<li>
						In such cases, if you have questions or concerns about the use of your
						personal data, you should contact AI.HYR.
					</li>
				</ul>
				<Typography>
					If you are using our Website, then we may collect the following types of
					personal information:
				</Typography>
				<Typography fontWeight={'bold'}>Information provided by you voluntarily</Typography>
				<ul>
					<li>Email ID</li>
					<li>Name</li>
					<li>Phone Number</li>
					<li>Country, State, City</li>
					<li>Current employer, your role</li>
					<li>Resume / CV</li>
				</ul>
				<Typography fontWeight={'bold'}>
					Information collected by AI.HYR automatically{' '}
				</Typography>
				<ul>
					<li>Your IP address, and its derived geographic location</li>
					<li>Your device info - type, device identification numbers, browser used</li>
					<li>Your Website usage - Pages accessed, links clicked</li>
					<li>Other technical information</li>
					<li>
						To collect some of this information, we may use cookies and similar tracking
						mechanisms
					</li>
				</ul>
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					Special note of third-party services
				</Typography>
				<Typography>
					Our Website and Services may contain links to other websites and services
					provided by third- parties, for example our Customers/Partners. Any personal
					information you provide on those third- party websites or services is provided
					directly to that third-party and is subject to that third-party's privacy
					policies. We are not responsible for the content or policies of third-party
					websites or services.
				</Typography>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					How AI.HYR uses the personal information.{' '}
				</Typography>
				<Typography>
					AI.HYR may use your personal information for the following purposes:
				</Typography>
				<ul>
					<li>To provide and enhance our Website and Services</li>
					<li>To improve the user experience of our Websites and Services</li>
					<li>To contact you in response to your request</li>
					<li>To contact you for administrative purposes or customer service purposes</li>
					<li>
						To track your status, submissions, video, audio, profile information as
						required for lawful delivery of our Services
					</li>
					<li>
						To verify your identity when subsequently logging in and using the Services
					</li>
					<li>
						To prevent you from navigating away from the test/interview window while an
						assessment/interview is ongoing
					</li>
					<li>
						To detect and prevent fraud, suspicious activities, and other illegal
						activities
					</li>
					<li>
						To analyze and improve products and services, collect feedback, enhance the
						Services, and evaluate the effectiveness of our marketing activities and
						overall services
					</li>
					<li>
						To perform statistical analysis on users of the Website and Services and
						track your use of the Website and Services
					</li>
					<li>
						To aggregate data; we may anonymize and aggregate data collected through our
						website and to create statistics that may be used for any purpose, provided
						that such statistics do not identify any customers or individuals or contain
						any Personal Information
					</li>
					<li>To comply with a law or regulation, court order, or other legal process</li>
					<li>To protect our rights and property</li>
					<li>To protect someone’s health, safety, or welfare</li>
				</ul>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					How AI.HYR shares the personal information.
				</Typography>
				<Typography>
					AI.HYR may disclose your personal information to the following types of
					recipients:
				</Typography>
				<ul>
					<li>To any person with your consent to the disclosure</li>
					<li>
						To our customers/partners on whose behalf we collected your personal
						information
					</li>
					<li>
						To our group companies and third-party service providers, while ensuring
						that we inform them that they must use your personal information only for
						the purposes disclosed in this Privacy Policy
					</li>
					<li>
						To a court, law enforcement body, government agency, court or other third
						party where we believe disclosure is necessary:
						<ol>
							<li>As a matter of applicable law or regulation</li>
							<li>To exercise, establish, or defend our legal rights</li>
							<li>To protect your vital interests or those of any other person</li>
						</ol>
					</li>
					<li>
						To a third-party in connection to a purchase, reorganization, merger, or
						acquisition of any part of our business, while ensuring that we inform them
						that they must use your personal information only for the purposes disclosed
						in this Privacy Policy
					</li>
				</ul>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					How AI.HYR protects the personal information{' '}
				</Typography>
				<Typography fontFamily={'Poppins'} fontWeight={'bold'} fontSize={'1.5rem'}>
					Data Protection
				</Typography>
				<Typography>
					To protect your personal information, we use all appropriate technical and
					organizational measures. We architect our systems to specifically protect your
					personal information, we regularly test our systems for intrusion/penetration,
					and we regularly get ourselves audited by third-parties for our technical and
					organizational security measures.
				</Typography>
				<Typography>
					However, please note that no system is completely secure/immune from attacks,
					intrusions, design errors, implementation errors, and human errors. Therefore,
					we do not warrant that the information you transmit via AI.HYR will not be lost
					or compromised.
				</Typography>
				<Typography fontFamily={'Poppins'} fontWeight={'bold'} fontSize={'1.5rem'}>
					Data Retention
				</Typography>
				<Typography>
					We retain the personal information for only as long as we have legitimate
					business reasons to do so.
				</Typography>
				<ul>
					<li>
						If we collected your personal information on behalf of our
						customers/partners, then we will retain your personal information as per
						instructions by our customers/partners.
					</li>
					<li>
						If we collected your personal information as AI.HYR, then we will retain
						your personal information as long as you are active in our systems for a
						legitimate business reason.
					</li>
				</ul>
				<Typography fontFamily={'Poppins'} fontWeight={'bold'} fontSize={'1.5rem'}>
					Data Transmission
				</Typography>
				<Typography>
					We are headquartered in India, but we use partners, systems &amp; services
					providers from across the globe. We store and process your personal information
					on third-party servers, which may be located in India, Singapore, Europe, or
					USA. These countries may have data protection laws that are different from the
					data protection laws in your country.
				</Typography>
				<Typography>
					As and when required, we will ensure that your personal information will remain
					protected in accordance with our Privacy Policy and the applicable laws.
				</Typography>
				<Typography fontFamily={'Poppins'} fontWeight={'bold'} fontSize={'1.5rem'}>
					Amendments to the policy{' '}
				</Typography>
				<Typography>
					Amendments to this policy will be posted on this URL, and those amendments will
					be effective from the time they get posted. Your continued use of our Website
					and Services, after we post any amendment, shall constitute your acceptance of
					the amendment.
				</Typography>
				<hr />
				<Typography fontFamily={'Poppins'} fontSize={'1.8rem'}>
					How to contact AI.HYR{' '}
				</Typography>
				<Typography>
					<b>Email: </b>
					<a href="mailto:nishtha@aihyr.com"> nishtha@aihyr.com</a>
				</Typography>
				<Typography>
					<b>Website: </b> <a href="https://aihyr.com">https://aihyr.com</a>
				</Typography>
				<Typography>
					<b>Phone:</b> <a href="tel:+919922002219">+919922002219</a>
				</Typography>
			</Stack>
		</>
	);
};

export default PrivacyPolicy;
