import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import LoadingDialog from '../CodeEditor/LoadingDialog';
import { Header } from '../shared/Header';
import MyTable from './MyTable';
const CompaniesAndJobs = () => {
	const navigate = useNavigate();
	const [data, setData] = React.useState([]);
	const location = useLocation();
	const [loading, setLoading] = React.useState(false);
	const [company, setCompany] = React.useState({
		name: '',
		id: '',
	});
	const { type } = useParams();
	const role = type === 'jobs-companies' ? 'company' : 'college';

	const [companies, setCompanies] = React.useState([]);
	const getData = async () => {
		setLoading(true);
		const { data } = await axios.get(`${API_URL}/labs/allCompaniesAndJobPostings`, {
			params: {
				role: role,
			},
		});
		setData(data.data);
		let companies = [];
		for (let company of data.data) {
			companies.push({
				name: company.company.name,
				id: company.company._id,
			});
		}
		setCompanies(companies);
		setLoading(false);
		console.log(companies);
	};

	React.useEffect(() => {
		getData();
	}, [type, location]);
	return (
		<>
			<Header />
			<Toaster />
			<LoadingDialog open={loading} />
			<Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
				<Typography
					variant="h6"
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '30px',
						fontWeight: 'bold',
						// marginBottom: "50px",
					}}
				>
					{type === 'jobs-colleges' ? `All Colleges` : `All Companies`}
				</Typography>

				{
					<Stack
						direction={{ md: 'row', sm: 'column' }}
						justifyContent={'center'}
						alignItems={'center'}
						spacing={1}
					>
						{companies.length > 0 && (
							<>
								<FormControl
									style={{
										width: '300px',
									}}
									size="small"
								>
									<InputLabel>
										{type === 'jobs-colleges'
											? `Choose College`
											: `Choose Company`}
									</InputLabel>
									<Select
										label="Choose Company"
										value={company.id}
										onChange={(e) => {
											console.log(e.target.value);
											setCompany({
												id: e.target.value,
												name: companies.find(
													(company) => company.id === e.target.value
												).name,
											});
										}}
									>
										{companies.map((company) => (
											<MenuItem value={company.id}>{company.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								<Button
									sx={{
										color: 'white',
										backgroundColor: '#224C84',
										'&:hover': {
											backgroundColor: '#224C84',
										},
										// marginBottom: "50px",

										textTransform: 'none',
										width: 'fit-content',
									}}
									onClick={() => {
										if (company.id) {
											navigate(`/create-job-posting/${role}/${company.id}`);
										} else {
											toast.error(`Please select a ${role}`);
										}
									}}
								>
									Create Job Posting
								</Button>
								<Button
									sx={{
										color: 'white',
										backgroundColor: '#224C84',
										'&:hover': {
											backgroundColor: '#224C84',
										},
										// marginBottom: "50px",

										textTransform: 'none',
										width: 'fit-content',
									}}
									onClick={() => {
										if (company.id) {
											navigate(`/edit-company/${company.id}`);
										} else {
											toast.error(`Please select a ${role}`);
										}
									}}
								>
									{type === 'jobs-colleges' ? `Edit College` : `	Edit Company`}
								</Button>
							</>
						)}
						<Button
							sx={{
								color: 'white',
								backgroundColor: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
								},
								// marginBottom: "50px",

								textTransform: 'none',
								width: 'fit-content',
							}}
							onClick={() => {
								if (type === 'jobs-colleges') {
									navigate('/create/create-college');
								} else {
									navigate('/create/create-company');
								}
							}}
						>
							{type === 'jobs-colleges'
								? `Create New College`
								: `   Create New Company`}
						</Button>
					</Stack>
				}
			</Stack>
			<MyTable data={data} />
		</>
	);
};

export default CompaniesAndJobs;
