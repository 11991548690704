import { createContext } from 'react';

export const InterviewContext = createContext({
	interviewEnded: false,
	setInterviewEnded: (value) => {},
});

// const initialState = {
//   interviewEnded: false
// };

// export const AdminContextProvider = ({ children }) => {
//   const [admin, setAdmin] = useState(initialState);

//   const logout = () => {
//     sessionStorage.removeItem("admin");
//     setAdmin(initialState);
//   };

//   useEffect(() => {
//     const adminData = JSON.parse(sessionStorage.getItem("admin"));

//     if (adminData) {
//       setAdmin({
//         ...adminData,
//         loading: false,
//         exists: true,
//       });
//     } else {
//       setAdmin({
//         loading: false,
//         exists: false,
//       })
//     }
//   }, []);

//   const value = { admin, setAdmin, logout };
//   return (
//     <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
//   );
// };

// export const useAdminContext = () => {
//   return useContext(AdminContext);
// };
