import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import telephone from '../images/telephone.png';
import footer from '../images/footer.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const Contact = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [number, setNumber] = useState('');
	const [company, setCompany] = useState('');
	const sendMail = async () => {
		if (
			name.length === 0 ||
			email.length === 0 ||
			number.length === 0 ||
			company.length === 0
		) {
			toast.error('Field cannot be empty!!');
			return;
		}
		setLoading(true);
		const response = await axios.post(`${API_URL}/labs/mail/contact`, {
			name,
			email,
			number,
			company,
		});
		if (response.data.success) {
			toast.success('Message sent successfully, AI.HYR will contact you shortly!!', {
				duration: 3000,
				position: 'top-center',
			});
			setName('');
			setEmail('');
			setCompany('');
			setNumber('');
		} else {
			toast.error('Error Sending Message, Try Again!!', {
				duration: 3000,
				position: 'top-center',
			});
		}

		setLoading(false);
	};
	return (
		<Stack id="contact">
			<Toaster />
			{/* <Stack p={3} alignItems={{ sm: "start", xs: "center" }}>
                <img src={bluelogo} width={"200px"} alt="logo" />
            </Stack> */}
			<Stack
				direction={{ sm: 'row', xs: 'column' }}
				alignItems={{ sm: 'start', xs: 'center' }}
				p={2}
			>
				<Stack
					width={{ sm: '50%' }}
					// p={{ sm: 5, xs: 2 }}
					ml={{ sm: 15, xs: 0 }}
					justifyContent={'center'}
					alignItems={{ sm: 'start', xs: 'center' }}
				>
					<img src={telephone} width={'100px'} alt="logo" />
					<Typography
						fontFamily={'Poppins'}
						fontWeight={'700'}
						fontSize={{ sm: '2.8rem', xs: '1.5rem' }}
						color={'#224C84'}
						textAlign={'center'}
					>
						Contact Us
					</Typography>
				</Stack>
				<Stack spacing={1} width={{ sm: '50%' }} p={2}>
					<TextField
						value={name}
						onChange={(e) => setName(e.target.value)}
						label="Full Name"
						variant="outlined"
						style={{
							width: '350px',
						}}
						required
					/>
					<TextField
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						label="Email"
						variant="outlined"
						style={{
							width: '350px',
						}}
						required
					/>
					<TextField
						value={number}
						onChange={(e) => setNumber(e.target.value)}
						label="Phone Number"
						variant="outlined"
						style={{
							width: '350px',
						}}
						required
					/>
					<TextField
						value={company}
						onChange={(e) => setCompany(e.target.value)}
						label="Company"
						variant="outlined"
						style={{
							width: '350px',
						}}
						required
					/>
					<Button
						variant="contained"
						endIcon={
							loading ? (
								<CircularProgress size={'30px'} color="inherit" />
							) : (
								<SendIcon />
							)
						}
						disabled={loading}
						onClick={sendMail}
						style={{
							backgroundColor: '#224C84',
							color: '#fff',
							textTransform: 'none',
							fontWeight: '600',
							width: '350px',
							height: '50px',
						}}
					>
						{loading ? 'Sending...' : 'Send'}
					</Button>
				</Stack>
			</Stack>
			<Stack
				height={'30vh'}
				direction={{ sm: 'row', xs: 'column-reverse' }}
				alignItems={{ sm: 'end', xs: 'start' }}
				justifyContent={'end'}
				style={{
					backgroundImage: `url(${footer})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
			>
				<Stack
					width={{ sm: '50%', xs: '100%' }}
					direction={'row'}
					pl={{ sm: 2 }}
					justifyContent={{ xs: 'center', sm: 'start' }}
				>
					<Button
						// variant="contained"
						onClick={() => navigate('/terms-of-use')}
						style={{
							// backgroundColor: "#224C84",
							color: '#fff',
							fontFamily: 'Poppins',
							fontSize: '1.2rem',
							textTransform: 'none',
							// borderRadius: "15px",
						}}
					>
						Terms of use
					</Button>
					<Button
						onClick={() => navigate('/privacy-policy')}
						style={{
							// backgroundColor: "#224C84",
							color: '#fff',
							fontFamily: 'Poppins',
							fontSize: '1.2rem',
							textTransform: 'none',
							// borderRadius: "15px",
						}}
					>
						Privacy Policy
					</Button>
				</Stack>
				<Stack
					direction={'row'}
					width={{ sm: '50%', xs: '100%' }}
					justifyContent={{ xs: 'center', sm: 'start' }}
				>
					<Button
						// variant="contained"
						size="large"
						onClick={() =>
							window.open('https://twitter.com/aihyr_official', '_blank', 'noopener')
						}
						style={{
							// backgroundColor: "#D2ECF6",
							color: '#ffff',
							textTransform: 'none',
							fontWeight: '600',
						}}
					>
						<TwitterIcon
							color={'#fff'}
							style={{
								fontSize: '3rem',
								padding: 0,
							}}
						/>
					</Button>
					<Button
						// variant="contained"
						size="large"
						onClick={() =>
							window.open(
								'https://www.linkedin.com/company/aihyr/',
								'_blank',
								'noopener'
							)
						}
						style={{
							// backgroundColor: "#D2ECF6",
							color: '#ffff',
							textTransform: 'none',
							fontWeight: '600',
						}}
					>
						<LinkedInIcon
							color={'#fff'}
							style={{
								fontSize: '3rem',
								padding: 0,
							}}
						/>
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Contact;
