import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from './demo.module.css';
import { CircularStatic } from '../CircularProgressWithLabel';
import { Button, Modal, TextField, Box, Stack, styled } from '@mui/material';
import { API_URL } from '../../global/constants';
import axios from 'axios';
import { useAdminContext } from '../../global/AdminContext';
import { InterviewInstructions } from '../InterviewInstructions';
import { Header } from '../shared/Header';
import { demoMapping } from './demo-mapping';

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	height: '80%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export const Demo = (props) => {
	const navigate = useNavigate();
	const { admin, setAdmin } = useAdminContext();
	const [loginType, setLoginType] = useState(props?.type || null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [candidateCode, setCandidateCode] = useState();
	const [isLoading, setisLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isOpenInstructionsModal, setIsOpenInstructionsModal] = useState(false);

	useEffect(() => {
		if (admin.loading) return;
		if (admin.exists) {
			navigate('/login/company');
		}
	}, [admin, navigate]);

	const switchLoginType = (value) => {
		setLoginType(value);
	};

	const handleCompanyLogin = async () => {
		try {
			setErrorMessage('');
			setisLoading(true);
			if (!email) setErrorMessage('email cannot be empty');
			if (!password) setErrorMessage('password cannot be empty');
			const result = await axios.post(`${API_URL}/labs/login`, {
				email,
				password,
			});
			setisLoading(false);
			setAdmin({
				...result.data,
				loading: false,
				exists: true,
			});
			sessionStorage.setItem('admin', JSON.stringify(result.data));
			navigate('/admin');
		} catch (error) {
			console.log(error);
			setErrorMessage(error.response.data.error);
			setisLoading(false);
		}
	};

	const validateKey = async () => {
		setisLoading(true);
		const interviewKey = demoMapping?.development[`${candidateCode}`];
		const result = await axios.get(`${API_URL}/labs/interview/${interviewKey}`);
		if (result?.data?.error) {
			setErrorMessage(result?.data?.message);
		} else {
			return result?.data?.fullName;
		}
	};

	const startInterview = async () => {
		const interviewKey = demoMapping?.[`${process.env.REACT_APP_ENV}`]?.[`${candidateCode}`];
		const validKey = await validateKey();
		if (validKey) {
			setisLoading(false);
			navigate(`/check/${validKey}/${interviewKey}`, {
				state: { interviewKey: interviewKey, fullName: validKey, isDemo: true },
			});
		} else {
			setisLoading(false);
			// setErrorMessage(
			//   "Invalid Key. Please contact the recruiter to get your interview key"
			// );
		}
	};

	const openInstructionsModal = () => {
		setIsOpenInstructionsModal(true);
	};

	const handleInstructionsModalClose = () => {
		setIsOpenInstructionsModal(false);
	};
	// const styles = theme => ({
	//   notchedOutline: {
	//     borderWidth: "1px",
	//     borderColor: "yellow !important"
	//   }
	// });
	const CustomTextField = styled(TextField)({
		'& label.Mui-focused': {
			color: '#224C84',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#B2BAC2',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#E0E3E7',
			},
			'&:hover fieldset': {
				borderColor: '#B2BAC2',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#224C84',
			},
		},
	});
	return (
		<>
			<Header />
			<Stack direction="column">
				<div className={styles.loginPageContainer}>
					{!isLoading && (
						<>
							<Link to="/interview/practice/" color="#224C84">
								Take a Practice Interview
							</Link>
							<div>Or</div>
						</>
					)}
					{!isLoading && (
						<div className={styles.loginContainer}>
							{loginType === 'company' && (
								<div>
									<h4>Enter Admin Email and Password</h4>
									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										id="outlined-basic"
										label="Enter Email"
										type="email"
										className="input-box"
										variant="outlined"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										id="outlined-basic2"
										label="Enter Password"
										type="password"
										variant="outlined"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<Button
										sx={{
											marginBottom: '20px',
											textTransform: 'none',
											width: '100%',
											backgroundColor: '#224C84',
											'&:hover': { backgroundColor: '#224C84' },
										}}
										variant="contained"
										onClick={handleCompanyLogin}
									>
										Login
									</Button>
									<div
										onClick={() => switchLoginType('candidate')}
										onKeyDown={() => switchLoginType('candidate')}
									>
										<h4 className={styles.switchLoginLink}>
											Are you a candidate? Go to Candidate login
										</h4>
									</div>
								</div>
							)}
							{loginType === 'candidate' && !isLoading && (
								<div>
									<h4>
										Please read the{' '}
										<span
											style={{
												color: '#224C84',
												textDecoration: 'underline',
												cursor: 'pointer',
											}}
											onClick={openInstructionsModal}
											onKeyDown={openInstructionsModal}
										>
											Interview Instructions
										</span>{' '}
										carefully before proceeding.
									</h4>
									<h4>Enter Interview Key to start Interview</h4>

									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										// id="outlined-basic"
										label="Enter Interview Key"
										variant="outlined"
										onChange={(e) => setCandidateCode(e?.target?.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<Button
										sx={{
											marginBottom: '20px',
											textTransform: 'none',
											width: '100%',
											backgroundColor: '#224C84',
											'&:hover': { backgroundColor: '#224C84' },
										}}
										variant="contained"
										onClick={startInterview}
									>
										Start Interview
									</Button>
									<div
										onClick={() => switchLoginType('company')}
										onKeyDown={() => switchLoginType('candidate')}
									>
										<h4 className={styles.switchLoginLink}>
											Not a candidate? Go to company login
										</h4>
									</div>
								</div>
							)}
						</div>
					)}
					{isLoading && (
						<div>
							<CircularStatic />
						</div>
					)}
					{isOpenInstructionsModal && (
						<Modal
							open={isOpenInstructionsModal}
							onClose={handleInstructionsModalClose}
							aria-labelledby="instructions-modal-title"
							aria-describedby="instructions-modal-description"
						>
							<Box sx={modalStyle}>
								<InterviewInstructions />
							</Box>
						</Modal>
					)}
				</div>
			</Stack>
		</>
	);
};
