import {
	Box,
	Button,
	Card,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import axios from 'axios';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import { isValidURL } from '../../helpers/utils';
import LoadingDialog from '../CodeEditor/LoadingDialog';
import { Header } from '../shared/Header';

const EditJob = () => {
	const params = useParams();
	const [job, setJob] = useState({
		job_role: '',
		type: '',
		job_description_link: '',
		faq_link: '',
		job_description: '',
		expected_ctc: '',
		qualifications: '',
		work_experience: '',
		skills_required: '',
		additional_requirements: '',
		expiry: '',
	});
	const [loading, setLoading] = useState(false);
	const isValid = () => {
		if (
			job.job_role === '' ||
			job.type === '' ||
			job.job_description_link === '' ||
			job.faq_link === '' ||
			job.job_description === '' ||
			job.qualifications === '' ||
			job.job_responsibilities === '' ||
			job.skills_required === '' ||
			job.work_experience === '' ||
			!isValidURL(job.job_description_link) ||
			!isValidURL(job.faq_link)
		) {
			return false;
		}
		return true;
	};

	const getData = async () => {
		setLoading(true);
		const { data } = await axios.get(`${API_URL}/labs/job-posting/${params.job_id}`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
			},
		});
		if (data.success) {
			setJob(data.data);
		} else {
			toast.error(data.message);
		}
		setLoading(false);
	};

	const updateJob = async () => {
		if (!isValid()) return toast.error('Please check fields again');
		setLoading(true);
		const { data } = await axios.put(
			`${API_URL}/labs/update-job-posting/${params.job_id}`,
			{
				...job,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			}
		);
		if (data.success) {
			toast.success(data.message);
		} else {
			toast.error(data.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<>
			<Toaster />
			<Header />
			<LoadingDialog open={loading} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '2rem',
					backgroundColor: '#ECF7FA',
				}}
			>
				<Card
					sx={{
						height: 'auto',
						// width: "500px",
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '30px',
						// backgroundColor: "#ECF7FA",
					}}
				>
					<Stack spacing={2}>
						<Stack
							spacing={2}
							justifyContent={'space-between'}
							// direction={"row"}
							// alignItems={"center"}
						>
							<Typography fontWeight={'bold'}>Edit Job</Typography>
							<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
								<TextField
									label={'Job Role'}
									value={job.job_role}
									error={!job.job_role}
									helperText={!job.job_role && 'Job Role is required'}
									onChange={(e) => {
										setJob((prev) => {
											return {
												...prev,
												job_role: e.target.value,
											};
										});
									}}
									style={{
										width: '300px',
									}}
									required
								/>
								<FormControl
									style={{
										width: '300px',
									}}
									error={!job.type}
									required
								>
									<InputLabel>Job Type</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={job.type}
										helperText={!job.type && 'Job Type is required'}
										label="Job Type"
										onChange={(e) => {
											setJob((prev) => {
												return {
													...prev,
													type: e.target.value,
												};
											});
										}}
									>
										<MenuItem value={'Remote'}>Remote</MenuItem>
										<MenuItem value={'In-Office'}>In-Office</MenuItem>
										<MenuItem value={'Hybrid'}>Hybrid</MenuItem>
									</Select>
									<FormHelperText>
										{!job.type && 'Job Type is required'}
									</FormHelperText>
								</FormControl>
							</Stack>
							<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
								<TextField
									label={'Job Description Link'}
									value={job.job_description_link}
									error={
										!job.job_description_link ||
										!isValidURL(job.job_description_link)
									}
									helperText={
										!job.job_description_link
											? 'Job Description Link is required'
											: !isValidURL(job.job_description_link) &&
												'Please enter a valid URL'
									}
									onChange={(e) => {
										setJob((prev) => {
											return {
												...prev,
												job_description_link: e.target.value,
											};
										});
									}}
									style={{
										width: '300px',
									}}
									required
								/>
								<TextField
									label={'Job FAQ Link'}
									value={job.faq_link}
									error={!job.faq_link || !isValidURL(job.faq_link)}
									helperText={
										!job.faq_link
											? 'Job FAQ Link is required'
											: !isValidURL(job.faq_link) &&
												'Please enter a valid URL'
									}
									onChange={(e) => {
										setJob((prev) => {
											return {
												...prev,
												faq_link: e.target.value,
											};
										});
									}}
									style={{
										width: '300px',
									}}
									required
								/>
							</Stack>
							<TextField
								label={'Job Description'}
								value={job.job_description}
								error={!job.job_description}
								helperText={!job.job_description && 'Job Description is required'}
								onChange={(e) => {
									setJob((prev) => {
										return {
											...prev,
											job_description: e.target.value,
										};
									});
								}}
								fullWidth
								multiline
								rows={4}
								required
							/>
							<TextField
								label={'Job Qualification'}
								value={job.qualifications}
								error={!job.qualifications}
								helperText={!job.qualifications && 'Job Qualifications is required'}
								onChange={(e) => {
									setJob((prev) => {
										return {
											...prev,
											qualifications: e.target.value,
										};
									});
								}}
								fullWidth
								multiline
								rows={4}
								required
							/>
							<TextField
								label={'Work Experience'}
								value={job.work_experience}
								error={!job.work_experience}
								helperText={!job.work_experience && 'Work Experience is required'}
								onChange={(e) => {
									setJob((prev) => {
										return {
											...prev,
											work_experience: e.target.value,
										};
									});
								}}
								fullWidth
								multiline
								rows={4}
								required
							/>
							<TextField
								label={'Skills Required'}
								value={job.skills_required}
								error={!job.skills_required}
								helperText={!job.skills_required && 'Skills are required'}
								onChange={(e) => {
									setJob((prev) => {
										return {
											...prev,
											skills_required: e.target.value,
										};
									});
								}}
								fullWidth
								multiline
								rows={4}
								required
							/>
							<TextField
								label={'Additional Requirements'}
								value={job.additional_requirements}
								// error={!job.skills_required}
								// helperText={
								//     !job.skills_required && "Skills are required"
								// }
								onChange={(e) => {
									setJob((prev) => {
										return {
											...prev,
											additional_requirements: e.target.value,
										};
									});
								}}
								fullWidth
								multiline
								rows={4}
								// required
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="Add Job Expiry"
									value={dayjs(job.expiry)}
									onChange={(value) => {
										// set date in iso format
										setJob((prev) => {
											return {
												...prev,
												expiry: value.toISOString(),
											};
										});
									}}
								/>
							</LocalizationProvider>
							<Button
								variant="contained"
								sx={{
									width: 'fit-content',
									backgroundColor: '#224C84',
									'&:hover': {
										backgroundColor: '#224C84',
									},
									// textTransform: "none",
									// textDecoration: "underline",
								}}
								disabled={!isValid() || loading}
								onClick={updateJob}
							>
								Update Job
							</Button>
						</Stack>
					</Stack>
				</Card>
			</Box>
		</>
	);
};

export default EditJob;
