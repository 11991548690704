import { Button, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import phoneImage from '../images/calltomobile.png';
import mobilePhoneImage from '../images/mobile-phone-image.png';
import './mockinterview.css';
import {
	GoogleOAuthProvider,
	googleLogout,
	useGoogleLogin,
	GoogleLogin,
} from '@react-oauth/google';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import SocialMediaPost from '../images/Social-media-post.png';
import { useNavigate } from 'react-router';

const CalltoMobileAction = () => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:600px)');

	const responseMockInterview = async (response) => {
		console.log(response);
		const accessToken = response.access_token || response.credential;
		// var user_details = jwt_decode(response.access_token);
		const user_details = await fetchUserDetailsFromBackend(accessToken);

		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data, 'Data');
		console.log(data.success, 'data success');
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			sessionStorage.setItem('student_interview_access', data.has_interview_access);
			sessionStorage.setItem('mock-interview', 'Yes');
			console.log('complete_profile', data.complete_profile);
			if (data.complete_profile) {
				navigate('/student/mock-interview/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/mock-interview/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};

	const errorMessage = (error) => {
		console.log(error);
		toast.error('Something went wrong, Try Again!!');
	};

	const login = useGoogleLogin({
		onSuccess: (response) => {
			responseMockInterview(response); // Proceed with your logic
		},
		onError: errorMessage,
	});

	const fetchUserDetailsFromBackend = async (accessToken) => {
		try {
			// Replace '/api/auth/google' with your backend API endpoint
			const response = await fetch(`${API_URL}/labs/api/auth/google`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ token: accessToken }),
			});

			if (!response.ok) {
				throw new Error('Failed to fetch user details from backend');
			}

			// Parse the response from the backend
			const data = await response.json();
			return data; // This should return user details like name, email, etc.
		} catch (error) {
			console.error('Error in fetchUserDetailsFromBackend:', error.message);
			return null;
		}
	};

	return (
		<div
			style={{
				// background: 'rgba(211, 211, 211, 0.2)',
				background: 'white',
				color: '#224C84',
				height: isMobile ? '100%' : '100vh',
				display: 'flex',
				flexDirection: isMobile ? 'column' : 'row',
				textAlign: 'start',
				gap: '10px',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'hidden',
				position: 'relative',
				margin: isMobile ? '15vw 12vw' : '',
				marginTop: isMobile ? '15vw' : '90px',
				marginBottom: isMobile ? '15vw' : '90px',
			}}
		>
			{/* <div
				style={{
					// width: '30%',
					position: 'relative',
					top: '2rem',
					left: '-1rem',
				}}
			>
				<img
					style={{
						width: '100%',
						maxWidth: '600px',
						height: 'auto',
					}}
					src={phoneImage}
					alt="phone image"
				/>
			</div> */}
			{isMobile && (
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row', // Adjust layout for mobile
						alignItems: 'start',
						justifyContent: 'space-between',
						gap: '5px',
					}}
				>
					<h1
						style={{
							fontSize: isMobile ? '8vw' : '2.5rem',
							fontWeight: 'bold',
							marginBottom: 0,
							order: isMobile ? 1 : 0, // Show first on mobile
						}}
					>
						Show your success & commitment to grow
					</h1>
					<img
						src={phoneImage} // Replace with your phone image source
						alt="MobilePhone"
						style={{
							width: isMobile ? '100%' : '', // Increase width for mobile
							height: isMobile ? '' : '',
							maxWidth: '500px', // Ensure it does not exceed a certain width
							order: isMobile ? 2 : 1, // Show second on mobile
							padding: isMobile ? '2rem 0' : 0,
						}}
					/>
					<h2
						style={{
							fontSize: isMobile ? '1.5rem' : '1.8rem',
							marginBottom: 0,
							order: isMobile ? 3 : 2, // Show third on mobile
						}}
					>
						Inspire, Connect, and Stand Out by Showcasing Your Growth on Social Media
					</h2>
					<p
						style={{
							fontSize: isMobile ? '1.0rem' : '1.2rem',
							marginBottom: '1rem',
							order: isMobile ? 4 : 3,
						}}
					>
						Share your interview progress on social media with just a click! Highlight
						improvements, celebrate milestones, and showcase your preparation journey to
						build your network and attract opportunities.
					</p>
					<Button
						size="large"
						className="play-btn"
						onClick={() => login()}
						sx={{
							width: '250px',
							height: '50px',
							fontFamily: 'Poppins',
							textTransform: 'none',
							// position: 'absolute',
							marginTop: isMobile ? '1rem' : '5rem',
							backgroundColor: '#FFC107',
							fontSize: '1.2rem',
							color: '#224C84',
							// borderRadius: '10px',
							'&:hover': {
								backgroundColor: '#FFC107',
								// border: "2px solid",
								// borderColor: "#FFC107",
								color: '#224C84',
							},
							order: isMobile ? 5 : 4,
						}}
					>
						SIGN UP NOW
					</Button>
				</div>
			)}

			{!isMobile && (
				<>
					<div
						style={{
							// width: '30%',
							// position: 'relative',
							// top: '2rem',
							// left: '-1rem',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<img
							style={{
								width: '55%',
								maxWidth: '50vw',
								height: '100%',
								maxHeight: '100%',
								objectFit: 'contain', // Ensures the image fits within the container
							}}
							src={phoneImage}
							alt="phone image"
						/>
					</div>
					<div
						style={{
							width: isMobile ? '80%' : '50%',
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							gap: '5px',
						}}
					>
						<h1
							style={{
								fontSize: isMobile ? '2.1rem' : '2.5rem',
								fontWeight: 'bold',
								marginBottom: 0,
								marginTop: 0,
							}}
						>
							Show your success & commitment to grow
						</h1>
						<h2
							style={{
								fontSize: isMobile ? '1.5rem' : '1.8rem',
								marginBottom: 0,
							}}
						>
							Inspire, Connect, and Stand Out by Showcasing Your Growth on Social
							Media
						</h2>
						<p
							style={{
								fontSize: isMobile ? '1.0rem' : '1.2rem',
								marginBottom: '1rem',
							}}
						>
							Share your interview progress on social media with just a click!
							Highlight improvements, celebrate milestones, and showcase your
							preparation journey to build your network and attract opportunities.
						</p>
						<Button
							size="large"
							className="play-btn"
							onClick={() => login()}
							sx={{
								width: '250px',
								height: '50px',
								fontFamily: 'Poppins',
								textTransform: 'none',
								// position: 'absolute',
								marginTop: isMobile ? '1rem' : '5.5rem',
								backgroundColor: '#FFC107',
								fontSize: '1.2rem',
								color: '#224C84',
								// borderRadius: '10px',
								'&:hover': {
									backgroundColor: '#FFC107',
									// border: "2px solid",
									// borderColor: "#FFC107",
									color: '#224C84',
								},
							}}
						>
							SIGN UP NOW
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default CalltoMobileAction;
