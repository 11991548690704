import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Header } from '../shared/Header';

export const ScreenShareViolation = () => {
	const navigate = useNavigate();

	const handleRestartInterview = () => {
		navigate('/login/candidate');
	};

	return (
		<>
			<Header />
			<div
				style={{
					width: '60%',
					backgroundColor: '#FFFFFF',
					margin: '20px auto',
					padding: '20px',
					borderRadius: '16px',
				}}
			>
				<p>In order to give this interview, you will have to share your Entire Screen.</p>
				<p>Please press Back Button and restart the interview.</p>
				<Button
					variant="contained"
					onClick={handleRestartInterview}
					sx={{ textTransform: 'none', marginTop: 3 }}
				>
					Restart Interview
				</Button>
			</div>
		</>
	);
};
