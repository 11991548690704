import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import InterviewIcon from '../images/interview-icon.png';
import ReportIcon from '../images/report-icon.png';
import ImprovementIcon from '../images/improvement-icons.png';

const DemoPaper = styled(Paper)(({ theme }) => ({
	// width: 120,
	// height: 120,
	padding: theme.spacing(2),
	...theme.typography.body2,
	textAlign: 'center',
	borderRadius: '15px',
	background: 'rgb(236, 247, 250, 1)',
	color: '#224C84',
	padding: '20px',
	borderRadius: '10px',
	width: isMobile ? '100%' : '100%',
	boxSizing: 'border-box',
	minHeight: '280px',
	gap: '10px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	transition: 'transform 0.5s, box-shadow 0.3s',
	'&:hover': {
		transform: 'scale(1.05)',
		boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
	},
}));

const Services = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

	return (
		<div
			id="mockinterview-services"
			style={{
				// background: 'rgba(211, 211, 211, 0.2)',
				background: 'white',
				color: '#224C84',
				height: isMobile ? '100%' : '100vh',
				display: 'flex',
				flexDirection: 'column',
				textAlign: 'center',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'hidden',
				position: 'relative',
				margin: '10vw 8vw',
			}}
		>
			<div
				style={{
					maxWidth: '950px',
				}}
			>
				<h1>Prepare Smart, Perform Better</h1>
				<p>
					AI.HYR’s mock interviews simulate real interview scenarios, providing instant
					feedback to refine your answers, you can review your body language, and overall
					performance. With AI-driven insights, you’ll know exactly where to improve.
				</p>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'row',
					justifyContent: 'space-around',
					alignItems: 'stretch', // Ensure all children have the same height
					width: '100%',
					// maxWidth: '1200px',
					margin: '2vw 0vw',
					padding: '3vw',
					gap: '30px',
					boxSizing: 'border-box',
					position: 'relative',
				}}
			>
				<DemoPaper>
					<img src={InterviewIcon} alt="InterviewIcon" width={'90vw'} />
					<h2 color="#224C84">Practice Realistic Interviews</h2>
					<p>
						Practice with AI-bots that simulate the interview experience, giving you a
						realistic feel for real-world interactions and feedback.
					</p>
				</DemoPaper>
				<DemoPaper>
					<img src={ReportIcon} alt="ReportIcon" width={'90vw'} />
					<h2 color="#224C84">Instant Performance Report</h2>
					<p>
						Get immediate feedback on your performance, including video and audio,
						answer transcripts, and a of your strengths and weaknesses.
					</p>
				</DemoPaper>
				<DemoPaper>
					<img src={ImprovementIcon} alt="ImprovementIcon" width={'90vw'} />
					<h2 color="#224C84">Targeted Improvement Areas</h2>
					<p>
						Use the detailed report to focus on specific areas for improvement, ensuring
						you're fully prepared for your next interview.
					</p>
				</DemoPaper>
			</div>
		</div>
	);
};

export default Services;
