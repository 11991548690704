import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Alert, Stack, Chip } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { API_URL } from '../../global/constants';
import { useAdminContext } from '../../global/AdminContext';
import { useNavigate } from 'react-router-dom';
import { Header } from '../shared/Header';

import axios from 'axios';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import UploadIcon from '@mui/icons-material/Upload';
import toast, { Toaster } from 'react-hot-toast';
import ClearIcon from '@mui/icons-material/Clear';
import UserTables from './UserTables';
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});
const AddApplicant = () => {
	const params = useParams();
	const { admin } = useAdminContext();
	const navigate = useNavigate();
	const [candidate, setCandidate] = useState({});
	const [candidateResumeFile, setCandidateResumeFile] = useState();
	const [errorMessage, setError] = useState(false);
	const [successMessage, setSuccess] = useState(false);
	const [addApplicantLoading, setAddApplicantLoading] = useState(false);
	const [resumes, setResumes] = useState([]);
	const [uploadResumeLoading, setUploadResumeLoading] = useState(false);
	const selectResumesRef = useRef(null);
	const selectexcelRef = useRef(null);
	const handleResumeDelete = (i) => {
		return () => {
			setResumes((resumes) => resumes.filter((resume, index) => index !== i));
			console.log(resumes);
		};
	};
	const [users, setUsers] = useState([]);
	const uploadResumes = async () => {
		setUploadResumeLoading(true);
		let formData = new FormData();
		for (let i = 0; i < resumes.length; i++) {
			formData.append(resumes[i].name, resumes[i]);
		}
		console.log(formData);
		const { data } = await axios.post(`${API_URL}/labs/parse-resumes`, formData, {
			headers: {
				Authorization: `Bearer ${admin.token}`,
				'content-type': 'multipart/form-data',
			},
		});
		console.log(data);
		if (data?.success) {
			console.log(data.data.users, 'resume details');
			setUsers([...users, ...data.data.users]);
			toast.success(data.message);
		} else {
			toast.error(data.message);
		}
		setUploadResumeLoading(false);
		setResumes([]);
	};

	const handleCandidateStateChanges = (event) => {
		const { name, value } = event.target;
		setCandidate((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};
	const formatandsetusers = (exceldata) => {
		const format_data = [];
		for (let userdata of exceldata) {
			const user = {
				first_name: userdata[0],
				last_name: userdata[1],
				contact: userdata[2],
				email: userdata[3],
			};
			format_data.push(user);
		}
		console.log(format_data, 'informat');
		setUsers(format_data);
	};

	const handleCandidateDetailsChange = async (e) => {
		const files = e.target.files;
		setCandidateResumeFile(files);
	};
	const excelfilechange = async (e) => {
		const files = e.target.files;
		// setCandidateResumeFile(files);

		// Parse Excel file if uploaded
		if (files && files[0]) {
			const file = files[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				const binaryString = e.target.result;
				const workbook = XLSX.read(binaryString, { type: 'binary' });
				const sheetName = workbook.SheetNames[0]; // Assuming first sheet
				const worksheet = workbook.Sheets[sheetName];
				const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
				formatandsetusers(data);
				console.log(data, 'candidate data'); // Do something with the parsed Excel data
			};
			reader.readAsArrayBuffer(file);
		}
	};

	const submitApplicant = async () => {
		setAddApplicantLoading(true);
		let formData = new FormData();
		if (candidateResumeFile) {
			formData.append('resume', candidateResumeFile[0], ['PROXY']);
			formData.append('pilot_id', candidate?.pilot_id);
		} else {
			if (
				!candidate.first_name ||
				!candidate.last_name ||
				!candidate.contact ||
				!candidate.email
			) {
				return alert('Please fill all the fields');
			} else {
				formData.append('pilot_id', candidate?.pilot_id);
				formData.append('first_name', candidate.first_name);
				formData.append('last_name', candidate.last_name);
				formData.append('contact', candidate.contact);
				formData.append('email', candidate.email);
				formData.append('role', params.role);
			}
		}

		const config = {
			headers: {
				Authorization: `Bearer ${admin.token}`,
				'content-type': 'multipart/form-data',
			},
		};

		try {
			await axios
				.post(
					`${API_URL}/labs/applicant/new/${params.company_id}/${params.job_id}`,
					formData,
					config
				)
				.then((response) => {
					const { data } = response;
					if (data?.success) {
						setSuccess(true);
						setError(false);
					} else {
						setError(true);
						setSuccess(false);
					}
					if (params.role === 'college') {
						setTimeout(() => navigate(`/college-admin`), 2000);
					} else {
						setTimeout(() => navigate(`/dashboard/jobslist/company`), 2000);
					}
				});
			// .then(result => {
			//   console.log(result)
			//   navigate("/admin")
			// })
		} catch (err) {
			console.log(err.message);
		}
		setAddApplicantLoading(false);
	};

	return (
		<>
			<Header />
			<Toaster />
			<Box
				sx={{
					backgroundColor: '#F5F5F5',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: '64px',
					padding: '30px',
					width: '90vw',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{successMessage && (
					<Alert severity="success">
						Applicant added successfully! Redirecting to dashboard ....
					</Alert>
				)}
				{errorMessage && (
					<Alert severity="error">There was an error. Please try later.</Alert>
				)}
				<Typography variant="h6" sx={{ fontFamily: 'Gordita' }}>
					Add Candidate Details
				</Typography>

				{/* <Input
                    id="candidate-resume"
                    type="file"
                    variant="outlined"
                    onChange={(e) => handleCandidateDetailsChange(e)}
                    sx={{
                        textTransform: "none",
                        width: "30vw",
                        marginBottom: "16px",
                        marginTop: "32px",
                        fontFamily: "Gordita",
                    }}
                >
                    {" "}
                    <UploadFileIcon />{" "}
                    <span style={{ marginLeft: "10px" }}>Upload File</span>
                </Input> */}
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'center'}
					alignItems={'center'}
					spacing={1}
					mt={2}
				>
					<Button
						variant="outlined"
						onClick={() => selectResumesRef.current.click()}
						style={{
							textTransform: 'none',
						}}
						sx={{
							textTransform: 'none',
							color: '#224C84',
							'&:hover': {
								backgroundColor: '#224C84',
								color: 'white',
								border: '1px solid #224C84',
							},
							border: '1px solid #224C84',
						}}
						startIcon={<UploadFileIcon />}
					>
						{' '}
						Select Resumes
					</Button>

					<Button
						component="label"
						role={undefined}
						sx={{
							textTransform: 'none',
							color: '#224C84',
							'&:hover': {
								backgroundColor: '#224C84',
								color: 'white',
								border: '1px solid #224C84',
							},
							border: '1px solid #224C84',
						}}
						variant="outlined"
						tabIndex={-1}
						startIcon={<CloudUploadIcon />}
					>
						Upload Excel File
						<VisuallyHiddenInput
							onChange={(e) => excelfilechange(e)}
							accept=".xlsx"
							type="file"
						/>
					</Button>
				</Stack>

				<input
					ref={selectResumesRef}
					type="file"
					accept="application/pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					onChange={(e) => {
						setResumes([...resumes, ...Array.from(e.target.files)]);
						console.log(Array.from(e.target.files));
					}}
					style={{ display: 'none' }}
					multiple
				/>
				<Typography fontSize={'0.9rem'}>{resumes.length} Resumes Selected</Typography>
				{resumes.length > 0 && (
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={1}
						p={2}
						useFlexGap
						flexWrap="wrap"
						alignItems={'center'}
						justifyContent={'center'}
					>
						{resumes.map((resume, i) => {
							return (
								<Chip
									key={i}
									variant="outlined"
									label={
										resume.name.length > 20
											? resume.name.substring(0, 20) + '...'
											: resume.name
									}
									onDelete={handleResumeDelete(i)}
								/>
							);
						})}
					</Stack>
				)}
				{resumes.length > 0 && (
					<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
						<Button
							variant="outlined"
							style={{
								textTransform: 'none',
							}}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
									color: 'white',
									border: '1px solid #224C84',
								},
								border: '1px solid #224C84',
							}}
							startIcon={<UploadIcon />}
							disabled={uploadResumeLoading}
							onClick={uploadResumes}
						>
							Upload Resumes
						</Button>
						<Button
							variant="outlined"
							style={{
								textTransform: 'none',
							}}
							sx={{
								textTransform: 'none',
								color: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
									color: 'white',
									border: '1px solid #224C84',
								},
								border: '1px solid #224C84',
							}}
							startIcon={<ClearIcon />}
							disabled={uploadResumeLoading}
							onClick={() => setResumes([])}
						>
							Clear All
						</Button>
					</Stack>
				)}

				<UserTables users={users} setUsers={setUsers} authToken={admin.token} />
				<Typography
					sx={{
						color: '#333333',
						opacity: '0.5',
						fontFamily: 'Gordita',
						padding: '10px',
					}}
				>
					or
				</Typography>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '20px',
						marginBottom: '20px',
						marginTop: 2,
					}}
				>
					<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
						<TextField
							label="Candidate's First Name"
							name="first_name"
							variant="outlined"
							value={candidate.first_name}
							onChange={handleCandidateStateChanges}
							sx={{ width: '300px', fontFamily: 'Gordita' }}
						/>
						<TextField
							label="Candidate's Last Name"
							variant="outlined"
							name="last_name"
							value={candidate.last_name}
							onChange={handleCandidateStateChanges}
							sx={{ width: '300px', fontFamily: 'Gordita' }}
						/>
						<TextField
							label="Candidate's Phone Number"
							variant="outlined"
							name="contact"
							value={candidate.contact}
							onChange={handleCandidateStateChanges}
							sx={{ width: '300px', fontFamily: 'Gordita' }}
						/>
						<TextField
							label="Candidate's Email"
							type="email"
							name="email"
							variant="outlined"
							value={candidate.email}
							onChange={handleCandidateStateChanges}
							sx={{
								width: '300px',
								marginBottom: '10px',
								fontFamily: 'Gordita',
							}}
						/>
					</Stack>
				</div>

				<Button
					variant="contained"
					color="primary"
					disabled={addApplicantLoading}
					onClick={submitApplicant}
					startIcon={<PersonAddAltIcon />}
					sx={{
						textTransform: 'none',
						backgroundColor: '#224C84',
						'&:hover': { backgroundColor: '#224C84' },
					}}
				>
					{addApplicantLoading ? 'Adding Applicant' : 'Add Applicant'}
				</Button>
			</Box>
		</>
	);
};

export default AddApplicant;
