import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../../shared/Header';
import { Button, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import { Box, height, Stack } from '@mui/system';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Interview from '../Interview';
import { useLocation, useNavigate } from 'react-router';
import MockInterview from './MockInterview';
import DemoMockinterview from './DemoMockinterview';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

const MockInterviewDashboard = () => {
	const [interviews, setInterviews] = useState([]);
	const [groupedInterviews, setGroupedInterviews] = useState([]);
	const [expandedGroups, setExpandedGroups] = useState({});
	const [profileImage, setProfileImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const { state } = useLocation();
	const navigate = useNavigate();
	const fullName = `${firstName} ${lastName}`;

	// New state to track locked/unlocked interviews
	const [lockedInterviews, setLockedInterviews] = useState({});

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const getAllInterviews = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/student/mock-interviews`,
				{ search: searchTerm },
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			setGroupedInterviews(data.groupedInterviews);
			if (data.success) {
				console.log(data);
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!sessionStorage.getItem('student-auth-token')) {
			navigate('/student/login');
		}
		getAllInterviews();
	}, [searchTerm]);

	useEffect(() => {
		if (!sessionStorage.getItem('student-auth-token')) {
			navigate('/student/login');
		}
		getDetails();
	}, []);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/details`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			console.log(data, 'data student');
			setFirstName(data.studentDetails.first_name);
			setLastName(data.studentDetails.last_name);
			// setEmail(data.studentDetails.email);
			setProfileImage(data.studentDetails.profile_image);
			// Create a map of locked/unlocked status for mock interviews
			const lockedStatus = {};
			data.studentDetails.groupedInterviews.forEach((group) => {
				group.mockInterviews.forEach((interview) => {
					lockedStatus[interview._id] = interview.isLocked;
				});
			});
			setLockedInterviews(lockedStatus);
			console.log(data);
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	const handleToggleExpand = (topicId) => {
		setExpandedGroups((prev) => ({
			...prev,
			[topicId]: !prev[topicId],
		}));
	};

	// New Search Functionality
	const filteredGroupedInterviews = groupedInterviews
		.map((group) => {
			// Filter mockInterviews within each group
			const filteredInterviews = group.mockInterviews.filter((interview) =>
				interview.job_role.toLowerCase().includes(searchTerm.toLowerCase())
			);

			// Only return groups that have at least one interview matching the search term
			if (filteredInterviews.length > 0) {
				return {
					...group,
					mockInterviews: filteredInterviews,
				};
			}

			// If no interviews match, return null (this will be filtered out later)
			return null;
		})
		.filter((group) => group !== null); // Filter out any null groups

	return (
		<>
			<Toaster />
			<Header fullName={fullName} />
			<Stack
				p={{ sm: 5 }}
				spacing={{ sm: 5, xs: 3 }}
				style={{
					backgroundColor: '#ecf7fa',
					width: '100%',
					minHeight: isMobile ? '100vh' : '100%',
					padding: isMobile ? '20px' : '30px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: isMobile ? '20px 0 0 0' : '0 10px 0 40px',
					}}
				>
					<div
						style={{
							color: '#495057',
						}}
					>
						<Typography fontWeight={'bold'} fontSize={isMobile ? '1.1rem' : '1.5rem'}>
							All Mock-Interviews
						</Typography>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: isMobile ? '10px' : '25px', // Adjust gap based on mobile or desktop
							width: 'auto',
							margin: isMobile ? '10px 10px' : '10px 30px',
						}}
					>
						<TextField
							variant="standard"
							label="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
							fullWidth={isMobile}
							InputProps={{
								endAdornment: (
									<SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
								),
							}}
							style={{
								marginBottom: '10px',
							}}
						/>

						<Button
							onClick={() =>
								navigate('/student/mock-interview/all-report', {
									state: {
										fullName: `${firstName} ${lastName}`,
									},
								})
							}
							variant="outlined"
							size="large"
							sx={{
								padding: isMobile ? '6px 12px' : '8px 16px', // Adjust padding for mobile
								minWidth: isMobile ? '100px' : '160px', // Minimum width to make button smaller on mobile
								fontSize: isMobile ? '0.8rem' : '1rem', // Adjust font size for mobile
							}}
						>
							<Typography
								fontWeight="semibold"
								style={{
									color: '#224C84',
								}}
							>
								{isMobile ? 'Reports' : 'Interview Reports'}
							</Typography>
						</Button>

						<Button
							onClick={() =>
								navigate('/student/mock-interview/profile', {
									state: {
										fullName: `${firstName} ${lastName}`,
									},
								})
							}
							variant="outlined"
							size="large"
							sx={{
								padding: isMobile ? '6px 12px' : '8px 16px', // Adjust padding for mobile
								minWidth: isMobile ? '100px' : '160px', // Minimum width to make button smaller on mobile
								fontSize: isMobile ? '0.8rem' : '1rem', // Adjust font size for mobile
							}}
						>
							<Typography
								fontWeight="semibold"
								style={{
									color: '#224C84',
								}}
							>
								{isMobile ? 'Stats' : 'View Stats'}
							</Typography>
						</Button>
					</div>
				</div>

				<Stack
					direction={isMobile ? 'column' : 'row'}
					useFlexGap
					flexWrap={'wrap'}
					spacing={4} // This spacing controls the gap between the grouped interview sections
					alignItems={'center'}
					margin={10}
					justifyContent={'center'}
				>
					{loading && (
						<Stack
							p={{ sm: 3, xs: 2 }}
							bgcolor={'#fff'}
							spacing={2}
							width={{ sm: '400px', xs: '100%' }}
							height={'auto'}
							borderRadius={'20px'}
							boxShadow={'8px 6px 12px 0 rgba(13,31,61,.04)'}
						>
							<Skeleton variant="text" width={'250px'} />
							<Skeleton variant="text" width={'200px'} />
							<Skeleton variant="text" />
							<Skeleton variant="button" width={'100px'} />
						</Stack>
					)}

					{!loading && filteredGroupedInterviews?.length === 0 && (
						<Typography>No interviews available for you.</Typography>
					)}

					{!loading &&
						filteredGroupedInterviews?.map((group) => (
							<Box
								key={group.topicId}
								sx={{
									display: 'block',
									width: '90vw',
									mb: 4,
									justifyContent: 'start',
								}}
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									style={{
										color: '#495057',
									}}
								>
									{group.topicName || 'No Topic'}
									{/* Display the topic name */}
								</Typography>
								<Stack
									direction={isMobile ? 'column' : 'row'}
									sx={{
										display: 'flex',
										flexDirection: isMobile ? 'column' : 'row',
										flexWrap: 'wrap',
										justifyContent: isMobile ? 'center' : 'start',
										alignItems: 'center',
										mt: isMobile ? 2 : 2,
										width: '90vw',
										gap: '2.5rem',
									}}
								>
									{(expandedGroups[group.topicId]
										? group.mockInterviews
										: group.mockInterviews.slice(0, 4)
									).map((interview) => (
										<DemoMockinterview
											interviewDescription={interview.job_description}
											key={interview._id}
											interview_id={interview._id}
											pilot_id={interview.pilot_id}
											role={interview.job_role}
											poster={interview.job_poster}
											interview_level={interview.interview_level}
											questions={interview.questions}
											fullName={fullName}
											locked={lockedInterviews[interview._id]}
											skills={interview.skills}
										/>
									))}
								</Stack>
								{group.mockInterviews.length > 4 && (
									<Button
										onClick={() => handleToggleExpand(group.topicId)}
										variant="outlined"
										style={{ marginTop: '16px' }}
									>
										{expandedGroups[group.topicId] ? 'Show Fewer' : 'Show More'}
									</Button>
								)}
							</Box>
						))}
				</Stack>
			</Stack>
		</>
	);
};

export default MockInterviewDashboard;
