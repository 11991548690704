import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './candidateReportV2.css';
import { Stack, Typography } from '@mui/material';

import CanvasJSReact from './canvasjs.react';
import { useAdminContext } from '../../global/AdminContext';
import { Header } from '../shared/Header';
import { FaCircleArrowLeft, FaCircleArrowRight } from 'react-icons/fa6';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const theme = createTheme({
	palette: {
		success: {
			main: '#66bb6a',
		},
		error: {
			main: '#FF0000',
		},
		warning: {
			main: '#ffc107',
		},
		neutral: {
			main: '#cccccc',
		},
		highly: {
			main: '#04AF70',
		},
		moderately: {
			main: '#05ED98',
		},
	},
});

const CandidateReportNew = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [reportData, setReportData] = useState();
	const [violation, setViolation] = useState('');
	const [overallScorePercentage, setOverallScorePercentage] = useState();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [role, setRole] = useState('');
	const params = useParams();
	const [proctoring, setProctoring] = useState(null);
	const [weightage, setWeightage] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [skills, setSkills] = useState([]);
	const [candidateInterviewVideo, setCandidateInterviewVideo] = useState('');
	const { key } = params;

	const navigate = useNavigate();
	const location = useLocation();
	const filteredData = location.state?.filteredData;
	const completedInterviews = location.state?.completedInterviews;
	const job_id = location.state?.job_id;

	const currentIndex = filteredData?.findIndex((item) => item.interview_key === key);
	const previousIndex = currentIndex > 0 ? currentIndex - 1 : null;
	const nextIndex = currentIndex < filteredData.length - 1 ? currentIndex + 1 : null;

	const previousInterviewKey =
		previousIndex !== null ? filteredData[previousIndex].interview_key : null;
	const nextInterviewKey = nextIndex !== null ? filteredData[nextIndex].interview_key : null;

	const checkIsOld = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		if (completedInterviews[idx].is_old) return true;
		return false;
	};
	const checkIsNewFormat = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		if (completedInterviews[idx].is_new_format) return true;
		return false;
	};
	const checkIsForeign = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		console.log('idx', idx, completedInterviews[idx].language, 'language');
		if (completedInterviews[idx].language !== 'en') return true;
		return false;
	};
	const is_interview_required = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		if (completedInterviews[idx].is_interview_required) return true;
		return false;
	};
	const checkEngProfIncluded = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		console.log(completedInterviews[idx]);
		console.log(completedInterviews[idx].is_english_proficiency_included);
		if (completedInterviews[idx].is_english_proficiency_included) return true;
		return false;
	};

	const handlePreviousClick = () => {
		if (checkIsForeign(previousInterviewKey)) {
			navigate(`/candidate/report-v5/${previousInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		if (checkEngProfIncluded(previousInterviewKey)) {
			navigate(`/candidate/report-v4/${previousInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		if (checkIsOld(previousInterviewKey)) {
			navigate(`/candidate/report-v1/${previousInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}
		if (!is_interview_required(previousInterviewKey)) {
			navigate(`/candidate/report-v6/${previousInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		if (checkIsNewFormat(previousInterviewKey)) {
			navigate(`/candidate/report-v3/${previousInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		navigate(`/candidate/report-v2/${previousInterviewKey}`, {
			state: { job_id, filteredData, completedInterviews },
		});
	};

	const handleNextClick = () => {
		if (checkIsForeign(nextInterviewKey)) {
			navigate(`/candidate/report-v5/${nextInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		if (checkEngProfIncluded(nextInterviewKey)) {
			navigate(`/candidate/report-v4/${nextInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		if (checkIsOld(nextInterviewKey)) {
			navigate(`/candidate/report-v1/${nextInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}
		if (!is_interview_required(nextInterviewKey)) {
			navigate(`/candidate/report-v6/${nextInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		if (checkIsNewFormat(nextInterviewKey)) {
			navigate(`/candidate/report-v3/${nextInterviewKey}`, {
				state: { job_id, filteredData, completedInterviews },
			});
			return;
		}

		navigate(`/candidate/report-v2/${nextInterviewKey}`, {
			state: { job_id, filteredData, completedInterviews },
		});
	};

	function convertToTitleCase(inputString) {
		// Split the string into words using underscore as delimiter
		const words = inputString.split('_');

		// Capitalize the first letter of each word and convert the rest to lowercase
		const titleCaseWords = words.map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		});

		// Join the words back together with a space separator
		const titleCaseString = titleCaseWords.join(' ');

		return titleCaseString;
	}
	const getArcLabel = (params) => {
		const percent =
			params.value / weightage.map((item) => item.value).reduce((a, b) => a + b, 0);
		return `${(percent * 100).toFixed(0)}%`;
	};

	const options = {
		animationEnabled: true,
		data: [
			{
				type: 'doughnut',
				showInLegend: true,
				indexLabel: '{name}: {y}',
				yValueFormatString: "#,###'%'",
				dataPoints: weightage,
			},
		],
	};
	const [authorized, setAuthorized] = useState(true);
	const { admin, setAdmin } = useAdminContext();
	const [eyeGazeData, setEyeGazeData] = useState([]);
	const [multifaceData, setMultifaceData] = useState([]);
	const eyeGazeOptions = {
		animationEnabled: true,
		data: [
			{
				type: 'doughnut',
				showInLegend: true,
				indexLabel: '{name}: {y}',
				yValueFormatString: "#,###'%'",
				dataPoints: eyeGazeData,
			},
		],
	};
	const multifaceOptions = {
		animationEnabled: true,
		data: [
			{
				type: 'doughnut',
				showInLegend: true,
				indexLabel: '{name}: {y}',
				yValueFormatString: "#,###'%'",
				dataPoints: multifaceData,
			},
		],
	};

	useEffect(() => {
		const getReportData = async () => {
			setIsLoading(true);
			const response = await axios.get(`${API_URL}/labs/generate-report/${key}`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			});
			// console.log(response.data.error);

			if (response.data.status === 401) {
				console.log('Unauthorized');
				setAuthorized(false);
				setIsLoading(false);
				return;
			}
			const data = response.data;
			console.log(data);
			setReportData(data.data);
			setFirstName(data.first_name);
			setLastName(data.last_name);
			setProctoring(data.proctoring);
			setViolation(data.interview_end_reason);
			console.log(data.proctoring);
			if (data.proctoring) {
				const eye_gaze_data = [];
				if (parseInt(data.proctoring.eye_gaze) > 0) {
					eye_gaze_data.push({
						name: 'Looking in the camera',
						y: data.proctoring.eye_gaze,
					});
				}
				if (parseInt(100 - data.proctoring.eye_gaze) > 0) {
					eye_gaze_data.push({
						name: 'Not looking in the camera',
						y: 100 - data.proctoring.eye_gaze,
					});
				}
				setEyeGazeData(eye_gaze_data);
				// setEyeGazeData([
				//     {
				//         name: "Not looking in the camera",
				//         y: 100 - data.proctoring.eye_gaze,
				//     },
				//     {
				//         name: "Looking in the camera",
				//         y: data.proctoring.eye_gaze,
				//     },
				// ]);
				let multi_face_data = [];
				if (parseInt(data.proctoring.multiface) > 0) {
					multi_face_data.push({
						name: 'Several or no faces in the camera',
						y: data.proctoring.multiface,
					});
				}
				if (parseInt(100 - data.proctoring.multiface) > 0) {
					multi_face_data.push({
						name: '1 Face in the camera',
						y: 100 - data.proctoring.multiface,
					});
				}
				setMultifaceData(multi_face_data);
				// setMultifaceData([
				//     {
				//         name: "1 Face in the camera",
				//         y: 100 - data.proctoring.multiface,
				//     },
				//     {
				//         name: "Several or no faces in the camera",
				//         y: data.proctoring.multiface,
				//     },
				// ]);
			}

			setEmail(data.email);
			setContact(data.contact);
			setRole(data.job_role);
			setOverallScorePercentage(parseInt(data?.final_score));
			setCandidateInterviewVideo(data?.candidate_interview_video);
			const sub_skills_overall_scores = data.sub_skills_overall_scores;
			let sub_skills_data = [];
			console.log(sub_skills_overall_scores);
			for (let sub_skill of sub_skills_overall_scores) {
				const idx = sub_skills_data.findIndex((item) => item.skill === sub_skill.skill);
				if (idx === -1) {
					let tmp = {
						skill: sub_skill.skill,
						sub_skills: [sub_skill],
					};
					sub_skills_data.push(tmp);
				} else {
					let tmp = sub_skills_data.at(idx);
					tmp.sub_skills.push(sub_skill);
				}
			}
			// console.log("Sub Skill Data", sub_skills_data);
			setSkills(sub_skills_data);

			if (weightage.length === 0 && data && data.weightage) {
				for (let w of data.weightage) {
					let tmp = weightage;
					// tmp.push({ label: w.skill, value: w.value, id: i++ });
					tmp.push({ name: w.skill, y: w.value });
					setWeightage(tmp);
				}
			}
			const questions = data.interview_details;
			let question_data = [];
			for (let question of questions) {
				const idx = question_data.findIndex((item) => item.skill === question.skill);
				if (idx === -1) {
					question_data.push({
						skill: question.skill,
						questions: [question],
					});
				} else {
					let curr = question_data.at(idx);
					curr.questions.push(question);
				}
			}
			console.log(question_data);
			setQuestions(question_data);
			// console.log(data);
			setIsLoading(false);
		};

		if (key) {
			getReportData();
		}
	}, [key]);

	const getPathColor = (value) => {
		return value >= 75 ? '#148F5D' : value >= 50 && value < 75 ? '#0AAF70' : '#FF0000';
	};

	const getLinearProgressColor = (value) => {
		return value >= 75 ? 'success' : value >= 50 && value < 75 ? 'warning' : 'error';
	};

	return (
		<Stack
			// display={"flex"}
			justifyContent={'center'}
			alignItems={'center'}
			bgcolor={'#ECF7FA'}
			// height={"auto"}
		>
			<Header role={role} />
			{isLoading && <CircularStatic />}
			{!authorized && (
				<Stack
					maxWidth={'800px'}
					// bgcolor={"#fff"}
					height={'auto'}
					p={3}
				>
					<Typography fontSize={'1.2rem'} color={'#000'} fontWeight={'bold'}>
						You Don't have access to view this report.
					</Typography>
				</Stack>
			)}
			{!isLoading && authorized && (
				<Stack
					maxWidth={'1300px'}
					// bgcolor={'#fff'}
					height={'auto'}
					direction="row"
					justifyContent="space-around"
					alignItems="top"
					p={0.4}
				>
					<Typography
						variant="h4"
						fontWeight="bold"
						style={{
							cursor: previousInterviewKey ? 'pointer' : 'default',
							color: previousInterviewKey ? '#004aad' : 'gray',
							margin: '5px',
						}}
						onClick={previousInterviewKey ? handlePreviousClick : null}
					>
						<FaCircleArrowLeft />
					</Typography>
					<Stack maxWidth={'800px'} bgcolor={'#fff'} height={'auto'}>
						{/* Header */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography
								fontSize={'1.2rem'}
								color={'#fff'}
								fontWeight={'bold'}
							>{`Round 1: Interview report for ${
								firstName + ' ' + lastName
							}`}</Typography>
							<Typography
								fontSize={'1.2rem'}
								color={'#fff'}
								fontWeight={'bold'}
							>{`Role : ${role}`}</Typography>
						</Stack>
						{/* Candidate Information */}
						<Stack
							direction={'row'}
							display={'flex'}
							justifyContent={{ sm: 'center' }}
							alignItems={{ sm: 'center' }}
							p={3}
						>
							<Stack
								alignItems={{ sm: 'start', xs: 'center' }}
								width={{ sm: '50%', xs: 'fit-content' }}
								pl={{ sm: 5, xs: 0 }}
							>
								<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
									Name:{' '}
								</Typography>
								<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
									Email:{' '}
								</Typography>
								<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
									Phone:{' '}
								</Typography>
							</Stack>
							<Stack width={'50%'}>
								<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
									{firstName + ' ' + lastName}
								</Typography>
								<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
									{email}
								</Typography>
								<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
									{contact}
								</Typography>
							</Stack>
						</Stack>
						{/* Recommendation */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
								Recommendation
							</Typography>
						</Stack>
						<Stack
							direction={{ sm: 'row', xs: 'column' }}
							s={{ width: '100%' }}
							spacing={2}
							justifyContent="center"
							alignItems="center"
							p={2}
						>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={overallScorePercentage < 50 ? 'error' : 'neutral'}
										sx={{
											width: '200px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Not Recommended
								</Typography>
							</Stack>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={
											overallScorePercentage >= 50 &&
											overallScorePercentage < 75
												? 'moderately'
												: 'neutral'
										}
										sx={{
											width: '200px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Recommended
								</Typography>
							</Stack>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<LinearProgress
										variant="determinate"
										color={overallScorePercentage >= 75 ? 'highly' : 'neutral'}
										sx={{
											width: '200px',
											height: '20px',
											borderRadius: '28px',
											backgroundColor: '#cccccc',
										}}
									/>
								</ThemeProvider>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Highly Recommended
								</Typography>
							</Stack>
						</Stack>
						{/* Performance Summary */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
								Performance Summary
							</Typography>
						</Stack>
						<Stack
							direction={{ sm: 'row', xs: 'column' }}
							sx={{ width: '100%' }}
							spacing={3}
							p={2}
							justifyContent="center"
							alignItems="center"
						>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Overall Score
								</Typography>
								<div
									style={{ width: '150px', height: '150px' }}
									justifyContent="center"
									alignItems="center"
								>
									<CircularProgressbar
										strokeWidth={12}
										value={overallScorePercentage}
										text={`${
											overallScorePercentage &&
											overallScorePercentage !== 'NaN'
												? parseInt(overallScorePercentage)
												: 0
										}%`}
										styles={buildStyles({
											textSize: '16px',
											textColor: getPathColor(overallScorePercentage),
											pathColor: getPathColor(overallScorePercentage),
										})}
									/>
								</div>
							</Stack>

							<div className="performance-legend">
								<div className="legendItem">
									<div className="highly" />
									<Typography fontWeight={'bold'} color={'#004aad'}>
										Highly Recommended
									</Typography>
								</div>
								<div className="legendItem">
									<div className="moderately" />
									<Typography pt={1} pb={1} fontWeight={'bold'} color={'#004aad'}>
										Recommended
									</Typography>
								</div>
								<div className="legendItem">
									<div className="error" />
									<Typography fontWeight={'bold'} color={'#004aad'}>
										Not Recommended
									</Typography>
								</div>
							</div>
						</Stack>
						{skills.length !== 0 &&
							skills.map((skill) => {
								return (
									<>
										<Stack bgcolor={'#004aad'} p={1} spacing={1}>
											<Typography
												fontSize={'1.2rem'}
												color={'#fff'}
												fontWeight={'bold'}
											>
												{skill.skill}
											</Typography>
										</Stack>
										{skill.sub_skills.map((sub_skill) => {
											const candidate_total = sub_skill.candidate_total;
											const total = sub_skill.total;
											const scaledScore = (candidate_total / total) * 100;
											const rating = (candidate_total / total) * 5;
											const pathColor = getLinearProgressColor(scaledScore);
											return (
												<Stack
													direction={{
														sm: 'row',
														xs: 'column',
													}}
													justifyContent="center"
													alignItems="center"
													spacing={2}
													p={2}
												>
													<Stack
														sx={{ width: '300px' }}
														justifyContent={{
															sm: 'start',
															xs: 'center',
														}}
														alignItems={{
															sm: 'start',
															xs: 'center',
														}}
													>
														<Typography
															fontWeight={'bold'}
															color={'#004aad'}
															textAlign={{
																sm: 'start',
																xs: 'center',
															}}
														>
															{sub_skill.sub_skill}
														</Typography>
													</Stack>
													<ThemeProvider theme={theme}>
														<LinearProgress
															variant="determinate"
															color={pathColor}
															value={scaledScore}
															sx={{
																width: '200px',
																height: '20px',
																borderRadius: '28px',
																backgroundColor: '#cccccc',
															}}
														/>
													</ThemeProvider>
													<Rating
														name="half-rating-read"
														defaultValue={parseInt(rating)}
														max={5}
														precision={0.1}
														readOnly
													/>
												</Stack>
											);
										})}
									</>
								);
							})}

						{/* Weightage */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
								Below are the interview evaluation parameters and weightage for each
								parameter. This is a graphical representation of evaluation criteria
								defined by the admin and it is standard for all interviewees.
							</Typography>
						</Stack>
						<Stack p={4} justifyContent={'left'} alignItems={'left'}>
							<CanvasJSChart options={options} />
						</Stack>
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
								Below are the rating definitions on all the answer evaluations.
							</Typography>
						</Stack>
						<Stack p={1} spacing={1}>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								1 - No Answer
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								2 - Significantly weak answer
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								3 - Weak answer
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								4 - Strong answer
							</Typography>
							<Typography color={'#004AAD'} fontWeight={'bold'}>
								5 - Significantly strong answer
							</Typography>
						</Stack>
						{/* Candidate's Answer and Detailed Evaluation */}
						<Stack bgcolor={'#004aad'} p={1} spacing={1}>
							<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
								Candidate's Answers & Detailed Evaluation{' '}
							</Typography>
						</Stack>
						<Stack>
							{/* For Other Questions */}
							{questions.length !== 0 &&
								questions.map((question) => {
									return (
										<>
											<Typography
												m={2}
												mb={0}
												fontWeight={'bold'}
												fontSize={'1.3rem'}
											>
												<u>{question.skill}</u>
											</Typography>
											{question.questions.map((curr_question, i) => {
												return (
													!curr_question.is_last_question && (
														<Stack p={2} pb={0} spacing={1}>
															<Typography
																color="#005aad"
																fontSize={'1.1rem'}
																fontWeight={'bold'}
															>
																Question :{' '}
																{curr_question.question_id + 1}
															</Typography>
															<Typography>
																{curr_question.question_text}
															</Typography>
															{curr_question.candidate_answer && (
																<>
																	<Typography
																		color="#005aad"
																		fontSize={'1.1rem'}
																		fontWeight={'bold'}
																	>
																		Candidate Response
																	</Typography>
																	<>
																		<audio controls>
																			<source
																				src={
																					curr_question.answer_audio_link
																				}
																				type="audio/mpeg"
																			/>
																			Your browser does not
																			support the audio tag.
																		</audio>
																	</>
																	<Typography>
																		{
																			curr_question.candidate_answer
																		}
																	</Typography>
																</>
															)}
															{curr_question.skippableQuestionRes && (
																<Typography
																	color="#005aad"
																	fontSize={'1.1rem'}
																	fontWeight={'bold'}
																>
																	Answer Summary
																</Typography>
															)}
															{curr_question.skippableQuestionRes && (
																<Typography>
																	{
																		curr_question.skippableQuestionRes
																	}
																</Typography>
															)}
															{curr_question.evaluation_rubrics &&
																curr_question.evaluation_rubrics
																	.length > 0 && (
																	<Typography
																		color="#005aad"
																		fontSize={'1.1rem'}
																		fontWeight={'bold'}
																	>
																		Score & Feedback
																	</Typography>
																)}
															{curr_question.evaluation_rubrics &&
																curr_question.evaluation_rubrics.map(
																	(rubric, j) => {
																		return (
																			<Stack spacing={1}>
																				<Typography
																					color="#005aad"
																					fontSize={
																						'1rem'
																					}
																					fontWeight={
																						'bold'
																					}
																				>
																					{j + 1}.{' '}
																					{convertToTitleCase(
																						rubric.sub_skill
																					)}
																				</Typography>
																				{rubric.evaluation.map(
																					(e, k) => {
																						return (
																							<Stack>
																								<Typography
																									fontWeight={
																										'bold'
																									}
																								>
																									{
																										e.name
																									}
																								</Typography>
																								<Typography>
																									Rating:{' '}
																									{
																										e.value
																									}

																									,{' '}
																									{
																										e.reason
																									}
																								</Typography>
																							</Stack>
																						);
																					}
																				)}
																			</Stack>
																		);
																	}
																)}
															<hr />
														</Stack>
													)
												);
											})}
										</>
									);
								})}
						</Stack>
						{proctoring && (
							<>
								<Stack bgcolor={'#004aad'} p={1} spacing={1}>
									<Typography
										fontSize={'1.2rem'}
										color={'#fff'}
										fontWeight={'bold'}
									>
										Proctoring Report
									</Typography>
								</Stack>
								{violation !== 'violation' && (
									<>
										<div
											style={{
												width: '150px',
												height: 'auto',
												padding: '10px',
												marginTop: '10px',
												backgroundColor: '#0AAF70',
												color: '#000000',
												borderRadius: '8px',
											}}
										>
											No Violations
										</div>
										<h4
											style={{
												color: '#cccccc',
												fontSize: '12px',
											}}
										>
											*The proctoring report is a combination of both AI and
											Human evaluation
										</h4>
									</>
								)}
								<Typography m={2} mb={0} fontWeight={'bold'} fontSize={'1.3rem'}>
									<u>Eye Gaze Report</u>
								</Typography>
								<CanvasJSChart options={eyeGazeOptions} />
								<Typography m={2} mb={0} fontWeight={'bold'} fontSize={'1.3rem'}>
									<u> No/Multi face detection report</u>
								</Typography>

								<CanvasJSChart options={multifaceOptions} />
							</>
						)}
						{violation && violation === 'violation' && (
							<>
								<Stack bgcolor={'#004aad'} p={1} spacing={1}>
									<Typography
										color="#fff"
										fontSize={'1.2rem'}
										fontWeight={'bold'}
									>
										Interview End Reason
									</Typography>
								</Stack>
								<Stack
									// justifyContent={"center"}
									// alignItems={"center"}
									p={2}
								>
									<Typography fontWeight={'bold'}>
										Accessed multiple windows or tabs
									</Typography>
								</Stack>
							</>
						)}
						{candidateInterviewVideo && candidateInterviewVideo !== '' && (
							<>
								<Stack bgcolor={'#004aad'} p={1} spacing={1}>
									<Typography
										color="#fff"
										fontSize={'1.2rem'}
										fontWeight={'bold'}
									>
										Candidate's Interview Video
									</Typography>
								</Stack>
								<Stack justifyContent={'center'} alignItems={'center'} p={2}>
									<video
										src={candidateInterviewVideo}
										controls
										width={'100%'}
										height={'auto'}
									/>
								</Stack>
							</>
						)}
						<Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
							<Typography color={'gray'} w={'full'} textAlign={'center'} p={2}>
								Report Generated by{' '}
								<a href="https://aihyr.com" target="_blank" rel="noreferrer">
									AI.HYR
								</a>
							</Typography>
						</Stack>
					</Stack>
					<Typography
						variant="h4"
						fontWeight="bold"
						style={{
							cursor: nextInterviewKey ? 'pointer' : 'default',
							color: nextInterviewKey ? '#004aad' : 'gray',
							margin: '5px',
						}}
						onClick={nextInterviewKey ? handleNextClick : null}
					>
						<FaCircleArrowRight />
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};

export default CandidateReportNew;
