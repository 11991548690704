import React from 'react';
import { Header } from '../shared/Header';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box, Button, Card, CircularProgress, Stack, Typography } from '@mui/material';
import Company from './Company';
import Admin from './Admin';
import toast, { Toaster } from 'react-hot-toast';
import Review from './Review';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { API_URL } from '../../global/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isValidEmail } from '../../helpers/utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const EditCompany = () => {
	const { companyId } = useParams();
	const [loading, setLoading] = React.useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [errors, setErrors] = React.useState({
		name: false,
		email: false,
		address: false,
		phone: false,
		logo: false,
		about: false,
		website: false,
		admin: [
			{
				first_name: false,
				last_name: false,
				email: false,
				password: false,
			},
		],
		jobPostings: [
			{
				job_role: false,
				type: false,
				job_description: false,
				requirements: false,
				job_responsibilities: false,
				job_description_link: false,
				faq_link: false,
			},
		],
	});
	const [details, setDetails] = React.useState({
		name: '',
		email: '',
		address: '',
		phone: '',
		logo: '',
		about: '',
		website: '',
		admin: [
			{
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				roles: ['company-admin'],
			},
		],
		jobPostings: [
			{
				job_role: '',
				type: '',
				job_description_link: '',
				faq_link: '',
				job_description: '',
				expected_ctc: '',
				//
				qualifications: '',
				work_experience: '',
				skills_required: '',
				additional_requirements: '',
				// job_responsibilities: "",
				// requirements: "",
			},
		],
	});

	useEffect(() => {
		const fetchCompanyDetails = async () => {
			try {
				const response = await axios.get(`${API_URL}/labs/edit-company/${companyId}`);
				const companyDetails = response.data;
				setDetails(companyDetails);
			} catch (error) {
				console.error('Error fetching company details:', error);
			}
		};

		fetchCompanyDetails();
	}, []);
	useEffect(() => {
		const fetchCompanyAdminDetails = async () => {
			try {
				const response = await axios.get(
					`${API_URL}/labs/edit-company-admins/${companyId}`
				);
				console.log('Admin Details Response:', response.data);
				const companyAdminDetails = response.data;
				setDetails((prevDetails) => ({
					...prevDetails,
					admin: companyAdminDetails,
				}));
			} catch (error) {
				console.error('Error fetching company admin details:', error);
			}
		};

		fetchCompanyAdminDetails();
	}, []);

	const [activeStep, setActiveStep] = React.useState(0);
	const steps = ['Create Company', 'Create Admin', 'Review Details'];
	const isButtonDisabled = () => {
		if (activeStep === 0) {
			if (
				errors.about ||
				errors.address ||
				errors.email ||
				errors.logo ||
				errors.name ||
				errors.phone ||
				details.about === '' ||
				details.address === '' ||
				details.email === '' ||
				details.logo === '' ||
				details.name === '' ||
				details.phone === ''
			) {
				return true;
			}
		}
		if (activeStep === 1) {
			for (let i = 0; i < details.admin.length; i++) {
				if (
					details.admin[i].first_name === '' ||
					details.admin[i].last_name === '' ||
					details.admin[i].email === '' ||
					details.admin[i].password === '' ||
					!isValidEmail(details.admin[i].email)
				) {
					return true;
				}
			}
		}

		if (activeStep === 2) {
			if (
				errors.about ||
				errors.address ||
				errors.email ||
				errors.logo ||
				errors.name ||
				errors.phone ||
				details.about === '' ||
				details.address === '' ||
				details.email === '' ||
				details.logo === '' ||
				details.name === '' ||
				details.phone === ''
			) {
				return true;
			}
			for (let i = 0; i < details.admin.length; i++) {
				if (
					details.admin[i].first_name === '' ||
					details.admin[i].last_name === '' ||
					details.admin[i].email === '' ||
					details.admin[i].password === '' ||
					!isValidEmail(details.admin[i].email)
				) {
					return true;
				}
			}
		}
	};
	const handleNext = async () => {
		if (activeStep === 0) {
			let flag = false;
			if (details.name === '') {
				setErrors((prev) => ({
					...prev,
					name: true,
				}));
				flag = true;
			}
			if (details.email === '') {
				setErrors((prev) => ({
					...prev,
					email: true,
				}));
				flag = true;
			}
			if (details.address === '') {
				setErrors((prev) => ({
					...prev,
					address: true,
				}));
				flag = true;
			}
			if (details.phone === '') {
				setErrors((prev) => ({
					...prev,
					phone: true,
				}));
				flag = true;
			}
			if (details.logo === '') {
				setErrors((prev) => ({
					...prev,
					logo: true,
				}));
				flag = true;
			}
			if (details.about === '') {
				setErrors((prev) => ({
					...prev,
					about: true,
				}));
				flag = true;
			}
			if (flag) return;
		}
		if (activeStep === 1) {
			for (let i = 0; i < details.admin.length; i++) {
				if (details.admin[i].first_name === '') {
					toast.error(`First name is required for admin ${i + 1}`);
					return;
				}
				if (details.admin[i].last_name === '') {
					toast.error(`Last name is required for admin ${i + 1}`);
					return;
				}
				if (details.admin[i].email === '') {
					toast.error(`Email is required for admin ${i + 1}`);
					return;
				}
				if (details.admin[i].password === '') {
					toast.error(`Password is required for admin ${i + 1}`);
					return;
				}
			}
		}

		// console.log(details);

		if (activeStep === steps.length - 1) {
			setLoading(true);
			const { data } = await axios.put(`${API_URL}/labs/company/edit/${companyId}`, details);
			console.log(data);
			if (data.success) {
				// toast.success("Company created successfully");
				enqueueSnackbar('Company created successfully', {
					variant: 'success',
				});
				// after 3 sec navigate to /admin/companies
				setTimeout(() => {
					navigate('/dashboard/jobs-companies');
				}, 3000);
				setDetails({
					name: '',
					email: '',
					address: '',
					phone: '',
					logo: '',
					about: '',
					admin: [
						{
							first_name: '',
							last_name: '',
							email: '',
							password: '',
							roles: ['company-admin'],
						},
					],
					jobPostings: [
						{
							job_role: '',
							type: '',
							job_description: '',
							requirements: '',
							job_responsibilities: '',
							job_description_link: '',
							faq_link: '',
							expected_ctc: '',
						},
					],
				});
				setActiveStep(0);
			} else {
				enqueueSnackbar('Company created successfully', {
					variant: 'success',
				});
			}
			setLoading(false);
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const renderSteps = () => {
		switch (activeStep) {
			case 0:
				return (
					<Company
						details={details}
						setDetails={setDetails}
						errors={errors}
						setErrors={setErrors}
					/>
				);
			case 1:
				return (
					<Admin
						details={details}
						setDetails={setDetails}
						errors={errors}
						setErrors={setErrors}
					/>
				);

			case 2:
				return (
					<Review
						details={details}
						setDetails={setDetails}
						errors={errors}
						setErrors={setErrors}
					/>
				);
			default:
				return <></>;
		}
	};
	return (
		<>
			<Dialog open={loading}>
				<DialogContent>
					<DialogContentText>
						<Stack
							direction="row"
							spacing={2}
							justifyContent={'center'}
							alignItems={'center'}
						>
							<CircularProgress />
							<Typography>Please wait...</Typography>
						</Stack>
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<Toaster />
			<Header />
			<Stack pt={3}>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</Stack>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '2rem',
					backgroundColor: '#ECF7FA',
				}}
			>
				<Card
					sx={{
						height: 'auto',
						// width: "500px",
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '30px',
						// backgroundColor: "#ECF7FA",
					}}
				>
					{renderSteps()}
				</Card>
			</Box>
			{activeStep < steps.length && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						backgroundColor: '#ECF7FA',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							pt: 2,
							width: '30vw',
							backgroundColor: '#ECF7FA',
							paddingBottom: '50px',
							marginTop: '10px',
						}}
					>
						<Button
							disabled={activeStep === 0}
							onClick={handleBack}
							variant="outlined"
							sx={{
								textTransform: 'none',
								color: '#224C84',
								borderColor: '#224C84',
								'&:hover': {
									borderColor: '#224C84',
								},
								mr: 2,
							}}
						>
							Back
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button
							onClick={handleNext}
							disabled={activeStep >= steps.length || isButtonDisabled()}
							variant="contained"
							sx={{
								textTransform: 'none',
								backgroundColor: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
								},
							}}
						>
							{activeStep >= steps.length - 1 ? 'Create' : 'Next'}
						</Button>
					</Box>
				</Box>
			)}
		</>
	);
};

export default EditCompany;
