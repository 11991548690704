import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { Header } from '../shared/Header';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

const ViolationEndScreen = () => {
	const location = useLocation();
	const reason = location.state?.reason;
	const params = useParams();
	const fullName = params?.fullName;
	return (
		<>
			<Header fullName={fullName} />
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					mt: '70px',
				}}
				maxWidth="md"
			>
				<Box>
					<Typography
						variant="h3"
						gutterBottom
						sx={{
							color: '#002244',
							fontWeight: 'bold',
						}}
					>
						Your interview has ended due to the following violation. {reason}
					</Typography>
				</Box>
			</Container>
		</>
	);
};

export default ViolationEndScreen;
