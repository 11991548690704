import React from 'react';
import Select from 'react-select';
import { languageOptions } from './constants/languageOptions';
// import Select from "@mui/material"

const LanguagesDropdown = ({ onSelectChange }) => {
	return (
		<Select
			placeholder={`Filter By Category`}
			options={languageOptions}
			styles={{
				control: (provided, state) => ({
					...provided,
					width: 150,
					border: '1px solid #224c84',
					// This line disable the blue border
					boxShadow: null,
					'&:hover': {
						border: '1px solid #224c84',
					},
				}),
			}}
			defaultValue={languageOptions[0]}
			onChange={(selectedOption) => onSelectChange(selectedOption)}
		/>
	);
};

export default LanguagesDropdown;
