export const jobFormreducer = (state, action) => {
	// console.log(state, action, 'state actions inside reducer');
	switch (action.type) {
		case 'UPDATE_ARRAY_FIELD':
			const updatedobj = { ...state, [action.field]: action.value };
			console.log(action.value, 'Action value');
			return updatedobj;

		case 'UPDATE_FIELD':
			console.log(action, 'Action');
			const updateobj = {
				...state,
				[action.field]: action.value,
			};
			console.log(updateobj);
			return updateobj;

		case 'UPDATE_BOOL':
			console.log(action, 'Action');
			const newobj = {
				...state,
				[action.field]: !action.value,
			};

			return newobj;

		case 'UPDATE_EXPERIENCE':
			return {
				...state,
				experience: {
					...state.experience,
					[action.field]: action.value,
				},
			};
		default:
			return state;
	}
};
