import React from 'react';
import { Stack, TextField, Typography, Button } from '@mui/material';

const SkillsWeightage = (props) => {
	const { details, setDetails } = props;
	return (
		<Stack spacing={2}>
			{details.weightage.map((weightage, index) => {
				return (
					<Stack
						direction={{ md: 'row', sm: 'column' }}
						gap={2}
						justifyContent={'space-between'}
						alignItems={{ sm: 'start', md: 'center' }}
					>
						<Typography>{weightage.skill}</Typography>
						<TextField
							type="number"
							label={'Weightage'}
							value={weightage.value}
							onChange={(e) => {
								setDetails((prev) => {
									const newWeightage = [...prev.weightage];
									newWeightage[index].value = e.target.value;
									return {
										...prev,
										weightage: newWeightage,
									};
								});
							}}
							style={{
								width: '300px',
							}}
						/>
					</Stack>
				);
			})}
			<Button
				style={{
					width: 'fit-content',
					color: '#224C84',
					textTransform: 'none',
					textDecoration: 'underline',
				}}
				onClick={() => {
					let skills = [];
					for (let question of details.questions) {
						if (!question.is_last_question && !question.skip_evaluation) {
							if (!skills.includes(question.skill)) {
								skills.push(question.skill);
							}
						}
					}
					let weightage = [];
					for (let skill of skills) {
						weightage.push({
							skill: skill,
							value: 100 / skills.length,
						});
					}
					setDetails((prev) => ({
						...prev,
						weightage: weightage,
					}));
				}}
			>
				Reset Weightages
			</Button>
		</Stack>
	);
};

export default SkillsWeightage;
