import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Stack } from '@mui/material';
import { Swiper, useSwiper } from 'swiper/react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { postreview } from '../../features/feedback/questions';

const NPSRating = ({ question, followupquestion, question_id }) => {
	const [selectedRating, setSelectedRating] = useState(null);
	const [feedback, setFeedback] = useState('');
	const [error, setError] = useState(false);
	const [showQuestion, setShowQuestion] = useState(false);

	const dispatch = useDispatch();
	const params = useParams();
	const swiper = useSwiper();

	const handleRatingClick = async (rating) => {
		setSelectedRating(rating);
		if (rating <= 6) {
			setShowQuestion(true);
		} else {
			const ratingForm = {
				interview_key: params.interview_key,
				question_id: question_id,
				user_rating: rating,
				user_feedback: '',
			};
			dispatch(postreview({ ratingForm }));
			swiper.slideNext();
		}
	};

	const handleFeedbackChange = (event) => {
		const value = event.target.value;
		setFeedback(value);
		setError(value.length < 20);
	};

	const handleFeedbackSubmit = async () => {
		if (feedback.length < 20) {
			setError(true);
			return;
		}
		const ratingForm = {
			interview_key: params.interview_key,
			question_id: question_id,
			user_rating: selectedRating,
			user_feedback: feedback,
		};
		dispatch(postreview({ ratingForm }));
		swiper.slideNext();
	};

	const getColor = (rating) => {
		if (rating <= 3) return '#ff4d4f'; // Red for 1-3
		if (rating <= 6) return '#ffec3d'; // Yellow for 4-6
		return '#52c41a'; // Green for 7-10
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleFeedbackSubmit();
		}
	};

	return (
		<Box>
			{!showQuestion ? (
				<Stack direction="column" spacing={2}>
					<Typography variant="h6">{question}</Typography>
					<Grid container spacing={2} justifyContent="center">
						{Array.from({ length: 10 }, (_, index) => {
							const rating = index + 1;
							return (
								<Grid item key={rating}>
									<Box
										sx={{
											width: 40,
											height: 40,
											backgroundColor: getColor(rating),
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontSize: '18px',
											fontWeight: 'bold',
											color: '#fff',
											cursor: 'pointer',
											transition: 'transform 0.2s',
											transform:
												selectedRating === rating
													? 'scale(1.2)'
													: 'scale(1)',
										}}
										onClick={() => handleRatingClick(rating)}
									>
										{rating}
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Stack>
			) : (
				<Box sx={{ textAlign: 'center' }}>
					<Typography variant="h6">{followupquestion}</Typography>
					<TextField
						autoFocus
						margin="dense"
						label="Your feedback"
						type="text"
						fullWidth
						value={feedback}
						onChange={handleFeedbackChange}
						error={error}
						helperText={error ? 'Feedback must be at least 20 characters long' : ''}
					/>
					<Button
						variant="contained"
						color="primary"
						sx={{ mt: 2 }}
						onClick={handleFeedbackSubmit}
						disabled={error}
					>
						Submit
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default NPSRating;
