export const languageOptions = [
	{
		id: 63,
		name: 'JavaScript',
		label: 'JavaScript',
		value: 'javascript',
	},
	// {
	//     id: 50,
	//     name: "C",
	//     label: "C",
	//     value: "c",
	// },
	{
		id: 54,
		name: 'C++',
		label: 'C++',
		value: 'cpp',
	},

	{
		id: 62,
		name: 'Java',
		label: 'Java',
		value: 'java',
	},
	{
		id: 71,
		name: 'Python',
		label: 'Python',
		value: 'python',
	},
	// {
	//     id: 74,
	//     name: "TypeScript (3.7.4)",
	//     label: "TypeScript (3.7.4)",
	//     value: "typescript",
	// },
];
