import React from 'react';

import { Stack, Typography, Button, Box } from '@mui/material';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

import TableCell from '@mui/material/TableCell';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import StudentTable from './StudentTable';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MaximizeIcon from '@mui/icons-material/Maximize';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
const StudentProgress = ({ scores }) => {
	if (!scores) {
		return (
			<MaximizeIcon
				style={{ fontSize: '40px', width: '40px', height: '40px', color: 'gray' }}
			/>
		);
	}

	let first = scores[0];
	let last = scores.slice(-1)[0];

	if (!first || !last) {
		return <HorizontalRuleIcon style={{ fontSize: '40px', width: '40px', height: '40px' }} />;
	}
	if (first > last) {
		return (
			<TrendingDownIcon
				style={{
					fontSize: '40px',
					width: '40px',
					height: '40px',
					color: 'red',
					color: 'red',
				}}
			/>
		);
	} else if (first < last) {
		return (
			<TrendingUpIcon
				style={{ fontSize: '40px', width: '40px', height: '40px', color: 'green' }}
			/>
		);
	} else {
		return (
			<HorizontalRuleIcon
				style={{ fontSize: '40px', width: '40px', height: '40px', color: 'gray' }}
			/>
		);
	}
};

const TabelcellComopnent = ({ columns, row }) => {
	const [open, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen((previousOpen) => !previousOpen);
	};

	const canBeOpen = open && Boolean(anchorEl);
	const id = canBeOpen ? 'transition-popper' : undefined;
	return (
		<>
			{columns.map((column, j) => {
				return (
					<TableCell sx={{ maxWidth: '300px' }} key={column.id}>
						{column.id === 'name' && (
							<Typography sx={{ marginLeft: '10px' }} color={'#224C84'}>
								{row['first_name']
									? row['first_name'][0].toUpperCase() +
										row['first_name'].slice(1)
									: 'Anonymous'}{' '}
								{row['last_name']
									? row['last_name'][0].toUpperCase() + row['last_name'].slice(1)
									: 'User'}
							</Typography>
						)}
						{column.id === 'attempts' && (
							<Typography sx={{ marginLeft: '40px' }} color={'#224C84'}>
								{row['total_attempts']}
							</Typography>
						)}
						{column.id === 'max_score' && (
							<div
								style={{
									width: '50px',
									height: '50px',
									marginLeft: '20px',
								}}
							>
								<CircularProgressbar
									strokeWidth={12}
									value={
										row['max_score'] && row['max_score'] !== 'NaN'
											? parseInt(row['max_score'])
											: 0
									}
									text={`${row['max_score'] ? parseInt(row['max_score']) : 0}%`}
									styles={buildStyles({
										textSize: '20px',
										textColor: '#004aad',
										pathColor: '#004aad',
									})}
								/>
							</div>
						)}
						{column.id === 'status' && (
							<Stack>
								{row['has_interview_ended'] ? (
									<>
										<Typography color={'#224C84'}>
											{row['startedDate']}
											<br />
											Completed
										</Typography>
									</>
								) : (
									<Typography color={'#224C84'}>
										Incomplete <br />
										{/* {`${row["completed_questions"]}/${row["total_questions"]}`} */}
									</Typography>
								)}
							</Stack>
						)}
						{column.id === 'min_score' && (
							<div
								style={{
									width: '50px',
									height: '50px',
									marginLeft: '20px',
								}}
							>
								<CircularProgressbar
									strokeWidth={12}
									value={
										row['min_score'] && row['min_score'] !== 'NaN'
											? parseInt(row['min_score'])
											: 0
									}
									text={`${row['min_score'] ? parseInt(row['min_score']) : 0}%`}
									styles={buildStyles({
										textSize: '20px',
										textColor: '#004aad',
										pathColor: '#004aad',
									})}
								/>
							</div>
						)}
						{column.id === 'student_id' && (
							<div style={{ paddingLeft: '40px' }}>
								<StudentProgress scores={row['overall_scores']} />
							</div>
						)}
						{column.id === 'more' && (
							<Button
								aria-describedby={column.id}
								type="button"
								onClick={handleClick}
								startIcon={<MoreHorizIcon />} // This adds the MoreHoriz icon before the text
							>
								More
							</Button>
						)}
						<Popper
							id={row['user_id']}
							open={open}
							anchorEl={anchorEl}
							placement="bottom-end"
							transition
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Box>
										<StudentTable all_interviews={row['all_interviews']} />
									</Box>
								</Fade>
							)}
						</Popper>
					</TableCell>
				);
			})}
		</>
	);
};

export default TabelcellComopnent;
