import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteInterviewDialog = ({
	open,
	setOpen,
	loading,
	onClick,
	setJobId,
	setPilotId,
	jobId,
	pilotId,
	jobRole,
	setJobRole,
}) => {
	const handleClose = () => {
		setOpen(false);
		setJobId('');
		setPilotId('');
		setJobRole('');
	};
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle id="alert-dialog-title">
				{`Are you sure you want to delete interview for ${jobRole} job?`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					This will delete the interview for the job.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} disabled={loading}>
					Cancel
				</Button>
				<Button onClick={onClick} disabled={loading || !jobId || !pilotId || !jobRole}>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteInterviewDialog;
