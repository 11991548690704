import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Pagination, Navigation } from 'swiper/modules';
import { Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
// import Rating from "@mui/material/Rating";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import './candidateReportV2.css';
import './styles.css';
import { Box, Link } from '@mui/material';

import { useAdminContext } from '../../global/AdminContext';
import CodingTestReport from './CodingTestReport';
import CanvasJSReact from './canvasjs.react';
import BulletPoints from './Bulletpoints';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const theme = createTheme({
	palette: {
		success: {
			main: '#66bb6a',
		},
		error: {
			main: '#FF0000',
		},
		warning: {
			main: '#ffc107',
		},
		neutral: {
			main: '#cccccc',
		},
		highly: {
			main: '#04AF70',
		},
		moderately: {
			main: '#05ED98',
		},
	},
});
const CandidateReportV6 = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [reportData, setReportData] = useState();
	const [violation, setViolation] = useState('');
	const [overallScorePercentage, setOverallScorePercentage] = useState();
	const [videoInterviewScore, setVideoInterviewScore] = useState();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [role, setRole] = useState('');
	const params = useParams();
	const [proctoring, setProctoring] = useState(null);
	const [weightage, setWeightage] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [skills, setSkills] = useState([]);
	const [candidateInterviewVideo, setCandidateInterviewVideo] = useState('');
	// const [screenshareVideo, setScreenshareVideo] = useState("");
	const [candidateCodingVideo, setCandidateCodingVideo] = useState('');
	const [codingTestIncluded, setCodingTestIncluded] = useState(false);
	const [candidateCodingScore, setCandidateCodingScore] = useState();
	const [maxScore, setMaxScore] = useState(0);
	const { key } = params;
	const [proctoringReportUrl, setproctoringReportUrl] = useState();

	const options = {
		animationEnabled: true,
		data: [
			{
				type: 'doughnut',
				showInLegend: true,
				indexLabel: '{name}: {y}',
				yValueFormatString: "#,###'%'",
				dataPoints: weightage,
			},
		],
	};
	const [authorized, setAuthorized] = useState(true);
	const { admin, setAdmin } = useAdminContext();
	const [eyeGazeData, setEyeGazeData] = useState([]);
	const [multifaceData, setMultifaceData] = useState([]);
	const [questionVideos, setQuestionVideos] = useState([]);
	const eyeGazeOptions = {
		animationEnabled: true,
		data: [
			{
				type: 'doughnut',
				showInLegend: true,
				indexLabel: '{name}: {y}',
				yValueFormatString: "#,###'%'",
				dataPoints: eyeGazeData,
			},
		],
	};
	const multifaceOptions = {
		animationEnabled: true,
		data: [
			{
				type: 'doughnut',
				showInLegend: true,
				indexLabel: '{name}: {y}',
				yValueFormatString: "#,###'%'",
				dataPoints: multifaceData,
			},
		],
	};
	useEffect(() => {
		const getReportData = async () => {
			setIsLoading(true);
			const response = await axios.get(`${API_URL}/labs/generate-coding-report/${key}`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			});
			// for (let k of response.data) {
			console.log(response.data);

			// }
			setproctoringReportUrl(response.data.proctoring_report_url);
			setQuestionVideos(response.data.question_videos);

			if (response.data.status === 401) {
				console.log('Unauthorized');
				setAuthorized(false);
				setIsLoading(false);
				return;
			}
			const data = response.data;
			setFirstName(data.first_name);
			setLastName(data.last_name);
			setProctoring(data.proctoring);
			if (data.proctoring) {
				const eye_gaze_data = [];
				if (parseInt(data.proctoring.eye_gaze) > 0) {
					eye_gaze_data.push({
						name: 'Looking in the camera',
						y: data.proctoring.eye_gaze,
					});
				}
				if (parseInt(100 - data.proctoring.eye_gaze) > 0) {
					eye_gaze_data.push({
						name: 'Not looking in the camera',
						y: 100 - data.proctoring.eye_gaze,
					});
				}
				setEyeGazeData(eye_gaze_data);
				// setEyeGazeData([
				//     {
				//         name: "Not looking in the camera",
				//         y: 100 - data.proctoring.eye_gaze,
				//     },
				//     {
				//         name: "Looking in the camera",
				//         y: data.proctoring.eye_gaze,
				//     },
				// ]);
				let multi_face_data = [];
				if (parseInt(data.proctoring.multiface) > 0) {
					multi_face_data.push({
						name: 'Several or no faces in the camera',
						y: data.proctoring.multiface,
					});
				}
				if (parseInt(100 - data.proctoring.multiface) > 0) {
					multi_face_data.push({
						name: '1 Face in the camera',
						y: 100 - data.proctoring.multiface,
					});
				}
				setMultifaceData(multi_face_data);
				// setMultifaceData([
				//     {
				//         name: "1 Face in the camera",
				//         y: 100 - data.proctoring.multiface,
				//     },
				//     {
				//         name: "Several or no faces in the camera",
				//         y: data.proctoring.multiface,
				//     },
				// ]);
			}

			setEmail(data.email);
			setContact(data.contact);
			setRole(data.job_role);
			setViolation(data.interview_end_reason);
			setVideoInterviewScore(data?.video_interview_score);
			setOverallScorePercentage(parseInt(data?.final_score));
			setCandidateInterviewVideo(data?.candidate_interview_video);
			setMaxScore(data?.max_score);
			// setScreenshareVideo(data?.screenshare_video);
			if (data?.is_coding_test_included) {
				setCodingTestIncluded(data?.is_coding_test_included);
				setCandidateCodingVideo(data?.candidate_coding_video);
				setCandidateCodingScore(data?.candidate_coding_score);
			}

			// console.log("Sub Skill Data", sub_skills_data);

			setIsLoading(false);
		};

		if (key) {
			getReportData();
		}
	}, [key]);
	const getPathColor = (value) => {
		return value >= 75 ? '#148F5D' : value >= 50 && value < 75 ? '#0AAF70' : '#FF0000';
	};

	return (
		<Stack
			// display={"flex"}
			justifyContent={'center'}
			alignItems={'center'}
			bgcolor={'#ECF7FA'}
			// height={"auto"}
		>
			{isLoading && <CircularStatic />}
			{!authorized && (
				<Stack
					maxWidth={'800px'}
					// bgcolor={"#fff"}
					height={'auto'}
					p={3}
				>
					<Typography fontSize={'1.2rem'} color={'#000'} fontWeight={'bold'}>
						You Don't have access to view this report.
					</Typography>
				</Stack>
			)}
			{!isLoading && authorized && (
				<Stack maxWidth={'800px'} bgcolor={'#fff'} height={'auto'}>
					{/* Header */}
					<Stack bgcolor={'#004aad'} p={1} spacing={1}>
						<Typography
							fontSize={'1.2rem'}
							color={'#fff'}
							fontWeight={'bold'}
						>{`Round 1: Interview report for ${
							firstName + ' ' + lastName
						}`}</Typography>
						<Typography
							fontSize={'1.2rem'}
							color={'#fff'}
							fontWeight={'bold'}
						>{`Role : ${role}`}</Typography>
					</Stack>
					{/* Candidate Information */}
					<Stack
						direction={'row'}
						display={'flex'}
						justifyContent={{ sm: 'center' }}
						alignItems={{ sm: 'center' }}
						p={3}
					>
						<Stack
							alignItems={{ sm: 'start', xs: 'center' }}
							width={{ sm: '50%', xs: 'fit-content' }}
							pl={{ sm: 5, xs: 0 }}
						>
							<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
								Name:{' '}
							</Typography>
							<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
								Email:{' '}
							</Typography>
							<Typography color="#005aad" fontWeight={'bold'} fontSize={'1.2rem'}>
								Phone:{' '}
							</Typography>
						</Stack>
						<Stack width={'50%'}>
							<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
								{firstName + ' ' + lastName}
							</Typography>
							<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
								{email}
							</Typography>
							<Typography color="#005aad" fontWeight={500} fontSize={'1.2rem'}>
								{contact}
							</Typography>
						</Stack>
					</Stack>
					{/* Recommendation */}
					<Stack bgcolor={'#004aad'} p={1} spacing={1}>
						<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
							Recommendation
						</Typography>
					</Stack>
					<Stack
						direction={{ sm: 'row', xs: 'column' }}
						s={{ width: '100%' }}
						spacing={2}
						justifyContent="center"
						alignItems="center"
						p={2}
					>
						<Stack justifyContent="center" alignItems="center" spacing={1}>
							<ThemeProvider theme={theme}>
								<LinearProgress
									variant="determinate"
									color={overallScorePercentage < 50 ? 'error' : 'neutral'}
									sx={{
										width: '200px',
										height: '20px',
										borderRadius: '28px',
										backgroundColor: '#cccccc',
									}}
								/>
							</ThemeProvider>
							<Typography fontWeight={'bold'} color={'#004aad'}>
								Not Recommended
							</Typography>
						</Stack>
						<Stack justifyContent="center" alignItems="center" spacing={1}>
							<ThemeProvider theme={theme}>
								<LinearProgress
									variant="determinate"
									color={
										overallScorePercentage >= 50 && overallScorePercentage < 75
											? 'moderately'
											: 'neutral'
									}
									sx={{
										width: '200px',
										height: '20px',
										borderRadius: '28px',
										backgroundColor: '#cccccc',
									}}
								/>
							</ThemeProvider>
							<Typography fontWeight={'bold'} color={'#004aad'}>
								Recommended
							</Typography>
						</Stack>
						<Stack justifyContent="center" alignItems="center" spacing={1}>
							<ThemeProvider theme={theme}>
								<LinearProgress
									variant="determinate"
									color={overallScorePercentage >= 75 ? 'highly' : 'neutral'}
									sx={{
										width: '200px',
										height: '20px',
										borderRadius: '28px',
										backgroundColor: '#cccccc',
									}}
								/>
							</ThemeProvider>
							<Typography fontWeight={'bold'} color={'#004aad'}>
								Highly Recommended
							</Typography>
						</Stack>
					</Stack>
					<Stack bgcolor={'#004aad'} p={1} spacing={1}>
						<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
							Candidate's Overall Score
						</Typography>
					</Stack>
					{/* Performance Summary */}
					{overallScorePercentage && (
						<Stack
							direction={{ sm: 'row', xs: 'column' }}
							sx={{ width: '100%' }}
							spacing={3}
							p={2}
							justifyContent="center"
							alignItems="center"
						>
							<Stack justifyContent="center" alignItems="center" spacing={1}>
								<Typography fontWeight={'bold'} color={'#004aad'}>
									Overall Score
								</Typography>
								<div
									style={{ width: '150px', height: '150px' }}
									justifyContent="center"
									alignItems="center"
								>
									<CircularProgressbar
										strokeWidth={12}
										value={overallScorePercentage}
										text={`${
											overallScorePercentage &&
											overallScorePercentage !== 'NaN'
												? parseInt(overallScorePercentage)
												: 0
										}%`}
										styles={buildStyles({
											textSize: '16px',
											textColor: getPathColor(overallScorePercentage),
											pathColor: getPathColor(overallScorePercentage),
										})}
									/>
								</div>
							</Stack>

							<div className="performance-legend">
								<div className="legendItem">
									<div className="highly" />
									<Typography fontWeight={'bold'} color={'#004aad'}>
										Highly Recommended
									</Typography>
								</div>
								<div className="legendItem">
									<div className="moderately" />
									<Typography pt={1} pb={1} fontWeight={'bold'} color={'#004aad'}>
										Recommended
									</Typography>
								</div>
								<div className="legendItem">
									<div className="error" />
									<Typography fontWeight={'bold'} color={'#004aad'}>
										Not Recommended
									</Typography>
								</div>
							</div>
						</Stack>
					)}

					{codingTestIncluded && <CodingTestReport interviewKey={key} />}

					{/* Weightage */}

					{/* <Typography color={"#004AAD"} fontWeight={"bold"}>
                            1 - No Answer
                        </Typography>
                        <Typography color={"#004AAD"} fontWeight={"bold"}>
                            2 - Significantly weak answer
                        </Typography>
                        <Typography color={"#004AAD"} fontWeight={"bold"}>
                            3 - Weak answer
                        </Typography>
                        <Typography color={"#004AAD"} fontWeight={"bold"}>
                            4 - Strong answer
                        </Typography>
                        <Typography color={"#004AAD"} fontWeight={"bold"}>
                        5 - Significantly strong answer
                    </Typography> */}

					{/* Candidate's Answer and Detailed Evaluation */}

					{proctoring && (
						<>
							<Stack bgcolor={'#004aad'} p={1} spacing={1}>
								<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
									Proctoring Report
								</Typography>
							</Stack>
							{violation !== 'violation' && (
								<>
									<div
										style={{
											width: '150px',
											height: 'auto',
											padding: '10px',
											marginTop: '10px',
											backgroundColor: '#0AAF70',
											color: '#000000',
											borderRadius: '8px',
										}}
									>
										No Violations
									</div>
									<h4
										style={{
											color: '#cccccc',
											fontSize: '12px',
										}}
									>
										*The proctoring report is a combination of both AI and Human
										evaluation
									</h4>
								</>
							)}
							<Typography m={2} mb={0} fontWeight={'bold'} fontSize={'1.3rem'}>
								<u>Eye Gaze Report</u>
							</Typography>
							<CanvasJSChart options={eyeGazeOptions} />
							<Typography m={2} mb={0} fontWeight={'bold'} fontSize={'1.3rem'}>
								<u> No/Multi face detection report</u>
							</Typography>

							<CanvasJSChart options={multifaceOptions} />
						</>
					)}
					{violation && violation === 'violation' && (
						<>
							<Stack bgcolor={'#004aad'} p={1} spacing={1}>
								<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
									Interview End Reason
								</Typography>
							</Stack>
							<Stack
								// justifyContent={"center"}
								// alignItems={"center"}
								p={2}
							>
								<Typography fontWeight={'bold'}>
									Accessed multiple windows or tabs
								</Typography>
							</Stack>
						</>
					)}
					{candidateInterviewVideo && candidateInterviewVideo !== '' && (
						<>
							<Stack bgcolor={'#004aad'} p={1} spacing={1}>
								<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
									Candidate's Interview Video
								</Typography>
							</Stack>
							<Stack justifyContent={'center'} alignItems={'center'} p={2}>
								<video
									src={candidateInterviewVideo}
									controls
									width={'100%'}
									height={'auto'}
								/>
							</Stack>
						</>
					)}
					{candidateCodingVideo && candidateCodingVideo !== '' && (
						<>
							<Stack bgcolor={'#004aad'} p={1} spacing={1}>
								<Typography color="#fff" fontSize={'1.2rem'} fontWeight={'bold'}>
									Candidate's Coding Interview Video
								</Typography>
							</Stack>
							<Stack justifyContent={'center'} alignItems={'center'} p={2}>
								<video
									src={candidateCodingVideo}
									controls
									width={'100%'}
									height={'auto'}
								/>
							</Stack>
						</>
					)}

					{proctoringReportUrl && proctoringReportUrl != '' && (
						<Box
							sx={{
								p: 2,
								border: '1px solid',
								borderColor: 'grey.300',
								borderRadius: 1,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'left',
							}}
						>
							<Stack bgcolor={'#004aad'} p={1} spacing={1}>
								<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
									Proctoring Report
								</Typography>
							</Stack>
							<Link href={proctoringReportUrl} target="_self">
								<Typography
									fontSize={'1.2rem'}
									color={'#005aad'}
									fontWeight={'bold'}
								>
									Click here to view the proctoring report
								</Typography>
							</Link>
						</Box>
					)}
					{/* {screenshareVideo && screenshareVideo !== "" && (
                        <>
                            <Stack bgcolor={"#004aad"} p={1} spacing={1}>
                                <Typography
                                    color="#fff"
                                    fontSize={"1.2rem"}
                                    fontWeight={"bold"}
                                >
                                    Candidate's Screenshare Video
                                </Typography>
                            </Stack>
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                p={2}
                            >
                                <video
                                    src={screenshareVideo}
                                    controls
                                    width={"100%"}
                                    height={"auto"}
                                />
                            </Stack>
                        </>
                    )} */}

					<Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
						<Typography color={'gray'} w={'full'} textAlign={'center'} p={2}>
							Report Generated by{' '}
							<a href="https://aihyr.com" target="_blank" rel="noreferrer">
								AI.HYR
							</a>
						</Typography>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export default CandidateReportV6;
