import { Button } from '@mui/material';
import styles from './index.module.css';
import ReplayIcon from '@mui/icons-material/Replay';
import Tooltip from '@mui/material/Tooltip';

export const ReplayButton = (props) => {
	const { replayQuestion, isPractice } = props;
	return (
		<div>
			<main>
				<div className={'audio-controls'}>
					<div className={styles.mic_holder}>
						{/* { isPractice && (
                            <>
                                <div className={styles.tooltip_mic}>
                                    Click to replay the question.
                                </div>
                                <div className={styles.arrow}>
                                    <ExpandMoreIcon
                                        fontSize={"50px"}
                                        className={styles.icon}
                                    />
                                </div>
                            </>
                        )} */}
						<Tooltip title="Replay" placement="top" arrow>
							<div className={styles.audio_recorder_div} style={{ zIndex: 99999 }}>
								<Button
									onClick={replayQuestion}
									style={{
										borderRadius: '50%',
										border: '1px',
										width: '50px',
										height: '50px',
										color: '#ffffff',
										borderColor: 'red',
										zIndex: 99999,
									}}
								>
									<ReplayIcon
										sx={{
											width: '2em',
											height: '2em',
											fontSize: 'large',
											zIndex: 99999,
										}}
									/>
								</Button>
							</div>
						</Tooltip>
					</div>
				</div>
			</main>
		</div>
	);
};
