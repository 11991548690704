import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
const LoadingDialog = ({ open }) => {
	return (
		<Dialog open={open}>
			<DialogTitle id="alert-dialog-title">{'Please Wait'}</DialogTitle>
			<DialogContent>
				<Stack justifyContent={'center'} alignItems={'center'}>
					<CircularProgress />
				</Stack>
				{/* <DialogContentText id="alert-dialog-description">
                  Let Google help apps determine location. This means sending
                  anonymous location data to Google, even when no apps are
                  running.
              </DialogContentText> */}
			</DialogContent>
			{/* <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleClose} autoFocus>
                  Agree
              </Button>
          </DialogActions> */}
		</Dialog>
	);
};

export default LoadingDialog;
