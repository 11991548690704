import { Avatar, Box, Button, Divider, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAdminContext } from '../../global/AdminContext';
import { useLocation, useNavigate } from 'react-router';
import { CircularStatic } from '../CircularProgressWithLabel';
import { ConfirmationModal } from '../ConfirmationModal';

export function InitialQuestions() {
	const { state } = useLocation();
	const [loading, setLoading] = useState(true);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const { admin } = useAdminContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (admin.loading) return;
		if (!admin.exists) navigate('/');
		setLoading(false);
	}, [admin, navigate]);

	const toggleGenerateInterviewModal = (value) => {
		setOpenConfirmationModal(value);
	};

	return (
		<>
			{loading ? (
				<CircularStatic />
			) : (
				<>
					{state?.questions ? (
						<div style={{ display: 'flex' }}>
							<Box width="70vw" height="auto" margin="30px auto">
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										margin: '10px',
									}}
								>
									<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
										Review Questions
									</Typography>
									{/* <Box>
                    <Button variant="contained" sx={{ margin: "0 10px" }}>
                      Save
                    </Button>
                  </Box> */}
								</Box>
								<Paper>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-around',
												margin: '10px',
												alignItems: 'center',
											}}
										>
											<Avatar sx={{ marginRight: '20px' }}>
												{state.applicant.first_name[0]}
											</Avatar>
											<Typography sx={{ fontWeight: 'bold' }}>
												{state.applicant.first_name}
												{' ' + state.applicant.last_name}
											</Typography>
										</Box>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-around',
												margin: '10px',
												alignItems: 'center',
											}}
										>
											<Button
												variant="outlined"
												sx={{ margin: '0 10px', textTransform: 'none' }}
												disabled={openConfirmationModal}
											>
												Edit
											</Button>
											<Button
												variant="contained"
												sx={{ margin: '0 10px', textTransform: 'none' }}
												onClick={toggleGenerateInterviewModal}
												disabled={openConfirmationModal}
											>
												Generate Interview
											</Button>
										</Box>
									</Box>
									<Divider />
									<ol
										style={{
											margin: '30px',
											paddingBottom: '30px',
											lineHeight: '41px',
										}}
									>
										{state.questions.map((Q, index) => (
											<li
												style={{
													color: '#1776D2',
													fontSize: '14px',
													fontWeight: 'bold',
												}}
												key={index}
											>
												{Q.question_text}
											</li>
										))}
									</ol>
								</Paper>
							</Box>
							{/* <ArrowForwardIosIcon sx={{ width: '50px', height: '60px', position: 'absolute', top: '50%', right: '50px', color: '#1776D2' }} /> */}
						</div>
					) : (
						<>
							Questions not found. This page should be reached from create-interview
							only.
						</>
					)}
				</>
			)}
			{openConfirmationModal && <ConfirmationModal openModal={openConfirmationModal} />}
		</>
	);
}
