import { Button, Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { API_URL } from '../../global/constants';
import toast, { Toaster } from 'react-hot-toast';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UploadResume = ({ interview_key, value, completedInterviews, setCompletedInterviews }) => {
	const resumeUploadRef = useRef(null);
	const [uploaded, setUploaded] = React.useState(false);
	const [uploading, setUploading] = React.useState(false);
	useEffect(() => {
		if (value) {
			setUploaded(true);
		}
	}, [value]);
	const uploadResumeToS3 = async (file, interviewKey) => {
		setUploading(true);
		let s3fileName = `${interviewKey}/resume`;
		const requestObject = {
			method: 'POST',
			body: {
				fileName: s3fileName,
				fileType: file.type,
			},
		};
		console.log(file);
		const signUrlData = await axios.post(`${API_URL}/labs/getPreSignedUrl`, requestObject);
		const updloadedData = await axios.put(`${signUrlData.data.url}`, file, {
			headers: {
				'Content-Type': file.type,
			},
		});
		const { data } = await axios.post(`${API_URL}/labs/upload/resume`, {
			interview_key: interviewKey,
		});
		// for the particular interview key update the link
		const resume_link = data.link;
		setCompletedInterviews(
			completedInterviews.map((interview) => {
				if (interview.interview_key === interviewKey) {
					return {
						...interview,
						candidate_resume: resume_link,
					};
				}
				return interview;
			})
		);
		if (data.success) {
			toast.success(data.message, {
				duration: 3000,
				position: 'top-center',
			});
		} else {
			toast.error(data.message, {
				duration: 3000,
				position: 'top-center',
			});
		}
		setUploaded(true);
		setUploading(false);
	};
	return (
		<>
			<Toaster />
			<input
				type="file"
				ref={resumeUploadRef}
				onChange={(e) => uploadResumeToS3(e.target.files[0], interview_key)}
				hidden
			/>

			<Tooltip title={!uploaded ? 'Upload Resume' : 'Resume Already Uploaded'}>
				<Button
					variant="outlined"
					size="small"
					disabled={uploading || uploaded}
					startIcon={<FileUploadIcon />}
					onClick={() => resumeUploadRef.current.click()}
					style={{
						color: uploading || uploaded ? '#BDBDBD' : '#224C84',
						textTransform: 'none',
						width: '130px',
					}}
				>
					{uploaded ? 'Uploaded' : uploading ? 'Uploading...' : 'Upload'}
				</Button>
			</Tooltip>
		</>
	);
};

export default UploadResume;
