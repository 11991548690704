import React, { useEffect, useState, useRef } from 'react';
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Chip,
	Box,
	Badge,
	Tooltip,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	DialogActions,
	Grid,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/Delete';

const DemoMockinterview = (props) => {
	const [loading, setLoading] = useState(false);
	const [fullName, setFullName] = useState(props.fullName);
	const [roles, setRoles] = useState([]);
	const navigate = useNavigate();
	const [hovered, setHovered] = useState(false); // State to handle hover
	const [open, setOpen] = useState(false);

	// Media query for responsive design
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		if (sessionStorage.getItem('admin') === null) return;
		const roles = JSON.parse(sessionStorage.getItem('admin')).roles || [];
		setRoles(roles);
	}, []);

	const createInterview = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/mock-interview/create/${props.interview_id}`,
				{},
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			if (data.success) {
				toast.success('Interview Created Successfully');
				navigate(`/check/${fullName}/${data.data._id}`, {
					state: {
						fullName: fullName,
						interviewKey: data.data._id,
						isStudentInterview: true,
					},
				});
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	// Check if the role is admin
	const isAdmin = roles.includes('aihyr-admin');

	// Open the confirmation modal
	const handleClickOpen = () => {
		setOpen(true);
	};

	// Close the confirmation modal
	const handleClose = () => {
		setOpen(false);
	};

	// delete mockinterview
	const deleteMockInterview = async () => {
		setLoading(true);
		try {
			const { data } = await axios.delete(
				`${API_URL}/labs/delete-mockinterview/${props.interview_id}`,
				{
					headers: {
						authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
					},
				}
			);
			if (data.success) {
				toast.success('Interview Deleted Successfully');
				props.refetch();
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	const chipContainerRef = useRef(null);

	const handleMouseDown = (e) => {
		if (!chipContainerRef.current) return;

		chipContainerRef.current.isDragging = true;
		chipContainerRef.current.startX = e.pageX - chipContainerRef.current.offsetLeft;
		chipContainerRef.current.scrollLeft = chipContainerRef.current.scrollLeft;
	};

	const handleMouseMove = (e) => {
		if (!chipContainerRef.current?.isDragging) return;

		const x = e.pageX - chipContainerRef.current.offsetLeft;
		const walk = (x - chipContainerRef.current.startX) * 2; // Multiplied by 2 for faster scrolling
		chipContainerRef.current.scrollLeft = chipContainerRef.current.scrollLeft - walk;
	};

	const handleMouseUp = () => {
		if (!chipContainerRef.current) return;
		chipContainerRef.current.isDragging = false;
	};

	return (
		<Box
			onMouseEnter={() => setHovered(true)} // Show chip on hover
			onMouseLeave={() => setHovered(false)} // Hide chip on leave
			sx={{
				display: 'flex',
				position: 'relative',
				justifyContent: 'center',
				alignItems: 'center',
				width: isMobile ? '330px' : '270px',
				height: isMobile ? '100%' : '320px',
			}}
		>
			{/* Card with blur effect when locked */}
			<Card
				sx={{
					width: '100%',
					height: '100%',
					borderRadius: '12px',
					boxShadow: 3,
					transition: 'transform 0.6s, box-shadow 0.6s, filter 0.6s', // Keep transition for non-admins
					transform: hovered ? 'scale(1.05)' : 'scale(1)', // Scale on hover
					boxShadow: hovered
						? '0 8px 16px rgba(0, 0, 0, 0.2)' // Shadow change
						: '0 4px 8px rgba(0, 0, 0, 0.1)',
					filter:
						props.locked && hovered && !isAdmin
							? 'blur(1px)'
							: hovered && !isAdmin
								? 'blur(0.5px)'
								: 'none',
					cursor: props.locked ? 'not-allowed' : 'pointer', // Prevent pointer events if locked
				}}
			>
				{roles.includes('aihyr-admin') && (
					<Box
						sx={{
							position: 'absolute',
							top: 14,
							right: 60,
							width: 40,
							height: 40,
							borderRadius: '50%',
							backgroundColor: 'white',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer',
							zIndex: 2,
							transition: 'background-color 0.3s, color 0.3s',
							'&:hover': {
								backgroundColor: 'white',
								'& .MuiSvgIcon-root': {
									color: '#224C84',
								},
							},
						}}
					>
						<EditRoundedIcon
							sx={{
								color: 'black',
								transition: 'color 0.3s',
							}}
							onClick={() => {
								navigate(
									`/edit-mock-interview/${props.interview_id}/${props.pilot_id}`
								);
							}}
						/>
					</Box>
				)}
				{roles.includes('aihyr-admin') && (
					<Box
						sx={{
							position: 'absolute',
							top: 14,
							right: 14,
							width: 40,
							height: 40,
							borderRadius: '50%',
							backgroundColor: 'white',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer',
							zIndex: 2,
							transition: 'background-color 0.3s, color 0.3s',
							'&:hover': {
								backgroundColor: 'white',
								'& .MuiSvgIcon-root': {
									color: '#224C84',
								},
							},
						}}
					>
						<IconButton onClick={handleClickOpen}>
							<DeleteIcon sx={{ color: 'red' }} />
						</IconButton>
					</Box>
				)}
				<Box sx={{ position: 'relative' }}>
					<CardMedia
						component="img"
						height={isMobile ? '200px' : '140'}
						image={props?.poster}
						alt="image"
						sx={{
							borderRadius: 5,
							padding: '8px',
							width: '100%',
						}}
					/>
					{/* Position chips between image and content */}
					<Box
						ref={chipContainerRef}
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						onMouseLeave={handleMouseUp}
						sx={{
							marginLeft: '15px',
							marginTop: '5px',
							marginRight: '15px',
							overflow: 'hidden', // Hide scrollbars
							whiteSpace: 'nowrap', // Prevent chips from wrapping to the next line
							display: 'flex',
							alignItems: 'center',
							// cursor: 'grab',
							userSelect: 'none',
						}}
					>
						{props.skills[0] &&
							props.skills[0].split(',').map((skill, index) => (
								<Tooltip key={index} title={skill.trim()}>
									<Chip
										label={skill.trim()}
										size="small"
										sx={{
											marginTop: '4px',
											fontSize: '10px',
											backgroundColor: '#e0f7fa',
											color: '#00695c',
											marginRight: '4px',
										}}
									/>
								</Tooltip>
							))}
					</Box>
				</Box>
				<CardContent>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{
							fontSize: '12px',
							fontWeight: '500',
							color: '#f50057',
						}}
					>
						{props.interview_level}
					</Typography>
					<Typography
						variant="h6"
						component="div"
						sx={{
							fontSize: isMobile ? '12px' : '14px', // Adjust font size for mobile
							fontWeight: 'bold',
							mt: 1,
						}}
					>
						{props.role}
					</Typography>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ fontSize: '12px', mt: 1 }}
					>
						{props?.interviewDescription}
					</Typography>
				</CardContent>
			</Card>

			{/* Show the Start Interview button only if the interview is not locked */}
			{!props.locked && !isAdmin && (
				<Chip
					label="Start Interview"
					color="primary"
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: hovered
							? 'translate(-50%, -50%) scale(1) translateY(0)' // Slide into place and scale
							: 'translate(-50%, -50%) scale(0.8) translateY(30px)', // Start slightly below and smaller
						backgroundColor: '#0056d2',
						color: '#fff',
						padding: '8px 16px',
						fontSize: '14px',
						fontWeight: 'bold',
						transition: 'transform 0.5s ease, opacity 0.5s ease', // Smooth fade, scale, and slide
						opacity: hovered ? 1 : 0, // Fade in smoothly
						pointerEvents: hovered ? 'auto' : 'none', // Disable click when not visible
						'&:hover': {
							backgroundColor: '#003e99',
						},
					}}
					onClick={createInterview}
				/>
			)}

			{/* Show a larger locked badge if the interview is locked */}
			{props.locked && (
				<Tooltip
					title="Score minimum 50% in the previous interview to unlock"
					arrow
					enterTouchDelay={0}
					leaveTouchDelay={3000}
				>
					<Badge
						badgeContent="Locked"
						color="error"
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							fontSize: '1.5rem', // Increase the size of the badge
							transform: 'scale(1.5)', // Enlarge badge
							zIndex: 1,
							// cursor: 'help', // Show help cursor when hovering over locked badge
						}}
					/>
				</Tooltip>
			)}

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<Typography>Are you sure you want to delete this interview?</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={deleteMockInterview} color="secondary" disabled={loading}>
						{loading ? 'Deleting...' : 'Delete'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DemoMockinterview;
