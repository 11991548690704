import { Interview } from '@smashorg/ai-bot';
import React, { useState } from 'react';
// import { useParams } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';

const SmashDemo = () => {
	let id;
	// const params = useParams();
	if (sessionStorage.getItem('smash_user_id')) {
		id = sessionStorage.getItem('smash_user_id');
	} else {
		id = uuidv4();
		sessionStorage.setItem('smash_user_id', id);
	}
	const [name, setName] = useState('');
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			{/* <Dialog open={open}>
                <DialogTitle>Enter Your Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            Bot Preference
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={botPreference}
                            name="radio-buttons-group"
                            onChange={(e) => setBotPreference(e.target.value)}
                        >
                            <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Female"
                            />
                            <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Male"
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={name === ""}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog> */}
			<Interview smashUserId={id.toString()} />
		</>
	);
};

export default SmashDemo;
