import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import JobForm from './jobdetailsform';
import Generateddetails from './generateddetails';
import { jobFormreducer } from '../Reducers';
import { useAdminContext } from '../../../global/AdminContext';
import toast, { Toaster } from 'react-hot-toast';
import '../styles.css';
import { useParams } from 'react-router';

const initialState = {
	position: '',
	company: '',
	company_id: '',
	qualifications: [],
	certifications: [],
	workType: '',
	experience: { min: '', max: '' },
	roleType: '',
	department: '',
	mustHaveSkills: [],
	additionalSkills: [],
	country: '',
	stateName: '',
	city: '',
	compensation: '',
	includectc: false,
};

const SplitLayout = ({ initialstate }) => {
	const [state, dispatch] = useReducer(jobFormreducer, initialState);
	const [leftWidth, setLeftWidth] = useState(20); // Initial left side width in percentage
	const [isResizing, setIsResizing] = useState(false);
	const [generatedJd, setgeneratedJd] = useState({});
	const { admin } = useAdminContext();

	useEffect(() => {
		if (!admin) {
			return;
		}
		async function fetchcompany() {
			const { data } = await axios.get(`${API_URL}/labs/companydetails`, {
				headers: {
					Authorization: `Bearer ${admin.token}`,
				},
			});
			const { company_details } = data;
			console.log(company_details, 'company details');
			if (!company_details) return;
			const { name, _id } = company_details;
			dispatch({ type: 'UPDATE_FIELD', field: 'company', value: name });
			dispatch({ type: 'UPDATE_FIELD', field: 'company_id', value: _id });
		}
		fetchcompany();
	}, [admin]);

	const { id } = useParams();
	console.log(id, 'params id');
	const handleMouseDown = () => {
		setIsResizing(true);
	};

	const handleGenerateClick = async () => {
		const { position, qualifications, workType, mustHaveSkills } = state;
		if (
			!position.length ||
			!qualifications.length ||
			!mustHaveSkills.length ||
			!workType.length
		) {
			toast.error('please fill all the required fields');
			return;
		}

		const response = await axios.post(`${API_URL}/jobdescription/generate`, { state });
		console.log(response.data.jobDescription, 'State');
		setgeneratedJd(response.data.jobDescription);
	};

	const handleMouseMove = useCallback(
		(e) => {
			if (!isResizing) return;
			const container = document.querySelector('.split-layout');
			if (!container) return;

			const containerWidth = container.offsetWidth;
			const newLeftWidth = (e.clientX / containerWidth) * 100;

			// Ensure left side doesn't go below 20% and above 80% of container width
			if (newLeftWidth >= 20 && newLeftWidth <= 80) {
				setLeftWidth(newLeftWidth);
			}
		},
		[isResizing]
	);

	const handleMouseUp = () => {
		setIsResizing(false);
	};

	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, [handleMouseMove]);

	return (
		<div className="split-layout" style={{ position: 'relative' }}>
			<Toaster />
			<div className="left-side" style={{ width: `${leftWidth}%` }}>
				<JobForm
					state={state}
					handleGenerateClick={handleGenerateClick}
					dispatch={dispatch}
				/>
			</div>
			<div
				className="resizer"
				onMouseDown={handleMouseDown}
				style={{ cursor: isResizing ? 'col-resize' : 'default' }}
			></div>
			<div className="right-side" style={{ width: `calc(${100 - leftWidth}%)` }}>
				<Generateddetails state={state} generatedJd={generatedJd} />
			</div>
		</div>
	);
};

export default SplitLayout;
