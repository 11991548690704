import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export const ConfirmationModal = (props) => {
	const [open, setOpen] = useState(props?.openModal);
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Thank you for your confirmation.
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						The interview will be generated within 12 hours. We will send an email to
						the candidate with a unique interview key after the interview is generated.
					</Typography>
				</Box>
			</Modal>
		</div>
	);
};
