import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectInterviewData } from '../features/interview/interview';
import { PROCTORING_TOOL } from '../global/constants';
const WEBSOCKET_SERVER_URL = 'wss://aihyrproctoringwebapp.azurewebsites.net/upload/';

const WebcamStream = (props) => {
	const videoRef = useRef(null);
	const wsRef = useRef(null);
	const interview_data = useSelector(selectInterviewData);
	const { fullName, isDemo, isStudentInterview, candidate_interview, proctoring_token } =
		interview_data;
	const { proctoring_tool } = candidate_interview;

	useEffect(() => {
		// Create a new WebSocket connection
		wsRef.current = new WebSocket(WEBSOCKET_SERVER_URL);

		// Handle WebSocket events
		wsRef.current.onopen = () => {
			console.log('WebSocket connected');
		};

		wsRef.current.onclose = () => {
			console.log('WebSocket disconnected');
		};

		wsRef.current.onerror = (error) => {
			console.error('WebSocket error: ', error);
		};

		wsRef.current.onmessage = (event) => {
			console.log('Received data:', event.data);
		};

		return () => {
			// Close the WebSocket connection when the component unmounts
			wsRef.current.close();
		};
	}, []);

	useEffect(() => {
		const setupWebcam = async () => {
			const constraints = { video: true };
			const stream = await navigator.mediaDevices.getUserMedia(constraints);
			videoRef.current.srcObject = stream;
			videoRef.current.addEventListener('loadeddata', () => {
				const frameRate = 10; // Adjust the frame rate as needed

				function sendFrame() {
					if (
						videoRef.current &&
						!props.interviewEnded &&
						!videoRef.current.paused &&
						!videoRef.current.ended
					) {
						const canvas = document.createElement('canvas');
						const context = canvas.getContext('2d');
						canvas.width = videoRef.current.videoWidth;
						canvas.height = videoRef.current.videoHeight;
						context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
						// wsRef.current.send("hello there");
						const imageData = canvas.toDataURL('image/jpeg');
						// Assuming imageData contains the data URL
						const dataUrlParts = imageData.split(',');

						if (dataUrlParts.length === 2) {
							const base64Data = dataUrlParts[1];
							if (wsRef.current.readyState === 1) {
								// Now, base64Data contains the actual Base64-encoded image data
								// You can send this to the server
								// console.log("sending data...");
								const data = {
									image: base64Data,
									interview_key: props.interviewKey,
								};
								const jsonData = JSON.stringify(data);
								// Now, base64Data contains the actual Base64-encoded image data
								// You can send this to the server
								console.log('sending data...');
								wsRef.current.send(jsonData);

								// wsRef.current.send(base64Data);
								// wsRef.current.send({ image: base64Data, interview_key:"6539605f1204e3261cb47ae3" })
							}
						}

						// console.log(imageData)
					}
					// console.log("yes")
					setTimeout(sendFrame, 1000 / frameRate);
				}
				if (!isStudentInterview) {
					sendFrame();
				}
			});
		};

		setupWebcam();
	}, []);
	return (
		<>
			{proctoring_tool !== PROCTORING_TOOL && (
				<video
					ref={videoRef}
					autoPlay={true}
					playsInline={true}
					muted={true}
					style={props.style}
				/>
			)}
			{proctoring_tool === PROCTORING_TOOL && (
				<video
					ref={videoRef}
					autoPlay={true}
					playsInline={true}
					muted={true}
					style={{ display: 'none' }}
				/>
			)}
		</>
	);
};

export default WebcamStream;
