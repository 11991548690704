import React, { useEffect, useRef, useState } from 'react';

const VideoPlayer = ({ questionVideo, diff }) => {
	const videoRef = useRef(null);
	const [seekValue, setSeekValue] = useState(0);

	useEffect(() => {
		const video = videoRef.current;

		const handleTimeUpdate = () => {
			const value = (100 / video.duration) * video.currentTime;
			setSeekValue(value);
		};

		video.addEventListener('timeupdate', handleTimeUpdate);

		return () => {
			video.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, [diff]);

	useEffect(() => {
		const video = videoRef.current;

		const handleIntersection = (entries) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					video.pause();
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersection, {
			threshold: 0.5, // Adjust this threshold as needed
		});

		observer.observe(video);

		return () => {
			observer.unobserve(video);
		};
	}, []);

	return (
		<div>
			<video ref={videoRef} width="600" height="400" controls>
				<source src={questionVideo} type="video/mp4" />
			</video>
			<br></br>
			<br></br>
			<br></br>
		</div>
	);
};

export default VideoPlayer;
