import React from 'react';
import { Stack, Typography } from '@mui/material';

const ChooseAIHYR = () => {
	return (
		<Stack
			id="benefits"
			// bgcolor={"white"}
			// height={{ sm: "100vh" }}
			display="flex"
			// justifyContent={"center"}
			alignItems={'center'}
			pt={{ xs: 8, sm: 5 }}
		>
			<Typography
				fontFamily={'Poppins'}
				fontWeight={'700'}
				fontSize={{ sm: '3.2rem', xs: '2.2rem' }}
				color={'#224C84'}
				textAlign={'center'}
				p={{ sm: 2 }}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
			>
				Why Choose AI.HYR?
			</Typography>
			<Stack
				display={'flex'}
				// justifyContent={"space-evenly"}
				direction={{ sm: 'row', xs: 'column' }}
				spacing={{ sm: 3, xs: 2 }}
				// p={{ sm: 3, xs: 2 }}
				ml={{ sm: 15, xs: 4 }}
				mr={{ sm: 15, xs: 4 }}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
			>
				<Stack
					spacing={1}
					width={{ sm: '33%' }}
					alignItems={{ xs: 'center', sm: 'start' }}
					bgcolor={'rgb(236, 247, 250, 0.7)'}
					p={2}
					borderRadius={'20px'}
				>
					<Typography fontSize={'4rem'}>🏆</Typography>
					<Typography
						color={'#224C84'}
						bgcolor={'#ffbd59'}
						p={'5px'}
						borderRadius={'10px'}
						fontFamily={'Poppins'}
						fontWeight={'400'}
						fontSize={'1.2rem'}
					>
						Superior Experience
					</Typography>
					<Typography
						fontFamily={'Poppins'}
						fontSize={'1.2rem'}
						color={'#224C84'}
						fontWeight={'500'}
						textAlign={{ xs: 'center', sm: 'start' }}
					>
						Elevates candidate performance through human-like AI interviewers, providing
						an experience akin to video interviews.
					</Typography>
				</Stack>
				<Stack
					spacing={1}
					width={{ sm: '33%' }}
					alignItems={{ xs: 'center', sm: 'start' }}
					bgcolor={'rgb(236, 247, 250, 0.7)'}
					p={2}
					borderRadius={'20px'}
				>
					<Typography fontSize={'4rem'}>📒</Typography>
					<Typography
						color={'#224C84'}
						bgcolor={'#ffbd59'}
						p={'5px'}
						borderRadius={'10px'}
						fontFamily={'Poppins'}
						fontWeight={'400'}
						fontSize={'1.2rem'}
					>
						Interview Playbooks
					</Typography>
					<Typography
						fontFamily={'Poppins'}
						fontSize={'1.2rem'}
						color={'#224C84'}
						fontWeight={'500'}
						textAlign={{ xs: 'center', sm: 'start' }}
					>
						Develops a reusable interview playbook for companies encompassing both hard
						and soft skills. This playbook comprises assessment criteria, thoughtfully
						crafted questions, and comprehensive evaluation parameters.
					</Typography>
				</Stack>
				<Stack
					spacing={1}
					width={{ sm: '33%' }}
					alignItems={{ xs: 'center', sm: 'start' }}
					bgcolor={'rgb(236, 247, 250, 0.7)'}
					p={2}
					borderRadius={'20px'}
				>
					<Typography fontSize={'4rem'}>🔍</Typography>
					<Typography
						color={'#224C84'}
						bgcolor={'#ffbd59'}
						p={'5px'}
						borderRadius={'10px'}
						fontFamily={'Poppins'}
						fontWeight={'400'}
						fontSize={'1.2rem'}
					>
						Enhanced Precision
					</Typography>
					<Typography
						fontFamily={'Poppins'}
						fontSize={'1.2rem'}
						color={'#224C84'}
						fontWeight={'500'}
						textAlign={{ xs: 'center', sm: 'start' }}
					>
						Built on cutting-edge technology, harnessing the power of multiple language
						models and machine learning algorithms to ensure precision. Leveraging video
						analytics to enhance proctoring capabilities
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ChooseAIHYR;
