import { Stack, Typography } from '@mui/material';
import React from 'react';

const Testimonials = () => {
	return (
		<>
			<Stack
				id="testimonials"
				// height={{ sm: "100vh" }}
				display="flex"
				justifyContent={'center'}
				alignItems={'center'}
				p={2}
				pt={3}
				pb={3}
			>
				<Stack direction={{ sm: 'row', xs: 'column' }} alignItems={'center'}>
					<Typography
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="300"
						width={{ sm: '40%', xs: '100%' }}
						fontFamily={'Poppins'}
						fontWeight={'700'}
						fontSize={{ sm: '3.2rem', xs: '2.2rem' }}
						color={'#224C84'}
						mr={{ sm: 0, xs: 0 }}
						ml={{ sm: 15, xs: 0 }}
						alignItems={'center'}
						textAlign={{ xs: 'center', sm: 'start' }}
						p={{ sm: 0, xs: 0 }}
					>
						AI
						<br /> Interviewers
						<br /> bring out the
						<br /> best in
						<br /> candidates{' '}
					</Typography>
					<Stack
						width={{ sm: '60%', xs: '100%' }}
						p={{ sm: 5, xs: 0 }}
						mr={{ sm: 15, xs: 0 }}
						spacing={{ sm: 3, xs: 2 }}
						alignItems={{ xs: 'center', sm: 'start' }}
					>
						<Typography
							textAlign={{ xs: 'center', sm: 'start' }}
							p={{ sm: 0, xs: 2 }}
							// pb={0}
							data-aos="zoom-in"
							data-aos-duration="1000"
							data-aos-delay="300"
							width={'full'}
							fontFamily={'Poppins'}
							fontWeight={'500'}
							fontSize={'1.5rem'}
							color={'#224C84'}
						>
							<u>Talent Speaks</u>
						</Typography>
						<Stack
							direction={'column'}
							data-aos="fade-up"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<Stack
								direction={'row'}
								// width={{ xs: "95%", sm: "100%" }}
								p={{ xs: 2, sm: 0 }}
								pb={0}
								pt={0}
							>
								<Typography fontSize={'2.5rem'} marginRight={-1.5} zIndex={2}>
									😀
								</Typography>
								<Typography
									fontFamily={'Poppins'}
									fontSize={'1.2rem'}
									p={1.5}
									color={'#224C84'}
									style={{
										backgroundColor: 'rgb(222, 229, 240, 0.8)',
										borderRadius: '10px',
									}}
								>
									"I wasn't conscious while giving an interview to AI because it
									doesn't judge. I can be myself while giving an interview."
								</Typography>
							</Stack>
							<Typography
								fontFamily={'Poppins'}
								color={'#224C84'}
								fontSize={'1.2rem'}
								ml={8}
							>
								- Rashmi Sbakle
							</Typography>
						</Stack>
						<Stack
							direction={'column'}
							data-aos="fade-up"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<Stack
								direction={'row'}
								p={{ xs: 2, sm: 0 }}
								pb={0}
								// width={{ xs: "90%", sm: "100%" }}
							>
								<Typography fontSize={'2.5rem'} marginRight={-1.5} zIndex={2}>
									😌
								</Typography>
								<Typography
									fontFamily={'Poppins'}
									fontSize={'1.2rem'}
									p={1.5}
									color={'#224C84'}
									style={{
										backgroundColor: 'rgb(255, 222, 89, 0.8)',
										borderRadius: '10px',
									}}
								>
									"The interview pressure makes me forgetful, but there is no
									pressure when I'm interviewing with AI."
								</Typography>
							</Stack>
							<Typography
								fontFamily={'Poppins'}
								color={'#224C84'}
								fontSize={'1.2rem'}
								ml={8}
							>
								- Shweta Jagtap{' '}
							</Typography>
						</Stack>
						<Stack
							direction={'column'}
							data-aos="fade-up"
							data-aos-duration="1000"
							data-aos-delay="300"
						>
							<Stack
								direction={'row'}
								p={{ xs: 2, sm: 0 }}
								pb={0}
								// width={{ xs: "90%", sm: "100%" }}
							>
								<Typography fontSize={'2.5rem'} marginRight={-1.5} zIndex={2}>
									😎
								</Typography>
								<Typography
									fontFamily={'Poppins'}
									fontSize={'1.2rem'}
									p={1.5}
									color={'#224C84'}
									style={{
										backgroundColor: 'rgb(193, 255, 114, 0.8)',
										borderRadius: '10px',
									}}
								>
									"Feels more relaxed to give interviews to an AI because you can
									pause and think about answers. Whereas with humans, there is
									pressure to continuously talk."
								</Typography>
							</Stack>
							<Typography
								fontFamily={'Poppins'}
								color={'#224C84'}
								fontSize={'1.2rem'}
								ml={8}
							>
								- Mansi Jadhav{' '}
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};

export default Testimonials;
