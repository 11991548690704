import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';

const Timestamps = (props) => {
	const { details, setDetails } = props;
	return (
		<Stack spacing={2}>
			<Typography fontWeight={'bold'}>Listening Timestamps</Typography>
			<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
				<TextField
					label={'Start Time'}
					type="number"
					value={details.listening_timestamp.start_time}
					onChange={(e) => {
						setDetails((prev) => ({
							...prev,
							listening_timestamp: {
								...prev.listening_timestamp,
								start_time: parseInt(e.target.value),
							},
						}));
					}}
					style={{
						width: '300px',
					}}
				/>
				<TextField
					label={'End Time'}
					type="number"
					value={details.listening_timestamp.end_time}
					onChange={(e) => {
						setDetails((prev) => ({
							...prev,
							listening_timestamp: {
								...prev.listening_timestamp,
								end_time: parseInt(e.target.value),
							},
						}));
					}}
					style={{
						width: '300px',
					}}
				/>
			</Stack>
			<Typography fontWeight={'bold'}>Question Timestamps</Typography>
			{details.questions.map((question, index) => {
				return (
					<Stack spacing={2}>
						<Typography maxWidth={{ md: '600px', sm: '300px' }}>
							{index + 1}. {question.question_text}
						</Typography>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<TextField
								label={'Start Time'}
								type="number"
								value={question.start_time}
								onChange={(e) => {
									setDetails((prev) => ({
										...prev,
										questions: prev.questions.map((q, i) => {
											if (i === index) {
												return {
													...q,
													start_time: parseInt(e.target.value),
												};
											}
											return q;
										}),
									}));
								}}
								style={{
									width: '300px',
								}}
							/>
							<TextField
								label={'End Time'}
								type="number"
								value={question.end_time}
								onChange={(e) => {
									setDetails((prev) => ({
										...prev,
										questions: prev.questions.map((q, i) => {
											if (i === index) {
												return {
													...q,
													end_time: parseInt(e.target.value),
												};
											}
											return q;
										}),
									}));
								}}
								style={{
									width: '300px',
								}}
							/>
						</Stack>
					</Stack>
				);
			})}
			{/* <Stack direction={{ md: "row", sm: "column" }} gap={2}>
                <TextField
                    label={"Desktop Intro Video Link"}
                    name="desktop_intro_video"
                    value={details.desktop_intro_video}
                    onChange={onChange}
                    style={{
                        width: "300px",
                    }}
                />
                <TextField
                    label={"Mobile Intro Video Link"}
                    value={details.mobile_intro_video}
                    name="mobile_intro_video"
                    onChange={onChange}
                    style={{
                        width: "300px",
                    }}
                />
            </Stack> */}
		</Stack>
	);
};

export default Timestamps;
