import React from 'react';
import { Header } from './shared/Header';
import { Stack, Typography } from '@mui/material';

const TermsOfUse = () => {
	return (
		<>
			<Header />
			<Stack p={2} spacing={1} bgcolor={'#fff'}>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Terms of Service
				</Typography>

				<Typography fontSize={'0.8rem'}>Last Updated: August 2023</Typography>
				<Typography>
					We invite you to access our website and use the AI.HYR products and services.
					But, please note that your invitation is subject to your agreement with these
					Terms of Service. This document describes in detail your rights and our rights
					relating to the provision of the Service, so please review these Terms
					carefully.
				</Typography>
				<Typography>
					By using our website, you accept these terms and conditions in full. If you
					disagree with these terms and conditions or any part of these terms and
					conditions, you must not use our website. If you register with our website or
					buy any product or service from our website, we will ask you to expressly agree
					to these terms and conditions.
				</Typography>
				<Typography>
					The following terminology applies to these Terms and Conditions, Privacy
					Statement and Disclaimer Notice and all Agreements: "Client", "Customer",
					"Partner", "You" and "Your" refers to you, the person logging on to this website
					and compliant to the Company's terms and conditions. "The Company", "Ourselves",
					"We", "Our" and "Us", refers to our Company - Providentia Labs Pvt. Ltd.
					"Party", "Parties", or "Us", refers to both the Client and ourselves. All terms
					refer to the offer, acceptance and consideration of payment necessary to
					undertake the process of our assistance to the Client in the most appropriate
					manner for the express purpose of meeting the Client's needs in respect of the
					provision of the Company's stated services, in accordance with and subject to,
					prevailing law of India. Any use of the above terminology or other words in the
					singular, plural, capitalization and/or he/she or they, are taken as
					interchangeable and therefore as referring to the same.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Cookies{' '}
				</Typography>
				<Typography>
					We use cookies on our website. By accessing AI.HYR, you agree to use cookies in
					agreement with the Providentia Labs Pvt. Ltd. Privacy Policy. If you continue to
					use our website by scrolling, clicking or navigating between the website pages,
					it is implied that you agree with our cookies policy.
				</Typography>
				<Typography>
					Most interactive websites use cookies to retrieve the visitor's usage details
					for each session visit. Cookies are used by our website to enhance the
					experience of our website by enabling the functionality of certain areas to make
					it easier for people visiting our website. Cookies that are in use on our
					website may include, but not limited to, those that are necessary for online
					advertising campaigns, web analytics, marketing automation / website performance
					enhancement tools and / or any other 3rd party as entrusted and appointed by us
					with the aim of providing relevant content and better website experience.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					License
				</Typography>
				<Typography>
					This website, including but not limited to text, content, photographs, video,
					audio and graphics (the “Site”), is protected by copyrights, trademarks, service
					marks, and/or other proprietary rights and laws. The Site is also protected as a
					collective work or compilation under applicable copyright law and other laws and
					treaties. The trademarks, service marks, trade names, logos, designs, associated
					with the Site are owned by the Company. You agree to abide by all applicable
					copyright, trademark, and other laws, as well as any additional copyright
					notices or restrictions contained in the Site.
				</Typography>
				<Typography>You must not:</Typography>
				<ul>
					<li>Republish material from AI.HYR website and platform.</li>
					<li>Sell, rent or sub-license material from AI.HYR website and platform.</li>
					<li>Reproduce, duplicate or copy material from AI.HYR website and platform.</li>
					<li>Redistribute content from AI.HYR website and platform.</li>
				</ul>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Hyperlinking to our Content
				</Typography>
				<Typography>
					The following organizations may link to our Website without prior written
					approval:
				</Typography>
				<ul>
					<li>Government agencies;</li>
					<li>Search engines;</li>
					<li>News organizations;</li>
					<li>
						Online directory distributors may link to our Website in the same manner as
						they hyperlink to the Websites of other listed businesses; and
					</li>
					<li>
						System-wide Accredited Businesses except soliciting non-profit
						organizations, charity shopping malls, and charity fundraising groups which
						may not hyperlink to our Website.
					</li>
				</ul>
				<Typography>
					These organizations may link to our home page, to publications or to other
					Website information so long as the link: (a) is not in any way deceptive; (b)
					does not falsely imply sponsorship, endorsement or approval of the linking party
					and its products and/or services; and (c) fits within the context of the linking
					party's site.
				</Typography>
				<Typography>
					We may consider and approve other link requests from the following types of
					organizations:
				</Typography>
				<ul>
					<li>Commonly-known consumer and/or business information sources;</li>
					<li>Dot.com community sites;</li>
					<li>Associations or other groups representing charities;</li>
					<li>Online directory distributors;</li>
					<li>Internet portals;</li>
					<li>Accounting, law, and consulting firms; and</li>
					<li>Educational institutions and trade associations.</li>
				</ul>
				<Typography>
					We will approve link requests from these organizations if we decide that: (a)
					the link would not make us look unfavorably to ourselves or to our accredited
					businesses; (b) the organization does not have any negative records with us; (c)
					the benefit to us from the visibility of the hyperlink compensates the absence
					of Providentia Labs Pvt. Ltd.; and (d) the link is in the context of general
					resource information.
				</Typography>
				<Typography>
					These organizations may link to our home page so long as the link: (a) is not in
					any way deceptive; (b) does not falsely imply sponsorship, endorsement or
					approval of the linking party and its products or services; and (c) fits within
					the context of the linking party's site.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Viruses and Malware
				</Typography>
				<Typography>
					While the company treats safety and security with utmost priority, Providentia
					Labs Pvt. Ltd. recommends that you install appropriate defenses against viruses
					and other malware before you download any information from this Website.
					Providentia Labs Pvt. Ltd. shall accept no liability for any virus or malware
					contracted a result of visiting this Website or any other web site and will not
					be liable for any claim, loss, damage, costs or expenses suffered or incurred by
					any person as a result of the presence of any virus or malware on this Website
					or another website.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Your Privacy
				</Typography>
				<Typography>
					Please read our{' '}
					<a href="/privacy-policy" target="_blank">
						{' '}
						Privacy Policy
					</a>
					.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Reservation of Rights{' '}
				</Typography>
				<Typography>
					We reserve the right to request that you remove all links or any particular link
					to our Website. You approve to immediately remove all links to our Website upon
					request. We also reserve the right to amend these terms and conditions and it's
					linking policy at any time. By continuously linking to our Website, you agree to
					be bound to and follow these linking terms and conditions.
				</Typography>
				<Typography fontWeight={'bold'}>Passive Collection of Information</Typography>
				<Typography>
					<a href="https://aihyr.com">Aihyr.com</a> collects information about how you
					interact with our site. <a href="https://aihyr.com">Aihyr.com</a> collects
					passive information for purposes such as testing and improving a user's
					experience at the site and the compilation of broad aggregate demographic data
					and related usage information for internal purposes and for disclosure to third
					parties such as advertisers and content partners. The types of passive
					information collected by this site include, without limitation, your IP address,
					which type of browser you are using, where you go on our site, and how long you
					stay on any part of our site.
				</Typography>
				<Typography>
					We may share this aggregate information with our partners, subsidiaries, and
					group companies, so they too can understand how the Website is used.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Accounts, Passwords and Security{' '}
				</Typography>
				<Typography>
					Certain features or services offered on or through the Site may require you to
					open an account. You are entirely responsible for maintaining the
					confidentiality of the information you hold for your account, including your
					password, and for any and all activity that occurs under your account as a
					result of your failing to keep this information secure and confidential. You
					agree to notify AI.HYR immediately of any unauthorized use of your account or
					password, or any other breach of security. AI.HYR will not be liable for any
					loss that you may incur as a result of someone else using your password or
					account, either with or without your knowledge. However, you could be held
					liable for losses incurred by AI.HYR or another party due to someone else using
					your account or password. You may not use anyone else's account at any time,
					without the permission of the account holder.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Removal of links from our website{' '}
				</Typography>
				<Typography>
					If you find any link on our Website that is offensive for any reason, you are
					free to contact us at <a href="mailto:nishtha@aihyr.com">nishtha@aihyr.com</a>{' '}
					and inform us any moment. We will consider requests to remove links but we are
					not obligated to or so or to respond to you directly.
				</Typography>
				<Typography>
					We do not ensure that the information on this website is correct, we do not
					warrant its completeness or accuracy; nor do we promise to ensure that the
					website remains available or that the material on the website is kept up to
					date.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Ownership
				</Typography>
				<Typography>
					The services are owned and copyrighted by AI.HYR. The recipient confers no title
					or ownership in the services and should not be construed as a sale of any right
					in the services.
				</Typography>
				<Typography fontWeight={'bold'}>LIMITATION OF LIABILITY</Typography>
				<Typography>
					To the maximum extent permitted by law, Providentia Labs Pvt. Ltd.., shall not
					be liable for any direct, indirect, incidental, consequential, or special
					damages or losses, whether tangible or intangible, resulting from authorized or
					unauthorized use of or access to our products.
				</Typography>
				<Typography>
					We do not guarantee that: (i) our services will be available at any particular
					time or location; (ii) the services will be free of viruses or other harmful
					materials; or (iii) the results of using the services will meet your
					expectations. You use the services solely at your own risk. Some jurisdictions
					do not allow limitations on implied warranties, so the above limitations may not
					apply to you.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Indemnification
				</Typography>
				<Typography>
					If Providentia Labs Pvt. Ltd. gets sued because of something that you did, you
					agree to defend and indemnify us. That means you'll defend Providentia Labs Pvt.
					Ltd.(including any of our employees) and hold us harmless from any legal claim
					or demand (including reasonable lawyer's fees) that arises from your actions,
					your use (or misuse) of our Services, your breach of the Terms, or your
					account's infringement of someone else's rights.
				</Typography>
				<Typography>
					We reserve the right to handle our legal defense however we see fit, even if you
					are indemnifying us, in which case you agree to cooperate with us so we can
					execute our strategy.
				</Typography>
				<Typography fontWeight={'bold'} fontFamily={'Poppins'} fontSize={'2.5rem'}>
					Disclaimer
				</Typography>
				<Typography>
					To the maximum extent permitted by applicable law, we exclude all
					representations, warranties and conditions relating to our website and the use
					of this website. Nothing in this disclaimer will:
				</Typography>
				<ul>
					<li>Limit or exclude our or your liability for death or personal injury;</li>
					<li>
						Limit or exclude our or your liability for fraud or fraudulent
						misrepresentation;
					</li>
					<li>
						Limit any of our or your liabilities in any way that is not permitted under
						applicable law; or
					</li>
					<li>
						Exclude any of our or your liabilities that may not be excluded under
						applicable law.
					</li>
				</ul>
				<Typography>
					The limitations and prohibitions of liability set in this Section and elsewhere
					in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern
					all liabilities arising under the disclaimer, including liabilities arising in
					contract, in tort and for breach of statutory duty.
				</Typography>
				<Typography>
					As long as the website and the information and services on the website are
					provided free of charge, we will not be liable for any loss or damage of any
					nature.
				</Typography>
				<Typography fontWeight={'bold'}>Changes to Terms of Service</Typography>
				<Typography>
					The Company reserves the right to make any changes to the terms of service, as
					deemed fit, from time to time, without prior intimation. By continuing to use
					our services, you agree to abide by any such changes.
				</Typography>
				<Typography fontWeight={'bold'}>Governing Law and Jurisdiction</Typography>
				<Typography>
					To the fullest extent permitted by law, these Terms of Use are governed by the
					internal laws of India and courts in Pune, India will have jurisdiction.
				</Typography>
			</Stack>
		</>
	);
};

export default TermsOfUse;
