import React, { useEffect, useState } from 'react';
import HeroSection from './components/HeroSection';
import { IconButton, Stack, Tooltip } from '@mui/material';
import About1 from './components/About1';
import About2 from './components/About2';
import ChooseAIHYR from './components/ChooseAIHYR';
import Testimonials from './components/Testimonials';
import Services from './components/Services';
import HiringInterview from './components/HiringInterview';
import LearningAssessments from './components/LearningAssessments';
import ExitInterviews from './components/ExitInterviews';
import Features from './components/Features';
import Contact from './components/Contact';
import ExampleBots from './components/ExampleBots';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AOS from 'aos';
import 'aos/dist/aos.css';
const LandingPage = () => {
	// const particlesInit = useCallback(async (engine) => {
	//     console.log(engine);
	//     await loadSlim(engine);
	// }, []);

	// const particlesLoaded = useCallback(async (container) => {
	//     await console.log(container);
	// }, []);
	const [showButton, setShowButton] = useState(false);
	useEffect(() => {
		AOS.init({
			once: true,
		});
		const handleScrollButtonVisibility = () => {
			window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
		};
		window.addEventListener('scroll', handleScrollButtonVisibility);

		return () => {
			window.removeEventListener('scroll', handleScrollButtonVisibility);
		};
	}, []);
	return (
		<Stack bgcolor={'#fff'}>
			{/* <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {
                        color: {
                            value: "#fff",
                        },
                    },

                    fpsLimit: 120,
                    // interactivity: {
                    //     events: {
                    //         onClick: {
                    //             enable: true,
                    //             mode: "push",
                    //         },
                    //         onHover: {
                    //             enable: true,
                    //             mode: "repulse",
                    //         },
                    //         resize: true,
                    //     },
                    //     modes: {
                    //         push: {
                    //             quantity: 4,
                    //         },
                    //         repulse: {
                    //             distance: 200,
                    //             duration: 0.4,
                    //         },
                    //     },
                    // },
                    particles: {
                        color: {
                            value: "#224C84",
                        },
                        links: {
                            color: "#224C84",
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 30,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            /> */}
			{showButton && (
				<Stack zIndex={1}>
					<Tooltip title="Scroll to top" placement="top">
						<IconButton
							onClick={() => {
								window.scrollTo({ top: 0, behavior: 'smooth' });
							}}
							style={{
								position: 'fixed',
								bottom: 10,
								right: 5,
								color: '#224C84',
								backgroundColor: 'rgb(210, 236, 246, 0.5)',
								WebkitBackdropFilter: 'blur(5px)',
								backdropFilter: 'blur(5px)',
							}}
						>
							<KeyboardArrowUpIcon fontSize="large" />
						</IconButton>
					</Tooltip>
				</Stack>
			)}
			<HeroSection />
			<About1 />
			<About2 />
			<ChooseAIHYR />
			<Testimonials />
			<ExampleBots />
			<Services />
			<HiringInterview />
			<LearningAssessments />
			<ExitInterviews />
			<Features />
			<Contact />
		</Stack>
	);
};

export default LandingPage;
