import { useState } from 'react';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

export const QuestionPopper = (props) => {
	const { questionHint } = props;
	const [open, setOpen] = useState(true);

	const handleClick = () => {
		setOpen((previousOpen) => !previousOpen);
	};

	return (
		<div
			style={{
				display: 'flex',
				WebkitUserSelect: 'none',
				WebkitTouchCallout: 'none',
				MozUserSelect: 'none',
				msUserSelect: 'none',
				userSelect: 'none',
			}}
		>
			<InfoIcon onClick={handleClick} color={'warning'} />
			{open && (
				<Box
					sx={{
						border: '4px solid #cccccc',
						borderRadius: '16px',
						bgcolor: 'background.paper',
						width: '350px',
						height: 'auto',
						padding: '10px',
					}}
				>
					<p style={{ fontWeight: '700', fontSize: '18px', lineHeight: 0 }}>
						{props?.questionTitle}
					</p>
					<p style={{ fontSize: '14px' }}>{props?.questionText}</p>
					<div style={{ display: 'flex', marginTop: '20px' }}>
						<TipsAndUpdatesIcon color={'warning'} />
						{questionHint && questionHint.question_hints ? (
							<div>
								<p style={{ fontSize: '14px', color: '#a9a9a9' }}>
									Hint: Include these points in your answer:
								</p>
								<ul>
									{questionHint?.question_hints.map((hint) => {
										return <li>{hint}</li>;
									})}
								</ul>
							</div>
						) : (
							<p style={{ fontSize: '14px', color: '#a9a9a9' }}>
								Please give detailed answers and share insights, experiences or
								examples.
							</p>
						)}
					</div>
				</Box>
			)}
		</div>
	);
};
