import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAdminContext } from '../../global/AdminContext';
import { API_URL } from '../../global/constants';
import axios from 'axios';
import { useEffect } from 'react';
import { Header } from '../shared/Header';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
export default function ModalUnstyled() {
	const navigate = useNavigate();
	const [companyid, setCompanyid] = React.useState('');
	const { admin } = useAdminContext();
	useEffect(() => {
		if (!admin) {
			return;
		}
		async function fetchcompany() {
			const { data } = await axios.get(`${API_URL}/labs/companydetails`, {
				headers: {
					Authorization: `Bearer ${admin.token}`,
				},
			});
			const { company_details } = data;
			if (!company_details) return;
			const { _id } = company_details;
			setCompanyid(_id);
		}
		fetchcompany();
	}, [admin]);
	const handleSubmit = (mode) => {
		if (mode === 'AI') {
			navigate(`/jobdescription/create`);
		} else {
			navigate(`/create-job-posting/company/${companyid}`);
		}
		console.log(`Mode selected: ${mode}`);
	};

	return (
		<div>
			<Header />
			<Modal
				aria-labelledby="unstyled-modal-title"
				aria-describedby="unstyled-modal-description"
				open={true}
				slots={{ backdrop: StyledBackdrop }}
			>
				<ModalContent
					sx={{
						width: '375px',
					}}
				>
					<div style={{ margin: '3px' }}></div>
					<Stack
						sx={{
							display: 'flex',
							flexDirection: 'column',
							mt: 2,
							width: '100%',
							fontSize: '1rem', // Adjust the font size
							fontWeight: 'bold', // Adjust the font weight
							fontFamily: 'Arial, sans-serif', // Adjust the font family
							textTransform: 'none', // Prevents automatic uppercase transformation
						}}
						spacing={3}
					>
						<h3>POST A JOB VIA</h3>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleSubmit('AI')}
							sx={{
								width: '100%',
								maxWidth: 300,
								paddingY: 1.5,
								borderRadius: 25,
								fontSize: '1rem', // Adjust the font size
								fontWeight: 'bold', // Adjust the font weight
								fontFamily: 'Arial, sans-serif', // Adjust the font family
								textTransform: 'none', // Prevents automatic uppercase transformation
								gap: 2,
							}}
						>
							<AutoAwesomeIcon />
							Write with AI
						</Button>
						<Button
							variant="outlined" // Change to "outlined" to show the border
							color="primary"
							onClick={() => handleSubmit('Normal')}
							sx={{
								width: '100%',
								marginBottom: 20,
								maxWidth: 300,
								paddingY: 1.5,
								borderRadius: 25,
								fontSize: '1rem', // Adjust the font size
								fontWeight: 'bold', // Adjust the font weight
								fontFamily: 'Arial, sans-serif', // Adjust the font family
								textTransform: 'none',
								borderColor: 'primary.main', // Border color set to primary
								color: 'primary.main', // Text color set to primary
								borderWidth: 2, // You can adjust the border width if needed
							}}
						>
							Write on my own
						</Button>
						<div style={{ margin: '20px' }}></div>
					</Stack>
				</ModalContent>
			</Modal>
		</div>
	);
}

const Backdrop = React.forwardRef((props, ref) => {
	const { open, className, ...other } = props;
	return <div className={clsx({ 'base-Backdrop-open': open }, className)} ref={ref} {...other} />;
});

Backdrop.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

const Modal = styled(BaseModal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
	({ theme }) => css`
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 500;
		text-align: start;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 8px;
		overflow: hidden;
		background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
		border-radius: 8px;
		border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
		box-shadow: 0 4px 12px
			${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
		padding: 24px;
		color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

		& .modal-title {
			margin: 0;
			line-height: 1.5rem;
			margin-bottom: 8px;
		}

		& .modal-description {
			margin: 0;
			line-height: 1.5rem;
			font-weight: 400;
			color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
			margin-bottom: 4px;
		}
	`
);
