import { Stack, TextField } from '@mui/material';
import React from 'react';
import { isValidEmail, isValidPhoneNumber, isValidURL } from '../../helpers/utils';
import { useParams } from 'react-router-dom';

const Company = (props) => {
	const { details, setDetails, errors, setErrors } = props;
	const { type } = useParams();
	const displaytype = type === 'create-college' ? 'College' : 'Company';
	return (
		<Stack spacing={2}>
			<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
				<TextField
					label={`${displaytype} Name`}
					value={details.name}
					error={errors.name}
					helperText={errors.name ? `${displaytype} name is required` : ''}
					onChange={(e) => {
						if (!e.target.value) {
							setErrors((prev) => ({
								...prev,
								name: true,
							}));
						} else {
							setErrors((prev) => ({
								...prev,
								name: false,
							}));
						}
						setDetails((prev) => ({
							...prev,
							name: e.target.value,
						}));
					}}
					style={{
						width: '300px',
					}}
					required
				/>
				<TextField
					label={`${displaytype} Address`}
					value={details.address}
					error={errors.address}
					helperText={errors.address ? `${displaytype} Address is required` : ''}
					onChange={(e) => {
						if (!e.target.value) {
							setErrors((prev) => ({
								...prev,
								address: true,
							}));
						} else {
							setErrors((prev) => ({
								...prev,
								address: false,
							}));
						}
						setDetails((prev) => ({
							...prev,
							address: e.target.value,
						}));
					}}
					style={{
						width: '300px',
					}}
					required
				/>
			</Stack>
			<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
				<TextField
					label={` ${displaytype} Phone`}
					value={details.phone}
					error={errors.phone}
					helperText={errors.phone ? 'Please enter a valid phone number' : ''}
					onChange={(e) => {
						if (e.target.value && !isValidPhoneNumber(e.target.value)) {
							setErrors((prev) => ({
								...prev,
								phone: true,
							}));
						} else {
							setErrors((prev) => ({
								...prev,
								phone: false,
							}));
						}
						setDetails((prev) => ({
							...prev,
							phone: e.target.value,
						}));
					}}
					style={{
						width: '300px',
					}}
					required
				/>
				<TextField
					label={`${displaytype} Email`}
					value={details.email}
					error={errors.email}
					helperText={errors.email ? 'Please enter a valid email' : ''}
					onChange={(e) => {
						if (e.target.value && !isValidEmail(e.target.value)) {
							setErrors((prev) => ({
								...prev,
								email: true,
							}));
						} else {
							setErrors((prev) => ({
								...prev,
								email: false,
							}));
						}
						setDetails((prev) => ({
							...prev,
							email: e.target.value,
						}));
					}}
					style={{
						width: '300px',
					}}
					required
				/>
			</Stack>
			<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
				<TextField
					label={`${displaytype} Website Link`}
					value={details.website}
					error={errors.website}
					helperText={errors.website ? 'Please enter a valid URL' : ''}
					onChange={(e) => {
						if (e.target.value && !isValidURL(e.target.value)) {
							setErrors((prev) => ({ ...prev, website: true }));
						} else {
							setErrors((prev) => ({ ...prev, website: false }));
						}
						setDetails((prev) => ({
							...prev,
							website: e.target.value,
						}));
					}}
					style={{
						width: '300px',
					}}
					required
				/>
				<TextField
					label={`${displaytype} Logo Link`}
					value={details.logo}
					error={errors.logo}
					helperText={errors.logo ? 'Please enter a valid URL' : ''}
					onChange={(e) => {
						if (e.target.value && !isValidURL(e.target.value)) {
							setErrors((prev) => ({ ...prev, logo: true }));
						} else {
							setErrors((prev) => ({ ...prev, logo: false }));
						}
						setDetails((prev) => ({
							...prev,
							logo: e.target.value,
						}));
					}}
					style={{
						width: '300px',
					}}
					required
				/>
			</Stack>
			<TextField
				label={`About ${displaytype}`}
				value={details.about}
				error={errors.about}
				helperText={errors.about ? 'Company about is required' : ''}
				onChange={(e) => {
					if (!e.target.value) {
						setErrors((prev) => ({
							...prev,
							about: true,
						}));
					} else {
						setErrors((prev) => ({
							...prev,
							about: false,
						}));
					}
					setDetails((prev) => ({
						...prev,
						about: e.target.value,
					}));
				}}
				fullWidth
				multiline
				rows={4}
				required
			/>
		</Stack>
	);
};

export default Company;
