import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { AudioRecorder } from '../AudioRecorder';
import { Header } from '../shared/Header';
import { CircularStatic } from '../CircularProgressWithLabel';
import { EndInterview } from '../EndInterview';
import MicIcon from '@mui/icons-material/Mic';
import styles from '../AudioRecorder/index.module.css';

import { BrowserView, MobileView } from 'react-device-detect';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack, Tooltip } from '@mui/material';
import { ReplayButton } from '../ReplayButton';
import ReplayIcon from '@mui/icons-material/Replay';

export const PracticeInterviewNew = () => {
	const reactPlayerRef = useRef(null);
	const [error, setError] = useState(false);
	const [videoUrl, setVideoUrl] = useState(
		'https://d1ssf9yeyykhpj.cloudfront.net/client-interviews/AI.HYR/Training+video+-+Desktop+-+compressed.mp4'
	);
	const [mobileVideoUrl, setMobileVideoUrl] = useState(
		'https://d1ssf9yeyykhpj.cloudfront.net/client-interviews/AI.HYR/Practise+interview+-+mobile+-+compressed.mp4'
	);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isListening, setIsListening] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [isQuestionZero, setIsQuestionZero] = useState(false);
	const [isQuestionOne, setIsQuestionOne] = useState(false);
	const [questionTimestamps, setQuestionTimestamps] = useState([
		{
			start_time: 4,
			end_time: 37,
		},
		{
			start_time: 38,
			end_time: 56,
		},
		{
			start_time: 57,
			end_time: 81,
		},
		{
			start_time: 82,
			end_time: 93,
		},
		{
			start_time: 94,
			end_time: 109,
		},
		{
			start_time: 110,
			end_time: 118,
		},
	]);
	const [loading, setLoading] = useState(false);
	const [hasInterviewEnded, setHasInterviewEnded] = useState(false);
	const [listeningTimestamp, setListeningTimestamp] = useState({
		start_time: 0,
		end_time: 3,
	});

	const [open, setOpen] = useState(true);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [start, setStart] = useState(false);

	const [updateEndedToDatabase, setUpdateEndedToDatabase] = useState(false);
	const [showReplayButton, setShowReplayButton] = useState(false);

	const [playing, setPlaying] = useState(false);
	const [playsinline, setPlaysinline] = useState(false);
	const [isIphone, setIsIphone] = useState(false);

	useEffect(() => {
		if (reactPlayerRef && reactPlayerRef.current) {
			reactPlayerRef.current.seekTo(
				questionTimestamps[currentQuestionIndex].start_time,
				'seconds'
			);
			setIsRecording(false);
			// reactPlayerRef.current.playing = true;
			// reactPlayerRef.current.playsinline = true;
		}
	}, [currentQuestionIndex]);

	const handlePostQuestionAction = () => {
		if (!isListening && currentQuestionIndex < questionTimestamps.length - 1) {
			setIsListening(true);
			handleListeningLoop();
		}
	};

	const handleProgress = ({ playedSeconds }) => {
		if (currentQuestionIndex === 0 && !start) {
			reactPlayerRef.current.seekTo(questionTimestamps[0].start_time);
			setStart(true);
		}
		if (
			!isListening &&
			currentQuestionIndex !== questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].end_time
		) {
			if (currentQuestionIndex === 0) {
				setIsQuestionZero(true);
			} else if (currentQuestionIndex === 1) {
				setIsQuestionOne(true);
			} else {
				setIsRecording(true);
				setShowReplayButton(true);
			}
			handlePostQuestionAction();
		}
		if (
			currentQuestionIndex >= questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].start_time
		) {
			console.log('Interview has ended successfully!!!');
			setIsListening(false);
			setIsRecording(false);
			setUpdateEndedToDatabase(true);
			setShowReplayButton(false);
		}
		if (
			currentQuestionIndex === questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].end_time
		) {
			setHasInterviewEnded(true);
			console.log('Interview has ended successfully!!!');
			setIsListening(false);
			setIsRecording(false);
			setUpdateEndedToDatabase(true);
		}

		if (isListening && playedSeconds >= listeningTimestamp.end_time) {
			reactPlayerRef.current.seekTo(listeningTimestamp.start_time, 'seconds');
		}
	};

	const handleEnded = () => {
		if (currentQuestionIndex < questionTimestamps.length - 1) {
			handlePostQuestionAction();
		} else {
			setHasInterviewEnded(true);
		}
	};

	const handleListeningLoop = () => {
		reactPlayerRef.current.seekTo(listeningTimestamp.start_time, 'seconds');
	};

	const goToNextQuestion = () => {
		if (currentQuestionIndex >= questionTimestamps.length - 1) {
			setHasInterviewEnded(true);
			setIsListening(false);
			setIsRecording(false);
		} else {
			setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
		}
	};

	const updateIsListening = (value) => {
		setIsListening(value);
		setShowReplayButton(false);
		if (value) {
			handleListeningLoop();
		} else {
			goToNextQuestion();
		}
	};

	const startInterview = () => {
		handleClose();
		setPlaying(true);
		setPlaysinline(true);
	};

	useEffect(() => {
		setTimeout(() => {
			if (isQuestionZero) {
				setIsQuestionZero(false);
				updateIsListening(false);
			}
		}, 5000);
	}, [isQuestionZero]);

	useEffect(() => {
		setTimeout(() => {
			if (isQuestionOne) {
				setIsQuestionOne(false);
				updateIsListening(false);
			}
		}, 5000);
	}, [isQuestionOne]);

	const replayQuestion = () => {
		reactPlayerRef.current.seekTo(questionTimestamps[currentQuestionIndex].start_time);
		setPlaying(true);
		setIsListening(false);
		setIsRecording(false);
		setShowReplayButton(false);
	};
	useEffect(() => {
		if (
			navigator.platform === 'iPad' ||
			navigator.platform === 'iPhone' ||
			navigator.platform === 'iPod'
		) {
			console.log('This is an iOS device.');
			setIsIphone(true);
		} else {
			console.log('This is not an iOS device!');
			setIsIphone(false);
		}
	}, []);

	return (
		<div>
			<>
				{!isIphone && (
					<Modal
						open={open}
						// onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Stack
							justifyContent="center"
							alignItems="center"
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '400px',
								p: 4,
							}}
						>
							<Button
								variant="contained"
								onClick={startInterview}
								p={0}
								sx={{
									width: '200px',
									backgroundColor: '#224C84',
									'&:hover': { backgroundColor: '#224C84' },
								}}
							>
								Start Interview
							</Button>
						</Stack>
					</Modal>
				)}
				{isQuestionZero && (
					<div
						className={styles.mic_holder}
						style={{
							position: 'absolute',
							bottom: '20px',
							zIndex: 1,
						}}
					>
						<Stack direction={'row'}>
							<Tooltip title="Record your answer" placement="top" arrow>
								<div className={styles.audio_recorder_div}>
									<Button
										onClick={() => {}}
										disabled={true}
										style={{
											width: '50px',
											height: '50px',
											borderRadius: '50%',
											border: '1px',
											color: '#ffffff',
											borderColor: '#ffffff',
										}}
									>
										<MicIcon
											sx={{
												width: '2em',
												height: '2em',
												fontSize: 'large',
											}}
										/>
									</Button>
								</div>
							</Tooltip>
							<Tooltip title="Replay" placement="top" arrow>
								<div className={styles.audio_recorder_div}>
									<Button
										onClick={() => {}}
										disabled={true}
										style={{
											width: '50px',
											height: '50px',
											borderRadius: '50%',
											border: '1px',
											color: '#ffffff',
											borderColor: '#ffffff',
										}}
									>
										<ReplayIcon
											sx={{
												width: '2em',
												height: '2em',
												fontSize: 'large',
											}}
										/>
									</Button>
								</div>
							</Tooltip>
						</Stack>
					</div>
				)}
			</>
			<>
				{isQuestionOne && (
					<div
						className={styles.mic_holder}
						style={{
							position: 'absolute',
							bottom: '10px',
							zIndex: 1,
						}}
					>
						<Tooltip title="Stop Recording" placement="top" arrow>
							<Button
								// onClick={}
								disabled={true}
								sx={{
									borderRadius: '50%',
									width: '100px',
									height: '100px',
									backgroundColor: 'red',
								}}
							>
								<img
									src="/images/audio-recorder.gif"
									style={{
										borderRadius: '50%',
										height: '100%',
									}}
								/>
							</Button>
						</Tooltip>
					</div>
				)}
			</>
			<MobileView>
				{isIphone ? (
					<>
						<Header />
						<Box
							p={3}
							display="flex"
							style={{ height: '85vh' }}
							justifyContent="center"
							alignItems="center"
						>
							<Typography variant="body2" fontWeight="bold" textAlign="center">
								The interview experience is not available on iPhones. Please use
								chrome or Firefox on your desktop to complete the interview.
							</Typography>
						</Box>
					</>
				) : (
					<div>
						{!hasInterviewEnded && <Header />}
						{loading && !hasInterviewEnded && <CircularStatic />}
						{!loading && !hasInterviewEnded && (
							<ReactPlayer
								ref={reactPlayerRef}
								url={mobileVideoUrl}
								playing={playing}
								playsinline={playsinline}
								width="100%"
								height="90vh"
								progressInterval={1000}
								onProgress={handleProgress}
								onEnded={handleEnded}
								pip={false}
							/>
						)}
						{hasInterviewEnded && (
							<EndInterview interviewKey={''} isPracticeInterview={true} />
						)}
						{isRecording && !hasInterviewEnded && (
							<Stack
								direction="row"
								width={'100vw'}
								display={'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								position={'absolute'}
								bottom={'80px'}
								gap={'20px'}
							>
								{/* <p>Recording...</p> */}
								{/* <ReactMic record={isRecording} onStop={handleRecordingComplete} /> */}
								<AudioRecorder
									interviewKey={''}
									handleAnswer={() => {}}
									updateIsListening={updateIsListening}
									questionId={currentQuestionIndex}
									candidateName={''}
									isPractice={true}
									enabledRecording={isRecording}
								/>
								{showReplayButton && (
									<ReplayButton
										replayQuestion={replayQuestion}
										isPractice={true}
									/>
								)}
							</Stack>
						)}
					</div>
				)}
			</MobileView>
			<BrowserView>
				{!hasInterviewEnded && <Header />}
				{loading && !hasInterviewEnded && <CircularStatic />}
				{!loading && !hasInterviewEnded && (
					<ReactPlayer
						ref={reactPlayerRef}
						url={videoUrl}
						playing={playing}
						playsinline={playsinline}
						width="100%"
						height="90vh"
						progressInterval={1000}
						onProgress={handleProgress}
						onEnded={handleEnded}
						pip={false}
					/>
				)}
				{hasInterviewEnded && <EndInterview interviewKey={''} isPracticeInterview={true} />}
				{isRecording && !hasInterviewEnded && (
					<Stack
						direction="row"
						width={'100vw'}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						position={'absolute'}
						bottom={'80px'}
						gap={'20px'}
					>
						{/* <p>Recording...</p> */}
						{/* <ReactMic record={isRecording} onStop={handleRecordingComplete} /> */}
						<AudioRecorder
							interviewKey={''}
							handleAnswer={() => {}}
							updateIsListening={updateIsListening}
							questionId={currentQuestionIndex}
							candidateName={''}
							isPractice={true}
							enabledRecording={isRecording}
						/>
						{showReplayButton && (
							<ReplayButton replayQuestion={replayQuestion} isPractice={true} />
						)}
					</Stack>
				)}
			</BrowserView>

			{/* <button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
        Previous
      </button> */}
			{/* <button onClick={handlePostQuestionAction} disabled={currentQuestionIndex === questionTimestamps.length - 1}>
        Next
      </button> */}
		</div>
	);
};
