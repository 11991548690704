import React from 'react';
import SplitLayout from './splitlayout.jsx';
import { Header } from '../../shared/Header';

const CreateJobdescription = () => {
	return (
		<div style={{}}>
			<Header />
			<SplitLayout />
		</div>
	);
};

export default CreateJobdescription;
