import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../../shared/Header';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import SearchIcon from '@mui/icons-material/Search';
import {
	Stack,
	Typography,
	Button,
	TextField,
	InputAdornment,
	Skeleton,
	useMediaQuery,
	Box,
	useTheme,
	CardContent,
	Card,
	CardActions,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import CsvDownloadButton from 'react-json-to-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './../styles.css';
function milliseconds_to_hms(millis) {
	var minutes = Math.floor(millis / 60000);
	var seconds = ((millis % 60000) / 1000).toFixed(0);
	//console.log(millis, minutes, seconds);
	return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

const MockInterviewReport = () => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	// Mobile-specific rendering
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [profileImage, setProfileImage] = useState('');
	const [totalCompletedInterviews, setTotalCompletedInterviews] = useState(0);
	const [totalStartedInterviews, setTotalStartedInterviews] = useState(0);
	const [loading, setLoading] = useState(false);
	const [interviews, setInterviews] = useState([]);
	const [Filteredinterviews, setFilteredinterviews] = useState([]);
	const [filter, setFilter] = useState('name');
	const [searchBarName, setSearchBarName] = useState('Name');
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const filters = [
		// { id: 'interview_key', label: 'Interview Key',  },
		{ id: 'interview name', label: 'interview Name' },
	];

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangeRowsPerPage = (event) => {
		let rowsperpage = Math.max(+event.target.value, 5);
		setRowsPerPage(rowsperpage);
		const filterdinterviews = interviews.slice(0, rowsperpage);
		if (filterdinterviews) {
			setFilteredinterviews(filterdinterviews);
		}
		setPage(0);
	};

	const [columns, setColumns] = useState([
		// { id: 'interview_key', label: 'Interview Key',  },
		{
			id: 'name',
			label: 'Interview',
			columnrowmarginleft: '10px',
			columnheadermarginleft: '10px',
			// minWidth: 200,
			// align: "center",
			// textAlign: "center",
		},
		{
			id: 'status',
			label: 'Status',
			columnrowmarginleft: '0px',
			columnheadermarginleft: '5px',
		},
		{
			id: 'attempt',
			label: 'Attempt No ',
			columnrowmarginleft: '20px',
			columnheadermarginleft: '0px',
		},

		{
			id: 'overall_score',
			label: 'Score',
			columnrowmarginleft: '0px',
			columnheadermarginleft: '0px',
		},
		{
			id: 'time',
			label: 'Time Taken',
			columnrowmarginleft: '20px',
			columnheadermarginleft: '0px',
		},
		{
			id: 'student_id',
			label: 'Report',
			columnrowmarginleft: '0px',
			columnheadermarginleft: '30px',
		},
		{
			id: 'action',
			label: 'Action',
			columnrowmarginleft: '0px',
			columnheadermarginleft: '20px',
		},
		// {
		//   id: "completion_time",
		//   label: `Completion Time${"\n"} (in minutes)`,
		//   // align: "center",
		//   // textAlign: "center",
		// },
		// { id: "email", label: "Email" },
		// { id: "contact", label: "Contact" },
		// {
		//   id: "interview_date_time",
		//   label: "Interview Date & Time",
		//   // align: "center",
		//   // textAlign: "center",
		// },

		// {
		//   id: "interview_key",
		//   label: "Report",
		//   // align: "center",
		//   // textAlign: "center"
		// },
	]);

	const getAllInterviews = async () => {
		try {
			const { data } = await axios.get(`${API_URL}/labs/student-mock-interview/all`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			if (data.success) {
				const interviews = data.data;
				setInterviews(data.data);
				setFilteredinterviews(interviews.slice(0, 10));

				console.log(data.data, 'all interviews data');
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/details`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			console.log(data, 'data student');
			setFirstName(data.studentDetails.first_name);
			setLastName(data.studentDetails.last_name);
			setEmail(data.studentDetails.email);
			setProfileImage(data.studentDetails.profile_image);
			setTotalCompletedInterviews(data.studentDetails.total_completed_interviews);
			setTotalStartedInterviews(data.studentDetails.total_started_interviews);
			//setFilteredinterviews(data.data);
			console.log(data);
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!sessionStorage.getItem('student-auth-token')) {
			navigate('/student/login');
		}
		getDetails();
		getAllInterviews();
	}, []);

	useEffect(() => {
		const currentpage = page;

		const rowstobedisplayed = interviews.slice(
			currentpage * rowsPerPage,
			(currentpage + 1) * rowsPerPage
		);
		console.log('page changed', rowstobedisplayed, currentpage);
		setFilteredinterviews(rowstobedisplayed);
	}, [page]);

	useEffect(() => {
		for (let col of filters) {
			if (col.id === filter) {
				setSearchBarName(col.label);
			}
		}
	}, [filter]);

	const requestSearch = (searchedVal) => {
		const filteredRows = interviews.filter((row) => {
			if (searchedVal === '') {
				return true;
			}
			return row['role'].toLowerCase().includes(searchedVal.toLowerCase());
		});
		setFilteredinterviews(filteredRows);
	};

	const handleChangePage = (event, newPage) => {
		console.log('page changed', newPage);
		setPage(newPage);
	};

	const handleRestartInterview = ({ interviewKey, fullName, has_interview_ended }) => {
		if (has_interview_ended) {
			navigate(`/student/report/${interviewKey}`);
		} else {
			navigate(`/check/${fullName}/${interviewKey}`, {
				state: {
					fullName: fullName,
					interviewKey: interviewKey,
					isStudentInterview: true,
				},
			});
		}
	};
	console.log(Filteredinterviews, 'filterd');

	const style = {
		backgroundColor: '#ECF7FA',
		height: '100%',
	};

	return (
		<div
			style={{
				...style,
				backgroundColor: `${style.backgroundColor} !important`,
				height: `${style.height} !important`,
			}}
		>
			<Toaster />
			<Header profile_image={profileImage} fullName={`${firstName} ${lastName}`} />

			<Stack
				mt={{ sm: 3, xs: 5 }}
				ml={{ md: 5 }}
				p={{ sm: 3, xs: 1, md: 1 }}
				spacing={{ sm: 2, xs: 5 }}
				style={{
					backgroundColor: '#ECF7FA',
					// maxWidth: '100vw',
					height: '100%',
				}}
			>
				<Stack
					direction={{ sm: 'row', xs: 'column-reverse' }}
					spacing={2}
					alignItems={'center'}
					justifyContent={{ sm: 'space-between', xs: 'center' }}
					style={{
						backgroundColor: '#ECF7FA',
						// maxWidth: '100vw',
						height: '100%',
					}}
				>
					{!loading && (
						<Typography
							fontWeight={'bold'}
							fontSize={'1.3rem'}
							style={{
								marginTop: '1rem',
							}}
							// justifySelf={"flex-start"}
						>
							{firstName}'s Reports
						</Typography>
					)}
				</Stack>
			</Stack>

			{/* closed text field */}
			<Stack
				direction="column"
				m={0}
				p={4}
				style={
					{
						// backgroundColor: '#ECF7FA',
						// maxWidth: '100vw',
						// height:'100%'
					}
				}
			>
				<Stack
					direction={'row'}
					width={'100%'}
					// spacing={2}
					mb={2}
				>
					<TextField
						size="small"
						fullWidth
						variant="outlined"
						onChange={(e) => requestSearch(e.target.value)}
						//   placeholder={`Search using ${searchBarName.toLowerCase()}...`}
						placeholder={`Search...`}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="start">
									<Typography
										borderRadius={'3px'}
										textAlign={'center'}
										// width={'8rem'}
										fontWeight={'bold'}
										color={'#224C84'}
										sx={{
											background: 'rgb(0, 0, 255, 0.1)',
										}}
										pl={1}
										fontSize={'12px'}
										mr={'-18.5px'}
									>
										{searchBarName}
										<Tooltip title="Choose Filter">
											<IconButton
												p={0}
												size="small"
												onClick={handleClick}
												aria-controls={open ? 'account-menu' : undefined}
												aria-haspopup="true"
												aria-expanded={open ? 'true' : undefined}
											>
												<FilterListIcon
													fontSize="small"
													sx={{
														color: 'blue',
													}}
												/>
											</IconButton>
										</Tooltip>
									</Typography>
								</InputAdornment>
							),
						}}
					/>
					<Menu
						anchorEl={anchorEl}
						id="account-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						transformOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'bottom',
						}}
					>
						{filters.map((col, i) => {
							return (
								<MenuItem value={col.id} onClick={() => setFilter(col.id)}>
									{col.label}
								</MenuItem>
							);
						})}
					</Menu>
					<Tooltip title="Download Candidate Data">
						<CsvDownloadButton
							className="csv-download-button"
							data={Filteredinterviews}
							delimiter=","
							filename="data.csv"
						>
							<DownloadIcon />
						</CsvDownloadButton>
					</Tooltip>
				</Stack>

				{isMobile ? (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '16px',
							// padding: '16px',
						}}
					>
						{Filteredinterviews.length > 0 ? (
							Filteredinterviews?.map((interview, index) => (
								<Card
									key={index}
									sx={{
										border: '1px solid #e0e0e0',
										borderRadius: '8px',
										padding: '16px',
										backgroundColor: '#ffffff',
										boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									}}
								>
									<CardContent>
										<Typography variant="h6" fontWeight="bold">
											{interview.role}
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											sx={{ mt: 1 }}
										>
											No. of Attempt: {interview.attemptno}
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											sx={{ mt: 0.5 }}
										>
											Status :{' '}
											{interview.has_interview_ended
												? `Completed ${interview.startedDate}`
												: 'Incomplete'}
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											sx={{ mt: 0.5 }}
										>
											Overall score:{' '}
											{interview.overall_score &&
											interview.overall_score !== 'NaN'
												? parseInt(interview.overall_score)
												: 0}
											%
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											sx={{ mt: 0.5 }}
										>
											Time Taken:{' '}
											{interview.has_interview_ended
												? interview.timetaken
												: '...'}
										</Typography>
									</CardContent>

									<CardActions>
										<Button
											variant="outlined"
											size="small"
											style={{
												color: '#224C84',
												textTransform: 'none',
												width: '120px',
											}}
											onClick={() => {
												if (interview['has_interview_ended']) {
													navigate(
														`/student/report/${interview['_id']}`,
														{
															state: {
																filteredData: Filteredinterviews,
															},
														}
													);
												}
											}}
										>
											{interview['has_interview_ended']
												? 'View Report'
												: '...'}
										</Button>
										{!interview.has_interview_ended && (
											<Button
												variant="outlined"
												size="small"
												style={{
													color: '#224C84',
													textTransform: 'none',
													width: '120px',
												}}
												onClick={() =>
													handleRestartInterview({
														interviewKey: interview['_id'],
														fullName: `${firstName} ${lastName}`,
														has_interview_ended:
															interview['has_interview_ended'],
													})
												}
											>
												Continue
											</Button>
										)}
									</CardActions>
								</Card>
							))
						) : (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									gap: '16px',
									padding: '16px',
								}}
							>
								{loading ? (
									<>
										<Skeleton
											variant="button"
											sx={{ fontSize: '1.3rem', width: '240px' }}
										/>
										<Skeleton
											variant="text"
											sx={{ fontSize: '1.3rem', width: '240px' }}
										/>
									</>
								) : (
									<Typography fontWeight={'bold'} fontSize={'1.3rem'}>
										No Data Available
									</Typography>
								)}
							</Box>
						)}
					</Box>
				) : (
					<Paper sx={{ width: '100%' }}>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												// align={"center"}
												//align="center"
												// style={{
												//   alignSelf: "center",
												// }}
											>
												<Typography
													fontWeight={'bold'}
													// textAlign={"center"}
													style={{
														marginLeft: column.columnheadermarginleft,
													}}
												>
													{column.label}
												</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{Filteredinterviews &&
										Filteredinterviews.map((row, i) => (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.code}
											>
												{columns.map((column, j) => {
													return (
														<TableCell
															sx={{ maxWidth: '300px' }}
															key={column.id}
														>
															{column.id === 'name' && (
																<Typography
																	sx={{ marginLeft: '10px' }}
																	color={'#224C84'}
																>
																	{row['role']}
																</Typography>
															)}
															{column.id === 'attempt' && (
																<Typography
																	style={{ marginLeft: '40px' }}
																	color={'#224C84'}
																>
																	{row['attemptno']}
																</Typography>
															)}
															{column.id === 'status' && (
																<Stack>
																	{row['has_interview_ended'] ? (
																		<>
																			<Typography
																				color={'#224C84'}
																			>
																				{row['startedDate']}
																				<br />
																				Completed
																			</Typography>
																		</>
																	) : (
																		<Typography
																			color={'#224C84'}
																		>
																			Incomplete <br />
																			{/* {`${row["completed_questions"]}/${row["total_questions"]}`} */}
																		</Typography>
																	)}
																</Stack>
															)}
															{column.id === 'overall_score' && (
																<div
																	style={{
																		width: '40px',
																		height: '50px',
																	}}
																>
																	<CircularProgressbar
																		strokeWidth={12}
																		value={
																			row['overall_score'] &&
																			row['overall_score'] !==
																				'NaN'
																				? parseInt(
																						row[
																							'overall_score'
																						]
																					)
																				: 0
																		}
																		text={`${
																			row['overall_score']
																				? parseInt(
																						row[
																							'overall_score'
																						]
																					)
																				: 0
																		}%`}
																		styles={buildStyles({
																			textSize: '30px',
																			textColor: '#004aad',
																			pathColor: '#004aad',
																		})}
																	/>
																</div>
															)}
															{column.id === 'student_id' && (
																<Tooltip title={row['_id']}>
																	<Button
																		variant="outlined"
																		size="small"
																		style={{
																			color: '#224C84',
																			textTransform: 'none',
																			width: '130px',
																		}}
																		startIcon={
																			row[
																				'has_interview_ended'
																			] && <VisibilityIcon />
																		}
																		onClick={() => {
																			if (
																				row[
																					'has_interview_ended'
																				]
																			) {
																				navigate(
																					`/student/report/${row['_id']}`,
																					{
																						state: {
																							filteredData:
																								Filteredinterviews,
																						},
																					}
																				);
																			}
																		}}
																	>
																		{row['has_interview_ended']
																			? 'View Report'
																			: '...'}
																		{/* </a> */}
																	</Button>
																</Tooltip>
															)}
															{column.id === 'time' && (
																<Typography
																	color={'#224C84'}
																	style={{ marginLeft: '30px' }}
																>
																	{row['has_interview_ended']
																		? row['timetaken']
																		: '....'}
																</Typography>
															)}
															{column.id === 'action' && (
																<Button
																	variant="outlined"
																	disabled={
																		row['has_interview_ended']
																	}
																	onClick={() =>
																		handleRestartInterview({
																			interviewKey:
																				row['_id'],
																			fullName: `${firstName} ${lastName}`,
																			has_interview_ended:
																				row[
																					'has_interview_ended'
																				],
																		})
																	}
																>
																	continue
																</Button>
															)}
														</TableCell>
													);
												})}
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={interviews.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				)}
			</Stack>
		</div>
	);
};

export default MockInterviewReport;
