import Editor from '@monaco-editor/react';
import CodeIcon from '@mui/icons-material/Code';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { InterviewContext } from '../../global/InterviewContext';
import { API_URL } from '../../global/constants';
import { VideoAndScreenRecorder } from '../VideoAndScreenRecorder';
import LanguagesDropdown from './LanguagesDropdown';
import LoadingDialog from './LoadingDialog';
import Questions from './Questions';
import ResetCodeDialog from './ResetCodeDialog';
import SubmitCodeDialog from './SubmitCodeDialog';
import TestCompleteDialog from './TestCompleteDialog';
import ThemeDropdown from './ThemeDropdown';
import { languageOptions } from './constants/languageOptions';
import { defineTheme } from './lib/defineTheme';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectInterviewData,
	selectisproctoringcheckdone,
	setIsProctoringCheckDone,
} from '../../features/interview/interview';
import { PROCTORING_TOOL } from '../../global/constants';
const CodeEditor = () => {
	const editorRef = useRef(null);
	const interview_data = useSelector(selectInterviewData);
	const Proctoringcheckpassed = useSelector(selectisproctoringcheckdone);
	const dispatch = useDispatch();

	const { fullName, isDemo, isStudentInterview, candidate_interview, proctoring_token } =
		interview_data;
	const { proctoring_tool, _id: interview_key } = candidate_interview;
	const { setInterviewEnded, interviewEnded } = useContext(InterviewContext);

	const navigate = useNavigate();
	const outputRef = useRef(null);
	const [theme, setTheme] = useState('cobalt');
	const [language, setLanguage] = useState(languageOptions[0]);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [time, setTime] = useState({ hours: 0, minutes: 59, seconds: 59 });
	const [resetCodeDialogOpen, setResetCodeDialogOpen] = useState(false);
	const [submitCodeDialogOpen, setSubmitCodeDialogOpen] = useState(false);
	const [testCompleteDialogOpen, setTestCompleteDialogOpen] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [userData, setUserData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	const [submissionId, setSubmissionId] = useState(null);
	const [errorInCode, setErrorInCode] = useState(null);
	const [codeExecutionStatus, setCodeExecutionStatus] = useState(null);
	const [codeExecutionTime, setCodeExecutionTime] = useState(null);
	const [codeExecutionMemory, setCodeExecutionMemory] = useState(null);
	const [testCaseResults, setTestCaseResults] = useState(null);

	// tab switch check
	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	const [videoUploaded, setVideoUploaded] = useState(false);
	// const [tabSwitches, setTabSwitches] = useState(0);
	let tabSwitches = 0;
	let timer;
	const [isInterviewRequired, setIsInterviewRequired] = useState(false);
	useEffect(() => {
		async function fetchinterview() {
			const data = await axios.get(`${API_URL}/labs/interview/${interview_key}`);
			const interviewdetails = data.data;
			console.log(interviewdetails, 'interviewdetails');
			const is_interview_required =
				interviewdetails?.candidate_interview?.is_interview_required;
			setIsInterviewRequired(is_interview_required);
		}
		fetchinterview();
	}, []);
	const handleEditorMount = (editor, monaco) => {
		editorRef.current = editor;
		editorRef.current.onKeyDown((event) => {
			const { keyCode, ctrlKey, metaKey } = event;
			if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
				event.preventDefault();
			}
		});
	};

	const endUserInterview = async (endInterviewReason, tab_switches) => {
		await axios.post(`${API_URL}/labs/interview/end`, {
			interview_key: interview_key,
			end_interview_reason: endInterviewReason,
			tab_switches,
		});
		setInterviewEnded(true);
		// setUploadVideo(true);
	};

	useEffect(() => {
		if (alertDialogOpen) {
			timer = setInterval(() => {
				// endUserInterview('violation', tabSwitches);
			}, [10000]);
		} else {
			clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [alertDialogOpen]);

	useEffect(() => {
		if (proctoring_token && proctoring_tool === PROCTORING_TOOL) {
			window.remoteProctoring
				.remoteProctorInitV2({ authToken: proctoring_token })
				.then(function () {
					console.log('Initialization is success');
					//TODO: start test player here
					dispatch(setIsProctoringCheckDone(true));
				})
				.catch(function (err) {
					console.log('Initialization failed', err);
					alert('Initialization failed');
				});
		}

		console.log('loaded');
	}, []);

	const exitRefresh = useCallback((e) => {
		e.preventDefault();
		e.returnValue = '';
	});
	const checkTabSwitches = useCallback(() => {
		if (!interviewEnded) {
			setAlertDialogOpen(true);
			tabSwitches++;
			if (tabSwitches > 3) {
				// setAlertDialogOpen(false);
				// console.log("You have switched tabs more than 3 times.");
				// alert('You violated the terms of the interview. The interview has been ended.');
				// endUserInterview('violaton', tabSwitches);
				setAlertDialogOpen(false);
			}
		}
	});

	useEffect(() => {
		// if (!videoUploaded) {
		//     window.addEventListener("beforeunload", exitRefresh);
		// } else {
		//     window.removeEventListener("beforeunload", exitRefresh);
		// }
		if (!interviewEnded) {
			window.addEventListener('blur', checkTabSwitches);
		} else {
			window.removeEventListener('blur', checkTabSwitches);
		}
		return () => {
			window.removeEventListener('beforeunload', exitRefresh);
			window.removeEventListener('blur', checkTabSwitches);
		};
	}, [interviewEnded]);

	const resetValues = () => {
		setErrorInCode(null);
		setCodeExecutionStatus(null);
		setCodeExecutionTime(null);
		setCodeExecutionMemory(null);
		setTestCaseResults(null);
		setSubmissionId(null);
	};

	const handleCompile = async () => {
		saveCode(false);
		resetValues();
		setLoading(true);
		const { data } = await axios.post(`${API_URL}/labs/coding-test/compile-code`, {
			interview_key,
			question_id: questions[currentQuestionIndex]._id,
			code: userData[currentQuestionIndex].code[language.value],
			language: language.value,
		});
		if (data.success) {
			showSuccessToast(data.message);
			getSubmissionStatus(data.submission_id);
		} else {
			showErrorToast(data.message);
		}
		setLoading(false);
	};

	const getSubmissionStatus = async (submission_id) => {
		setLoading(true);
		const { data } = await axios.post(`${API_URL}/labs/coding-test/get-submission-status`, {
			submission_id,
		});
		const results = data.results;
		for (const result of results) {
			if (result.status_id === 1 || result.status_id === 2) {
				setTimeout(() => {
					getSubmissionStatus(submission_id);
				}, 2000);
				return;
			}
			if (result.error) {
				setLoading(false);
				setCodeExecutionStatus(result.status_description);
				setCodeExecutionTime(result.time);
				setCodeExecutionMemory(result.memory);
				setErrorInCode(atob(result.error));
				return;
			}
		}
		setTestCaseResults(results);
		setSubmissionId(submission_id);
		if (data.success) {
			// showSuccessToast(data.message);
			outputRef.current.scrollIntoView();
		} else {
			showErrorToast(data.message);
		}
		setLoading(false);
	};

	function handleThemeChange(th) {
		const theme = th;

		if (['light', 'vs-dark'].includes(theme.value)) {
			setTheme(theme);
		} else {
			defineTheme(theme.value).then((_) => setTheme(theme));
		}
	}
	useEffect(() => {
		defineTheme('oceanic-next').then((_) =>
			setTheme({ value: 'oceanic-next', label: 'Oceanic Next' })
		);
	}, []);

	const showSuccessToast = (msg) => {
		toast.success(msg || `Compiled Successfully!`, {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const showErrorToast = (msg, timer) => {
		toast.error(msg || `Something went wrong! Please try again.`, {
			position: 'top-right',
			autoClose: timer ? timer : 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const goToNotSubmittedQuestion = () => {
		for (let ud of userData) {
			if (!ud.submitted) {
				setCurrentQuestionIndex(userData.indexOf(ud));
				return;
			}
		}
		setTestCompleteDialogOpen(true);
	};

	const resetCode = () => {
		resetValues();
		let newUserData = [...userData];
		newUserData[currentQuestionIndex].code[language.value] =
			questions[currentQuestionIndex].code_snippet[language.value];
		setUserData(newUserData);
	};

	const getData = async () => {
		setLoading(true);
		const { data } = await axios.post(`${API_URL}/labs/coding-test/get-questions`, {
			interview_key,
		});
		if (data.success) {
			setQuestions(data.questions);
			setUserData(data.user_data);
			const time_limit = data.time_limit; // in mins
			setTime({
				hours: Math.floor(time_limit / 60),
				minutes: time_limit % 60,
				seconds: 0,
			});
			for (let ud of data.user_data) {
				if (!ud.submitted) {
					setCurrentQuestionIndex(data.user_data.indexOf(ud));
					setLoading(false);
					return;
				}
			}
		}
		setLoading(false);
		setTestCompleteDialogOpen(true);
	};

	const saveCode = async (showToast) => {
		setSaveLoading(true);
		const { data } = await axios.post(`${API_URL}/labs/coding-test/save-code`, {
			interview_key,
			codes: userData,
		});
		if (data.success) {
			showToast && showSuccessToast(data.message);
		} else {
			showErrorToast(data.message);
		}
		setSaveLoading(false);
	};

	const submitCode = async () => {
		setLoading(true);
		const { data } = await axios.post(`${API_URL}/labs/coding-test/submit-code`, {
			submission_id: submissionId,
		});
		if (data.success) {
			showSuccessToast(data.message);
			let newUserData = [...userData];
			newUserData[currentQuestionIndex].submitted = true;
			setUserData(newUserData);
			goToNotSubmittedQuestion();
			resetValues();
		} else {
			showErrorToast(data.message);
		}
		setLoading(false);
	};

	const handleGoToNextSection = async () => {
		setLoading(true);
		const { data } = await axios.post(`${API_URL}/labs/coding-test/end`, {
			interview_key,
		});
		if (data.success) {
			showSuccessToast(data.message);
			if (!isInterviewRequired) {
				navigate(`/end/${fullName}/${interview_key}`, {
					state: {
						isDemo: false,
						isStudentInterview,
					},
				});
			} else {
				navigate(`/interview`);
			}
		} else {
			showErrorToast(data.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (interview_key) {
			getData();
		}
	}, [interview_key]);

	useEffect(() => {
		if (interview_key && userData.length > 0) {
			saveCode();
		}
	}, [currentQuestionIndex, language, theme]);

	useEffect(() => {
		const timer = setInterval(() => {
			let newTime;
			const timeLeft = localStorage.getItem('timeLeft');
			const parsedTimeLeft = JSON.parse(timeLeft);
			if (!parsedTimeLeft?.hours && !parsedTimeLeft?.minutes && !parsedTimeLeft?.seconds) {
				newTime = { hours: 0, minutes: 59, seconds: 59 };
			} else {
				newTime = parsedTimeLeft;
			}
			// Calculate new time by decrementing seconds, minutes, and hours accordingly
			let newSeconds = parseInt(newTime.seconds) - 1;
			let newMinutes = parseInt(newTime.minutes);
			let newHours = parseInt(newTime.hours);

			if (newSeconds < 0) {
				newMinutes -= 1;
				newSeconds = 59; // Reset seconds to 59 if it goes below 0
			}
			if (newMinutes < 0) {
				newHours -= 1;
				newMinutes = 59; // Reset minutes to 59 if it goes below 0
			}
			if (newHours < 0) {
				// If hours go below 0, you can stop the timer or reset the time
				clearInterval(timer);
				// Optionally, you can set time to 0 here
				setTime({ hours: 0, minutes: 0, seconds: 0 });
				localStorage.setItem(
					'timeLeft',
					JSON.stringify({ hours: 0, minutes: 0, seconds: 0 })
				);
				return;
			}
			if (newSeconds === 0 && newMinutes === 0 && newHours === 0) {
				localStorage.setItem(
					'timeLeft',
					JSON.stringify({ hours: 0, minutes: 0, seconds: 0 })
				);
				setTime({ hours: 0, minutes: 0, seconds: 0 });
				clearInterval(timer);
				setTestCompleteDialogOpen(true);
				return;
			}

			const timeObj = {
				hours: newHours,
				minutes: newMinutes,
				seconds: newSeconds,
			};

			setTime(timeObj);

			localStorage.setItem('timeLeft', JSON.stringify(timeObj));
		}, 1000); // Update time every second

		// Clean up the interval to prevent memory leaks
		return () => clearInterval(timer);
	}, [time]);
	useEffect(() => {
		const endTest = async () => {
			await axios.post(`${API_URL}/labs/coding-test/end`, {
				interview_key,
			});
			if (!isInterviewRequired) {
				navigate(`/end/${fullName}/${interview_key}`);
			}
		};
		if (testCompleteDialogOpen && proctoring_tool === PROCTORING_TOOL) {
			window.remoteProctoring
				.stop()
				.then(function () {
					endTest();
					console.log('Remote Proctoring session ended successfully');
				})
				.catch(function (err) {
					console.log('Remote Proctoring session end FAILED', err);
				});
		}
		if (testCompleteDialogOpen) {
			endTest();
		}
	}, [testCompleteDialogOpen]);
	if (proctoring_token && !Proctoringcheckpassed) {
		return <></>;
	}

	return (
		<Stack>
			<Dialog open={alertDialogOpen}>
				<DialogTitle display="flex" alignItems={'center'}>
					<WarningAmberIcon color="red" />
					<b>Warning</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						We noticed that you moved out of the interview.{' '}
						<b>The hiring team is tracking</b> this activity. We recommend staying on
						this page until you complete the interview.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						style={{
							textTransform: 'none',
							color: '#fff',
							backgroundColor: 'green',
						}}
						onClick={() => {
							setAlertDialogOpen(false);
						}}
					>
						Agree & Continue
					</Button>
				</DialogActions>
			</Dialog>
			<Toaster />
			<LoadingDialog open={loading} />
			{isInterviewRequired && (
				<TestCompleteDialog
					open={testCompleteDialogOpen}
					isInterviewRequired={isInterviewRequired}
					handleGoToNextSection={handleGoToNextSection}
				/>
			)}

			{questions.length > 0 && (
				<Stack direction={'row'} spacing={2}>
					<Stack width={'30%'} bgcolor={'#fff'} spacing={2} p={2} pl={0}>
						<Typography fontWeight={'bold'} justifySelf={'end'} pl={2}>
							Time Remaining: {String(time.hours).padStart(2, '0')}:
							{String(time.minutes).padStart(2, '0')}:
							{String(time.seconds).padStart(2, '0')}
						</Typography>
						<Questions
							questions={questions}
							currentQuestionIndex={currentQuestionIndex}
							setCurrentQuestionIndex={setCurrentQuestionIndex}
							resetValues={resetValues}
							userData={userData}
						/>
					</Stack>
					<Stack width={'70%'} spacing={2} p={2}>
						<Stack spacing={2} direction={'row'} alignItems={'center'} w={'100%'}>
							<Typography fontWeight={'bold'}>Select Language</Typography>

							<LanguagesDropdown
								onSelectChange={(sl) => {
									setLanguage(sl);
									resetValues();
								}}
							/>
							<Typography fontWeight={'bold'}>Select Theme</Typography>
							<ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
							<ResetCodeDialog
								open={resetCodeDialogOpen}
								setOpen={setResetCodeDialogOpen}
								handleResetCode={resetCode}
							/>
							<Tooltip
								title="Reset to boilerplate code"
								placement="bottom"
								arrow
								followCursor
							>
								<Button
									startIcon={<ReplayIcon />}
									variant={'contained'}
									onClick={() => setResetCodeDialogOpen(true)}
								>
									Reset Code
								</Button>
							</Tooltip>
							<Button
								startIcon={<SaveIcon />}
								variant={'contained'}
								onClick={() => saveCode(true)}
								disabled={saveLoading}
							>
								Save Code
							</Button>
						</Stack>
						<Editor
							height="80vh"
							width="100%"
							onMount={handleEditorMount}
							language={language.value}
							value={userData[currentQuestionIndex].code[language.value]}
							theme={theme.value}
							defaultValue={userData[currentQuestionIndex].code[language.value]}
							onChange={(value) => {
								let newUserData = [...userData];
								newUserData[currentQuestionIndex].code[language.value] = value;
								setUserData(newUserData);
							}}
							options={{
								contextmenu: false,
							}}
						/>
						<Stack
							direction={'row'}
							spacing={2}
							justifyContent={'end'}
							alignItems={'end'}
						>
							<Tooltip
								title="Run code to test against sample test cases"
								placement="left"
								arrow
								followCursor
							>
								<Button
									variant="outlined"
									onClick={handleCompile}
									startIcon={<CodeIcon />}
								>
									Run Code
								</Button>
							</Tooltip>
							<SubmitCodeDialog
								open={submitCodeDialogOpen}
								setOpen={setSubmitCodeDialogOpen}
								handleSubmitCode={submitCode}
							/>
							<Tooltip
								title={
									!submissionId
										? 'Please run the code first.'
										: 'Submit code for evaluation'
								}
								placement="left"
								arrow
								followCursor
							>
								{submissionId ? (
									<Button
										variant="contained"
										onClick={() => setSubmitCodeDialogOpen(true)}
										disabled={!submissionId}
									>
										Submit Code
									</Button>
								) : (
									<span>
										<Button
											variant="contained"
											onClick={() => setSubmitCodeDialogOpen(true)}
											disabled={!submissionId}
										>
											Submit Code
										</Button>
									</span>
								)}
							</Tooltip>
						</Stack>
						<div ref={outputRef}>
							<Stack spacing={2}>
								{(codeExecutionStatus ||
									(testCaseResults && testCaseResults.length > 0)) && (
									<Typography fontWeight={'bold'} fontSize={'1.3rem'}>
										Output
									</Typography>
								)}
								{!testCaseResults && (
									<Stack spacing={1}>
										{codeExecutionStatus && (
											<Typography fontWeight={'bold'}>
												Status: {codeExecutionStatus}
											</Typography>
										)}
										{codeExecutionTime && (
											<Typography fontWeight={'bold'}>
												Time: {codeExecutionTime}
											</Typography>
										)}
										{codeExecutionMemory && (
											<Typography fontWeight={'bold'}>
												Memory: {codeExecutionMemory}
											</Typography>
										)}
										{errorInCode && (
											<Typography
												fontWeight={'bold'}
												color={'red'}
												fontSize={'0.7rem'}
											>
												{errorInCode}
											</Typography>
										)}
									</Stack>
								)}
								{testCaseResults &&
									testCaseResults.map((result, index) => (
										<Stack
											spacing={1}
											border={`2px solid ${
												result.status_id === 3 ? '#027148' : '#e53935'
											}`}
											borderRadius={'10px'}
											direction="row"
											p={2}
											bgcolor={
												result.status_id === 3
													? 'rgb(2, 113, 72, 0.5)'
													: 'rgb(229, 57, 53, 0.5)'
											}
										>
											<Typography fontWeight={'bold'}>
												Test Case: {index + 1}
											</Typography>
											<Typography fontWeight={'bold'}>
												Status: {result.status_description}
											</Typography>
											<Typography fontWeight={'bold'}>
												Time: {result.time}
											</Typography>
											<Typography fontWeight={'bold'}>
												Memory: {result.memory}
											</Typography>
										</Stack>
									))}
							</Stack>
						</div>
						{!proctoring_token && (
							<VideoAndScreenRecorder
								interviewKey={interview_key}
								isStudentInterview={isStudentInterview}
								fullName={fullName}
							/>
						)}
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};
export default CodeEditor;
