import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import image1 from '../images/page2-1.png';
import image2 from '../images/page2-2.png';
import image3 from '../images/page2-3.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import './style.css';
import { useNavigate } from 'react-router';
const About1 = () => {
	const navigate = useNavigate();
	return (
		<Stack
			// bgcolor={"white"}
			// height={"100vh"}
			id="about1"
			display="flex"
			justifyContent={'center'}
			alignItems={'center'}
			spacing={2}
			p={2}
			pt={'65px'}
		>
			<Stack
				direction={'row'}
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
				spacing={2}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="200"
			>
				<img className="img1" src={image1} alt="img1" />
				<img className="img2" src={image2} alt="img2" />
				<img className="img1" src={image3} alt="img1" />
			</Stack>
			<Typography
				fontFamily={'Poppins'}
				fontWeight={'700'}
				fontSize={{ sm: '3.2rem', xs: '2.2rem' }}
				color={'#224C84'}
				textAlign={'center'}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="200"
			>
				AI.HYR finds your next crew
				<br />
				member with excellent
				<br />
				functional skills & perfect
				<br />
				cultural fit.
			</Typography>
			<Button
				variant="contained"
				size="large"
				data-aos="fade-up"
				data-aos-duration="1200"
				data-aos-delay="200"
				onClick={() => navigate('/interview/practice')}
				endIcon={<PlayCircleOutlineIcon fontSize="large" />}
				style={{
					backgroundColor: '#224C84',
					fontFamily: 'Poppins',
					fontSize: '1.2rem',
					textTransform: 'none',
					borderRadius: '15px',
				}}
			>
				Try Now
			</Button>
		</Stack>
	);
};

export default About1;
