import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import learningassessments from '../images/learningassessments.png';
import './style.css';
import CallIcon from '@mui/icons-material/Call';

const LearningAssessments = () => {
	return (
		<Stack display="flex" justifyContent={'center'} alignItems={'center'} pt={3} pb={3}>
			<Stack textAlign={'center'} alignItems={'center'}>
				<Typography
					fontFamily={'Poppins'}
					fontWeight={'700'}
					fontSize={{ sm: '3rem', xs: '2rem' }}
					color={'#224C84'}
					textAlign={'center'}
					p={1}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					Learning needs assessments,
					<br /> feedback for learning & development
				</Typography>
				<Typography
					color={'#224C84'}
					textAlign={'center'}
					fontSize={{ sm: '1.3rem', xs: '1.2rem' }}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					Analyze the learning need, gather detailed feedback, and evaluate program
					effectiveness{' '}
				</Typography>
			</Stack>
			<Stack
				justifyContent={'center'}
				spacing={{ sm: 5, xs: 2 }}
				direction={{ sm: 'row', xs: 'column' }}
				width={{ sm: '90%' }}
				p={3}
			>
				<Stack spacing={2}>
					<Typography
						fontSize={'1.3rem'}
						p={1}
						color={'#224C84'}
						style={{
							backgroundColor: '#ffbd59',
							borderRadius: '10px',
						}}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						Pre-program
						<br /> need analysis
					</Typography>
					<Stack
						direction={'row'}
						alignItems={'center'}
						data-aos="fade-up"
						data-aos-duration="1200"
						data-aos-delay="300"
					>
						<Typography fontSize={'2.5rem'}>🤔</Typography>
						<Typography fontSize={'1.2rem'} color={'#224C84'}>
							Skill gap
							<br /> analysis
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'2.5rem'}>🤓</Typography>
						<Typography fontSize={'1.2rem'} color={'#224C84'}>
							Learning
							<br /> Experience
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'2.5rem'}>😃</Typography>
						<Typography fontSize={'1.2rem'} color={'#224C84'}>
							Expected
							<br /> Outcome{' '}
						</Typography>
					</Stack>
				</Stack>
				<Stack
					// width={{ sm: "33%" }}
					alignItems={{ xs: 'center' }}
					data-aos="zoom-in"
					data-aos-duration="1000"
					data-aos-delay="300"
					// mr={{ sm: 5, xs: 0 }}
				>
					<img
						className="hiring-interview"
						src={learningassessments}
						alt="hiring-interviews"
						width={'300px'}
					/>
				</Stack>
				<Stack spacing={2}>
					<Typography
						fontSize={'1.3rem'}
						p={1}
						color={'#224C84'}
						style={{
							backgroundColor: '#ffbd59',
							borderRadius: '10px',
						}}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						Post-program <br />
						outcome analysis
					</Typography>
					<Stack
						direction={'row'}
						alignItems={'center'}
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'2.5rem'}>😎</Typography>
						<Typography fontSize={'1.2rem'} color={'#224C84'}>
							Performance
							<br /> Improvement
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'2.5rem'}>🤑</Typography>
						<Typography fontSize={'1.2rem'} color={'#224C84'}>
							Business
							<br /> Outcomes{' '}
						</Typography>
					</Stack>
				</Stack>
			</Stack>

			<Button
				variant="contained"
				size="large"
				data-aos="zoom-in"
				data-aos-duration="1000"
				data-aos-delay="300"
				onClick={() => (window.location.href = 'https://meetings.hubspot.com/nrohatgi')}
				startIcon={<CallIcon fontSize="large" />}
				style={{
					backgroundColor: '#224C84',
					fontFamily: 'Poppins',
					fontSize: '1.2rem',
					textTransform: 'none',
					borderRadius: '15px',
				}}
			>
				Book a Demo
			</Button>
		</Stack>
	);
};

export default LearningAssessments;
