import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import CallIcon from '@mui/icons-material/Call';

const Features = () => {
	return (
		<Stack
			id="features"
			display="flex"
			justifyContent={{ sm: 'start', xs: 'center' }}
			alignItems={'center'}
			p={2}
			pt={'65px'}
			pb={3}
			direction={{ sm: 'row', xs: 'column' }}
		>
			<Typography
				fontFamily={'Poppins'}
				fontSize={{ sm: '3rem', xs: '2rem' }}
				width={{ sm: '400px' }}
				fontWeight={'700'}
				p={2}
				ml={{ sm: 15, xs: 2 }}
				mr={{ sm: 10, xs: 2 }}
				textAlign={{ sm: 'start', xs: 'center' }}
				color={'#224C84'}
				style={{
					backgroundColor: 'rgb(255, 189, 89, 0.8)',
					borderRadius: '10px',
				}}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
			>
				Build a hiring operating system for your organization
			</Typography>
			<Stack
				spacing={2}
				p={2}
				data-aos="fade-up"
				data-aos-duration="1000"
				data-aos-delay="300"
			>
				<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
					<Stack direction={'row'}>
						<Stack
							// spacing={1}
							width={{ sm: '350px', xs: '300px' }}
							alignItems={{ xs: 'center', sm: 'start' }}
							bgcolor={'rgb(236, 247, 250, 0.7)'}
							p={2}
							borderRadius={'20px'}
						>
							<Typography fontSize={'2.5rem'}>🧑‍🏫</Typography>
							<Typography
								color={'#224C84'}
								// p={"5px"}
								borderRadius={'10px'}
								fontFamily={'Poppins'}
								fontWeight={'bold'}
								fontSize={'1rem'}
							>
								Structured
							</Typography>
							<Typography
								fontFamily={'Poppins'}
								fontSize={{ sm: '1.2rem', xs: '1.2rem' }}
								color={'#224C84'}
								// fontWeight={"500"}
								textAlign={{ xs: 'center', sm: 'start' }}
							>
								Streamlines interviews with set
								<br /> rubrics, promoting transparency
								<br /> and uniformity across the
								<br /> organization.
							</Typography>
						</Stack>
					</Stack>
					<Stack direction={'row'}>
						<Stack
							// spacing={1}
							width={{ sm: '350px', xs: '300px' }}
							alignItems={{ xs: 'center', sm: 'start' }}
							bgcolor={'rgb(236, 247, 250, 0.7)'}
							p={2}
							borderRadius={'20px'}
						>
							<Typography fontSize={'2.5rem'}>👨‍⚖️</Typography>
							<Typography
								color={'#224C84'}
								// p={"5px"}
								borderRadius={'10px'}
								fontFamily={'Poppins'}
								fontWeight={'bold'}
								fontSize={'1rem'}
							>
								Unbiased
							</Typography>
							<Typography
								fontFamily={'Poppins'}
								fontSize={{ sm: '1.2rem', xs: '1.2rem' }}
								color={'#224C84'}
								// fontWeight={"500"}
								textAlign={{ xs: 'center', sm: 'start' }}
							>
								Evaluate on predefined criteria,
								<br /> removing subjective bias,
								<br /> providing equitable & unbiased
								<br /> assessments
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2}>
					<Stack direction={'row'}>
						<Stack
							// spacing={1}
							width={{ sm: '350px', xs: '300px' }}
							alignItems={{ xs: 'center', sm: 'start' }}
							bgcolor={'rgb(236, 247, 250, 0.7)'}
							p={2}
							borderRadius={'20px'}
						>
							<Typography fontSize={'2.5rem'}>🤵</Typography>
							<Typography
								color={'#224C84'}
								// p={"5px"}
								borderRadius={'10px'}
								fontFamily={'Poppins'}
								fontWeight={'bold'}
								fontSize={'1rem'}
							>
								Tailored
							</Typography>
							<Typography
								fontFamily={'Poppins'}
								fontSize={{ sm: '1.2rem', xs: '1.2rem' }}
								color={'#224C84'}
								// fontWeight={"500"}
								textAlign={{ xs: 'center', sm: 'start' }}
							>
								Crafts role-specific rubrics,
								<br /> updates to align with evolving
								<br /> organizational requirements.
							</Typography>
						</Stack>
					</Stack>
					<Stack direction={'row'}>
						<Stack
							// spacing={1}
							width={{ sm: '350px', xs: '300px' }}
							alignItems={{ xs: 'center', sm: 'start' }}
							bgcolor={'rgb(236, 247, 250, 0.7)'}
							p={2}
							borderRadius={'20px'}
						>
							<Typography fontSize={'2.5rem'}>👮</Typography>
							<Typography
								color={'#224C84'}
								// p={"5px"}
								borderRadius={'10px'}
								fontFamily={'Poppins'}
								fontWeight={'bold'}
								fontSize={'1rem'}
							>
								Objective
							</Typography>
							<Typography
								fontFamily={'Poppins'}
								fontSize={{ sm: '1.2rem', xs: '1.2rem' }}
								color={'#224C84'}
								// fontWeight={"500"}
								textAlign={{ xs: 'center', sm: 'start' }}
							>
								Transforms subjective info into
								<br /> objective insights, enabling
								<br /> unbiased candidate analysis.
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				<Stack direction={{ sm: 'row', xs: 'column' }} spacing={2} alignItems={'center'}>
					<Stack direction={'row'}>
						<Stack
							// spacing={1}
							width={{ sm: '350px', xs: '300px' }}
							alignItems={{ xs: 'center', sm: 'start' }}
							bgcolor={'rgb(236, 247, 250, 0.7)'}
							p={2}
							borderRadius={'20px'}
						>
							<Typography fontSize={'2.5rem'}>🧑‍✈️</Typography>
							<Typography
								color={'#224C84'}
								// p={"5px"}
								borderRadius={'10px'}
								fontFamily={'Poppins'}
								fontWeight={'bold'}
								fontSize={'1rem'}
							>
								Efficient
							</Typography>
							<Typography
								fontFamily={'Poppins'}
								fontSize={{ sm: '1.2rem', xs: '1.2rem' }}
								color={'#224C84'}
								// fontWeight={"500"}
								textAlign={{ xs: 'center', sm: 'start' }}
							>
								Conduct numerous parallel
								<br /> interviews, promptly identifying
								<br /> top-recommended candidates—
								<br />
								time-saving for teams.
							</Typography>
						</Stack>
					</Stack>
					<Stack
						justifyContent={'center'}
						alignItems={'center'}
						width={{ sm: '350px', xs: '300px' }}
					>
						<Button
							variant="contained"
							size="large"
							onClick={() =>
								(window.location.href = 'https://meetings.hubspot.com/nrohatgi')
							}
							startIcon={<CallIcon fontSize="large" />}
							style={{
								backgroundColor: '#224C84',
								fontFamily: 'Poppins',
								fontSize: '1.2rem',
								textTransform: 'none',
								borderRadius: '15px',
							}}
						>
							Book a Demo
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Features;
