import { useState, useEffect } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import { Button } from '@mui/material';
import { API_URL } from '../../global/constants';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import styles from './index.module.css';
import Tooltip from '@mui/material/Tooltip';
//import 'webrtc-adapter';

// const audioRecorder = new MicRecorder({ bitRate: 16 });

const mimeType = 'audio/mpeg';

export const AudioRecorder = (props) => {
	const {
		interviewKey,
		handleAnswer,
		updateIsListening,
		questionId,
		isPractice,
		isTooltipRunning,
		setIsTooltipRunning,
		setStepIndex,
		enabledRecording,
		setAnsweringTime,
	} = props;
	const [recordingStatus, setRecordingStatus] = useState('inactive');
	const [isRecordingStarted, setIsRecordingStarted] = useState(false);
	const [isStopRecordingTooltip, setStopRecordingTooltip] = useState(false);
	const [error, setError] = useState(false);
	const [startTime, setStartTime] = useState();
	const [endTime, setEndTime] = useState();
	useEffect(() => {
		setIsRecordingStarted(false);
		setError(false);
		setRecordingStatus('inactive');
	}, [questionId]);

	const { startRecording, stopRecording, recordingBlob, isRecording } = useAudioRecorder();

	useEffect(() => {
		if (!recordingBlob) return;
		transcribeAndUpload();
	}, [recordingBlob]);

	const transcribeAndUpload = async () => {
		const myUuid = uuidv4();
		const key = `${myUuid}_${interviewKey}.mpeg`;
		var file = new File([recordingBlob], key, { type: 'audio/mpeg' });
		// console.log(file, 'xyz');
		try {
			// let response;
			// if (!isPractice) {
			//     response = await axios.post(
			//         "https://api.openai.com/v1/audio/transcriptions",
			//         {
			//             file: file,
			//             model: "whisper-1",
			//         },
			//         {
			//             headers: {
			//                 "Content-Type": "multipart/form-data",
			//                 Authorization:
			//                     `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
			//             },
			//         }
			//     );
			//     console.log(response?.data?.text)
			// }
			// handleUploadToS3(file, key);
			handleAnswer(key, file);
			// if (!isPractice && response?.data?.text) {
			// }
			updateIsListening(false);
		} catch (error) {
			console.log('error', error);
			setError(true);
		}
	};

	const start = async () => {
		isPractice && isTooltipRunning && setIsTooltipRunning(false);
		isPractice &&
			setStepIndex &&
			setTimeout(() => {
				setStepIndex(1);
				setIsTooltipRunning(true);
			}, 2000);
		if (!isPractice) {
			setTimeout(() => {
				setStopRecordingTooltip(true);
			}, 3000);
		}
		try {
			updateIsListening(true);
			startRecording();
			setStartTime(Date.now());
			setRecordingStatus('recording');
		} catch (error) {
			console.error('Error accessing audio:', error);
		}
	};
	const calculateAnsweringTime = () => {
		const differenceInMillis = Math.abs(startTime - Date.now()); // Absolute value to ensure a positive result
		setAnsweringTime(differenceInMillis);
	};
	const stop = async () => {
		setEndTime(Date.now());
		stopRecording();
		calculateAnsweringTime();
	};

	const handleUploadToS3 = async (ev, fileName) => {
		let s3fileName;
		if (isPractice) {
			const date = new Date();
			let day = date.getDate();
			let month = date.getMonth() + 1;
			let year = date.getFullYear();
			const currentDate = `${day}-${month}-${year}`;
			s3fileName = `practice-interviews/${currentDate}/${fileName}`;
		} else {
			s3fileName = `${interviewKey}/${fileName}`;
		}
		const requestObject = {
			method: 'POST',
			body: {
				fileName: s3fileName,
				fileType: mimeType,
			},
		};

		const signUrlData = await axios.post(`${API_URL}/labs/getPreSignedUrl`, requestObject);

		const updloadedData = await axios.put(`${signUrlData.data.url}`, ev, {
			headers: {
				'Content-Type': 'audio/mpeg',
			},
		});
		return updloadedData;
	};

	return (
		<div>
			<main>
				<div
					className={`audio-controls ${
						recordingStatus === 'recording'
							? 'recording-stop-btn'
							: 'recording-start-btn'
					}`}
				>
					{/* {!permission && !audio ? (
            <Button onClick={getMicrophonePermission} type="button" sx={{ color: '#ffffff' }}>
              Get Microphone Permission
            </Button>
          ): null} */}
					{recordingStatus !== 'recording' && !isRecording ? (
						<div className={styles.mic_holder}>
							{/* { isPractice && (
                                <>
                                    <div className={styles.tooltip_mic}>
                                        Click to record your answer.
                                    </div>
                                    <div className={styles.arrow}>
                                        <ExpandMoreIcon
                                            fontSize={"50px"}
                                            className={styles.icon}
                                        />
                                    </div>
                                </>
                            )} */}
							<Tooltip title="Record your answer" placement="top" arrow>
								<div className={styles.audio_recorder_div}>
									<Button
										onClick={start}
										style={{
											borderRadius: '50%',
											border: '1px',
											width: '50px',
											height: '50px',
											color: enabledRecording ? '#ffffff' : 'gray',
											borderColor: '#ffffff',
											zIndex: 99999,
										}}
									>
										<MicIcon
											sx={{
												width: '2em',
												height: '2em',
												fontSize: 'large',
												zIndex: 99999,
											}}
										/>
									</Button>
								</div>
							</Tooltip>
						</div>
					) : null}

					{recordingStatus === 'recording' && isRecording ? (
						<div className={styles.mic_holder}>
							{/* {isStopRecordingTooltip && isPractice && (
                                <>
                                    <div className={styles.tooltip_mic}>
                                        Click to stop recording.
                                    </div>
                                    <div className={styles.arrow}>
                                        <ExpandMoreIcon
                                            fontSize={"50px"}
                                            className={styles.icon}
                                        />
                                    </div>
                                </>
                            )} */}
							<Tooltip title="Stop Recording" placement="top" arrow>
								<Button
									onClick={stop}
									sx={{
										borderRadius: '50%',
										width: '100px',
										height: '100px',
										backgroundColor: 'red',
										zIndex: 99999,
									}}
								>
									<img
										src="/images/audio-recorder.gif"
										style={{
											borderRadius: '50%',
											height: '100%',
											zIndex: 99999,
										}}
									/>
								</Button>
							</Tooltip>
						</div>
					) : null}
				</div>
			</main>
		</div>
	);
};
