import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

export const CountdownTimer = (props) => {
	const { removeCountdownTimer } = props;
	const [timer, setTimer] = useState(5);

	useEffect(() => {
		if (timer > 0) {
			const countdown = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);

			return () => {
				clearInterval(countdown);
			};
		} else {
			removeCountdownTimer();
		}
	}, [timer]);

	return (
		<Box
			width="100vw"
			height="80vh"
			textAlign={'center'}
			display="flex"
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{timer > 0 && (
				<>
					<h1>{props.timerText}</h1>
					<div
						style={{
							borderRadius: '50%',
							border: '8px solid #1976d2',
							width: '100px',
							height: '100px',
							margin: '0 auto',
							textAlign: 'center',
						}}
					>
						<h1 style={{ color: '#1976d2' }}>{timer}</h1>
					</div>
				</>
			)}
		</Box>
	);
};
