import { useState, useRef } from 'react';
import { MobileStepper, Button, Snackbar, Alert, Stack, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import styles from './interviewTrivia.module.css';
import { CSSTransition } from 'react-transition-group';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const Quiz_Set = [
	{
		queno: 'que_1',
		que: "Who is often referred to as the 'Shahenshah' (meaning 'Emperor') of Bollywood?",
		options: [
			{ que_options: 'Raj Kapoor', selected: false },
			{ que_options: 'Dharmendra', selected: false },
			{ que_options: 'Amitabh Bachchan', selected: false },
			{ que_options: 'Shah Rukh Khan', selected: false },
		],
		ans: 'Amitabh Bachchan',
		success_gif: '/images/success-1.gif',
		failure_gif: '/images/fail-1.gif',
	},
	{
		queno: 'que_2',
		que: 'When was the first recorded job interview in history?',
		options: [
			{ que_options: '15th century', selected: false },
			{ que_options: '18th century', selected: false },
			{ que_options: '20th century', selected: false },
			{ que_options: '19th century', selected: false },
		],
		ans: '19th century',
		success_gif: '/images/success-2.gif',
		failure_gif: '/images/fail-2.gif',
	},
	{
		queno: 'que_3',
		que: 'Who was the first Indian to be awarded the Nobel Prize, winning it in Literature in 1913?',
		options: [
			{ que_options: 'Rabindranath Tagore', selected: false },
			{ que_options: 'C.V. Raman', selected: false },
			{ que_options: 'Subrahmanyan Chandrasekhar', selected: false },
			{ que_options: 'Amartya Sen', selected: false },
		],
		ans: 'Rabindranath Tagore',
		success_gif: '/images/success-3.gif',
		failure_gif: '/images/fail-3.gif',
	},
	{
		queno: 'que_4',
		que: "What's the typical duration of an elevator pitch during an interview?",
		options: [
			{ que_options: '5 minutes', selected: false },
			{ que_options: '1 minute', selected: false },
			{ que_options: '30 seconds', selected: false },
			{ que_options: '10 minute', selected: false },
		],
		ans: '30 seconds',
		success_gif: '/images/success-4.gif',
		failure_gif: '/images/fail-4.gif',
	},
	{
		queno: 'que_5',
		que: "Sending a thank-you email after an interview is a common practice. What's the recommended time frame for sending it?",
		options: [
			{ que_options: 'Within 24 hours', selected: false },
			{ que_options: 'Within a week', selected: false },
			{ que_options: 'Within 48 hours', selected: false },
			{ que_options: 'Within a month', selected: false },
		],
		ans: 'Within 24 hours',
		success_gif: '/images/success-5.gif',
		failure_gif: '/images/fail-5.gif',
	},
	{
		queno: 'que_6',
		que: 'What does the STAR in the STAR method stand for?',
		options: [
			{
				que_options: 'Structured, Thorough, Analytical, Reflective',
				selected: false,
			},
			{
				que_options: 'Situation, Task, Assessment, Result',
				selected: false,
			},
			{
				que_options: 'Scenario, Technique, Action, Response',
				selected: false,
			},
			{
				que_options: 'Strategic, Tactical, Analytical, Resourceful',
				selected: false,
			},
		],
		ans: 'Situation, Task, Assessment, Result',
		success_gif: '/images/success-6.gif',
		failure_gif: '/images/fail-6.gif',
	},
	{
		queno: 'que_7',
		que: 'Which of the following is considered a job interview superstition?',
		options: [
			{
				que_options: 'Wearing mismatched socks for good luck',
				selected: false,
			},
			{
				que_options: "Carrying a rabbit's foot for success",
				selected: false,
			},
			{
				que_options: 'Avoiding the number 13 during the interview process',
				selected: false,
			},
			{
				que_options: 'Always arriving exactly 7 minutes early to interviews',
				selected: false,
			},
		],
		ans: 'Avoiding the number 13 during the interview process',
		success_gif: '/images/success-7.gif',
		failure_gif: '/images/fail-7.gif',
	},
	{
		queno: 'que_8',
		que: "What's a common mistake to avoid when following up after an interview?",
		options: [
			{ que_options: 'Asking for a salary increase', selected: false },
			{
				que_options: 'Sending a generic thank-you email to all interviewers',
				selected: false,
			},
			{
				que_options: 'Mentioning that you have other job offers',
				selected: false,
			},
			{
				que_options: 'Forgetting to include your contact information',
				selected: false,
			},
		],
		ans: 'Sending a generic thank-you email to all interviewers',
		success_gif: '/images/success-8.gif',
		failure_gif: '/images/fail-8.gif',
	},
	{
		queno: 'que_9',
		que: 'According to a famous saying, you never get a second chance to make a what?',
		options: [
			{ que_options: 'Mistake', selected: false },
			{ que_options: 'Good Impression', selected: false },
			{ que_options: 'Cup of coffee', selected: false },
			{ que_options: 'Decision', selected: false },
		],
		ans: 'Good Impression',
		success_gif: '/images/success-9.gif',
		failure_gif: '/images/fail-9.gif',
	},
	{
		queno: 'que_10',
		que: 'Which color is often associated with confidence and authority in job interview attire?',
		options: [
			{ que_options: 'Red', selected: false },
			{ que_options: 'Yellow', selected: false },
			{ que_options: 'Purple', selected: false },
			{ que_options: 'Green', selected: false },
		],
		ans: 'Red',
		success_gif: '/images/success-10.gif',
		failure_gif: '/images/fail-10.gif',
	},
];

export const InterviewTrivia = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [quizSet, setQuizSet] = useState(Quiz_Set);
	const [booleanOnSubmit, setBooleanOnSubmit] = useState(false);
	const [total, setTotal] = useState(0);
	const [open, setOpen] = useState(false);
	const [catchMsg, setCatchMsg] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [successGif, setSuccessGif] = useState(null);
	const [failureGif, setFailureGif] = useState(null);
	const nodeRef = useRef(null);

	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setSuccessGif(null);
		setFailureGif(null);
		setSelectedAnswer(null);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	// const onInputChange = (e) => {
	//   setSelectedAnswer(e.target.value);
	// }

	const onInputChange = (e) => {
		const nexState = quizSet.map((card) => {
			if (card.queno !== e.target.name) return card;
			return {
				...card,
				options: card.options.map((opt) => {
					const checked = opt.que_options === e.target.value;
					return {
						...opt,
						selected: checked,
					};
				}),
			};
		});
		setSelectedAnswer(e.target.value);
		setQuizSet(nexState);
	};

	const checkAnswer = (e) => {
		setSuccessGif(null);
		setFailureGif(null);
		if (selectedAnswer === quizSet[activeStep].ans) {
			setSuccessGif(quizSet[activeStep].success_gif);
		} else {
			setFailureGif(quizSet[activeStep].failure_gif);
		}
	};

	const onSubmit = () => {
		let list = quizSet;
		let count = 0;
		let notattempcount = 0;

		list.map((item, key) => {
			item.options.map((anslist, key) => {
				//  console.log("anslist.selected===>",anslist.selected)
				if (anslist.selected === true) {
					if (anslist.que_options === item.ans) {
						//   console.log("===>",anslist.que_options,item.ans)
						count = count + 1;
					}
				} else {
					notattempcount = notattempcount + 1;
				}
			});
		});

		if (notattempcount <= 24 && notattempcount > 16) {
			setBooleanOnSubmit(false);
			setTotal(count);
			setCatchMsg('Please attempt all questions');
			setErrorMsg('error');
			setOpen(true);
		} else {
			setBooleanOnSubmit(true);
			setTotal(count);
		}
	};

	const snackBarRender = () => {
		return open ? (
			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				style={{ marginTop: '0px', width: '100%' }}
			>
				<Alert elevation={6} variant="filled" onClose={handleClose} severity={errorMsg}>
					{catchMsg}
				</Alert>
			</Snackbar>
		) : null;
	};

	const setReplay = () => {
		setBooleanOnSubmit(false);
		setActiveStep(0);
		setQuizSet(Quiz_Set);
		setTotal(0);
	};

	return (
		<div className="Quiz_render_container">
			{booleanOnSubmit ? (
				<div className="Quiz-DisplayResult">
					<h2> The score is {total} Out Of 8 </h2>
					<Button onClick={setReplay}>
						{' '}
						<ReplayIcon /> Try again{' '}
					</Button>
				</div>
			) : (
				<Stack alignItems={'center'} spacing={2}>
					{quizSet.map((item, index) => {
						if (Math.abs(activeStep - index) <= 0) {
							return (
								<Stack spacing={2}>
									<Typography fontSize={'1.1rem'} fontWeight={'bold'}>
										{item.que}
									</Typography>
									<Stack
										direction={{ xs: 'column', sm: 'row' }}
										justifyContent={'center'}
										spacing={1}
									>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												name="row-radio-buttons-group"
											>
												{item.options.map((ans, index_ans) => {
													index_ans = index_ans + 1;
													return (
														<FormControlLabel
															key={index_ans}
															value={ans.que_options}
															control={
																<Radio onChange={onInputChange} />
															}
															label={ans.que_options}
														/>
													);
												})}
											</RadioGroup>
										</FormControl>
									</Stack>
									<CSSTransition
										in={successGif}
										nodeRef={nodeRef}
										timeout={1000}
										classNames="alert"
										unmountOnExit
									>
										<div ref={nodeRef} className={styles.imgDiv}>
											<p style={{ color: 'green', margin: '16px auto' }}>
												Right Answer!!
											</p>
											<img src={successGif} width="300px" height="auto" />
										</div>
									</CSSTransition>
									<CSSTransition
										in={failureGif}
										nodeRef={nodeRef}
										timeout={1000}
										classNames="alert"
										unmountOnExit
									>
										<div ref={nodeRef} className={styles.imgDiv}>
											<p style={{ color: 'red', margin: '16px auto' }}>
												Wrong Answer
											</p>
											<img src={failureGif} width="300px" height="auto" />
										</div>
									</CSSTransition>
									<Button
										type="button"
										variant="contained"
										className={styles.checkAnswerBtn}
										style={{
											width: 'fit-content',
											alignSelf: 'center',
										}}
										sx={{ textTransform: 'none' }}
										onClick={checkAnswer}
									>
										Submit
									</Button>
								</Stack>
							);
						} else {
							return null;
						}
					})}

					<div className={styles.quizMobileStepper}>
						<MobileStepper
							variant="dots"
							steps={quizSet.length}
							position="static"
							activeStep={activeStep}
							nextButton={
								<Button
									size="small"
									onClick={handleNext}
									disabled={activeStep === quizSet.length}
								>
									Next
								</Button>
							}
							backButton={
								<Button
									size="small"
									onClick={handleBack}
									disabled={activeStep === 0}
								>
									Back
								</Button>
							}
						/>
					</div>
				</Stack>
			)}
			{snackBarRender}
		</div>
	);
};
