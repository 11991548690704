import {
	Button,
	Stack,
	TextField,
	Typography,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	FormHelperText,
} from '@mui/material';
import React from 'react';
import { isValidURL } from '../../helpers/utils';

const Job = (props) => {
	const { details, setDetails } = props;
	return (
		<Stack spacing={2}>
			{details.jobPostings.map((job, index) => {
				return (
					<Stack spacing={2} key={index}>
						<Stack
							spacing={2}
							justifyContent={'space-between'}
							direction={'row'}
							alignItems={'center'}
						>
							<Typography fontWeight={'bold'}>Job Posting {index + 1}</Typography>
							{index !== 0 && (
								<Button
									style={{
										width: 'fit-content',
										color: '#224C84',
										textTransform: 'none',
										textDecoration: 'underline',
									}}
									onClick={() => {
										setDetails((prev) => {
											const newJobPostings = [...prev.jobPostings];
											newJobPostings.splice(index, 1);
											return {
												...prev,
												jobPostings: newJobPostings,
											};
										});
									}}
								>
									Remove Job Posting
								</Button>
							)}
						</Stack>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<TextField
								label={'Job Role'}
								value={job.job_role}
								error={!job.job_role}
								helperText={!job.job_role && 'Job Role is required'}
								onChange={(e) => {
									setDetails((prev) => {
										const newJobPostings = [...prev.jobPostings];
										newJobPostings[index].job_role = e.target.value;
										return {
											...prev,
											jobPostings: newJobPostings,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
							<FormControl
								style={{
									width: '300px',
								}}
								error={!job.type}
								required
							>
								<InputLabel>Job Type</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={job.type}
									helperText={!job.type && 'Job Type is required'}
									label="Job Type"
									onChange={(e) => {
										setDetails((prev) => {
											const newJobPostings = [...prev.jobPostings];
											newJobPostings[index].type = e.target.value;
											return {
												...prev,
												jobPostings: newJobPostings,
											};
										});
									}}
								>
									<MenuItem value={'Remote'}>Remote</MenuItem>
									<MenuItem value={'In-Office'}>In-Office</MenuItem>
									<MenuItem value={'Hybrid'}>Hybrid</MenuItem>
								</Select>
								<FormHelperText>
									{!job.type && 'Job Type is required'}
								</FormHelperText>
							</FormControl>
						</Stack>
						<Stack direction={{ md: 'row', sm: 'column' }} gap={2}>
							<TextField
								label={'Job Description Link'}
								value={job.job_description_link}
								error={
									!job.job_description_link ||
									!isValidURL(job.job_description_link)
								}
								helperText={
									!job.job_description_link
										? 'Job Description Link is required'
										: !isValidURL(job.job_description_link) &&
											'Please enter a valid URL'
								}
								onChange={(e) => {
									setDetails((prev) => {
										const newJobPostings = [...prev.jobPostings];
										newJobPostings[index].job_description_link = e.target.value;
										return {
											...prev,
											jobPostings: newJobPostings,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
							<TextField
								label={'Job FAQ Link'}
								value={job.faq_link}
								error={!job.faq_link || !isValidURL(job.faq_link)}
								helperText={
									!job.faq_link
										? 'Job FAQ Link is required'
										: !isValidURL(job.faq_link) && 'Please enter a valid URL'
								}
								onChange={(e) => {
									setDetails((prev) => {
										const newJobPostings = [...prev.jobPostings];
										newJobPostings[index].faq_link = e.target.value;
										return {
											...prev,
											jobPostings: newJobPostings,
										};
									});
								}}
								style={{
									width: '300px',
								}}
								required
							/>
						</Stack>
						<TextField
							label={'Job Description'}
							value={job.job_description}
							error={!job.job_description}
							helperText={!job.job_description && 'Job Description is required'}
							onChange={(e) => {
								setDetails((prev) => {
									const newJobPostings = [...prev.jobPostings];
									newJobPostings[index].job_description = e.target.value;
									return {
										...prev,
										jobPostings: newJobPostings,
									};
								});
							}}
							fullWidth
							multiline
							rows={4}
							required
						/>
						<TextField
							label={'Job Qualification'}
							value={job.qualifications}
							error={!job.qualifications}
							helperText={!job.qualifications && 'Job Qualifications is required'}
							onChange={(e) => {
								setDetails((prev) => {
									const newJobPostings = [...prev.jobPostings];
									newJobPostings[index].qualifications = e.target.value;
									return {
										...prev,
										jobPostings: newJobPostings,
									};
								});
							}}
							fullWidth
							multiline
							rows={4}
							required
						/>
						<TextField
							label={'Work Experience'}
							value={job.work_experience}
							error={!job.work_experience}
							helperText={!job.work_experience && 'Work Experience is required'}
							onChange={(e) => {
								setDetails((prev) => {
									const newJobPostings = [...prev.jobPostings];
									newJobPostings[index].work_experience = e.target.value;
									return {
										...prev,
										jobPostings: newJobPostings,
									};
								});
							}}
							fullWidth
							multiline
							rows={4}
							required
						/>
						<TextField
							label={'Skills Required'}
							value={job.skills_required}
							error={!job.skills_required}
							helperText={!job.skills_required && 'Skills are required'}
							onChange={(e) => {
								setDetails((prev) => {
									const newJobPostings = [...prev.jobPostings];
									newJobPostings[index].skills_required = e.target.value;
									return {
										...prev,
										jobPostings: newJobPostings,
									};
								});
							}}
							fullWidth
							multiline
							rows={4}
							required
						/>
						<TextField
							label={'Additional Requirements'}
							value={job.additional_requirements}
							// error={!job.skills_required}
							// helperText={
							//     !job.skills_required && "Skills are required"
							// }
							onChange={(e) => {
								setDetails((prev) => {
									const newJobPostings = [...prev.jobPostings];
									newJobPostings[index].additional_requirements = e.target.value;
									return {
										...prev,
										jobPostings: newJobPostings,
									};
								});
							}}
							fullWidth
							multiline
							rows={4}
							// required
						/>
					</Stack>
				);
			})}
			<Button
				style={{
					width: 'fit-content',
					color: '#224C84',
					textTransform: 'none',
					textDecoration: 'underline',
				}}
				onClick={() => {
					setDetails((prev) => ({
						...prev,
						jobPostings: [
							...prev.jobPostings,
							{
								job_role: '',
								type: '',
								job_description_link: '',
								faq_link: '',
								job_description: '',
								expected_ctc: '',
								//
								qualifications: '',
								work_experience: '',
								skills_required: '',
								additional_requirements: '',
								// job_responsibilities: "",
								// requirements: "",
							},
						],
					}));
				}}
			>
				Add More Job Postings
			</Button>
		</Stack>
	);
};

export default Job;
