import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, Input, TextField } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { API_URL } from '../../global/constants';
import { useAdminContext } from '../../global/AdminContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CustomizedStepper } from './CustomizedStepper';
import { CircularStatic } from '../CircularProgressWithLabel';
import { Header } from '../shared/Header';

const steps = ['Add Job Description', 'Add Candidate Details'];

export const CreateInterview = () => {
	const { admin } = useAdminContext();
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [generatingQuestions, setGeneratingQuestions] = useState(false);
	const [jobDetails, setJobDetails] = useState({});
	const [candidate, setCandidate] = useState({});
	const [candidateResume, setCandidateResume] = useState();
	const [jobInTextFormat, setJobInTextFormat] = useState();

	const handleJobStateChanges = (event) => {
		const { name, value } = event.target;
		setJobDetails((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleCandidateStateChanges = (event) => {
		const { name, value } = event.target;
		setCandidate((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleJobDescriptionChange = async (e) => {
		const files = e.target.files;
		var formdata = new FormData();
		formdata.append('pdfFile', files[0], ['PROXY']);

		await fetch(`${API_URL}/labs/extract-text`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${admin.token}`,
			},
			body: formdata,
		})
			.then((response) => response.text())
			.then((result) => {
				if (activeStep === 0) {
					setJobInTextFormat(result);
				}
			})
			.catch((error) => console.log('error', error));
	};

	const handleCandidateDetailsChange = async (e) => {
		const files = e.target.files;
		var formdata = new FormData();
		formdata.append('pdfFile', files[0], ['PROXY']);

		await fetch(`${API_URL}/labs/extract-text`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${admin.token}`,
			},
			body: formdata,
		})
			.then((response) => response.text())
			.then((result) => {
				setCandidateResume(result);
			})
			.catch((error) => console.log('error', error));
	};

	const handleSubmit = async () => {
		let isInfoUploadedInFiles = jobInTextFormat && candidateResume ? true : false;
		try {
			setGeneratingQuestions(true);
			const options = {
				headers: {
					Authorization: `Bearer ${admin.token}`,
					'Content-Type': 'application/json',
				},
			};
			const response = await axios.post(
				`${API_URL}/labs/questions/new`,
				{
					jobDetails: isInfoUploadedInFiles ? jobInTextFormat : jobDetails,
					candidate: isInfoUploadedInFiles ? candidateResume : candidate,
					isInfoUploadedInFiles,
				},
				options
			);

			navigate('/initial-questions', {
				state: {
					...response.data,
				},
			});
		} catch (error) {
			console.log(error);
		}
		setGeneratingQuestions(false);
	};

	const renderSteps = () => {
		switch (activeStep) {
			case 0:
				return (
					<>
						<Typography variant="h6" sx={{ fontFamily: 'Gordita' }}>
							Add Job Description
						</Typography>
						<div>
							<Input
								type="file"
								onChange={handleJobDescriptionChange}
								sx={{
									textTransform: 'none',
									width: '30vw',
									marginBottom: '16px',
									marginTop: '32px',
									fontFamily: 'Gordita',
								}}
							/>
						</div>
						{/* <input
              // className={classes.input}
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              hidden
            />
            <label htmlFor="raised-button-file">
              <Button variant="raised" component="span">
                Upload
              </Button>
            </label>  */}
						{/* <Input
              type="file"
              id="jd-file"
              onChange={(e) => handleJobDescriptionChange(e)}
              sx={{
                textTransform: "none",
                width: "30vw",
                marginBottom: "16px",
                marginTop: "32px",
                fontFamily: "Gordita",
              }}
            >
              <label htmlFor="jd-file">
                <Button variant="raised" component="span">
                  Upload
                </Button>
              </label>
              {/* {" "}
              <UploadFileIcon />{" "}
              <span style={{ marginLeft: "10px" }}>Upload File</span> */}
						{/* </Input> */}
						<Typography
							sx={{ color: '#333333', opacity: '0.5', fontFamily: 'Gordita' }}
						>
							or
						</Typography>
						<TextField
							id="job-role"
							label="Enter Job Role"
							name="job_role"
							value={jobDetails.job_role}
							onChange={(e) => handleJobStateChanges(e)}
							variant="outlined"
							sx={{
								width: '30vw',
								marginBottom: '32px',
								marginTop: '16px',
								fontFamily: 'Gordita',
							}}
						/>
						<textarea
							id="job-desc"
							rows="8"
							cols="40"
							placeholder="Enter Job Description"
							name="job_description"
							value={jobDetails.job_description}
							onChange={handleJobStateChanges}
							style={{
								width: '30vw',
								backgroundColor: 'inherit',
								border: '1px solid rgba(51, 51, 51, 0.5)',
								fontFamily: 'Gordita',
								padding: '20px',
								borderRadius: '4px',
							}}
						></textarea>
					</>
				);
			case 1:
				return (
					<>
						<Typography variant="h6" sx={{ fontFamily: 'Gordita' }}>
							Add Candidate Details
						</Typography>
						<Input
							id="candidate-resume"
							type="file"
							variant="outlined"
							onChange={(e) => handleCandidateDetailsChange(e)}
							sx={{
								textTransform: 'none',
								width: '30vw',
								marginBottom: '16px',
								marginTop: '32px',
								fontFamily: 'Gordita',
							}}
						>
							{' '}
							<UploadFileIcon />{' '}
							<span style={{ marginLeft: '10px' }}>Upload File</span>
						</Input>
						<Typography
							sx={{ color: '#333333', opacity: '0.5', fontFamily: 'Gordita' }}
						>
							or
						</Typography>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '20px',
								marginBottom: '32px',
								marginTop: '32px',
							}}
						>
							<TextField
								label="Candidate's First Name"
								name="first_name"
								variant="outlined"
								value={candidate.first_name}
								onChange={handleCandidateStateChanges}
								sx={{ width: '20vw', fontFamily: 'Gordita' }}
							/>
							<TextField
								label="Candidate's Last Name"
								variant="outlined"
								name="last_name"
								value={candidate.last_name}
								onChange={handleCandidateStateChanges}
								sx={{ width: '20vw', fontFamily: 'Gordita' }}
							/>
							<TextField
								label="Candidate's Phone Number"
								variant="outlined"
								name="contact"
								value={candidate.contact}
								onChange={handleCandidateStateChanges}
								sx={{ width: '20vw', fontFamily: 'Gordita' }}
							/>
							<TextField
								label="Candidate's Email"
								type="email"
								name="email"
								variant="outlined"
								value={candidate.email}
								onChange={handleCandidateStateChanges}
								sx={{ width: '20vw', marginBottom: '10px', fontFamily: 'Gordita' }}
							/>
						</div>
						<textarea
							name="resume"
							rows="8"
							value={candidate.resume}
							onChange={handleCandidateStateChanges}
							cols="40"
							placeholder="Enter Candidate's resume"
							style={{
								width: '30vw',
								backgroundColor: 'inherit',
								border: '1px solid rgba(51, 51, 51, 0.5)',
								fontFamily: 'Gordita',
								padding: '20px',
								borderRadius: '4px',
							}}
						></textarea>
					</>
				);
			case 2:
				return (
					<>
						<Typography sx={{ mt: 2, mb: 1 }}>
							All steps completed - you&apos;re finished
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								pt: 2,
								columnGap: '20px',
							}}
						>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button onClick={handleReset}>Reset</Button>
							<Button variant="contained" onClick={handleSubmit}>
								Continue Onwards
							</Button>
						</Box>
					</>
				);
			default:
				return <></>;
		}
	};

	const handleNext = () => {
		if (activeStep >= steps.length - 1) {
			handleSubmit();
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<>
			<Header />
			{generatingQuestions ? (
				<>
					<CircularStatic loadingText={'AI.HYR is generating questions for candidates'} />
				</>
			) : (
				<div style={{ marginTop: '2rem' }}>
					<CustomizedStepper activeStep={activeStep} />
					{/* ALL STEPS components RENDERED BY renderSteps() function here */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '2rem',
						}}
					>
						<Card
							sx={{
								height: 'auto',
								width: '90%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								paddingTop: '32px',
								paddingBottom: '50px',
							}}
						>
							{renderSteps()}
						</Card>
					</Box>
					{activeStep < steps.length && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								backgroundColor: '#ECF7FA',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									pt: 2,
									width: '30vw',
									backgroundColor: '#ECF7FA',
									paddingBottom: '50px',
									marginTop: '10px',
								}}
							>
								<Button
									disabled={activeStep === 0}
									onClick={handleBack}
									variant="outlined"
									sx={{ mr: 1 }}
								>
									Back
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button
									onClick={handleNext}
									disabled={activeStep >= steps.length}
									variant="outlined"
								>
									{activeStep >= steps.length - 1 ? 'Finish' : 'Next'}
								</Button>
							</Box>
						</Box>
					)}
				</div>
			)}
		</>
	);
};
