import { Box, Button, Modal, Stack, TextField, styled } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAdminContext } from '../../global/AdminContext';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import { InterviewInstructions } from '../InterviewInstructions';
import { Header } from '../shared/Header';
import styles from './login.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setInterviewDetails } from '../../features/interview/interview';
import { PROCTORING_TOOL } from '../../global/constants';
const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	height: '80%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export const Login = () => {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const { admin, setAdmin } = useAdminContext();
	const [loginType, setLoginType] = useState(state?.type || params?.type || null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [candidateCode, setCandidateCode] = useState();
	const [isLoading, setisLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isOpenInstructionsModal, setIsOpenInstructionsModal] = useState(false);

	useEffect(() => {
		if (admin.loading) return;
		if (admin.exists) {
			navigate('/login/company');
		}
	}, [admin, navigate]);

	const switchLoginType = (value) => {
		setLoginType(value);
	};

	const handleCompanyLogin = async () => {
		try {
			setErrorMessage('');
			setisLoading(true);
			if (!email) setErrorMessage('email cannot be empty');
			if (!password) setErrorMessage('password cannot be empty');
			const result = await axios.post(`${API_URL}/labs/login`, {
				email,
				password,
			});
			setisLoading(false);
			setAdmin({
				...result.data,
				loading: false,
				exists: true,
			});
			console.log(result.data, 'result.data');
			sessionStorage.setItem('admin', JSON.stringify(result.data));
			const roles = JSON.parse(sessionStorage.getItem('admin')).roles || [];
			console.log(roles, 'Roles');
			for (let role of roles) {
				if (role === 'college') {
					navigate('/college-admin');
					return;
				}
			}

			navigate('/dashboard/jobslist/company');
		} catch (error) {
			console.log(error);
			setErrorMessage(error.response.data.error);
			setisLoading(false);
		}
	};

	const generateCandidateDetails = async () => {
		setisLoading(true);
		const result = await axios.get(`${API_URL}/labs/interview/${candidateCode}`);
		console.log(result.data.error, 'Result.data.error');

		if (result?.data?.error) {
			setErrorMessage(result?.data?.message);
			setisLoading(false);
			return;
		}
		const { candidate_interview, fullName } = result.data;
		const { _id, proctoring_tool, job_id } = candidate_interview;
		if (proctoring_tool === PROCTORING_TOOL) {
			const jwtpayload = {
				candidateName: fullName,
				candidateId: _id,
				userType: 'candidate',
				organisationId: '78d87160-8408-472b-be40-e342daf666a3',
				sourceEventId: 'coding assesment',
				eventName: 'coding assesment',
				callBackURL: `${API_URL}/proctoring/details`,
				examDurationInMins: 45,
				iat: parseInt(Date.now() / 1000),
				exp: parseInt(Date.now() / 1000 + 12 * 60 * 60),
			};

			const response = await axios.post(`${API_URL}/proctoring/token`, jwtpayload);
			const interview_data = {
				candidate_interview,
				fullName,
				proctoring_token: response.data.token,
			};

			dispatch(setInterviewDetails(interview_data));
			return interview_data;
		} else {
			const interview_data = {
				candidate_interview,
				fullName,
			};

			dispatch(setInterviewDetails(interview_data));
			return interview_data;
		}
	};

	const startInterview = async () => {
		const interview_data = await generateCandidateDetails();
		if (!interview_data) return;
		const { fullName, isDemo, isStudentInterview, candidate_interview, proctoring_token } =
			interview_data;
		const {
			proctoring_tool,
			is_coding_test_included,
			_id: interview_key,
		} = candidate_interview;
		if (proctoring_tool === PROCTORING_TOOL) {
			if (is_coding_test_included) {
				navigate(`/coding-test/${fullName}/${interview_key}`);
			} else {
				navigate(`/interview`);
			}
		} else {
			navigate(`/check/${fullName}/${interview_key}`);
		}
	};

	const updateCandidateCode = (e) => {
		setCandidateCode(e?.target?.value.trim());
	};

	const openInstructionsModal = () => {
		setIsOpenInstructionsModal(true);
	};

	const handleInstructionsModalClose = () => {
		setIsOpenInstructionsModal(false);
	};
	// const styles = theme => ({
	//   notchedOutline: {
	//     borderWidth: "1px",
	//     borderColor: "yellow !important"
	//   }
	// });
	const CustomTextField = styled(TextField)({
		'& label.Mui-focused': {
			color: '#224C84',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#B2BAC2',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#E0E3E7',
			},
			'&:hover fieldset': {
				borderColor: '#B2BAC2',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#224C84',
			},
		},
	});
	return (
		<>
			<Header />
			<Stack direction="column">
				<div className={styles.loginPageContainer}>
					{!isLoading && (
						<>
							<Link to="/interview/practice/" color="#224C84">
								Take a Practice Interview
							</Link>
							<div>Or</div>
						</>
					)}
					{!isLoading && (
						<div className={styles.loginContainer}>
							{loginType === 'company' && (
								<div>
									<h4>Enter Admin Email and Password</h4>
									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										id="outlined-basic"
										label="Enter Email"
										type="email"
										className="input-box"
										variant="outlined"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										id="outlined-basic2"
										label="Enter Password"
										type="password"
										variant="outlined"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<Button
										sx={{
											marginBottom: '20px',
											textTransform: 'none',
											width: '100%',
											backgroundColor: '#224C84',
											'&:hover': { backgroundColor: '#224C84' },
										}}
										variant="contained"
										onClick={handleCompanyLogin}
									>
										Login
									</Button>
									<div
										onClick={() => switchLoginType('candidate')}
										onKeyDown={() => switchLoginType('candidate')}
									>
										<h4 className={styles.switchLoginLink}>
											Are you a candidate? Go to Candidate login
										</h4>
									</div>
								</div>
							)}
							{loginType === 'college' && (
								<div>
									<h4>Enter college Email and Password</h4>
									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										id="outlined-basic"
										label="Enter college Email"
										type="email"
										className="input-box"
										variant="outlined"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										id="outlined-basic2"
										label="Enter Password"
										type="password"
										variant="outlined"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<Button
										sx={{
											marginBottom: '20px',
											textTransform: 'none',
											width: '100%',
											backgroundColor: '#224C84',
											'&:hover': { backgroundColor: '#224C84' },
										}}
										variant="contained"
										onClick={() => navigate('/college-admin')}
									>
										Login
									</Button>
									{/* <div
                    onClick={() => switchLoginType("candidate")}
                    onKeyDown={() => switchLoginType("candidate")}
                  >
                    <h4 className={styles.switchLoginLink}>
                      Are you a candidate? Go to Candidate login
                    </h4>
                  </div> */}
								</div>
							)}
							{loginType === 'candidate' && !isLoading && (
								<div>
									<h4>
										Please read the{' '}
										<span
											style={{
												color: '#224C84',
												textDecoration: 'underline',
												cursor: 'pointer',
											}}
											onClick={openInstructionsModal}
											onKeyDown={openInstructionsModal}
										>
											Interview Instructions
										</span>{' '}
										carefully before proceeding.
									</h4>
									<h4>Enter Interview Key to start Interview</h4>

									<TextField
										sx={{ width: '100%', marginBottom: '20px' }}
										// id="outlined-basic"
										label="Enter Interview Key"
										variant="outlined"
										onChange={(e) => setCandidateCode(e?.target?.value.trim())}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<Button
										sx={{
											marginBottom: '20px',
											textTransform: 'none',
											width: '100%',
											backgroundColor: '#224C84',
											'&:hover': { backgroundColor: '#224C84' },
										}}
										variant="contained"
										onClick={startInterview}
									>
										Start Interview
									</Button>
									<div
										onClick={() => switchLoginType('company')}
										onKeyDown={() => switchLoginType('company')}
									>
										<h4 className={styles.switchLoginLink}>
											Not a candidate? Go to company login
										</h4>
									</div>
								</div>
							)}
						</div>
					)}
					{isLoading && (
						<div>
							<CircularStatic />
						</div>
					)}
					{isOpenInstructionsModal && (
						<Modal
							open={isOpenInstructionsModal}
							onClose={handleInstructionsModalClose}
							aria-labelledby="instructions-modal-title"
							aria-describedby="instructions-modal-description"
						>
							<Box sx={modalStyle}>
								<InterviewInstructions />
							</Box>
						</Modal>
					)}
				</div>
			</Stack>
		</>
	);
};
