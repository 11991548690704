import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { API_URL } from '../../global/constants';
import LoadingDialog from '../CodeEditor/LoadingDialog';

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

const CandidateDetailDialog = ({ open, setOpen, details, setDetails, setResumeData }) => {
	const [loading, setLoading] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const updateChanges = async () => {
		setLoading(true);
		const { data } = await axios.put(`${API_URL}/labs/resume/update`, details, {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
			},
		});
		if (data.success) {
			toast.success(data.message);
			setResumeData((prev) => {
				return prev.map((item) => {
					if (item.resume_id === details.resume_id) {
						return { ...item, ...details };
					}
					return item;
				});
			});
			setDetails({
				first_name: '',
				last_name: '',
				email: '',
				contact: '',
			});
			handleClose();
		} else {
			toast.error(data.message);
		}
		setLoading(false);
	};
	return (
		<StyledDialog open={open}>
			<LoadingDialog open={loading} />
			<DialogTitle sx={{ m: 0, p: 2 }}>Candidate Details</DialogTitle>
			<IconButton
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<Stack direction={'column'} spacing={2}>
					<Stack direction={'row'} spacing={2}>
						<TextField
							label="First Name"
							size="small"
							variant="outlined"
							value={details.first_name}
							onChange={(e) => {
								setDetails({
									...details,
									first_name: e.target.value,
								});
							}}
						/>
						<TextField
							label="Last Name"
							size="small"
							variant="outlined"
							value={details.last_name}
							onChange={(e) => {
								setDetails({
									...details,
									last_name: e.target.value,
								});
							}}
						/>
					</Stack>
					<TextField
						label="Email"
						size="small"
						variant="outlined"
						value={details.email}
						onChange={(e) => {
							setDetails({
								...details,
								email: e.target.value,
							});
						}}
						fullWidth
					/>
					<TextField
						label="Contact"
						size="small"
						variant="outlined"
						value={details.contact}
						onChange={(e) => {
							setDetails({
								...details,
								contact: e.target.value,
							});
						}}
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={updateChanges}>Save changes</Button>
			</DialogActions>
		</StyledDialog>
	);
};

export default CandidateDetailDialog;
