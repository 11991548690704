import React from 'react';
import Contact from './Contact';
import { Header } from '../../shared/Header';

const ContactPage = () => {
	return (
		<>
			<Header />
			<br></br>
			<br></br>
			<br></br>
			<Contact />
		</>
	);
};

export default ContactPage;
