import React, { useEffect, useState } from 'react';
import desktopBackgroundVideo from '../images/Desktop-background.mp4';
import mobileBackgroundVideo from '../images/Mobile-background.mp4';
import { Button } from '@mui/material';
import './mockinterview.css';
import {
	GoogleOAuthProvider,
	googleLogout,
	useGoogleLogin,
	GoogleLogin,
} from '@react-oauth/google';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';

const CalltoAction = () => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [bgVideo, setBgVideo] = useState(desktopBackgroundVideo);
	const navigate = useNavigate();

	useEffect(() => {
		if (isMobile) {
			setBgVideo(mobileBackgroundVideo);
		} else {
			setBgVideo(desktopBackgroundVideo);
		}
	}, [isMobile]);

	const responseMockInterview = async (response) => {
		console.log(response);
		const accessToken = response.access_token || response.credential;
		// var user_details = jwt_decode(response.access_token);
		const user_details = await fetchUserDetailsFromBackend(accessToken);

		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data, 'Data');
		console.log(data.success, 'data success');
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			sessionStorage.setItem('student_interview_access', data.has_interview_access);
			sessionStorage.setItem('mock-interview', 'Yes');
			console.log('complete_profile', data.complete_profile);
			if (data.complete_profile) {
				navigate('/student/mock-interview/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/mock-interview/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};

	const errorMessage = (error) => {
		console.log(error);
		toast.error('Something went wrong, Try Again!!');
	};

	const login = useGoogleLogin({
		onSuccess: (response) => {
			responseMockInterview(response); // Proceed with your logic
		},
		onError: errorMessage,
	});

	const fetchUserDetailsFromBackend = async (accessToken) => {
		try {
			// Replace '/api/auth/google' with your backend API endpoint
			const response = await fetch(`${API_URL}/labs/api/auth/google`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ token: accessToken }),
			});

			if (!response.ok) {
				throw new Error('Failed to fetch user details from backend');
			}

			// Parse the response from the backend
			const data = await response.json();
			return data; // This should return user details like name, email, etc.
		} catch (error) {
			console.error('Error in fetchUserDetailsFromBackend:', error.message);
			return null;
		}
	};

	return (
		<div
			id="calltoaction"
			style={{
				// backgroundColor: '#224C84',
				// color: 'white',
				height: isMobile ? '90vh' : '100vh',
				textAlign: 'center',
				justifyContent: 'center',
				overflow: 'hidden',
				position: 'relative',
			}}
		>
			<div
				style={{
					display: isMobile ? 'none' : '',
					position: 'absolute',
					top: -10,
					width: '100%',
					height: '20vh', // Adjust as needed
					background:
						'linear-gradient(to bottom, rgba(49, 86, 134, 0.9) 0%, rgba(255, 0, 0, 0) 100%)', // Adjusted gradient
					zIndex: '3',
				}}
			/>
			<video
				autoPlay
				loop
				muted
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100dvw',
					minHeight: '100dvh',
					objectFit: 'cover',
					objectPosition: 'center',
					zIndex: '0',
				}}
			>
				<source src={bgVideo} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<div
				className="container"
				style={{
					display: 'flex',
					height: '100%',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					margin: 'auto',
					// maxWidth: '700px',
					width: '100%',
					position: 'relative',
					zIndex: '1',
					color: 'white',
					background: 'rgba(49, 86, 134, 0.7)',
					backdropFilter: 'blur(10px)',
					WebkitBackdropFilter: 'blur(10px)',
				}}
			>
				<div
					data-aos="fade-up"
					data-aos-duration="1400"
					data-aos-delay="500"
					style={{
						maxWidth: isMobile ? '100vw' : '100vw',

						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '20px',
						margin: '7vw',
					}}
				>
					{isMobile ? (
						<>
							<h1
								style={{
									fontSize: '1.5rem',
									fontWeight: 'bold',
									marginBottom: 0,
								}}
							>
								Opportunity’s about to knock – make sure you’re ready to answer!{' '}
							</h1>
							<h1
								style={{
									fontSize: '1.5rem',
									fontWeight: 'bold',
									marginBottom: 0,
								}}
							>
								Practice your mock interviews with AI.HYR today!
							</h1>
						</>
					) : (
						<>
							<h1>
								Opportunity’s about to knock – make sure you’re ready to answer!{' '}
							</h1>
							<h1>Practice your mock interviews with AI.HYR today!</h1>
						</>
					)}
					<p>Get started now and ace your next interview with confidence</p>
					<div
						className="container"
						style={{ marginTop: '25px', position: 'relative' }}
						data-aos="fade-up"
						data-aos-duration="1400"
						data-aos-delay="500"
					>
						<div className="bg"></div>
						<Button
							size="large"
							onClick={() => login()}
							sx={{
								width: '300px',
								height: '50px',
								fontFamily: 'Poppins',
								textTransform: 'none',
								// position: 'absolute',
								// backgroundColor: "#224C84",
								backgroundColor: '#FFC107',
								fontSize: '1.2rem',
								color: '#224C84',
								borderRadius: '10px',
								'&:hover': {
									backgroundColor: '#FFC107',
									// border: "2px solid",
									// borderColor: "#FFC107",
									color: '#224C84',
								},
							}}
							className="play-btn"
						>
							Get Started!
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CalltoAction;
