import React from 'react';
import { Header } from '../shared/Header';
import { GoogleLogin } from '@react-oauth/google';
import { Stack } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { API_URL } from '../../global/constants';
import { useNavigate } from 'react-router-dom';

const StudentLogin = () => {
	// const [user, setUser] = useState(null);
	// const [profile, setProfile] = useState(null);
	const navigate = useNavigate();
	const responseMessage = async (response) => {
		console.log(response);
		var user_details = jwt_decode(response.credential);
		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data);
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			if (data.complete_profile) {
				navigate('/student/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/mock-interview/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};
	const errorMessage = (error) => {
		console.log(error);
		toast.error('Something went wrong, Try Again!!');
	};

	return (
		<>
			<Toaster />
			<Header />
			<Stack m={2} justifyContent={'center'} alignItems={'center'}>
				<GoogleLogin onSuccess={responseMessage} onError={errorMessage} useOneTap />
			</Stack>
		</>
	);
};

export default StudentLogin;
