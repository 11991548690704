import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';

const FAQs = () => {
	const faqs = [
		{
			question: 'What is a AI.HYR mock interviews?',
			answer: 'AI.HYR simulates interview experience using human-like AI bots. We offer structured interviews for different roles and technologies. You can take these interviews anytime, anywhere and get the performance report immediately.',
		},
		{
			question: 'How do mock interviews help in job preparation?',
			answer: 'They allow you to practice answering typical interview questions, receive feedback on your performance, and identify areas for improvement.',
		},
		{
			question: 'What types of interviews can I practice?',
			answer: 'You can practice technical interviews (e.g., front-end, back-end development, data science), behavioral interviews, or industry-specific interviews.',
		},
		{
			question: 'How are mock interviews conducted?',
			answer: 'Mock interviews can be conducted online with an AI interviewer (human-like bot). Our platform provides automated feedback based on your responses.',
		},
		{
			question: 'How is feedback provided after a mock interview?',
			answer: 'We use LLMs and custom algorithms to transcribe and analyze your answers. Based on the analysis, the platform generates strong points, weak points, and recommended answers for each attempted question.',
		},
		{
			question: 'How soon my interview report will be generated?',
			answer: 'Generally, the report is generated instantly but the maximum timeline of report update is 24 working hours.',
		},
		{
			question: 'How long does a typical mock interview last?',
			answer: 'The duration varies, but a typical mock interview lasts between 20 to 30 minutes, depending on the role and the type of questions asked.',
		},
		{
			question: 'Can I schedule mock interviews for different job roles?',
			answer: 'Yes, you can choose to practice for various roles like software development, data science, marketing, and more, depending on your career goals.',
		},
		{
			question: 'What kind of questions are asked in mock interviews?',
			answer: 'Mock interviews include role-specific technical questions, problem-solving scenarios, and common behavioral questions to simulate real-life interviews.',
		},
		{
			question: 'How often should I take mock interviews?',
			answer: 'It depends on your preparation level, but taking mock interviews once a week is a good starting point to consistently track and improve your performance.',
		},
		{
			question: 'Can I review my previous mock interviews?',
			answer: 'Yes, our platform allows you to review recordings of past interviews, along with detailed feedback and score progress over time.',
		},
		{
			question: 'How are my skills assessed during the mock interview?',
			answer: 'Skills are assessed based on your responses, including technical accuracy, problem-solving, communication, and overall interview performance.',
		},
		{
			question: 'Will mock interviews guarantee job success?',
			answer: 'While mock interviews provide valuable preparation, success in a real interview depends on multiple factors, including the actual job requirements and your performance.',
		},
	];
	const isMobile = useMediaQuery('(max-width:600px)');

	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div
			style={{
				// background: 'rgba(211, 211, 211, 0.2)',
				background: 'white',
				color: '#224C84',
				minHeight: '100vh',
				display: 'flex',
				margin: 'auto',
				flexDirection: 'column',
				textAlign: 'center',
				justifyContent: 'center',
				alignItems: 'start',
				position: 'relative',
				marginBottom: '70px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					margin: 'auto',
					maxWidth: isMobile ? '80%' : '70%',
					position: 'relative',
					top: '3rem',
					color: '#224C84',
				}}
			>
				<h1
					style={{
						fontSize: isMobile ? '2.3rem' : '3rem',
						fontWeight: 'bold',
						marginBottom: 30,
					}}
				>
					Frequently asked questions
				</h1>
				<div
					style={{
						textAlign: 'start',
					}}
				>
					{faqs.map((faq, index) => (
						<Accordion
							expanded={expanded === `panel${index + 1}-header`}
							onChange={handleChange(`panel${index + 1}-header`)}
							key={index}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`panel${index + 1}-content`}
								id={`panel${index + 1}-header`}
								style={{
									color: '#224C84',
									fontWeight: 'bold',
								}}
							>
								{faq.question}
							</AccordionSummary>
							<AccordionDetails
								style={{
									color: '#224C84',
								}}
							>
								{faq.answer}
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			</div>
		</div>
	);
};

export default FAQs;
