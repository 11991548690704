import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import options from './dropdownoptions.json';
import { useState } from 'react';
const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
	'& .MuiFilledInput-root': {
		backgroundColor: theme.palette.mode === 'dark' ? '#212121' : '#f5f5f5',
	},
	'& .MuiChip-root': {
		margin: theme.spacing(0.5),
		backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#e0e0e0',
		color: theme.palette.mode === 'dark' ? '#e8e8e8' : '#000000',
		'& .MuiChip-deleteIcon': {
			color: theme.palette.mode === 'dark' ? '#ffffff' : '#9f9fa2',
		},
	},
	'& .MuiAutocomplete-inputRoot': {
		minWidth: 200,
	},
}));
function getFormattedLabel(string, isrequired) {
	const newstring = string
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
	console.log(newstring);

	if (newstring === 'Musthaveskills') {
		return 'Must Have Skills *';
	}
	if (newstring === 'Additionalskills') {
		return 'Additional Skills';
	}
	return newstring;
}
function CustomizedAutocomplete({ field, state, handleChange, isrequired }) {
	// default value
	const dropdownoptions = options[field] || [];
	const [istouched, setIstouched] = useState(false);
	return (
		<>
			<CustomAutocomplete
				multiple
				id="tags-filled"
				options={dropdownoptions.map((option) => option)}
				value={state[field]}
				onChange={handleChange(field)}
				freeSolo
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							key={index}
							variant="outlined"
							label={option}
							onDelete={() => {
								const newValue = [...value];
								newValue.splice(index, 1);
							}}
							{...getTagProps({ index })}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="filled"
						label={getFormattedLabel(field, isrequired)}
						placeholder="Choose or type"
						// required={isrequired && istouched && state[field].length === 0}
						required={isrequired}
						onFocus={() => setIstouched(true)}
					/>
				)}
			/>
		</>
	);
}

export default CustomizedAutocomplete;
