import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ResetCodeDialog = ({ open, setOpen, handleResetCode }) => {
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{'Are you sure you want to reset the code?'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					This will reset the code to the original code provided in the question.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					onClick={() => {
						handleResetCode();
						handleClose();
					}}
				>
					Reset
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ResetCodeDialog;
