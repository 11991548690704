import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import astronut from '../images/astronut.gif';
import CallIcon from '@mui/icons-material/Call';

const Services = () => {
	return (
		<Stack
			id="product"
			display="flex"
			// height={"100vh"}
			justifyContent={{ sm: 'start', xs: 'center' }}
			// alignItems={"center"}
			direction={'row'}
			spacing={10}
			p={2}
			pt={5}
			pb={5}
		>
			<Stack
				p={2}
				spacing={3}
				display={'flex'}
				alignItems={'center'}
				ml={{ sm: 15, xs: 0 }}
				mr={{ sm: 15, xs: 0 }}
			>
				<Typography
					width={{ xs: '310px', sm: '100%' }}
					m={{ sm: 1, xs: 0 }}
					fontFamily={'Poppins'}
					fontWeight={'700'}
					fontSize={{ sm: '3.2rem', xs: '1.7rem' }}
					color={'#224C84'}
					textAlign={'center'}
					p={{ sm: 3, xs: 2 }}
					style={{
						backgroundColor: 'rgb(222, 229, 240, 0.8)',
						borderRadius: '20px',
					}}
					data-aos="zoom-in"
					data-aos-duration="1000"
					data-aos-delay="300"
				>
					Empower your crew
					<br /> from onboarding to
					<br /> offboarding, gain
					<br />
					valuable insights
				</Typography>
				<Stack direction={'row'} spacing={2} justifyContent={'center'}>
					<Stack
						alignItems={'center'}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'3rem'}>🤝</Typography>
						<Typography textAlign={'center'} fontWeight={'bold'} color={'#224C84'}>
							Hiring Interviews
						</Typography>
					</Stack>
					<Stack
						alignItems={'center'}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'3rem'}>👍</Typography>
						<Typography textAlign={'center'} fontWeight={'bold'} color={'#224C84'}>
							Learning need
							<br /> assessment & <br />
							Feedback Interviews
						</Typography>
					</Stack>
					<Stack
						alignItems={'center'}
						data-aos="zoom-in"
						data-aos-duration="1000"
						data-aos-delay="300"
					>
						<Typography fontSize={'3rem'}>🖖</Typography>
						<Typography textAlign={'center'} fontWeight={'bold'} color={'#224C84'}>
							Exit Interviews
						</Typography>
					</Stack>
				</Stack>
				<Button
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="300"
					variant="contained"
					onClick={() => (window.location.href = 'https://meetings.hubspot.com/nrohatgi')}
					size="large"
					startIcon={<CallIcon fontSize="large" />}
					style={{
						// width:"100%",
						backgroundColor: '#224C84',
						fontFamily: 'Poppins',
						fontSize: '1.2rem',
						textTransform: 'none',
						borderRadius: '15px',
					}}
				>
					Talk to us
				</Button>
			</Stack>
			<Stack
				// width={"30%"}
				display={{ xs: 'none', sm: 'flex' }}
				justifyContent={'start'}
				alignItems={'center'}
				p={3}
				data-aos="fade-up"
				data-aos-duration="1200"
				data-aos-delay="300"
			>
				<img
					src={astronut}
					alt="astronut"
					width={'300px'}
					style={{
						borderRadius: '20px',
					}}
				/>
			</Stack>
		</Stack>
	);
};

export default Services;
