import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CircularStatic } from '../CircularProgressWithLabel';
import { API_URL } from '../../global/constants';
import { setInterviewDetails } from '../../features/interview/interview';
import { PROCTORING_TOOL } from '../../global/constants';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import {
	Container,
	Typography,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
} from '@mui/material';
import { styled } from '@mui/system';

// Styled components
const FullHeightContainer = styled(Container)({
	minHeight: '100vh',
	padding: '0',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundImage: 'url("/Career-Fair-background-image.png")', // Replace with the actual path to your background image
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	position: 'relative',
	'&::after': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better text visibility
		zIndex: 1, // Ensure the overlay is under the content
	},
});

const LogoContainer = styled('div')({
	position: 'absolute',
	top: '25px',
	left: '7%',
	transform: 'translateX(-50%)',
	zIndex: 2, // Ensure the logo is above the overlay
});

const ContentWrapper = styled('div')({
	position: 'relative',
	zIndex: 2, // Ensure the content is above the overlay
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	maxWidth: '90%',
	width: '100%',
	padding: '20px',
	gap: '40px',
});

const FormContainer = styled('div')({
	flex: '1 1 35%',
	backgroundColor: 'white',
	padding: '40px',
	borderRadius: '10px',
	boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
	boxSizing: 'border-box',
});

// New styled component for text content
const TextContent = styled('div')({
	flex: '1 1 55%',
	textAlign: 'left',
	color: '#ffffff',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
	padding: '10px',
	boxSizing: 'border-box',
});

export function CareerFairLogin() {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [gender, setGender] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const generateCandidateDetails = async (candidateCode) => {
		setIsLoading(true);
		try {
			let result = '';
			if (sessionStorage.getItem('mock-interview')) {
				result = await axios.get(`${API_URL}/labs/mock-interview/${candidateCode}`);
			} else {
				result = await axios.get(`${API_URL}/labs/interview/${candidateCode}`);
			}

			if (result?.data?.error) {
				setErrorMessage(result?.data?.message);
				setIsLoading(false);
				return null;
			}
			const { candidate_interview, fullName } = result.data;
			const interview_data = { candidate_interview, fullName };
			if (candidate_interview.proctoring_tool === PROCTORING_TOOL) {
				const jwtpayload = {};
				const response = await axios.post(`${API_URL}/proctoring/token`, jwtpayload);
				interview_data.proctoring_token = response.data.token;
			}

			dispatch(setInterviewDetails(interview_data));
			return interview_data;
		} catch (error) {
			console.error('Error generating candidate details:', error);
			setErrorMessage('Failed to load interview data');
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	const startInterview = async () => {
		sessionStorage.setItem('careerfairinterview', 'yes');

		setIsLoading(true);
		setErrorMessage(null);

		try {
			await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

			const response = await axios.post(`${API_URL}/labs/create-career-fair-interview`, {
				email,
				name,
				gender,
			});

			if (response.data.success) {
				const interviewKey = response.data.interview_key;
				const state = { interviewKey, fullName: name };
				await generateCandidateDetails(interviewKey);
				navigate(`/interview`, { state });
			} else {
				setErrorMessage('Failed to start the interview. Please try again.');
			}
		} catch (error) {
			console.error('Error during permissions or API call:', error);
			setErrorMessage('Error starting the interview. Ensure you have granted permissions.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FullHeightContainer maxWidth={false}>
			{/* Logo at the top center */}
			<LogoContainer>
				<img src="/logo.png" alt="AI.HYR Logo" style={{ height: '60px' }} />
			</LogoContainer>

			{!isLoading && (
				<ContentWrapper>
					{/* Left side text content */}
					<TextContent>
						<Typography
							variant="h2"
							gutterBottom
							sx={{ fontWeight: 'bold', marginBottom: '30px' }}
						>
							Step into the future with AI-powered interviews!
						</Typography>
						<Typography variant="body1" sx={{ fontSize: '1.5rem' }}>
							Experience cutting-edge AI interviews that bring your skills to life.
							Discover how AI redefines your interview experience with precision and
							unmatched convenience.
						</Typography>
					</TextContent>

					{/* Right side - Form */}
					<FormContainer>
						<Typography variant="h5" gutterBottom align="center">
							Start an AI interview
						</Typography>
						<form onSubmit={startInterview}>
							<TextField
								fullWidth
								label="Name"
								variant="outlined"
								margin="normal"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
								error={!!errorMessage}
								helperText={errorMessage}
								style={{ marginBottom: '15px' }}
							/>
							<TextField
								fullWidth
								label="Email"
								type="email"
								variant="outlined"
								margin="normal"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								error={!!errorMessage}
								helperText={errorMessage}
								style={{ marginBottom: '20px' }}
							/>
							<RadioGroup
								value={gender}
								onChange={(e) => setGender(e.target.value)}
								row
								style={{ marginBottom: '20px' }}
							>
								<FormControlLabel value="male" control={<Radio />} label="Male" />
								<FormControlLabel
									value="female"
									control={<Radio />}
									label="Female"
								/>
							</RadioGroup>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									size="large"
									sx={{ mt: 2 }}
								>
									START INTERVIEW
								</Button>
							</div>
						</form>
					</FormContainer>
				</ContentWrapper>
			)}
			{isLoading && (
				<div>
					<CircularStatic />
				</div>
			)}
		</FullHeightContainer>
	);
}
