import {
	Box,
	Button,
	DialogContent,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import Divider from '@mui/material/Divider';
import blue_logo from '../images/blue-logo.png';
import white_logo from '../images/white_logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import bgimg from '../images/bg-img.png';
import bgimgmobile from '../images/bg-mobile.png';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BusinessIcon from '@mui/icons-material/Business';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import Dialog from '@mui/material/Dialog';
const HeroSection = () => {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		// width: 400,
		// bgcolor: "background.paper",
		// border: "2px solid #000",
		boxShadow: 24,
		borderRadius: '20px',
		// p: 4,
	};
	const isMobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const isInViewport = (element) => {
		const rect = element.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		return rect.top < windowHeight && rect.bottom > windowHeight / 1.2;
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorEl2, setAnchorEl2] = React.useState(null);
	const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
	const [openMockDialog, setOpenMockDialog] = useState(false);
	const [logo, setLogo] = useState(white_logo);
	const [bgColor, setBgColor] = useState('');
	const [bgImg, setBgImg] = useState('');
	const [textColor, setTextColor] = useState('#fff');
	const open = Boolean(anchorEl);
	const open2 = Boolean(anchorEl);
	const loginRef = React.useRef(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickScroll = (id) => {
		const element = document.getElementById(id);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({ behavior: 'smooth' });
		}
		handleClose();
	};
	const [modalOpen, setModalOpen] = React.useState(false);
	// const handleOpen = () => setOpen(true);
	// const handleClose = () => setOpen(false);
	useEffect(() => {
		const element = document.getElementById('main-page');
		window.addEventListener(
			'scroll',
			() => {
				if (!isInViewport(element)) {
					setLogo(blue_logo);
					setBgColor('#fff');
					setTextColor('#224C84');
				} else {
					setLogo(white_logo);
					setBgColor('');
					setTextColor('#fff');
				}
			},
			false
		);
	}, []);
	useEffect(() => {
		if (isMobile) {
			setBgImg(bgimgmobile);
		} else {
			setBgImg(bgimg);
		}
	}, [isMobile]);
	// const navigate = useNavigate();

	const responseMockInterview = async (response) => {
		console.log(response);
		var user_details = jwt_decode(response.credential);
		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data, 'Data');
		console.log(data.success, 'data success');
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			sessionStorage.setItem('student_interview_access', data.has_interview_access);
			sessionStorage.setItem('mock-interview', 'Yes');
			console.log('complete_profile', data.complete_profile);
			if (data.complete_profile) {
				navigate('/student/mock-interview/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/mock-interview/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};

	const responseMessage = async (response) => {
		console.log(response);
		var user_details = jwt_decode(response.credential);
		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data, 'Data');
		console.log(data.success, 'data success');
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			sessionStorage.setItem('student_interview_access', data.has_interview_access);
			console.log('complete_profile', data.complete_profile);
			if (data.complete_profile) {
				navigate('/student/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};
	const errorMessage = (error) => {
		console.log(error);
		toast.error('Something went wrong, Try Again!!');
	};
	// const login = useGoogleLogin({
	//     onSuccess: responseMessage,
	//     onError: errorMessage,
	//     flow: "auth-code",
	// });
	return (
		<Stack
			id="main-page"
			height={'100vh'}
			// justifyContent={"center"}

			alignItems={'center'}
			style={{
				backgroundImage: `url(${bgImg})`,
				transition: 'all 1s ease-in-out',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				// opacity:0.8x
				// position: "relative",
			}}
		>
			<Toaster />
			<Dialog open={openLoginDialog} onClose={() => setOpenLoginDialog(false)}>
				<DialogContent>
					<GoogleLogin
						onSuccess={responseMessage}
						onError={errorMessage}
						// useOneTap
						// type="standard"
						// shape="pill"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={openMockDialog} onClose={() => setOpenMockDialog(false)}>
				<DialogContent>
					<GoogleLogin
						onSuccess={responseMockInterview}
						onError={errorMessage}
						// useOneTap
						// type="standard"
						// shape="pill"
					/>
				</DialogContent>
			</Dialog>

			<Stack
				id="home"
				// bgcolor={"white"}
				// height={"100vh"}
				zIndex={2}
			>
				<Stack
					direction={'row'}
					justifyContent={'end'}
					p={1}
					spacing={1}
					position={'fixed'}
					top={0}
					right={0}
					width={'100vw'}
					zIndex={2}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="500"
					// style={{ backdropFilter: "blur(5px)" }}
					bgcolor={bgColor}
				>
					<img
						src={logo}
						height={'40px'}
						alt="logo"
						style={{
							position: 'fixed',
							left: '40px',
							top: '15px',
						}}
					/>
					{isMobile && (
						<>
							<IconButton
								onClick={handleClick}
								size="large"
								style={{
									color: `${textColor}`,
								}}
								// sx={{ ml: 2 }}
							>
								<MenuIcon fontSize="medium" />
							</IconButton>
							<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
								<MenuItem
									onClick={() => handleClickScroll('home')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <HomeIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Home
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScroll('benefits')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <StarBorderPurple500Icon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Benefits
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScroll('testimonials')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <MessageIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Testimonials
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScroll('product')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <BoltIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Product
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScroll('features')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <SettingsIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Features
								</MenuItem>
								<MenuItem
									onClick={() => navigate('/contact')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <EmailIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Contact
								</MenuItem>
								<MenuItem
									onClick={() => navigate('/mock-interview')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									Mock Interviews
								</MenuItem>
								<Divider />
								{/* <MenuItem onClick={() => setOpenLoginDialog(true)}>
									<Stack>
										<Typography color="#224C84" fontWeight={600}>
											For Students
										</Typography>
										<Typography fontSize={'0.9rem'} color="#cccccc">
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem> */}
								<MenuItem onClick={() => setOpenMockDialog(true)}>
									<Stack>
										<Typography color="#224C84" fontWeight={600}>
											For Mock Interviews
										</Typography>
										<Typography fontSize={'0.9rem'} color="#cccccc">
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem>
								<MenuItem onClick={() => navigate('/login/candidate')}>
									<Stack>
										<Typography color="#224C84" fontWeight={600}>
											For Company Interviews
										</Typography>
										<Typography fontSize={'0.9rem'} color="#cccccc">
											Give company interviews
										</Typography>
									</Stack>
								</MenuItem>

								<MenuItem
									onClick={() => navigate('/login/company')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									For Companies
								</MenuItem>
							</Menu>
						</>
					)}
					{!isMobile && (
						<>
							<Button
								onClick={() => handleClickScroll('home')}
								// startIcon={<HomeIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Home
							</Button>
							<Button
								onClick={() => handleClickScroll('benefits')}
								// startIcon={<StarBorderPurple500Icon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Benefits
							</Button>
							<Button
								onClick={() => handleClickScroll('testimonials')}
								// startIcon={<MessageIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Testimonials
							</Button>
							<Button
								onClick={() => handleClickScroll('product')}
								// startIcon={<BoltIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Product
							</Button>
							<Button
								onClick={() => handleClickScroll('features')}
								// startIcon={<SettingsIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Features
							</Button>
							<Button
								onClick={() => navigate('/contact')}
								// startIcon={<EmailIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Contact
							</Button>
							<Button
								onClick={() => navigate('/mock-interview')}
								style={{
									color: '#224C84',
									fontWeight: 600,
									textTransform: 'none',
									backgroundColor: '#FFC107',
									margin: '0 10px',
									padding: '5px 10px',
								}}
							>
								Mock Interviews
							</Button>
						</>
					)}
					{!isMobile && (
						<>
							{/* <Divider orientation="vertical" sx={{
                            height:"40px",
                            width:"3px"
                        }}/> */}
							<Button
								variant="outlined"
								size="large"
								// onClick={() => navigate("/login/candidate")}
								onClick={(event) => setAnchorEl2(event.currentTarget)}
								style={{
									// backgroundColor: "#D2ECF6",
									color: `${textColor}`,
									borderColor: `${textColor}`,
									border: '2px solid',
									borderRadius: '10px',
									textTransform: 'none',
									fontWeight: '600',
								}}
								startIcon={<PeopleOutlineIcon />}
							>
								For Interviewees
							</Button>

							<Menu
								id="menu-appbar"
								anchorEl={anchorEl2}
								open={Boolean(anchorEl2)}
								onClose={() => setAnchorEl2(null)}
							>
								{/* <MenuItem
								// onClick={() => {
								//     setOpenLoginDialog(true);
								// }}
								>
									<Stack justifyContent={'center'} alignItems={'left'} rowGap={2}>
										<Typography
											fontWeight={'bold'}
											color={'#224C84'}
											fontSize={'1rem'}
										>
											For Students
										</Typography>
										<GoogleLogin
											onSuccess={responseMessage}
											onError={errorMessage}
											// useOneTap
											// type="standard"
											// shape="pill"
										/>
										<Typography color={'#cccccc'} fontSize={'0.7rem'}>
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem> */}
								{/* <Divider /> */}
								<MenuItem
								// onClick={() => {
								//     setOpenLoginDialog(true);
								// }}
								>
									<Stack justifyContent={'center'} alignItems={'left'} rowGap={2}>
										<Typography
											fontWeight={'bold'}
											color={'#224C84'}
											fontSize={'1rem'}
										>
											For Mock Interiews
										</Typography>
										<GoogleLogin
											onSuccess={responseMockInterview}
											onError={errorMessage}
											// useOneTap
											// type="standard"
											// shape="pill"
										/>
										<Typography color={'#cccccc'} fontSize={'0.7rem'}>
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem>
								<Divider />
								<MenuItem onClick={() => navigate('/login/candidate')}>
									<Stack justifyContent={'center'} alignItems={'left'}>
										<Typography
											fontWeight={'bold'}
											color={'#224C84'}
											fontSize={'1rem'}
										>
											For Company Interviews
										</Typography>
										<Typography color={'#cccccc'} fontSize={'0.7rem'}>
											Give company interviews
										</Typography>
									</Stack>
								</MenuItem>
							</Menu>
							<Button
								variant="outlined"
								size="large"
								onClick={() => navigate('/login/company')}
								style={{
									color: `${textColor}`,
									borderColor: `${textColor}`,
									border: '2px solid',
									borderRadius: '10px',
									textTransform: 'none',
									fontWeight: '600',
								}}
								startIcon={<BusinessIcon />}
							>
								For Companies
							</Button>
						</>
					)}
				</Stack>
			</Stack>
			<Stack
				justifyContent={'center'}
				alignItems={'center'}
				mt={{ xs: '20vh', sm: '25vh' }}
				// mt={"10vh"}
				// style={{
				//     marginTop: "25vh",
				// }}
				spacing={2}
			>
				<h1
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="500"
					style={{
						marginLeft: 2,
						marginRight: 2,
						marginBottom: 0,
						marginTop: 0,
						zIndex: 2,
						color: '#fff',
						fontWeight: 900,
						fontSize: isMobile ? '2.3rem' : '4.8rem',
						textAlign: 'center',
					}}
				>
					Lightning fast AI interviews
				</h1>
				<Typography
					data-aos="fade-up"
					data-aos-duration="1200"
					data-aos-delay="500"
					fontSize={{ sm: '1.4rem', xs: '1.2rem' }}
					color={'#fff'}
					fontWeight={'300'}
					fontFamily={'Poppins'}
					textAlign={'center'}
					// width={"1000px"}
					ml={2}
					mr={2}
					pl={2}
					pr={2}
				>
					Our AI interview platform swiftly identifies top talent, streamlines hiring, and
					saves resources. <br />
					Experience unparalleled efficiency.
				</Typography>
				<Modal
					open={modalOpen}
					// onClose={}
				>
					<Box sx={style}>
						<Stack
							justifyContent={{ xs: 'center', sm: 'start' }}
							alignItems={{ xs: 'center', sm: 'end' }}
						>
							<IconButton
								style={{
									width: 'fit-content',
									color: '#fff',
								}}
								onClick={() => setModalOpen(false)}
							>
								<CloseIcon />
							</IconButton>
							{!isMobile ? (
								<video
									id="desktop-video"
									width={'1000px'}
									style={{
										borderRadius: '20px',
										// border: "3px solid #fff",
									}}
									// height="85%"

									autoPlay
									loop
									muted
									controls
								>
									<source src="hero-page-video.mp4" type="video/mp4" />
								</video>
							) : (
								<video
									id="mobile-video"
									width={'300px'}
									// height="90%"
									style={{ borderRadius: '20px' }}
									autoPlay
									loop
									muted
									controls
								>
									<source src="hero-page-video-mobile.mp4" type="video/mp4" />
								</video>
							)}
						</Stack>
					</Box>
				</Modal>
				{/* <span
                    class="glightbox play-btn mb-4"
                    // onClick={() => setModalOpen(true)}
                    onClick={() => setModalOpen(true)}
                ></span> */}
				<div
					className="container"
					style={{ marginTop: '50px' }}
					data-aos="fade-up"
					data-aos-duration="1400"
					data-aos-delay="500"
				>
					<div className="bg"></div>
					<Button
						size="large"
						className="play-btn"
						startIcon={<PlayCircleOutlineIcon />}
						onClick={() => setModalOpen(true)}
						sx={{
							width: '300px',
							height: '50px',
							fontFamily: 'Poppins',
							textTransform: 'none',
							position: 'absolute',
							// backgroundColor: "#224C84",
							backgroundColor: '#FFC107',
							fontSize: '1.2rem',
							color: '#224C84',
							borderRadius: '10px',
							// border: "2px solid",
							// borderColor: "#FFC107",
							'&:hover': {
								backgroundColor: '#FFC107',
								// border: "2px solid",
								// borderColor: "#FFC107",
								color: '#224C84',
							},
						}}
					>
						Watch Video
					</Button>
				</div>

				{/* <IconButton style={{ border: "none" }}></IconButton> */}
				<IconButton
					size="large"
					zIndex={2}
					// variant="outlined"
					// startIcon={}
					// mt="100px"
					// data-aos="fade"
					// data-aos-duration="1600"
					// data-aos-delay="500"
					onClick={() => handleClickScroll('about1')}
					className="down-arrow"
					style={{
						position: 'absolute',
						marginTop: `${isMobile ? '550px' : '650px'}`,
					}}
					sx={{
						// width: "300px",
						fontFamily: 'Poppins',
						textTransform: 'none',
						// backgroundColor: "#224C84",
						position: 'absolute',
						color: '#FFC107',
						marginTop: '200px',
						fontSize: '1.3rem',
						// borderRadius: "10px",
						// border: "2px solid",
						// borderColor: "#FFC107",
						// "&:hover": {
						//     backgroundColor: "#224C84",
						//     border: "2px solid",
						//     borderColor: "#224C84",
						//     color: "#FFC107",
						// },
					}}
				>
					<KeyboardDoubleArrowDownIcon fontSize="large" />
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default HeroSection;
