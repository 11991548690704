import TableCell from '@mui/material/TableCell';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
import { Stack, Typography, Chip, Button, Checkbox } from '@mui/material';
import UploadResume from './UploadResume';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { API_URL } from '../../global/constants';
export default function Cell({
	columns,
	completedInterviews,
	row,
	setCompletedInterviews,
	setFilteredData,
	filteredData,
	page,
	rowsPerPage,
	i,
	job_id,
}) {
	console.log(filteredData, 'filtered data');
	const navigate = useNavigate();
	const checkIsOld = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		if (completedInterviews[idx].is_old) return true;
		return false;
	};
	const checkIsNewFormat = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		if (completedInterviews[idx].is_new_format) return true;
		return false;
	};
	const checkIsForeign = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		console.log('idx', idx, completedInterviews[idx].language, 'language');
		if (completedInterviews[idx].language !== 'en') return true;
		return false;
	};
	const is_interview_required = (interview_key) => {
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		if (completedInterviews[idx].is_interview_required) return true;
		return false;
	};

	const getNameFromKey = (interview_key) => {
		for (let i of completedInterviews) {
			if (i.interview_key === interview_key) {
				return i.name;
			}
		}
	};

	const handleStatusChange = async (interview_key, status) => {
		const { data } = await axios.put(`${API_URL}/labs/candidate/status`, {
			interview_key: interview_key,
			status: status,
		});
		if (data.success) {
			toast.success(data.message, {
				duration: 3000,
				position: 'top-center',
			});
			return true;
		} else {
			toast.error(data.message, {
				duration: 3000,
				position: 'top-center',
			});
			return false;
		}
	};
	const handleConfirmationChange = async (interview_key) => {
		const { data } = await axios.put(`${API_URL}/labs/candidate/confirmation`, {
			interview_key: interview_key,
		});
		if (data.success) {
			toast.success(data.message, {
				duration: 3000,
				position: 'top-center',
			});
			return true;
		} else {
			toast.error(data.message, {
				duration: 3000,
				position: 'top-center',
			});
			return false;
		}
	};
	const recommendationLabel = (recommendation, is_violated = false) => {
		if (recommendation === '1') {
			return (
				<Chip
					size="small"
					label={'Highly Recommended'}
					style={{
						backgroundColor: '#148F50',
						color: 'black',
						width: '100%',
						fontSize: '0.8rem',
					}}
				/>
			);
		} else if (recommendation === '0') {
			return (
				<Chip
					size="small"
					label={'Recommended'}
					style={{
						backgroundColor: '#0AAF70',
						color: 'black',
						width: '100%',
						fontSize: '0.8rem',
					}}
				/>
			);
		} else if (recommendation === '-1') {
			return (
				<Chip
					size="small"
					label={'Not Recommended'}
					style={{
						backgroundColor: 'red',
						color: 'white',
						width: '100%',
						fontSize: '0.8rem',
					}}
				/>
			);
		} else if (is_violated) {
			return (
				<Chip
					size="small"
					label={'Disqualified'}
					style={{
						backgroundColor: '#BDBDBD',
						color: '#000000',
						width: '100%',
						fontSize: '0.8rem',
					}}
				/>
			);
		} else {
			return (
				<Chip
					size="small"
					label={'NA'}
					style={{
						backgroundColor: '#BDBDBD',
						color: 'white',
						width: '100%',
						fontSize: '0.8rem',
					}}
				/>
			);
		}
	};
	const getOverallScore = (overall_score) => {
		console.log(parseInt(overall_score));
		return parseInt(overall_score);
	};
	function titleCase(str) {
		str = str.toLowerCase().split(' ');
		for (let i = 0; i < str.length; i++) {
			str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
		}
		return str.join(' ');
	}
	const getOverallScoreFromKey = (interview_key) => {
		for (let i of completedInterviews) {
			if (i.interview_key === interview_key) {
				return i.overall_score;
			}
		}
	};
	const overallScoreLabel = (overall_score, recommendation) => {
		if (recommendation === '-1') {
			return (
				<Typography
					textAlign={'center'}
					style={{
						backgroundColor: '#FF0000',
						color: 'white',
						width: '80px',
						fontSize: '0.8rem',
						textTransform: 'none',
						padding: 10,
						paddingTop: 2,
						paddingBottom: 2,
						borderRadius: '10px',
					}}
				>
					{parseInt(overall_score)}%
				</Typography>
			);
		} else if (recommendation === '0') {
			return (
				<Typography
					textAlign={'center'}
					style={{
						backgroundColor: '#ffc107',
						color: 'black',
						width: '80px',
						padding: 10,
						fontSize: '0.8rem',
						textTransform: 'none',
						paddingTop: 2,
						paddingBottom: 2,
						borderRadius: '10px',
					}}
				>
					{parseInt(overall_score)}%
				</Typography>
			);
		} else if (recommendation === '1') {
			return (
				<Typography
					textAlign={'center'}
					style={{
						backgroundColor: '#66bb6a',
						color: 'black',
						width: '80px',
						fontSize: '0.8rem',
						textTransform: 'none',
						padding: 10,
						paddingTop: 2,
						paddingBottom: 2,
						borderRadius: '10px',
					}}
				>
					{parseInt(overall_score)}%
				</Typography>
			);
		}
	};
	const checkEngProfIncluded = (interview_key) => {
		console.log('is this called');
		const idx = completedInterviews.findIndex((item) => item.interview_key === interview_key);
		console.log('idx');
		console.log(completedInterviews[idx]);
		console.log(completedInterviews[idx].is_english_proficiency_included);
		if (completedInterviews[idx].is_english_proficiency_included) return true;
		return false;
	};
	const getResumeFromKey = (interview_key) => {
		for (let i of completedInterviews) {
			if (i.interview_key === interview_key) {
				return i.candidate_resume;
			}
		}
	};
	return (
		<>
			{columns.map((column, j) => {
				const value = row[column.id];
				return (
					<TableCell key={column.id} align={'center'}>
						{column.id === 'interview_key' && (
							<Tooltip title={row['interview_key']}>
								<Button
									variant="outlined"
									size="small"
									style={{
										color: '#224C84',
										textTransform: 'none',
										width: '130px',
									}}
									startIcon={<VisibilityIcon />}
									onClick={() => {
										console.log(value, 'onclick');
										console.log(
											is_interview_required(value),
											'is interview_required'
										);

										if (checkIsNewFormat(value)) {
											navigate(`/candidate/report-v3/${value}`, {
												state: {
													job_id,
													filteredData,
													completedInterviews,
												},
											});
											return;
										}

										if (checkIsForeign(value)) {
											navigate(`/candidate/report-v5/${value}`, {
												state: {
													job_id,
													filteredData,
													completedInterviews,
												},
											});
											return;
										}

										if (checkEngProfIncluded(value)) {
											navigate(`/candidate/report-v4/${value}`, {
												state: {
													job_id,
													filteredData,
													completedInterviews,
												},
											});
											return;
										}

										if (checkIsOld(value)) {
											navigate(`/candidate/report-v1/${value}`, {
												state: {
													job_id,
													filteredData,
													completedInterviews,
												},
											});
											return;
										}
										if (!is_interview_required(value)) {
											navigate(`/candidate/report-v6/${value}`, {
												state: {
													job_id,
													filteredData,
													completedInterviews,
												},
											});
											return;
										}

										navigate(`/candidate/report-v2/${value}`, {
											state: { job_id, filteredData, completedInterviews },
										});
									}}
								>
									View Report
								</Button>
							</Tooltip>
						)}

						{column.id === 'overall_score' && (
							<div
								style={{
									width: '50px',
									height: '50px',
								}}
							>
								<CircularProgressbar
									strokeWidth={12}
									value={
										row['overall_score'] && row['overall_score'] !== 'NaN'
											? parseInt(row['overall_score'])
											: 0
									}
									text={`${
										row['overall_score'] ? parseInt(row['overall_score']) : 0
									}%`}
									styles={buildStyles({
										textSize: '20px',
										textColor: '#004aad',
										pathColor: '#004aad',
									})}
								/>
							</div>
						)}
						{column.id === 'candidate_resume' && (
							<>
								{
									<UploadResume
										key={row['interview_key']}
										interview_key={row['interview_key']}
										value={value}
										completedInterviews={completedInterviews}
										setCompletedInterviews={setCompletedInterviews}
									/>
								}
							</>
						)}

						{column.id === 'email' && (
							<Tooltip title="Mail Now">
								<Button
									style={{
										textTransform: 'none',
										fontSize: '0.8rem',
									}}
								>
									<a
										href={`mailto:${value}`}
										style={{
											textDecoration: 'none',
											color: '#224C84',
										}}
									>
										{value}
									</a>
								</Button>
							</Tooltip>
						)}
						{column.id === 'contact' && (
							<Tooltip title="Call Now">
								<Button
									style={{
										textTransform: 'none',
										fontSize: '0.8rem',
									}}
								>
									<a
										href={`tel:${value}`}
										style={{
											textDecoration: 'none',
											color: '#224C84',
										}}
									>
										{value}
									</a>
								</Button>
							</Tooltip>
						)}
						{column.id === 'name' && (
							<Stack>
								<Tooltip title={'View Resume'}>
									<Button
										style={{
											textTransform: 'none',
											fontSize: '0.8rem',
											textAlign: 'start',
											justifyContent: 'flex-start',
											padding: '0px',
											width: 'fit-content',
										}}
									>
										<a
											target="_blank"
											rel="noreferrer"
											href={getResumeFromKey(row['interview_key'])}
											style={{
												textDecoration: 'none',
												color: '#224C84',
											}}
										>
											{titleCase(value)}
										</a>
									</Button>
								</Tooltip>
								<Button
									style={{
										textTransform: 'none',
										fontSize: '0.7rem',
										textAlign: 'start',
										justifyContent: 'flex-start',
										padding: '0px',
										width: 'fit-content',
									}}
								>
									<a
										href={`mailto:${row['email']}`}
										style={{
											textDecoration: 'none',
											color: '#224C84',
										}}
									>
										{row['email']}
									</a>
								</Button>
								<Button
									style={{
										textTransform: 'none',
										fontSize: '0.7rem',
										textAlign: 'start',
										justifyContent: 'flex-start',
										padding: '0px',
										width: 'fit-content',
									}}
								>
									<a
										href={`tel:${row['contact']}`}
										style={{
											textDecoration: 'none',
											color: '#224C84',
										}}
									>
										{row['contact']}
									</a>
								</Button>
							</Stack>
						)}
						{column.id === 'completion_time' && (
							<Typography
								style={{
									color: '#224C84',
									fontSize: '0.8rem',
								}}
							>
								{value}
							</Typography>
						)}
						{column.id === 'interview_date_time' && (
							<Typography
								style={{
									color: '#224C84',
									fontSize: '0.8rem',
									alignSelf: 'center',
								}}
							>
								{value}
							</Typography>
						)}
						{column.id === 'recommendation' &&
							recommendationLabel(
								row['recommendation'],
								row['interview_end_reason'] === 'violation'
							)}
						{column.id === 'is_confirmed_by_admin' && (
							<Checkbox
								checked={value}
								style={{
									color: '#224C84',
								}}
								onChange={async (e) => {
									const confirmation = await handleConfirmationChange(
										row['interview_key']
									);

									// Update the value in the data
									const updatedRow = {
										...row,
										is_confirmed_by_admin: !value,
									};

									// Update the data at the same index in the filteredData array
									console.log(filteredData, 'filtered data');
									const updatedData = [...filteredData];
									updatedData[page * rowsPerPage + i] = updatedRow;
									console.log('page: ', page * rowsPerPage);
									console.log('index: ', i);
									console.log('upadted row: ', updatedData);
									setFilteredData(updatedData);
								}}
							/>
						)}
						{column.id === 'status' && (
							<FormControl
								fullWidth
								size="small"
								variant="outlined"
								sx={{
									width: '150px',
								}}
							>
								<InputLabel id="demo-simple-select-label">Status</InputLabel>
								<Select
									value={row['status']}
									label="Status"
									onChange={async (e) => {
										const confirmation = await handleStatusChange(
											row['interview_key'],
											e.target.value
										);
										const updatedRow = {
											...row,
											status: e.target.value,
										};
										const updatedData = [...filteredData];
										updatedData[page * rowsPerPage + i] = updatedRow;
										console.log('page: ', page * rowsPerPage);
										console.log('index: ', i);
										console.log('upadted row: ', updatedData);
										setFilteredData(updatedData);
									}}
									disabled={row['status'] === 'reject_and_notify'}
								>
									<MenuItem value={'rejected'}>Rejected</MenuItem>
									<MenuItem value={'shortlisted'}>Shortlisted</MenuItem>
									<MenuItem value={'disqualified'}>Disqualified</MenuItem>
									<MenuItem value={'interview_scheduled'}>
										Interview Scheduled
									</MenuItem>
									<MenuItem value={'reject_and_notify'}>
										Reject and Notify
									</MenuItem>
								</Select>
							</FormControl>
						)}
					</TableCell>
				);
			})}
		</>
	);
}
