import Editor from '@monaco-editor/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../global/constants';

const CodingTestReport = ({ interviewKey }) => {
	const [answers, setAnswers] = useState([]);
	const [totalScore, setTotalScore] = useState(0);
	const getData = async () => {
		const { data } = await axios.post(`${API_URL}/labs/coding-test/get-report`, {
			interview_key: interviewKey,
		});
		console.log(data);
		setAnswers(data.data.answers);
		setTotalScore(data.data.total_score);
	};
	const getPathColor = (value) => {
		return value >= 75 ? '#148F5D' : value >= 50 && value < 75 ? '#0AAF70' : '#FF0000';
	};
	useEffect(() => {
		getData();
	}, []);
	return (
		<>
			{/* Coding Test Summary */}
			<Stack bgcolor={'#004aad'} p={1} spacing={1}>
				<Typography fontSize={'1.2rem'} color={'#fff'} fontWeight={'bold'}>
					Coding Test Summary
				</Typography>
			</Stack>
			<Stack>
				{answers.map((answer, index) => (
					<Stack p={2} pb={2} spacing={1}>
						<Typography fontSize={'1.2rem'} color={'#004aad'} fontWeight={'bold'}>
							Question {index + 1} : {answer.question}
						</Typography>
						<Typography>
							<div
								dangerouslySetInnerHTML={{
									__html: answer.statement,
								}}
							></div>
						</Typography>
						{answer.submitted && (
							<>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Stack direction={'row'} spacing={1} alignItems={'center'}>
											<Typography fontWeight={'bold'}>Show Code</Typography>
											<Typography
												bgcolor={'#ECF7FA'}
												color={'gray'}
												fontSize={'0.7rem'}
												borderRadius={'15px'}
												p={1}
											>
												{answer.submitted_code.language.toUpperCase()}
											</Typography>
										</Stack>
									</AccordionSummary>
									<AccordionDetails>
										<Editor
											height="30vh"
											language={answer.submitted_code.language}
											value={answer.submitted_code.code}
											options={{
												readOnly: true,
											}}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Stack direction={'row'} spacing={1} alignItems={'center'}>
											<Typography fontWeight={'bold'}>
												Test Cases Result
											</Typography>
											<Typography
												bgcolor={'#ECF7FA'}
												color={'gray'}
												fontSize={'0.7rem'}
												borderRadius={'15px'}
												p={1}
											>
												{
													answer?.test_case_results?.filter(
														(caseItem) => caseItem.status === true
													).length
												}{' '}
												/ {answer.test_case_results.length} test cases
												passed
											</Typography>
										</Stack>
									</AccordionSummary>
									<AccordionDetails>
										<Stack spacing={1}>
											{answer.test_case_results.map((testCase, index) => (
												<Stack>
													<Stack
														direction={'row'}
														spacing={1}
														alignItems={'center'}
													>
														<Typography fontWeight={'bold'}>
															Test Case - {index + 1}
														</Typography>
														<Typography
															bgcolor={
																testCase.status
																	? '#02C677'
																	: '#FF0000'
															}
															color={'#fff'}
															fontSize={'0.7rem'}
															borderRadius={'15px'}
															p={1}
														>
															{testCase.status ? 'Passed' : 'Failed'}
														</Typography>
													</Stack>
													<Typography>
														<b>Input - </b>
														{testCase.input}
													</Typography>
													<Typography>
														<b>Expected Output - </b>
														{testCase.expected_output}
													</Typography>
													<Typography>
														<b>Output - </b>
														{testCase.output}
													</Typography>
													<Typography>
														<b>Time Taken - </b>
														{testCase.time_taken}
													</Typography>
													<Typography>
														<b>Memory Taken - </b>
														{testCase.memory_taken}
													</Typography>
													<br />
												</Stack>
											))}
										</Stack>
										{/* <Editor
                                    height="30vh"
                                    language={answer.submitted_code.language}
                                    value={answer.submitted_code.code}
                                    options={{
                                        readOnly: true,
                                    }}
                                /> */}
									</AccordionDetails>
								</Accordion>
							</>
						)}
						{!answer.submitted && (
							<Stack spacing={1}>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Stack direction={'row'} spacing={1} alignItems={'center'}>
											<Typography fontWeight={'bold'} color={'#ff0000'}>
												<u> No code submitted or errors in code</u>
											</Typography>
											<Typography>(Click to show tried codes)</Typography>
										</Stack>
									</AccordionSummary>
									<AccordionDetails>
										<Stack spacing={1}>
											{Object.keys(answer.code).map((key, index) => (
												<Stack spacing={1}>
													{key !== '_id' &&
														answer.code[key] !==
															answer.code_snippet[key] && (
															<>
																<Stack
																	direction={'row'}
																	spacing={1}
																	alignItems={'center'}
																>
																	<Typography
																		bgcolor={'#ECF7FA'}
																		color={'gray'}
																		fontSize={'0.7rem'}
																		borderRadius={'15px'}
																		p={1}
																	>
																		{key.toUpperCase()}
																	</Typography>
																</Stack>
																<Editor
																	height="30vh"
																	language={key}
																	value={answer.code[key]}
																	options={{
																		readOnly: true,
																	}}
																/>
															</>
														)}
												</Stack>
											))}
										</Stack>
									</AccordionDetails>
								</Accordion>
							</Stack>
						)}
					</Stack>
				))}
			</Stack>
		</>
	);
};

export default CodingTestReport;
