import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
const interviewSlice = createSlice({
	name: 'interviewdata',
	initialState: {
		interview_data: [],
		is_proctoring_check_done: false,
		status: 'idle',
		error: null,
	},
	reducers: {
		setInterviewDetails: (state, action) => {
			console.log(action.payload, 'Action payload');
			state.interview_data = action.payload;
		},
		setIsProctoringCheckDone: (state, action) => {
			state.is_proctoring_check_done = action.payload;
		},
	},
	extraReducers: (builder) => {
		// Add cases for extraReducers here if needed in the future
	},
});

export const { setInterviewDetails, setIsProctoringCheckDone } = interviewSlice.actions;
export const selectInterviewData = (state) => state.interviewdata.interview_data;
export const selectisproctoringcheckdone = (state) => state.interviewdata.is_proctoring_check_done;
export default interviewSlice.reducer;
