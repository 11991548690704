import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import LoadingDialog from '../CodeEditor/LoadingDialog';
import { Header } from '../shared/Header';
import CandidateDetailDialog from './CandidateDetailDialog';

const CircularPtogresswithdots = () => {
	return (
		<Box
			position="relative"
			display="flex"
			justifyContent="center"
			alignItems="center"
			width={40}
			height={40}
			borderRadius="50%"
			border="5px solid rgba(0, 0, 0, 0.2)"
		>
			<span
				style={{
					fontSize: '25px',
					marginBottom: '17px',
					color: 'gray',
				}}
			>
				...
			</span>
		</Box>
	);
};

const CircularProgress = ({ progress }) => {
	//const percentage = (progress * 100) / 5;
	let percentage = 0;
	let decimal_value;
	if (!isNaN(parseFloat(progress))) {
		decimal_value = parseFloat(progress).toFixed(1);
		percentage = (progress * 100) / 5;
	} else {
		decimal_value = '0.0';
	}
	return (
		<div
			style={{
				width: '40px',
				height: '40px',
			}}
		>
			<CircularProgressbar
				strokeWidth={12}
				value={percentage}
				text={`${decimal_value}`}
				styles={buildStyles({
					textSize: '20px',
					textColor: percentage < 60 ? '#D32F2F' : '#2E7D32',
					pathColor: percentage < 60 ? '#D32F2F' : '#2E7D32',
				})}
			/>
		</div>
	);
};

const ViewResumeEvaluation = () => {
	const { state } = useLocation();
	const { jobRole, jobId, companyId } = state;
	const [candidateDetail, setCandidateDetail] = useState({
		first_name: '',
		last_name: '',
		email: '',
		contact: '',
		resume_id: '',
	});
	const [candidateDialogOpen, setCandidateDialogOpen] = useState(false);
	const [resumeData, setResumeData] = useState([]);
	const [loading, setLoading] = useState(true);
	const formatHeader = (header) => {
		return header
			.split('_')
			.map((word) => word[0].toUpperCase() + word.slice(1))
			.join(' ');
	};

	const [columns, setColumns] = useState([
		{
			field: 'shortlist',
			headerName: 'Shortlist',
			align: 'center',
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<Checkbox
						key={`checkbox-${params.row.id}`}
						style={{ color: '#224C84' }}
						checked={params.value}
						disabled={params.value}
						onChange={() =>
							changeStatus(
								params.row.resume_id,
								params.row.first_name,
								params.row.last_name,
								params.row.email,
								params.row.contact,
								params.row.resume_link,
								params.row.source
							)
						}
					/>
				);
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			width: 20,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'name',
			headerName: 'Name',
			width: 250,
			renderCell: (params) => (
				<Stack>
					<Tooltip title={'View Resume'}>
						<Button
							style={{
								textTransform: 'none',
								fontSize: '0.8rem',
								textAlign: 'start',
								justifyContent: 'flex-start',
								padding: '0px',
								width: 'fit-content',
							}}
						>
							<a
								target="_blank"
								rel="noreferrer"
								href={params.row.resume_link}
								style={{
									textDecoration: 'none',
									color: '#224C84',
								}}
							>
								{params.row.name}
							</a>
						</Button>
					</Tooltip>
					<Button
						style={{
							textTransform: 'none',
							fontSize: '0.7rem',
							textAlign: 'start',
							justifyContent: 'flex-start',
							padding: '0px',
							width: 'fit-content',
						}}
					>
						<a
							href={params.row.email}
							style={{
								textDecoration: 'none',
								color: '#224C84',
							}}
						>
							{params.row.email}
						</a>
					</Button>
					<Button
						style={{
							textTransform: 'none',
							fontSize: '0.7rem',
							textAlign: 'start',
							justifyContent: 'flex-start',
							padding: '0px',
							width: 'fit-content',
						}}
					>
						<a
							href={'tel:' + params.row.contact}
							style={{
								textDecoration: 'none',
								color: '#224C84',
							}}
						>
							{params.row.contact}
						</a>
					</Button>
				</Stack>
			),
		},

		{
			field: 'email',
			headerName: 'Email',
		},
		{
			field: 'contact',
			headerName: 'Contact',
		},
		{
			field: 'source',
			headerName: 'Source',
			width: 160,
			align: 'center',
			headerAlign: 'center',
		},
	]);

	const getData = async () => {
		setLoading(true);
		const { data } = await axios.get(`${API_URL}/labs/resumes/${companyId}/${jobId}`, {
			headers: {
				'Content-Type': 'multipart/form-data',
				authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
			},
		});
		setLoading(false);
		if (data.success) {
			// toast.success(data.message);
			let rows = [];
			for (let i = 0; i < data.data.length; i++) {
				let row = {
					resume_id: data.data[i]._id,
					id: i + 1,
					is_evaluated: data.data[i].is_evaluated,
					name: data.data[i].first_name + ' ' + data.data[i].last_name,
					email: data.data[i].email,
					contact: data.data[i].contact,
					shortlist: data.data[i].is_shortlisted,
					first_name: data.data[i].first_name,
					last_name: data.data[i].last_name,
					resume_link: data.data[i].resume_link,
					source: data.data[i].source ?? '-',
				};
				for (let j = 0; j < data.data[i].evaluations.length; j++) {
					row[data.data[i].evaluations[j].type] = data.data[i].evaluations[j].value;
				}
				row.recommendation = row.overall_score >= 3 ? true : false;
				rows.push(row);
			}
			console.log('rows', rows);
			let new_columns = [...columns];
			setResumeData(rows);
			new_columns.push({
				field: 'overall_score',
				headerName: formatHeader('overall_score'),
				align: 'center',
				headerAlign: 'center',
				width: 'overall_score' === 'number_of_jobs' ? 160 : 130,
				renderCell: (params) => {
					if (!params.row.is_evaluated) {
						return <CircularPtogresswithdots />;
					}

					return <CircularProgress progress={params.value} />;
				},
			});
			for (let i = 0; i < data.data[0]?.evaluations.length; i++) {
				console.log(data.data[0], 'data typexyz');
				const type = data.data[0].evaluations[i].type;
				if (
					type !== 'overall_score' &&
					type !== 'recommendation' &&
					type !== 'additionalskills' &&
					type !== 'certifications'
				) {
					new_columns.push({
						field: data.data[0].evaluations[i].type,
						headerName: formatHeader(data.data[0].evaluations[i].type),
						align: 'center',
						headerAlign: 'center',
						width: data.data[0].evaluations[i].type === 'number_of_jobs' ? 160 : 130,
						renderCell: (params) => {
							console.log(params, 'abc');
							if (!params.row.is_evaluated) {
								return <CircularPtogresswithdots />;
							}
							if (data.data[0]?.evaluations[i].type === 'number_of_jobs') {
								return <Typography>{params.value}</Typography>;
							}

							return <CircularProgress progress={params.value} />;
						},
					});
				}
			}
			console.log('columns', new_columns);

			new_columns.push({
				field: 'action',
				headerName: 'Action',
				width: 160,
				renderCell: (params) => (
					<Tooltip title="Edit Details">
						<IconButton
							onClick={() => {
								setCandidateDetail({
									first_name: params.row.first_name,
									last_name: params.row.last_name,
									email: params.row.email,
									contact: params.row.contact,
									resume_id: params.row.resume_id,
								});
								setCandidateDialogOpen(true);
								console.log(candidateDetail);
							}}
							sx={{
								color: '#224C84',
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				),
			});
			setColumns(new_columns);
			setLoading(false);
		} else {
			setLoading(false);
			toast.error(data.message);
		}
	};
	const changeStatus = async (
		resume_id,
		first_name,
		last_name,
		email,
		contact,
		resume_link,
		source
	) => {
		console.log(resume_id);
		const { data } = await axios.get(`${API_URL}/labs/resume/change-status/${resume_id}`, {
			headers: {
				'Content-Type': 'multipart/form-data',
				authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
			},
		});
		if (data.success) {
			toast.success(data.message);
			setResumeData((prev) => {
				let new_data = [...prev];
				for (let i = 0; i < new_data.length; i++) {
					if (new_data[i].resume_id === resume_id) {
						new_data[i].shortlist = !new_data[i].shortlist;
					}
				}
				return new_data;
			});
			setLoading(true);
			const type = 'shortlisted_resume';
			const formData = new FormData();
			formData.append('first_name', first_name);
			formData.append('last_name', last_name);
			formData.append('contact', contact);
			formData.append('email', email);
			formData.append('resume_link', resume_link);
			formData.append('type', type);
			formData.append('source', source);
			const { data: interviewData } = await axios.post(
				`${API_URL}/labs/applicant/new/${companyId}/${jobId}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${
							JSON?.parse(sessionStorage?.getItem('admin'))?.token
						}`,
						'content-type': 'multipart/form-data',
					},
				}
			);
			if (interviewData.success) {
				toast.success('Interview Created Successfully');
			} else {
				toast.error('Error Creating Interview');
			}
			setLoading(false);
		} else {
			toast.error(data.message);
		}
	};
	useEffect(() => {
		getData();
	}, []);
	return (
		<Stack justifyContent={'center'} alignItems={'center'} pl={5} pr={5}>
			<Header />
			<Toaster />
			<LoadingDialog open={loading} />
			<CandidateDetailDialog
				details={candidateDetail}
				setDetails={setCandidateDetail}
				open={candidateDialogOpen}
				setOpen={setCandidateDialogOpen}
				setResumeData={setResumeData}
			/>
			<Typography m={3} fontSize={'1.5rem'} textAlign={'center'} fontWeight={'bold'}>
				Resume Evaluations
				<br /> for {jobRole}
			</Typography>
			{/* {resumeData.length===0 && !loading && <Typography> No Evaluations Available</Typography>} */}
			{resumeData.length > 0 && (
				<Box sx={{ height: '70vh', width: '100%', bgcolor: '#fff' }}>
					<DataGrid
						rows={resumeData}
						rowHeight={80}
						columns={columns}
						slots={{
							noRowsOverlay: () => <Typography>No Evaluations Available</Typography>,
							toolbar: GridToolbar,
						}}
						slotProps={{
							toolbar: {
								csvOptions: {
									allColumns: true,
								},
							},
						}}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 10,
								},
							},
							columns: {
								columnVisibilityModel: {
									email: false,
									contact: false,
								},
							},
						}}
						pageSizeOptions={[10, 20, 30, 50, 100]}
						// checkboxSelection
						disableRowSelectionOnClick
					/>
				</Box>
			)}
		</Stack>
	);
};

export default ViewResumeEvaluation;
