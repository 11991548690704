export const demoMapping = {
	development: {
		A123: '66d83b43bdde0b87e2d3dfee',
		A456: '66d83b43bdde0b87e2d3dff5',
		A789: '66d83b44bdde0b87e2d3dffc',
		B123: '66d83b44bdde0b87e2d3e003',
		B456: '66d83b44bdde0b87e2d3e00a',
		B789: '66d83b44bdde0b87e2d3e011',
		C123: '66d83b44bdde0b87e2d3e019',
		C456: '66d83b44bdde0b87e2d3e021',
		C789: '66d83b44bdde0b87e2d3e02c',
		D123: '66d83b44bdde0b87e2d3e033',
		D456: '66d83b44bdde0b87e2d3e03a',
		D789: '66d83b44bdde0b87e2d3e041',
		E123: '66d83b44bdde0b87e2d3e049',
		E456: '66d83b44bdde0b87e2d3e052',
		E789: '66d83b44bdde0b87e2d3e05b',
		F123: '66d83b44bdde0b87e2d3e062',
		F456: '66d83b44bdde0b87e2d3e06a',
		F789: '66d83b44bdde0b87e2d3e072',
		G123: '66d83b45bdde0b87e2d3e07a',
		G456: '66d83b45bdde0b87e2d3e081',
		G789: '66d83b45bdde0b87e2d3e08a',
		H123: '66d83b45bdde0b87e2d3e095',
		H456: '66d83b45bdde0b87e2d3e09c',
		H789: '66d83b45bdde0b87e2d3e0a3',
		I123: '66d83b45bdde0b87e2d3e0aa',
		I456: '66d83b45bdde0b87e2d3e0b2',
		I789: '66d83b45bdde0b87e2d3e0ba',
		J123: '66d83b45bdde0b87e2d3e0c4',
		J456: '66d83b45bdde0b87e2d3e0cd',
		J789: '66d83b45bdde0b87e2d3e0d5',
		K123: '66d83b45bdde0b87e2d3e0dc',
		K456: '66d83b45bdde0b87e2d3e0e3',
		K789: '66d83b45bdde0b87e2d3e0eb',
		L123: '66d83b46bdde0b87e2d3e0f2',
		L456: '66d83b46bdde0b87e2d3e0fa',
		L789: '66d83b46bdde0b87e2d3e103',
		M123: '66d83b46bdde0b87e2d3e10a',
		M456: '66d83b46bdde0b87e2d3e115',
		M789: '66d83b46bdde0b87e2d3e11c',
		N123: '66d83b46bdde0b87e2d3e123',
		N456: '66d83b46bdde0b87e2d3e12b',
		N789: '66d83b46bdde0b87e2d3e132',
		O123: '66d83b46bdde0b87e2d3e13a',
		O456: '66d83b46bdde0b87e2d3e142',
		O789: '66d83b46bdde0b87e2d3e149',
		P123: '66d83b46bdde0b87e2d3e151',
		P456: '66d83b46bdde0b87e2d3e15b',
		P789: '66d83b46bdde0b87e2d3e163',
		Q123: '66d83b46bdde0b87e2d3e16a',
		Q456: '66d83b47bdde0b87e2d3e173',
	},
	production: {
		A123: '6548f7aa7d1150114f548d1c',
		A456: '6548f7ce7d1150114f548d39',
		A789: '6548f7eb7d1150114f548d56',
		B123: '6548f85e7d1150114f548d73',
		B456: '6548f8797d1150114f548d90',
		B789: '6548f8987d1150114f548dad',
		C123: '6548f8b87d1150114f548dca',
		C456: '6548f8cf7d1150114f548de7',
		C789: '6548f8f37d1150114f548e04',
		D123: '6548f9247d1150114f548e21',
		D456: '6548f99c7d1150114f548e3e',
		D789: '6548f9b87d1150114f548e5b',
	},
};
