import React from 'react';
import { Header } from '../shared/Header';
import { useParams } from 'react-router-dom';
import Job from '../CreateCompany/Job';
import { Box, Card, Button, Stack } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { useNavigate } from 'react-router-dom';
const CreateJobPosting = () => {
	const { company_id, role } = useParams();
	const navigate = useNavigate();
	console.log(company_id);
	const [details, setDetails] = React.useState({
		jobPostings: [
			{
				job_role: '',
				type: '',
				job_description_link: '',
				faq_link: '',
				job_description: '',
				expected_ctc: '',
				//
				qualifications: '',
				work_experience: '',
				skills_required: '',
				additional_requirements: '',
				// job_responsibilities: "",
				// requirements: "",
			},
		],
		role: role,
	});
	const handleOnClick = async () => {
		for (let i = 0; i < details.jobPostings.length; i++) {
			if (details.jobPostings[i].job_role === '') {
				toast.error(`Job Role is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].type === '') {
				toast.error(`Job Type is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].job_description === '') {
				toast.error(`Job Description is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].qualifications === '') {
				toast.error(`Qualifications is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].work_experience === '') {
				toast.error(`Work Experience is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].job_description_link === '') {
				toast.error(`Job Description Link is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].faq_link === '') {
				toast.error(`FAQ Link is required for Job ${i + 1}`);
				return;
			}
			if (details.jobPostings[i].skills_required === '') {
				toast.error(`Skills are required for Job ${i + 1}`);
				return;
			}
		}
		const { data } = await axios.put(`${API_URL}/labs/job/new/${company_id}`, details, {
			headers: {
				Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('admin')).token}`,
			},
		});
		if (data.success) {
			toast.success('Jobs Created Successfully');
			const navigateTo = role === 'company' ? 'jobslist/company' : 'college-admin';
			navigate(`/dashboard/${navigateTo}`);
		} else {
			toast.error('Error in creating Jobs');
		}
	};
	return (
		<Stack
			style={{
				backgroundColor: '#ECF7FA',
			}}
			justifyContent={'center'}
			alignItems={'center'}
			p={2}
		>
			<Toaster />
			<Header />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '2rem',
					backgroundColor: '#ECF7FA',
				}}
			>
				<Card
					sx={{
						height: 'auto',
						// width: "500px",
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '30px',
						// backgroundColor: "#ECF7FA",
					}}
				>
					<Job details={details} setDetails={setDetails} />
				</Card>
			</Box>
			<Button
				sx={{
					color: 'white',
					backgroundColor: '#224C84',
					'&:hover': {
						backgroundColor: '#224C84',
					},
					marginTop: '20px',

					textTransform: 'none',
					width: 'fit-content',
				}}
				onClick={handleOnClick}
			>
				Create{' '}
			</Button>
		</Stack>
	);
};

export default CreateJobPosting;
