import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../global/constants';

export const fetchQuestions = createAsyncThunk('fetchUserQuestions', async () => {
	const response = await axios.get(`${API_URL}/labs/review/questions`);
	return response.data?.questions;
});
export const postreview = createAsyncThunk('addreview', async (ratingForm) => {
	const response = await axios.post(`${API_URL}/labs/review/submit`, ratingForm);
	return response.data?.questions;
});

const questionsSlice = createSlice({
	name: 'feedbackquestions',
	initialState: {
		data: [],
		status: 'idle',
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchQuestions.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchQuestions.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.data = action.payload;
				console.log(state.data, 'state.data');
			})
			.addCase(fetchQuestions.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});
export const selectAllQuestions = (state) => {
	console.log(state, 'inside selectall questions');
	return state.feedbackquestions?.data;
};
export const selectQuestionById = (id) => (state) => {
	if (id == null) {
		return null;
	}
	return state.feedbackquestions.data.find((question) => question.id === id);
};

export const getQuestionsStatus = (state) => state.feedbackquestions?.status;
export const getQuestionsError = (state) => state.feedbackquestions?.error;

export default questionsSlice.reducer;
