const isValidEmail = (email) => {
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	const testEmail = emailRegex.test(email);
	return testEmail;
};

const isValidURL = (url) => {
	var testURL = url.match(
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	);
	return testURL;
};

const isValidPhoneNumber = (phoneNumber) => {
	const phoneNumberRegex = /^\d{10}$/;
	const testPhoneNumber = phoneNumberRegex.test(phoneNumber);
	return testPhoneNumber;
};
const getFormattedDate = (date) => {
	// Create a new Date object with the current date and time
	const currentDate = new Date(date);

	// Get the month name

	const monthName = currentDate.toLocaleString('default', { month: 'short' });

	// Get the day
	const day = currentDate.getDate();

	// Get the hours
	let hours = currentDate.getHours();

	// Determine whether it's morning or afternoon
	const amPm = hours >= 12 ? 'PM' : 'AM';

	// Convert 24-hour time to 12-hour time
	hours = hours % 12 || 12;

	// Get the minutes
	const minutes = currentDate.getMinutes();

	// Format the date and time
	const formattedDate = `${monthName} ${day}, ${hours}:${minutes
		.toString()
		.padStart(2, '0')} ${amPm}`;

	return formattedDate;
};
const getCompletiontime = (time_spent, date1Input, date2Input) => {
	let milliseconds = 0;
	// console.log(time_spent?.values(), time_spent, 'time_spent util');
	// if (time_spent) {
	// 	for (let timesession of time_spent) {
	// 		milliseconds += time_spent[timesession];
	// 	}
	// }
	if (time_spent) {
		for (const timestamp in time_spent) {
			milliseconds += time_spent[timestamp];
		}
	}
	// console.log(time_spent, 'time_spent');
	// Define the two dates if theere is ms it return directly else calculates the difference
	const date1 = new Date(date1Input);
	const date2 = new Date(date2Input); // This will use the current date and time

	if (!milliseconds) {
		milliseconds = date2 - date1;
	}

	// Convert milliseconds into seconds
	let totalSeconds = Math.floor(milliseconds / 1000);

	// Calculate total minutes
	let totalMinutes = Math.floor(totalSeconds / 60);

	// Remaining seconds after extracting minutes
	let remainingSeconds = totalSeconds % 60;

	// Format the time as "mm:ss"
	let formattedTime =
		totalMinutes.toString().padStart(2, '0') +
		':' +
		remainingSeconds.toString().padStart(2, '0');

	return formattedTime;
};

export { isValidEmail, isValidURL, isValidPhoneNumber, getCompletiontime };
