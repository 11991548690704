import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import Company from './Company';
import Admin from './Admin';

const Review = (props) => {
	const { details, setDetails, errors, setErrors } = props;
	return (
		<Stack spacing={2}>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Company Details</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Company
						details={details}
						setDetails={setDetails}
						errors={errors}
						setErrors={setErrors}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Company Admin Details</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Admin
						details={details}
						setDetails={setDetails}
						errors={errors}
						setErrors={setErrors}
					/>
				</AccordionDetails>
			</Accordion>
		</Stack>
	);
};

export default Review;
