import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import Videos from './Videos';
import Questions from './Questions';
import SkillsWeightage from './SkillsWeightage';
import Timestamps from './Timestamps';

const Review = (props) => {
	const { details, setDetails } = props;
	return (
		<Stack spacing={2}>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Videos</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Videos details={details} setDetails={setDetails} />
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Questions</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Questions details={details} setDetails={setDetails} />
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Skills Weightage</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<SkillsWeightage details={details} setDetails={setDetails} />
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Timestamps</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Timestamps details={details} setDetails={setDetails} />
				</AccordionDetails>
			</Accordion>
		</Stack>
	);
};

export default Review;
