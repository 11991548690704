import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './index.css';

export const CandidateReport = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [reportData, setReportData] = useState();
	const params = useParams();

	const { key } = params;

	useEffect(() => {
		const getReportData = async () => {
			const { data } = await axios.get(`${API_URL}/labs/generate-report/${key}`);
			setReportData(data);
		};

		if (key) {
			getReportData();
		}
	}, [key]);

	useEffect(() => {
		if (reportData) {
			setIsLoading(false);
		}
	}, [reportData]);

	const getCandidateRelevanceScore = () => {
		const res = Object.values(reportData?.interview_details).reduce((acc, data) => {
			const { relevance_score, question_id } = data;
			if (
				question_id !== 0 &&
				relevance_score &&
				question_id !== reportData?.interview_details.length - 1
			) {
				acc = parseInt(acc, 10) + parseInt(relevance_score, 10);
			}
			return acc;
		}, 0);
		const totalQuestions = reportData?.total_questions - 2; // first question and last question doesnt matter
		const total = totalQuestions * 5;
		return parseFloat((res / total) * 100).toFixed(2);
	};

	const getCandidateLanguageScore = () => {
		const res = Object.values(reportData?.interview_details).reduce((acc, data) => {
			const { language_score, question_id } = data;
			if (
				question_id > 0 &&
				language_score &&
				question_id !== reportData?.interview_details.length - 1
			) {
				acc = parseInt(acc, 10) + parseInt(language_score, 10);
			}
			return acc;
		}, 0);
		const totalQuestions = reportData?.total_questions - 2;
		const total = totalQuestions * 5;
		return parseFloat((res / total) * 100).toFixed(2);
	};

	const getPercentageScore = (questionScore, total) => {
		const score = parseFloat((questionScore / total) * 100).toFixed(2);
		return score;
	};

	const getCandidateRecommendation = () => {
		const averageRelevanceScore = getCandidateRelevanceScore();
		const averageLanguageScore = getCandidateLanguageScore();
		const totalScore = 0.6 * averageRelevanceScore + 0.4 * averageLanguageScore;
		if (totalScore >= 50) {
			return 1;
		} else {
			return 0;
		}
	};

	return (
		<div style={{ backgroundColor: '#ECF7FA' }}>
			<div className="report-container">
				{isLoading && <CircularStatic />}
				{!isLoading && (
					<>
						<div className="report-details">
							<div className="report-details-name">
								<div className="report-details-name-value">
									{`${reportData?.first_name} ${reportData?.last_name}`}
								</div>
								<div className="report-details-subDetails-value">
									Interviewed for : {reportData?.job_role}
								</div>
							</div>
							{/* <div className="report-details-date">
              <div className="report-details-date-value">
                29 April 2023
              </div>
            </div> */}
						</div>
						<div className="report-details-container">
							{Object.values(reportData?.interview_details).map((data) => {
								const {
									question_id,
									question_text,
									relevance_score,
									language_score,
									candidate_answer,
									reasoning,
								} = data;
								const questionRelevanceScore = getPercentageScore(
									relevance_score,
									5
								);
								const questionLanguageScore = getPercentageScore(language_score, 3);
								return (
									<>
										{question_id !== 0 && question_id !== 4 && (
											<div key={question_id}>
												<div className="question">
													<b>Q{question_id}:</b> {question_text}
												</div>
												<hr></hr>
												<div className="user-response">
													<div className="user-response-heading">
														User Response:
													</div>
													<div className="user-response-details">
														{candidate_answer}
													</div>
												</div>
												<hr></hr>
												<div className="feedback">
													<div className="feedback-heading">
														Feedback:
													</div>
													<div className="feedback-details">
														{reasoning}
													</div>
												</div>
												<hr></hr>
												<div className="scores-container">
													<div className="scorename-heading">Score:</div>
													<div className="scores-container-row">
														<div>
															<h3>Relevance</h3>
															<div
																style={{
																	width: '100px',
																	height: '100px',
																}}
															>
																<CircularProgressbar
																	value={questionRelevanceScore}
																	text={`${questionRelevanceScore}%`}
																/>
															</div>
														</div>
														<div>
															<h3>Language</h3>
															<div
																style={{
																	width: '100px',
																	height: '100px',
																}}
															>
																<CircularProgressbar
																	value={questionLanguageScore}
																	text={`${questionLanguageScore}%`}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								);
							})}
							<div className="report-summary-heading">
								<div className="report-summary-title">
									Summary and recommendation
								</div>
							</div>
							<div className="scores-container">
								<div className="scorename-heading">Average Score:</div>
								<div className="scores-container-row">
									<div>
										<h3>Relevance</h3>
										<div style={{ width: '100px', height: '100px' }}>
											<CircularProgressbar
												value={getCandidateRelevanceScore()}
												text={`${getCandidateRelevanceScore()}%`}
											/>
										</div>
									</div>
									<div>
										<h3>Language</h3>
										<div style={{ width: '100px', height: '100px' }}>
											<CircularProgressbar
												value={getCandidateLanguageScore()}
												text={`${getCandidateLanguageScore()}%`}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="recomendation">
								Recomendation for next round
								<button
									style={{
										backgroundColor:
											getCandidateRecommendation() === 1 ? '#37a723' : 'red',
									}}
								>
									{getCandidateRecommendation() === 1
										? 'Highly Recommended'
										: 'Not Recommended'}
								</button>
							</div>
						</div>
					</>
				)}
				<div className="report-footer">
					{`${reportData?.first_name} ${reportData?.last_name}`} interview report by
					AI.HYR
				</div>
			</div>
		</div>
	);
};
