import React, { useState } from 'react';
import { API_URL } from '../../global/constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

const ReactivateLink = ({ interviewKey, hasInterviewEnded, hasKeyExpired }) => {
	const [successAlert, setSuccessAlert] = useState(false);

	const handleReactivateClick = async () => {
		try {
			const response = await axios.put(
				`${API_URL}/labs/interview/re-activate/${interviewKey}`,
				{},
				{
					headers: {
						authorization: `Bearer ${
							JSON?.parse(sessionStorage?.getItem('admin'))?.token
						}`,
					},
				}
			);
			if (response.data.success) {
				toast.success('Re-activated Interview Successfully!');
				setSuccessAlert(true);
			}
		} catch (error) {
			console.error('Error occurred while finalizing reactivation:', error);
		}
	};

	return (
		<>
			{hasKeyExpired && !successAlert ? (
				<Link onClick={handleReactivateClick}>Reactivate Key</Link>
			) : hasInterviewEnded && !successAlert ? (
				<Link onClick={handleReactivateClick}>Reactivate Interview</Link>
			) : (
				<h4>Interview is Active</h4>
			)}
		</>
	);
};

export default ReactivateLink;
