import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Questions({
	questions,
	currentQuestionIndex,
	setCurrentQuestionIndex,
	resetValues,
	userData,
}) {
	const handleChange = (event, value) => {
		setCurrentQuestionIndex(value);
		resetValues();
	};

	useEffect(() => {
		const handleContextMenu = (event) => {
			event.preventDefault();
		};
		document.addEventListener('contextmenu', handleContextMenu);
		const handleCopyPaste = (event) => {
			event.preventDefault();
		};
		document.addEventListener('copy', handleCopyPaste);
		document.addEventListener('cut', handleCopyPaste);
		document.addEventListener('paste', handleCopyPaste);
		return () => {
			document.removeEventListener('copy', handleCopyPaste);
			document.removeEventListener('cut', handleCopyPaste);
			document.removeEventListener('paste', handleCopyPaste);
			document.removeEventListener('contextmenu', handleContextMenu);
		};
	}, []);

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: 'background.paper',
				display: 'flex',
				height: '90vh',
			}}
		>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={currentQuestionIndex}
				onChange={handleChange}
				sx={{
					borderRight: 1,
					borderColor: 'divider',
					color: '#224c84',
				}}
			>
				{questions.map((question, index) => (
					<Tooltip
						title={
							userData[index].submitted
								? 'You have already submitted the code.'
								: `Click to view Question ${index + 1}`
						}
						placement="right"
						arrow
						followCursor
					>
						{userData[index].submitted ? (
							<div>
								<Tab
									disabled
									label={`Q${index + 1}`}
									id={`vertical-tab-${index}`}
									style={{
										width: '20px',
									}}
								/>
							</div>
						) : (
							<Tab
								disabled={userData[index].submitted}
								label={`Q${index + 1}`}
								id={`vertical-tab-${index}`}
								style={{
									width: '20px',
								}}
							/>
						)}
					</Tooltip>
				))}
			</Tabs>

			<Stack spacing={2} width="100%" p={1} overflow={'scroll'}>
				<Typography>
					<div
						dangerouslySetInnerHTML={{
							__html: questions[currentQuestionIndex].question,
						}}
					></div>
				</Typography>
				{/* <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
                            Question
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: questions[currentQuestionIndex]
                                        .question,
                                }}
                            ></div>
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}

				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
							Description
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							<div
								dangerouslySetInnerHTML={{
									__html: questions[currentQuestionIndex].statement,
								}}
							></div>
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
							Constraints
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							<div
								dangerouslySetInnerHTML={{
									__html: questions[currentQuestionIndex].constraints,
								}}
							></div>
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
							Input Format
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							<div
								dangerouslySetInnerHTML={{
									__html: questions[currentQuestionIndex].input_format,
								}}
							></div>
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
							Output Format
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							<div
								dangerouslySetInnerHTML={{
									__html: questions[currentQuestionIndex].output_format,
								}}
							></div>
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
							Sample Test Cases
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{questions[currentQuestionIndex].sample_io.map((io, index) => (
							<Stack>
								<Typography fontWeight={'bold'} fontSize={'1rem'}>
									Example {index + 1}
								</Typography>
								<Typography>
									<b>Input: </b>
									{io.input}
								</Typography>
								<Typography>
									<b>Output: </b>
									{io.output}
								</Typography>
								{io.explanation && (
									<Typography mb={1}>
										<b>Explanation: </b>
										<div
											dangerouslySetInnerHTML={{
												__html: io.explanation,
											}}
										></div>
									</Typography>
								)}
							</Stack>
						))}
					</AccordionDetails>
				</Accordion>
			</Stack>
		</Box>
	);
}
