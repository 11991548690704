import React, { useEffect, useState } from 'react';
import HeroSection from './components/HeroSection';
import { IconButton, Stack, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CalltoAction from './components/CalltoAction';
import Footer from './components/Footer';
import Services from './components/Services';
import TrackProgress from './components/TrackProgress';
import CalltoMobileAction from './components/CalltoMobileAction';
import FAQs from './components/FAQs';

const MockInterviewLandingPage = () => {
	const [showButton, setShowButton] = useState(false);
	useEffect(() => {
		AOS.init({
			once: true,
		});
		const handleScrollButtonVisibility = () => {
			window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
		};
		window.addEventListener('scroll', handleScrollButtonVisibility);

		return () => {
			window.removeEventListener('scroll', handleScrollButtonVisibility);
		};
	}, []);

	return (
		<Stack bgcolor={'#fff'}>
			{showButton && (
				<Stack zIndex={5}>
					<Tooltip title="Scroll to top" placement="top">
						<IconButton
							onClick={() => {
								window.scrollTo({ top: 0, behavior: 'smooth' });
							}}
							style={{
								position: 'fixed',
								bottom: 10,
								right: 5,
								color: '#224C84',
								backgroundColor: 'rgb(210, 236, 246, 0.5)',
								WebkitBackdropFilter: 'blur(5px)',
								backdropFilter: 'blur(5px)',
							}}
						>
							<KeyboardArrowUpIcon fontSize="large" />
						</IconButton>
					</Tooltip>
				</Stack>
			)}
			<HeroSection />
			<CalltoAction />
			<Services />
			<TrackProgress />
			<CalltoMobileAction />
			<FAQs />
			<Footer />
		</Stack>
	);
};

export default MockInterviewLandingPage;
